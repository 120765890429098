import React, { useEffect, useState } from "react";
import CardBox from "../../../components/Common/CardBox";
import { Input, Segmented } from "antd";
import { NavigationItem } from "../../../constants/type";
import ProcessTab from "./ProcessTab";
import ClosedTradeTab from "./ClosedTradeTab";
import { plainAxiosInstance } from "../../../services/axiosSetup";
import { APIs } from "../../../services/apis";
import { ErrorCatchValidator, ErrorMessageHandler } from "../../../utils/Common";
import { SUCCESS_FAILED } from "../../../constants";
import { ServerDetails } from "./type";
import LoadingComponent from "../../../components/Loading";

const NegativeEquityRecord = () => {
    const [currentActiveSegment, setCurrentActiveSegment] = useState<string>("process"); // For segment
    const [servers, setServers] = useState<ServerDetails[]>([]);
    const [isFilterLoaded, setIsFilterLoaded] = useState<boolean>(false);
    const [searchVal, setSearchVal] = useState<string>("");

    const SegmentList: NavigationItem[] = [
        { key: "process", title: "Negative Equity Process" },
        { key: "closedTrade", title: "Negative Equity Closed Trade" },
    ];

    useEffect(() => {
        plainAxiosInstance
            .get(`${APIs.RC_NEGATIVE_EQUITY_RECORD.GET_SERVERS}`)
            .then((res: any) => {
                if (res.status === 200) {
                    setServers(res.data);
                } else setServers([]);
            })
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("server list", SUCCESS_FAILED.FAILED_LOAD_DATA, err)))
            .finally(() => setIsFilterLoaded(true));
    }, []);

    return (
        <div className="negative-equity-record-container">
            <CardBox title="Negative Equity Record">
                <div className="nav-bar">
                    <Segmented
                        value={currentActiveSegment}
                        options={SegmentList.map((x: NavigationItem) => ({
                            label: x.title,
                            value: x.key,
                        }))}
                        onChange={(activeKey: any) => setCurrentActiveSegment(activeKey)}
                    />
                    <Input.Search
                        placeholder="Search"
                        enterButton
                        onSearch={(value: string) => setSearchVal(value)}
                        allowClear
                    />
                </div>
                <LoadingComponent tip="Loading filters..." spinning={!isFilterLoaded}>
                    {
                        currentActiveSegment === "process"
                            ? <ProcessTab servers={servers} searchValue={searchVal} />
                            : currentActiveSegment === "closedTrade"
                                ? <ClosedTradeTab servers={servers} searchValue={searchVal} />
                                : null
                    }
                </LoadingComponent>
            </CardBox>
        </div>
    );
};

export default NegativeEquityRecord;
import React from "react";
import { AttributeProps, BrandsList, CrmBrandList, RegulatorList, RestrictionsAuditLogChanges, TccAuditLogChanges, ToxicClientCheckAuditLogData, ToxicClientLabelProps, ToxicClientRestrictions, UsersList, accountRestrictionsAuditLog, entryPropsAuditLog, labelAttrsAuditLog, triggeredByAccounts } from "../../../../../constants/type";
import { Table, Timeline } from "antd";
import EmptyData from "../../../../../components/Common/Empty";
import { DTColProps, DataTableColumnRender } from "../../../../../utils/Common";
import { formatDistance } from "date-fns";
import { ArrowRightOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { isEmptyOrNull } from "../../../../../utils/string";
import { TIMEZONE_FORMATS, TIMEZONE_FORMATS_ENUM, TOXIC_CLIENT_CHECK_AUDIT_LOG_CATEGORY, TOXIC_CLIENT_CHECK_ENTRY_PICKUP_TEAM, TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM } from "../../../../../constants";
import moment from "moment";

interface ToxicClientCheckAuditLogProps {
    auditLogData: ToxicClientCheckAuditLogData[];
    users: UsersList[];
    tcLabelProps: ToxicClientLabelProps[];
    brands: BrandsList[];
    regulators: RegulatorList[];
    crmBrands: CrmBrandList[];
    tcLabelAttrs: AttributeProps[];
    tcRestrictions: ToxicClientRestrictions[];
    servers: any[];
};

const AuditLog = (props: ToxicClientCheckAuditLogProps) => {
    const restrictionsColumns = [
        DTColProps.Middle({
            title: "Restrictions",
            dataIndex: "restrictionCode",
            render: (restrictionCode: string) => {
                let thisRestriction = props.tcRestrictions.find((x: ToxicClientRestrictions) => x.restrictionCode === restrictionCode);
                return restrictionCode ? thisRestriction && thisRestriction.restrictionName : restrictionCode
            },
        }),
        DTColProps.Large({
            title: "Changes",
            dataIndex: "changes",
            render: (_: any, rowData: accountRestrictionsAuditLog) => (
                <>
                    <span className="before">
                        {isEmptyOrNull(rowData.before) ? "No value" : rowData.before} <>&nbsp;</>
                    </span>
                    <ArrowRightOutlined style={{ fontSize: 12 }} /> <>&nbsp;</>
                    {isEmptyOrNull(rowData.after) ? "No value" : rowData.after}
                </>
            ),
        }),
        DTColProps.Small({
            title: "Execution Status",
            dataIndex: "isDownstreamIntegrated",
            render: (isDownstreamIntegrated: boolean) => (
                <CheckCircleOutlined style={{ color: "#0ab76e" }} />
            ),
        },
            ["text-center"]
        ),
        // DTColProps.Middle({
        //     title: "Remarks",
        //     dataIndex: "remarks",
        // }),
    ];

    const generalEntryColumns = [
        DTColProps.Middle({
            title: "General",
            dataIndex: "displayName",
        }),
        DTColProps.XLarge({
            title: "Changes",
            dataIndex: "changes",
            render: (changes: any, rowData: entryPropsAuditLog) => {
                let compareContent: React.ReactNode = <></>;
                switch (rowData.propName) {
                    case "Symbols":
                        compareContent = (
                            <>
                                <span className="before">
                                    {isEmptyOrNull(rowData.before)
                                        ? "No value"
                                        : rowData.before.length > 0
                                            ? rowData.before.join(", ")
                                            : "No value"
                                    } <>&nbsp;</>
                                </span>
                                <ArrowRightOutlined style={{ fontSize: 12 }} /> <>&nbsp;</>
                                {isEmptyOrNull(rowData.after)
                                    ? "No value"
                                    : rowData.after.length > 0
                                        ? rowData.after.join(", ")
                                        : "No value"
                                }
                            </>
                        );
                        break;
                    case "Status":
                        compareContent = (
                            <>
                                <span className="before">
                                    {rowData.before === 0 ? "Following" : "Done"} <>&nbsp;</>
                                </span>
                                <ArrowRightOutlined style={{ fontSize: 12 }} /> <>&nbsp;</>
                                {rowData.after === 0 ? "Following" : "Done"}
                            </>
                        );
                        break;
                    case "BrandId":
                        let beforeBrand = props.brands.find((b: BrandsList) => b.id === rowData.before);
                        let afterBrand = props.brands.find((b: BrandsList) => b.id === rowData.after);
                        compareContent = (
                            <>
                                <span className="before">
                                    {isEmptyOrNull(beforeBrand) ? "-" : beforeBrand?.brand} <>&nbsp;</>
                                </span>
                                <ArrowRightOutlined style={{ fontSize: 12 }} /> <>&nbsp;</>
                                {isEmptyOrNull(afterBrand) ? "-" : afterBrand?.brand}
                            </>
                        );
                        break;
                    case "CrmBrandId":
                        let beforeCrmBrand = props.crmBrands.find((x: CrmBrandList) => x.id === rowData.before);
                        let afterCrmBrand = props.crmBrands.find((x: CrmBrandList) => x.id === rowData.after);
                        compareContent = (
                            <>
                                <span className="before">
                                    {isEmptyOrNull(beforeCrmBrand) ? "-" : beforeCrmBrand?.brand} <>&nbsp;</>
                                </span>
                                <ArrowRightOutlined style={{ fontSize: 12 }} /> <>&nbsp;</>
                                {isEmptyOrNull(afterBrand) ? "-" : afterCrmBrand?.brand}
                            </>
                        );
                        break;
                    case "RegulatorId":
                        let beforeRegulator = props.regulators.find((x: RegulatorList) => x.id === rowData.before);
                        let afterRegulator = props.regulators.find((x: RegulatorList) => x.id === rowData.after);
                        compareContent = (
                            <>
                                <span className="before">
                                    {(isEmptyOrNull(beforeRegulator) || rowData.before === 0) ? "No value" : beforeRegulator?.name} <>&nbsp;</>
                                </span>
                                <ArrowRightOutlined style={{ fontSize: 12 }} /> <>&nbsp;</>
                                {(isEmptyOrNull(afterRegulator) || rowData.after === 0) ? "No value" : afterRegulator?.name}
                            </>
                        );
                        break;
                    case "Inspector":
                        let beforeUser = props.users.find((x: UsersList) => x.id === rowData.before);
                        let afterUser = props.users.find((x: UsersList) => x.id === rowData.after);
                        compareContent = (
                            <>
                                <span className="before">
                                    {isEmptyOrNull(beforeUser) ? "-" : `${beforeUser?.name} | ${beforeUser?.email}`} <>&nbsp;</>
                                </span>
                                <ArrowRightOutlined style={{ fontSize: 12 }} /> <>&nbsp;</>
                                {isEmptyOrNull(afterUser) ? "-" : `${afterUser?.name} | ${afterUser?.email}`}
                            </>
                        );
                        break;
                    case "PickupTeam":
                        compareContent = (
                            <>
                                <span className="before">
                                    {TOXIC_CLIENT_CHECK_ENTRY_PICKUP_TEAM[rowData.before]} <>&nbsp;</>
                                </span>
                                <ArrowRightOutlined style={{ fontSize: 12 }} /> <>&nbsp;</>
                                {TOXIC_CLIENT_CHECK_ENTRY_PICKUP_TEAM[rowData.after]}
                            </>
                        );
                        break;
                    case "LabelId":
                        let beforeLabel = props.tcLabelProps.find((x: ToxicClientLabelProps) => x.labelId === rowData.before);
                        let afterLabel = props.tcLabelProps.find((x: ToxicClientLabelProps) => x.labelId === rowData.after);
                        compareContent = (
                            <>
                                <span className="before">
                                    {isEmptyOrNull(beforeLabel) ? rowData.before : beforeLabel?.labelName} <>&nbsp;</>
                                </span>
                                <ArrowRightOutlined style={{ fontSize: 12 }} /> <>&nbsp;</>
                                {isEmptyOrNull(afterLabel) ? rowData.after : afterLabel?.labelName}
                            </>
                        );
                        break;
                    default:
                        compareContent = (
                            <>
                                <span className="before">
                                    {isEmptyOrNull(rowData.before) ? "No value" : rowData.before} <>&nbsp;</>
                                </span>
                                <ArrowRightOutlined style={{ fontSize: 12 }} /> <>&nbsp;</>
                                {isEmptyOrNull(rowData.after) ? "No value" : rowData.after}
                            </>
                        );
                        break;
                };
                return (
                    <div>
                        {compareContent}
                    </div>
                )
            }
        }),
    ];

    const labelAttrsColumns = [
        DTColProps.Middle({
            title: "Attributes",
            dataIndex: "attrId",
            render: (attrId: number) => {
                let currAttr = props.tcLabelAttrs.find((y: AttributeProps) => y.attrId === attrId);
                return (
                    <span>
                        {currAttr === undefined
                            ? attrId
                            : (currAttr.uiElementType === TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.DatePicker || currAttr.uiElementType === TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.DateTimePicker)
                                ? `${currAttr.attrName} (${currAttr.timeZoneFormat && TIMEZONE_FORMATS[currAttr.timeZoneFormat]})`
                                : currAttr.attrName
                        }
                    </span>
                );
            }
        }),
        DTColProps.XLarge({
            title: "Changes",
            dataIndex: "changes",
            render: (changes: any, currAttrLog: labelAttrsAuditLog) => {
                let compareContent: React.ReactNode = <></>;
                let currAttr = props.tcLabelAttrs.find((y: AttributeProps) => y.attrId === currAttrLog.attrId);
                switch (currAttr?.uiElementType) {
                    case TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.DropdownMenu:
                    case TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.Checkbox:
                        let beforeArr = JSON.parse(currAttrLog.before);
                        let afterArr = JSON.parse(currAttrLog.after);
                        compareContent = (
                            <>
                                <span className="before">
                                    {isEmptyOrNull(currAttrLog.before)
                                        ? "No value"
                                        : beforeArr.length > 0
                                            ? beforeArr.join(", ")
                                            : "No value"} <>&nbsp;</>
                                </span>
                                <ArrowRightOutlined style={{ fontSize: 12 }} /> <>&nbsp;</>
                                {isEmptyOrNull(currAttrLog.after)
                                    ? "No value"
                                    : afterArr.length > 0
                                        ? afterArr.join(", ")
                                        : "No value"}
                            </>
                        );
                        break;
                    case TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.ToggleSwitcher:
                        compareContent = (
                            <>
                                <span className="before">
                                    {currAttrLog.before === "True" ? "Yes" : "No"} <>&nbsp;</>
                                </span>
                                <ArrowRightOutlined style={{ fontSize: 12 }} /> <>&nbsp;</>
                                {currAttrLog.after === "True" ? "Yes" : "No"}
                            </>
                        );
                        break;
                    case TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.DateTimePicker:
                        compareContent = (
                            <>
                                <span className="before">
                                    {isEmptyOrNull(currAttrLog.before)
                                        ? "No value"
                                        : currAttr.timeZoneFormat === TIMEZONE_FORMATS_ENUM.Server
                                            ? DataTableColumnRender.DateTime_ServerTime(currAttrLog.before)
                                            : currAttr.timeZoneFormat === TIMEZONE_FORMATS_ENUM.Local
                                                ? DataTableColumnRender.DateTime(currAttrLog.before)
                                                : moment.parseZone(currAttrLog.before, "YYYY-MM-DDTHH:mm:ss").utc(true).format("YYYY-MM-DD HH:mm:ssZZ")
                                    } <>&nbsp;</>
                                </span>
                                <ArrowRightOutlined style={{ fontSize: 12 }} /> <>&nbsp;</>
                                {isEmptyOrNull(currAttrLog.after)
                                    ? "No value"
                                    : currAttr.timeZoneFormat === TIMEZONE_FORMATS_ENUM.Server
                                        ? DataTableColumnRender.DateTime_ServerTime(currAttrLog.after)
                                        : currAttr.timeZoneFormat === TIMEZONE_FORMATS_ENUM.Local
                                            ? DataTableColumnRender.DateTime(currAttrLog.after)
                                            : moment.parseZone(currAttrLog.after, "YYYY-MM-DDTHH:mm:ss").utc(true).format("YYYY-MM-DD HH:mm:ssZZ")
                                }
                            </>
                        );
                        break;
                    default:
                        compareContent = (
                            <>
                                <span className="before">
                                    {isEmptyOrNull(currAttrLog.before) ? "No value" : currAttrLog.before} <>&nbsp;</>
                                </span>
                                <ArrowRightOutlined style={{ fontSize: 12 }} /> <>&nbsp;</>
                                {isEmptyOrNull(currAttrLog.after) ? "No value" : currAttrLog.after}
                            </>
                        );
                        break;
                };
                return (
                    <div>
                        {compareContent}
                    </div>
                )
            }
        }),
    ];

    return (
        <div className="toxic-client-check-audit-log-container">
            {
                props.auditLogData.length > 0 ? (
                    <Timeline>
                        {
                            props.auditLogData.map((currCategoryLog: ToxicClientCheckAuditLogData, i: number) => {
                                if (currCategoryLog.logCategory === TOXIC_CLIENT_CHECK_AUDIT_LOG_CATEGORY.ENTRY) {
                                    let currLog = currCategoryLog && currCategoryLog.log;
                                    let currChangesObj = currLog && currLog.changes as TccAuditLogChanges;
                                    let thisUser = props.users.find((x: UsersList) => x.id === currLog.createdBy);

                                    let newEntryPropsData: any[] = [];
                                    if (!isEmptyOrNull(currLog.changes)) {
                                        if (currChangesObj.entryProps.length > 0) {
                                            currChangesObj.entryProps.forEach((currEntryLog: entryPropsAuditLog, i: number) => {
                                                newEntryPropsData.push({
                                                    ...currEntryLog,
                                                    key: i
                                                });
                                            });
                                        };
                                    };
                                    return (
                                        <Timeline.Item key={`entry-${currLog.id}-item-${i}`}>
                                            <div className="timeline-box">
                                                <div className="timeline-title">
                                                    <b>{thisUser && thisUser.name ? thisUser.name : currLog.createdBy}</b> {currLog.changes === null ? "created" : "updated"} toxic client entry.
                                                </div>
                                                {currLog.changes !== null && (
                                                    <div className="timeline-changes">
                                                        {currChangesObj.entryProps.length > 0 && (
                                                            <div className="timeline-items-title-div">
                                                                <span className="entry-title">General Log</span>
                                                            </div>
                                                        )}
                                                        {currChangesObj.entryProps.length > 0 && (
                                                            <div className="entry-table-div">
                                                                <Table
                                                                    rowKey={"key"}
                                                                    columns={generalEntryColumns}
                                                                    dataSource={newEntryPropsData}
                                                                    bordered
                                                                    size="small"
                                                                    pagination={false}
                                                                />
                                                            </div>
                                                        )}
                                                        {currChangesObj.labelAttrs.length > 0 && (
                                                            <div className="timeline-items-title-div">
                                                                <span className="attr-title">Toxic Client Label Attribute Log</span>
                                                            </div>
                                                        )}
                                                        {currChangesObj.labelAttrs.length > 0 && (
                                                            <div className="entry-table-div">
                                                                <Table
                                                                    rowKey={"attrId"}
                                                                    columns={labelAttrsColumns}
                                                                    dataSource={currChangesObj.labelAttrs}
                                                                    bordered
                                                                    size="small"
                                                                    pagination={false}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                                <div className="timeline-datetime-info">
                                                    {formatDistance(new Date(currLog.createdDateUtc), new Date(), {
                                                        includeSeconds: true,
                                                    })}{" "}
                                                    ago - {DataTableColumnRender.DateTime(currLog.createdDateUtc)}
                                                </div>
                                            </div>
                                        </Timeline.Item>
                                    );
                                } else if (currCategoryLog.logCategory === TOXIC_CLIENT_CHECK_AUDIT_LOG_CATEGORY.RESTRICTION) {
                                    let currLog = currCategoryLog && currCategoryLog.log;
                                    let currChangesObj = currLog && currLog.changes as RestrictionsAuditLogChanges;
                                    let thisUser = props.users.find((x: UsersList) => x.id === currLog.createdBy);
                                    return (
                                        <Timeline.Item key={`client-${currLog.batchId}-item-${i}`}>
                                            <div className="timeline-box">
                                                <div className="timeline-title">
                                                    <b>{thisUser && thisUser.name ? thisUser.name : currLog.createdBy}</b> updated toxic client restrictions.
                                                </div>
                                                <div className="timeline-changes">
                                                    {currChangesObj.accountRestrictions.length > 0 && (
                                                        <div className="timeline-items-title-div">
                                                            <span className="restrict-title">Account Restrictions Log</span>
                                                        </div>
                                                    )}
                                                    {currChangesObj.accountRestrictions.length > 0 && (
                                                        <div className="restriction-table-div">
                                                            <Table
                                                                rowKey={"id"}
                                                                columns={restrictionsColumns}
                                                                dataSource={currChangesObj.accountRestrictions}
                                                                bordered
                                                                size="small"
                                                                pagination={false}
                                                            />
                                                        </div>
                                                    )}
                                                    {currChangesObj.clientRestrictions.length > 0 && (
                                                        <div className="timeline-items-title-div">
                                                            <span className="restrict-title">Client Restrictions Log</span>
                                                        </div>
                                                    )}
                                                    {currChangesObj.clientRestrictions.length > 0 && (
                                                        <div className="restriction-table-div">
                                                            <Table
                                                                rowKey={"id"}
                                                                columns={restrictionsColumns}
                                                                dataSource={currChangesObj.clientRestrictions}
                                                                bordered
                                                                size="small"
                                                                pagination={false}
                                                                {...(currChangesObj.clientRestrictions.length > 0 && currChangesObj.clientRestrictions[0].triggeredByAccounts !== null && {
                                                                    footer: () => {
                                                                        if (isEmptyOrNull(currChangesObj.clientRestrictions[0].triggeredByAccounts)) return <><>&nbsp;</></>;
                                                                        else return (
                                                                            <span className="client-restriction-footer">Triggered By: <>&nbsp;</>
                                                                                [ {
                                                                                    currChangesObj.clientRestrictions[0].triggeredByAccounts
                                                                                        .map((x: triggeredByAccounts) => {
                                                                                            let thisServer = props.servers.find((y: any) => y.value === x.serverId);
                                                                                            let thisBrand = props.brands.find((y: any) => y.id === x.brandId);
                                                                                            return (
                                                                                                `Account ID: ${x.accountId}, Server: ${x.serverId ? thisServer && thisServer.text : x.serverId}, Brand:  ${isEmptyOrNull(thisBrand) ? "" : thisBrand?.brand}`
                                                                                            );
                                                                                        })
                                                                                        .join(' ], [ ')
                                                                                } ]
                                                                            </span>
                                                                        );
                                                                    }
                                                                })}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="timeline-datetime-info">
                                                    {formatDistance(new Date(currLog.createdDateUtc), new Date(), {
                                                        includeSeconds: true,
                                                    })}{" "}
                                                    ago - {DataTableColumnRender.DateTime(currLog.createdDateUtc)}
                                                </div>
                                            </div>
                                        </Timeline.Item>
                                    );
                                }
                            })
                        }
                    </Timeline>
                ) : (
                    <EmptyData />
                )
            }
        </div>
    )
}

export default AuditLog;
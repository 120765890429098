import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import LoadingComponent from "../../components/Loading";
import { initialize, setProfileInfo } from "../../services/api";
import { removeSource } from "../../services/localStorage";
import { SUCCESS_FAILED } from "../../constants";
import { ErrorCatchValidator, ErrorMessageHandler } from "../../utils/Common";

function SSORedirection() {
    const [searchParams] = useSearchParams();

    useEffect(() => {
        initialize(searchParams.get("token") || "", searchParams.get("refreshToken") || "");
        setProfileInfo({ manualCatch: 1 })
            .then(() => {
                removeSource();
                window.location.href = "/dashboard";
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => {
                    if (err.response.status === 403) {
                        window.location.href = "/forbidden";
                    } else {
                        ErrorMessageHandler("my account data", SUCCESS_FAILED.FAILED_LOAD_DATA, err);
                    }
                })
            );
        return () => {};
    }, []);

    return (
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", height: "100vh", alignItems: "center", overflow: "hidden" }}>
            <LoadingComponent tip="Processing..." />
        </div>
    );
}

export default SSORedirection;

import { Button, List } from "antd";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CardBox from "../../../components/Common/CardBox";
import { apiRequest, APIs } from "../../../services/apiConfig";
import { ClientAccountsInfo } from "../../../constants/type";
import { EyeOutlined } from "@ant-design/icons";
import { ACCOUNT_PROFILE_FROM_MODULE, getAccountProfileId, getAccountProfileParams, getNavigationUrl } from "../AccountProfileHelper";
import { ErrorCatchValidator } from "../../../utils/Common";

interface ClientAccountsProps {
    client360Id: string;
    clientId: number;
    regulatorId: number;
    crmServerId: number;
}

const ClientAccounts = (props: ClientAccountsProps) => {
    let navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<ClientAccountsInfo[]>([]);

    const getClientAccsList = () => {
        apiRequest(APIs.GET_CLIENT_ACCOUNTS, {
            clientId: props.clientId,
            regulatorId: props.regulatorId,
            crmServerId: props.crmServerId,
        })
            .then((data: any) => {
                let markupData = data.map((x: ClientAccountsInfo) => {
                    x.id = getAccountProfileId(
                        x.serverId,
                        x.accountId,
                        ACCOUNT_PROFILE_FROM_MODULE.CLIENTS_LIST,
                        "",
                        props.clientId,
                        props.regulatorId,
                        props.crmServerId
                    );
                    return x;
                });
                setData(markupData);
            })
            .catch(error => {
                ErrorCatchValidator(error, (err: any) => console.log(err));
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        getClientAccsList();
        return () => {};
    }, []);

    return (
        <CardBox title={"Account List"} small>
            <List
                itemLayout="vertical"
                size="large"
                pagination={{
                    pageSize: 5,
                }}
                dataSource={data}
                renderItem={item => (
                    <List.Item key={item.id}>
                        <div className="item-container">
                            <div className="item">
                                <div className="title">Account ID :</div>
                                <div className="subtitle">{item.accountId}</div>
                            </div>
                            <div className="item">
                                <div className="title">Brand :</div>
                                <div className="subtitle">{item.brand}</div>
                            </div>
                            <div className="item">
                                <div className="title">Server :</div>
                                <div className="subtitle">{item.server}</div>
                            </div>
                            <div className="buttons-group">
                                <Link
                                    to={getNavigationUrl(
                                        ACCOUNT_PROFILE_FROM_MODULE.CLIENTS_LIST,
                                        getAccountProfileParams(
                                            item.serverId,
                                            item.accountId,
                                            ACCOUNT_PROFILE_FROM_MODULE.CLIENTS_LIST,
                                            "",
                                            props.clientId,
                                            props.regulatorId,
                                            props.crmServerId
                                        )
                                    )}
                                >
                                    <Button
                                        type="text"
                                        icon={<EyeOutlined />}
                                        onClick={event => {
                                            event.preventDefault();
                                            navigate(
                                                getNavigationUrl(
                                                    ACCOUNT_PROFILE_FROM_MODULE.CLIENTS_LIST,
                                                    getAccountProfileParams(
                                                        item.serverId,
                                                        item.accountId,
                                                        ACCOUNT_PROFILE_FROM_MODULE.CLIENTS_LIST,
                                                        "",
                                                        props.clientId,
                                                        props.regulatorId,
                                                        props.crmServerId
                                                    )
                                                )
                                            );
                                        }}
                                    />{" "}
                                </Link>
                            </div>
                        </div>
                    </List.Item>
                )}
            />
        </CardBox>
    );
};

export default ClientAccounts;

import { CloseOutlined, ExclamationCircleOutlined, MessageOutlined } from "@ant-design/icons";
import { Divider, Empty, Form, List, Modal } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { FormComponent } from "../../../components/FormComponent";
import LoadingComponent from "../../../components/Loading";
import { ComponentType, SUCCESS_FAILED } from "../../../constants";
import { REQUIRED_FIELD } from "../../../constants/errorMessage";
import { Comment } from "../../../constants/type";
import "../../../index.less";
import { apiRequest, APIs } from "../../../services/apiConfig";
import { DataTableColumnRender, ErrorCatchValidator, ErrorMessageHandler } from "../../../utils/Common";
import { ClientAccountComment } from "./clientAccountCommentsHistory";

interface ClientCommentsHistoryProps {
    clientId: number;
    regulatorId: number;
    crmServerId: number;
    disableUpdate?: boolean;
}

interface ClientComments extends ClientAccountComment {
    canModify: boolean;
    clientId: number;
    id: number;
    regulatorId: number;
    crmServerId: number;
}

const ClientCommentsHistory = (props: ClientCommentsHistoryProps) => {
    const [commentForm] = Form.useForm();
    const [visible, setVisible] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isAddAction, setIsAddAction] = useState<boolean>(false);
    const [data, setData] = useState<ClientComments[]>([]);
    let initialData = {
        comment: "",
    };
    const [comData] = useState<Comment>(initialData);

    const refetchClientCommentsHistory = () => {
        setIsLoading(true);
        apiRequest(APIs.GET_CLIENT_COMMENTS, {
            clientId: props.clientId,
            regulatorId: props.regulatorId,
            crmServerId: props.crmServerId,
        })
            .then((data: any) => {
                setData(data);
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("comments history", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            )
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        if (props?.clientId < 0) {
            setIsLoading(false);
        } else {
            refetchClientCommentsHistory();
        }
        return () => {};
    }, []);

    const deleteModalContent = (values: ClientComments) => {
        Modal.confirm({
            icon: <ExclamationCircleOutlined />,
            title: "Are you sure you want to delete this comment?",
            width: "50%",
            content: (
                <div className="delete-modal-comment">
                    <div className="delete-modal-comment-box">
                        <div className="delete-modal-comment-title">{values?.comment}</div>
                        <div className="delete-modal-comment-description">
                            {DataTableColumnRender.DateTime(values?.createdDateUtc)} - {values?.user}
                        </div>
                    </div>
                </div>
            ),
            onOk() {
                apiRequest(APIs.DELETE_CLIENT_COMMENT, {
                    clientCommentId: values.id,
                })
                    .then((data: any) => {
                        ErrorMessageHandler("Comment", SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                        refetchClientCommentsHistory();
                    })
                    .catch((error) =>
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("comment", SUCCESS_FAILED.FAILED_DELETE_DATA, err))
                    );
            },
            onCancel() {},
        });
    };

    return (
        <>
            <div className="box-header">
                <h1 className="profile-bigTitle">Comment History</h1>
                {!props.disableUpdate && (
                    <MessageOutlined
                        style={{ fontSize: "1.25rem" }}
                        onClick={() => {
                            setVisible(true);
                            setIsAddAction(true);
                        }}
                    />
                )}
            </div>
            <Divider />

            <div className={`client-comments-history nice-scrollbar`}>
                {isLoading ? (
                    <div className="loading-container">
                        <LoadingComponent tip="Loading..." />
                    </div>
                ) : data?.length > 0 ? (
                    <List>
                        {data.map((currData: any) => {
                            let isLineThrough = currData.isDeleted;
                            return (
                                <List.Item key={currData?.id}>
                                    <div className="list-box">
                                        <div className="list-comment">
                                            <div className={`list-title ${isLineThrough ? "text-line-throught" : ""}`}>{currData?.comment}</div>
                                            {currData.canModify && (
                                                <CloseOutlined style={{ color: "#8e8e8e" }} onClick={() => deleteModalContent(currData)} />
                                            )}
                                        </div>
                                        <div className={`list-description ${isLineThrough ? "text-line-throught" : ""}`}>
                                            {DataTableColumnRender.DateTime(currData?.createdDateUtc)} - {currData?.user}
                                        </div>
                                    </div>
                                </List.Item>
                            );
                        })}
                    </List>
                ) : (
                    <Empty />
                )}
                <Modal
                    open={visible}
                    width={"50%"}
                    title={"Create New Comment"}
                    onCancel={() => {
                        commentForm.resetFields();
                        setVisible(false);
                    }}
                    onOk={() => {
                        commentForm.validateFields().then((values: any) => {
                            commentForm.resetFields();
                            apiRequest(APIs.CREATE_CLIENT_COMMENT, {
                                clientId: props.clientId,
                                regulatorId: props.regulatorId,
                                crmServerId: props.crmServerId,
                                ...values,
                            })
                                .then((data: any) => {
                                    ErrorMessageHandler("New comment", SUCCESS_FAILED.SUCCESS_CREATE_DATA);
                                    refetchClientCommentsHistory();
                                    setVisible(false);
                                })
                                .catch((error: any) =>
                                    ErrorCatchValidator(error, (err: any) =>
                                        ErrorMessageHandler("new comment", SUCCESS_FAILED.FAILED_CREATE_DATA, err)
                                    )
                                );
                        });
                    }}
                >
                    <Form form={commentForm} labelCol={{ span: 4.5 }} wrapperCol={{ span: 20 }} layout="horizontal" initialValues={comData}>
                        {!isAddAction && <FormComponent label={""} name={"id"} extra={{ type: ComponentType.hidden, value: "" }} />}
                        <FormComponent
                            label="Comment"
                            name={"comment"}
                            extra={{
                                type: ComponentType.textarea,
                                value: "",
                                rules: [
                                    {
                                        required: true,
                                        message: REQUIRED_FIELD,
                                    },
                                ],
                                inputProps: {
                                    showCount: true,
                                    maxLength: 1000,
                                    rows: 10,
                                },
                            }}
                        />
                    </Form>
                </Modal>
            </div>
        </>
    );
};

export default ClientCommentsHistory;

import { LineChartOutlined } from "@ant-design/icons";
import { Button, Modal, Table, Form, DatePicker, Typography, Space } from "antd";
import useRCMidBiasSolved from "../../../hooks/useRCMidBiasSolved";
import "./midbiastable.css";
import { useState } from "react";
import { plainAxiosInstance } from "../../../services/axiosSetup";
import { APIs } from "../../../services/apis";
import MidBiasLineChart from "./MidBiasLineChart";
import moment from "moment";
import BasicFilter from "../../../components/BasicFilter";
const MidBiasSolvedTable = () => {
    const [from, setFrom] = useState<Date>(new Date());
    const [to, setTo] = useState<Date>(new Date());
    const { RangePicker } = DatePicker;
    const [form] = Form.useForm();
    const { Item } = Form;
    const { Text } = Typography;
    const initFormValue = {
        // use from and to value;
        range: [moment(), moment()],
        // range: [moment().subtract(1, "days"), moment()],
    };
    // derived from the difference between the two dates, output to total minutes
    const duration = moment(to).diff(moment(from)) / 60000;
    // console.log("duration", duration);
    // console.log(
    //     "dog",
    //     moment(
    //         // today date without time
    //         moment().format("YYYY-MM-DD")
    //     ).diff(moment(from)) / 60000
    // );

    const { massagedData } = useRCMidBiasSolved({ duration, from, to });
    const detailColumns = [
        {
            title: "Line",
            align: "center",
            dataIndex: "Action",
            render: (text: any, data: any) => (
                <Button className="m-1" type="primary" icon={<LineChartOutlined />} onClick={e => showBiasLine(data)} />
            ),
        },
        {
            title: "Datetime",
            dataIndex: "dateTimeStr",
            align: "left",
        },
        {
            title: "Server",
            dataIndex: "server",
            align: "left",
        },

        {
            title: "Symbol",
            dataIndex: "symbol",
            align: "left",
        },
        {
            title: "Compare Server",
            dataIndex: "compareServer",
            align: "left",
        },
        // {
        //     title: "Compare Symbol",
        //     dataIndex: "compareSymbol",
        //     align: "left",
        // },
        {
            title: "Max score",
            dataIndex: "showMaxScore",
            align: "right",
        },
        {
            title: "Score Sum",
            dataIndex: "showScoreSum",
            align: "right",
        },
        {
            title: "Correlation",
            dataIndex: "showPPMCC",
            align: "right",
        },
        {
            title: "Solved User",
            dataIndex: "solvedUser",
            align: "right",
        },
        {
            title: "Solved Time",
            dataIndex: "solvedTimeStr",
            align: "right",
        },

        // {
        //     title: "Solved",
        //     align: "center",
        //     dataIndex: "Action",
        //     render: (text, data, index) => (
        //         <>
        //             <Button
        //                 className="m-1"
        //                 type="primary"
        //                 icon={<CheckOutlined />}
        //                 onClick={(e) => solvedMid(data, index)}
        //             >

        //             </Button>
        //         </>
        //     ),
        // },
    ];

    const [biasLineCompareData, setBiasLineCompareData] = useState(null);
    const [biasLineTitle, setBiasLineTitle] = useState("");

    const showBiasLine = async (data: any) => {
        const requestStr =
            data["dateTimeStr"].substring(0, data["dateTimeStr"].length - 3) +
            "," +
            data["server"] +
            "," +
            data["symbol"] +
            "," +
            data["compareServer"] +
            "," +
            data["compareSymbol"] +
            ",1";
        const formData = new FormData();
        formData.append("keyStr", requestStr);
        const resp = await plainAxiosInstance.post(`${APIs.RC_MIDBIAS.GET_MIDBIAS_CHART}`, formData);

        console.log("【MidBiasDataSummary.showLine】resp : ");
        console.log(resp);
        setBiasLineCompareData(resp.data);
        setBiasLineTitle(
            data["server"] + "-" + data["symbol"] + " vs " + data["compareServer"] + "-" + data["compareSymbol"] + " " + data["dateTimeStr"]
        );
        // setBiasShow(true);
    };
    return (
        <Space direction="vertical" style={{ width: "100%" }}>
            <BasicFilter>
                <Form
                    form={form}
                    layout="vertical"
                    initialValues={initFormValue}
                    requiredMark={true}
                    onFinish={data => {
                        console.log(data);
                    }}
                >
                    <Text>Date Range *</Text>
                    <Item
                        style={{ margin: 0, padding: 0 }}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        required
                        name="range"
                        rules={[{ required: true, message: "Please select date range" }]}
                    >
                        <RangePicker
                            style={{ width: "100%" }}
                            // disable selecting the day after today, and 20 days before today
                            disabledDate={current => {
                                return current && (current > moment().endOf("day") || current < moment().subtract(31, "days").startOf("day"));
                            }}
                            onChange={data => {
                                form.setFieldsValue({ range: data });
                                // setFrom to Date format
                                if (data?.[0]) {
                                    setFrom(moment(data?.[0]?.format("YYYYMMDD")).toDate());
                                }
                                if (data?.[1]) {
                                    setTo(moment(data?.[1]?.format("YYYYMMDD")).toDate());
                                }
                            }}
                        />
                    </Item>
                </Form>
            </BasicFilter>
            <Table
                // loading={loading}
                // @ts-ignore
                columns={detailColumns}
                dataSource={massagedData || []}
                size="small"
                rowKey={record =>
                    record.server + "_" + record.symbol + "_" + record.compareServer + "_" + record.compareSymbol + "_" + record.dateTimeStr
                }
                rowClassName={(record, index) =>
                    record.ppmcc < 80 || record.scoreMax > 15
                        ? "bg-red-800"
                        : record.ppmcc < 95 && record.ppmcc > 80 && (record.scoreSum > 50 || record.scoreMax > 8)
                        ? "bg-yellow-700"
                        : ""
                }
                pagination={{
                    defaultPageSize: 20,
                    pageSizeOptions: ["10", "20", "50", "100", "500", "1000"],
                }}
                // pagination={defaultPagination}
            />
            {biasLineCompareData && (
                <Modal
                    open={biasLineCompareData !== null}
                    closable={true}
                    onCancel={() => {
                        setBiasLineCompareData(null);
                        setBiasLineTitle("");
                    }}
                    width={1080}
                    footer={null}
                    title={biasLineTitle}
                >
                    <MidBiasLineChart biaslineCompareData={biasLineCompareData} />
                </Modal>
            )}
        </Space>
    );
};

export default MidBiasSolvedTable;

import { Button, Modal, Space, Table, Tag, notification } from "antd";
import React, { useState } from "react";
import useRCIssuesLog, { useRCIssuesLogMutate } from "../../../../hooks/useRCIssuesLog";
import MessageCard from "../../components/MessageCard";
import { colorMap, thousandSeparator, toFixed } from "../../StatusMonitor/statusUtils";

const columns = [
    {
        title: "Server",
        dataIndex: "server",
        sorter: (a: any, b: any) => {
            // sort alphabetically and length
            if (a.server?.toLowerCase() < b.server?.toLowerCase()) {
                return -1;
            }
            if (a.server?.toLowerCase() > b.server?.toLowerCase()) {
                return 1;
            }
            return 0;
        },
    },
    {
        title: "Login",
        dataIndex: "login",
        sorter: (a: any, b: any) => a.login - b.login,
        render: (text: any, data: any) => <div style={{ textAlign: "right" }}>{text}</div>,
    },
    {
        title: "Balance",
        dataIndex: "balance",
        sorter: (a: any, b: any) => a.balance - b.balance,
        render: (text: any, data: any) => <div style={{ textAlign: "right" }}>{toFixed(thousandSeparator(text), 2)}</div>,
    },
    {
        title: "Count",
        dataIndex: "alarmCount",
        sorter: (a: any, b: any) => a.alarmCount - b.alarmCount,
        render: (text: any, data: any) => <div style={{ textAlign: "right" }}>{thousandSeparator(text)}</div>,
    },
    {
        title: "State",
        dataIndex: "state",
        sorter: (a: any, b: any) => a.state - b.state,
        render: (text: any, data: any) => (
            <Tag color={text === 1 ? colorMap.info : text === 2 ? colorMap.warning : colorMap.error}>
                {text === 1 ? "Minor" : text === 2 ? "Warning" : "Serious"}
            </Tag>
        ),
    },
    {
        title: "Count Down(Min)",
        dataIndex: "diffMinutes",
        sorter: (a: any, b: any) => a.diffMinutes - b.diffMinutes,
        render: (text: any, data: any) => <div style={{ textAlign: "right" }}>{thousandSeparator(text)}</div>,
    },
    {
        title: "Error",
        dataIndex: "exceptionStatus",
        sorter: (a: any, b: any) => a.exceptionStatus - b.exceptionStatus,
        render: (text: any, data: any) =>
            text === 3 ? "User Not Found" : text === 2 ? "Brand Setting Not Found" : text === 1 ? "Change Password Error" : "-",
    },
    {
        title: "Job Start Time",
        dataIndex: "startTime",
        sorter: (a: any, b: any) => {
            // sort alphabetically and length
            if (a.startTime?.toLowerCase() < b.startTime?.toLowerCase()) {
                return -1;
            }
            if (a.startTime?.toLowerCase() > b.startTime?.toLowerCase()) {
                return 1;
            }
            return 0;
        },
    },
    {
        title: "Job End time",
        dataIndex: "endTime",
        sorter: (a: any, b: any) => {
            // sort alphabetically and length
            if (a.endTime?.toLowerCase() < b.endTime?.toLowerCase()) {
                return -1;
            }
            if (a.endTime?.toLowerCase() > b.endTime?.toLowerCase()) {
                return 1;
            }
            return 0;
        },
    },
];

const NoMoneyTable = () => {
    const { rcIssuesLog, isLoading, rcIssuesLogError } = useRCIssuesLog("NO_MONEY");
    const { deleteIssueLogNoMoney } = useRCIssuesLogMutate({ type: "NO_MONEY" });
    // console.log(rcIssuesLog);

    // massage rcIssuesLog with unique key

    const massagedData =
        rcIssuesLog?.length >= 1
            ? rcIssuesLog?.map((item: any, index: number) => {
                  return {
                      ...item,
                      key: item.server + "_" + item.login,
                  };
              })
            : [];

    // totalMinor, reduce and sum up all the state === 1
    const totalMinor = rcIssuesLog?.reduce((acc: any, cur: any) => {
        return acc + (cur.state === 1 ? 1 : 0);
    }, 0);
    const totalWarning = rcIssuesLog?.reduce((acc: any, cur: any) => {
        return acc + (cur.state === 2 ? 1 : 0);
    }, 0);
    const totalSerious = rcIssuesLog?.reduce((acc: any, cur: any) => {
        return acc + (cur.state === 3 ? 1 : 0);
    }, 0);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const onSelectChange = (selectedKeys: any) => {
        setSelectedRowKeys(selectedKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const handleDelete = async () => {
        try {
            const resp = await deleteIssueLogNoMoney(selectedRowKeys);
            if (resp) {
                setSelectedRowKeys([]);
                setOpenDeleteModal(false);
                notification.success({
                    message: "Success Deleted",
                    description: `Selected Alarm are deleted.`,
                });
            }
        } catch (error) {
            console.log("Error in deleting", error);
            notification.error({
                message: "Delete Failed",
                description: `Selected Alarm are not deleted. Error: ${error}`,
            });
        }
    };

    return (
        <Space direction="vertical" style={{ width: "100%", padding: "1rem" }}>
            {isLoading ? (
                <MessageCard type="info">Loading...</MessageCard>
            ) : rcIssuesLogError ? (
                <MessageCard type="error">{rcIssuesLogError}</MessageCard>
            ) : (
                <>
                    {rcIssuesLog?.length === 0 ? (
                        <MessageCard type="success">All good</MessageCard>
                    ) : (
                        <MessageCard type={totalSerious >= 1 ? "error" : totalWarning >= 1 ? "warning" : "warning"}>
                            <div style={{ display: "flex", gap: 12 }}>
                                {totalSerious >= 1 && <span>{totalSerious} Serious</span>}
                                {totalWarning >= 1 && <span>{totalWarning} Warning</span>}
                                {totalMinor >= 1 && <span>{totalMinor} Minor</span>}
                            </div>
                        </MessageCard>
                    )}

                    <Space>
                        {/* <Button disabled={selectedRowKeys.length <= 0} onClick={() => handleCheck()}>
                    Checked
                </Button> */}
                        <Button danger disabled={selectedRowKeys?.length <= 0} onClick={() => setOpenDeleteModal(true)}>
                            Delete
                        </Button>
                    </Space>
                    <Table
                        columns={columns}
                        dataSource={massagedData}
                        size="small"
                        rowSelection={rowSelection}
                        pagination={{ defaultPageSize: 50 }}
                    />
                    <Modal
                        title={"Delete Alarm"}
                        open={openDeleteModal}
                        onCancel={() => {
                            setOpenDeleteModal(false);
                        }}
                        onOk={() => handleDelete()}
                        okText="Delete"
                        okButtonProps={{ danger: true }}
                    >
                        <p>Are you sure you want to delete the selected alarm?</p>
                        <div>
                            {selectedRowKeys.map(each => {
                                return <Tag key={each}>{each}</Tag>;
                            })}
                        </div>
                    </Modal>
                </>
            )}
        </Space>
    );
};

export default NoMoneyTable;

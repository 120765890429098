import { useEffect, useMemo, useState } from "react";
import FlexiDataTable from "../../../../components/FlexiDataTable";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "../../../../constants";
import { CustomPaginationProps, FlexiDataColumnProps, FlexiDataTableCallbackProps, FlexiDataTableOptionsProps } from "../../../../constants/type";
import { APIs } from "../../../../services/apis";
import { plainAxiosInstance } from "../../../../services/axiosSetup";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "../../../../utils/Common";
import { DeleteOutlined, ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import CardBox from "../../../../components/Common/CardBox";
import SetReadOnlyModal from "./SetReadOnlyModal";
import { Modal } from "antd";
import DownloadModal from "./DownloadModal";

type Server = {
    text: string;
    value: number;
};
type RowToCancelType = {
    server: string;
    login: string;
};
const ReadOnlyPage = () => {
    const initialFilterParams = {
        login: "",
    };
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [datalist, setDatalist] = useState<any[]>([]);
    const [serverList, setServerList] = useState<Server[]>([]);
    const [filterParams, setFilterParams] = useState<{ login?: string }>(initialFilterParams);
    const [rerunFetch, setRerunFetch] = useState<boolean>(false);
    const [pagination, setPagination] = useState<CustomPaginationProps>({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const [openReadOnlyModal, setOpenReadOnlyModal] = useState<boolean>(false);
    const [openDownloadModal, setOpenDownloadModal] = useState<boolean>(false);
    const [selectedRowToCancel, setSelectedRowToCancel] = useState<RowToCancelType[]>([]);

    const columns: FlexiDataColumnProps[] = useMemo(
        () => [
            DTColProps.Small({
                title: "Server",
                dataIndex: "server",
                key: "server",
            }),
            DTColProps.Small({
                title: "Login",
                dataIndex: "login",
                key: "login",
                options: {
                    filter: {
                        type: ComponentType.text,
                        value: "",
                        inputProps: {
                            placeholder: "Please input login, seperate with comma",
                        },
                        callback(filterValue: string, rowData: any) {
                            const logins = filterValue.split(",").map(x => x.trim());
                            if (logins.length > 0) {
                                return logins.includes(rowData.login.toString());
                            }
                            return false;
                        },
                    },
                },
            }),
            DTColProps.Small({
                title: "Pre-Status",
                dataIndex: "preStatus",
                key: "preStatus",
            }),
            DTColProps.Small({
                title: "Post-Status",
                dataIndex: "postStatus",
                key: "postStatus",
            }),
            DTColProps.Small({
                title: "Operator",
                dataIndex: "operator",
                key: "operator",
            }),
            DTColProps.Small({
                title: "Reason",
                dataIndex: "reason",
                key: "reason",
            }),
            DTColProps.DateTime({
                title: "Create Date",
                dataIndex: "createTime",
                key: "createTime",
            }),
        ],
        []
    );

    useEffect(() => {
        setIsLoading(true);
        plainAxiosInstance
            .get(`${APIs.RC_READ_ONLY.GET_READ_ONLY_SERVER}`)
            .then(serverListRes => {
                const serverList = serverListRes.data.map((server: any) => ({ text: server.name, value: server.value }));
                setServerList(serverList);
                getReadOnlyList();
            })
            .catch(error => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("server list", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
            })
            .finally(() => setIsLoading(false));

        return () => {
            setIsLoading(false);
            setSelectedRowToCancel([]);
        };
    }, []);

    useEffect(() => {
        if (rerunFetch) {
            getReadOnlyList();
            setRerunFetch(false);
        }
    }, [rerunFetch]);

    function getReadOnlyList(_filterParams?: typeof filterParams) {
        const param = _filterParams ? _filterParams : { ...filterParams };
        const searchParam = {
            limit: pagination.pageSize,
            offset: ((pagination.current ?? 1) - 1) * (pagination.pageSize ?? 10),
            search: param.login,
        };

        setIsLoading(true);
        plainAxiosInstance
            .post(`${APIs.RC_READ_ONLY.GET_READ_ONLY}`, searchParam)
            .then((res: any) => {
                const data = res.data;
                if (data) {
                    setDatalist(data.rows);
                    setPagination(prev => ({ ...prev, total: data.total }));
                }
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("data", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
                setDatalist([]);
                setPagination(prev => ({ ...prev, total: 0 }));
            })
            .finally(() => setIsLoading(false));
    }

    async function confirmCancelReadOnly() {
        return new Promise<void>((resolve, reject) => {
            const form = new FormData();
            form.append("data", JSON.stringify(selectedRowToCancel));
            plainAxiosInstance
                .post(`${APIs.RC_READ_ONLY.POST_CANCEL_READ_ONLY}`, form)
                .then(() => {
                    resolve();
                    ErrorMessageHandler("ReadOnly", SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                    setRerunFetch(true);
                })
                .catch(error => {
                    reject(error);
                });
        }).catch(error => {
            ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("ReadOnly", SUCCESS_FAILED.FAILED_DELETE_DATA, err));
        });
    }

    function onCancelReadOnly() {
        if (selectedRowToCancel.length === 0) {
            return Modal.error({
                title: "No data selected",
                content: "Please select row to cancel ReadOnly",
            });
        }
        Modal.confirm({
            icon: <ExclamationCircleOutlined />,
            title: `Are you sure you want to cancel ReadOnly for these rows?`,
            content: (
                <div>
                    {selectedRowToCancel.map((row, index) => (
                        <div key={index}>
                            <span>
                                {row.server} - {row.login}
                            </span>
                        </div>
                    ))}
                </div>
            ),
            width: "30%",
            onOk() {
                return confirmCancelReadOnly();
            },
            onCancel() {},
        });
    }

    const componentCallback: FlexiDataTableCallbackProps = (type, cbData) => {
        switch (type) {
            case CALLBACK_KEY.HANDLE_PAGINATION_SORTING:
                setPagination(prev => ({ ...prev, ...cbData.pagination }));
                setRerunFetch(true);
                break;

            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                let fParams: typeof filterParams = {};
                Object.keys(cbData)
                    .filter(x => cbData[x] !== undefined && cbData[x].toString().length > 0)
                    .map(x => {
                        if (x === "login") {
                            fParams["login"] = cbData[x];
                        }
                        return x;
                    });
                setFilterParams(fParams);
                getReadOnlyList(fParams);
                setPagination(prev => ({ ...prev, current: 1 }));
                break;
            case CALLBACK_KEY.EXPORT_CSV_EXCEL:
                //do download
                setOpenDownloadModal(true);
                break;
            case CALLBACK_KEY.OTHERS:
                switch (cbData) {
                    case "setReadOnly":
                        setOpenReadOnlyModal(true);
                        break;
                    case "cancelReadOnly":
                        onCancelReadOnly();
                        break;
                    default:
                        break;
                }
                break;
            case CALLBACK_KEY.ROW_SELECTION_CALLBACK:
                const selectedRows = cbData.selectedRows ?? [];
                if (selectedRows.length > 0) {
                    setSelectedRowToCancel(selectedRows.map((row: any) => ({ server: row.server, login: row.login })));
                }
                break;
            default:
                break;
        }
    };

    const options: FlexiDataTableOptionsProps = useMemo(
        () => ({
            enableRowSelection: true,
            export: { text: "Download" },
            extraButtons: [
                ...[{ text: "Set ReadOnly", value: "setReadOnly", icon: <PlusOutlined /> }],
                ...(datalist.length > 0 ? [{ text: "Cancel ReadOnly", value: "cancelReadOnly", icon: <DeleteOutlined /> }] : []),
            ],
        }),
        [datalist]
    );

    return (
        <CardBox title={"Read Only"}>
            <FlexiDataTable
                rowKeyProperty="id"
                title=""
                columns={columns}
                dataSource={datalist}
                options={options}
                callback={componentCallback}
                loading={isLoading}
                filterInitialValue={initialFilterParams}
                bordered
                serverSide={true}
                pagination={pagination}
            />
            <SetReadOnlyModal
                serversOptions={serverList}
                onModalCancel={() => setOpenReadOnlyModal(false)}
                onModalOk={() => {
                    setOpenReadOnlyModal(false);
                    setPagination(prev => ({ ...prev, current: 1 }));
                    setRerunFetch(true);
                }}
                open={openReadOnlyModal}
            />
            <DownloadModal open={openDownloadModal} onClose={() => setOpenDownloadModal(false)} />
        </CardBox>
    );
};

export default ReadOnlyPage;

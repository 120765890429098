import { useState } from "react";
import { DatePicker, Space, Row, Col, Typography, Button, Modal, message } from "antd";
import moment from "moment";
import { APIs } from "../../../services/apis";
import { plainAxiosInstance } from "../../../services/axiosSetup";
import { ErrorCatchValidator, ErrorMessageHandler } from "../../../utils/Common";
import { SUCCESS_FAILED } from "../../../constants";

type ExcelTabProps = {};

const ExcelTab = ({}: ExcelTabProps) => {
    const { Text } = Typography;
    const [selectedDateString, setSelectedDateString] = useState<string>("");
    const [selectedDateVal, setSelectedDateVal] = useState<any>();
    const [downloading, setDownloading] = useState<boolean>(false);

    function handleDownload() {
        setDownloading(true);
        plainAxiosInstance
            .get(`${process.env.REACT_APP_API_URL}${APIs.RC_HFT_REPORT.POST_DOWNLOAD_HFT_REPORT}?date=${selectedDateString}`)
            .then(res => {
                if (res.data === 0) {
                    message.success("Add to batch successed. Please download from【Report】→【Report Batch】", 3);
                } else {
                    message.error(`Add to batch failed`, 3);
                }
                setSelectedDateString("");
                setSelectedDateVal(undefined);
            })
            .catch(err => {
                ErrorCatchValidator(err, (error: any) => ErrorMessageHandler("HFT Report", SUCCESS_FAILED.FAILED_DOWNLOAD_DATA, error));
            })
            .finally(() => {
                setDownloading(false);
            });
    }
    return (
        <Space style={{ width: "100%", padding: "1em" }} direction="vertical" size="large">
            <Row gutter={[8, 8]}>
                <Col span={16} xs={{ span: 24 }} lg={{ span: 8 }}>
                    <Text>Date *</Text>
                    <DatePicker
                        format={"YYYY-MM-DD"}
                        style={{ width: "100%" }}
                        onChange={(date, dateString) => {
                            setSelectedDateString(dateString);
                            setSelectedDateVal(date);
                        }}
                        // disable selecting the day after today, and 20 days before today
                        disabledDate={current => {
                            return current && current > moment().endOf("day");
                        }}
                        // read start and end from store
                        value={selectedDateVal}
                    />
                </Col>
            </Row>
            <Space style={{ display: "flex", flexWrap: "wrap" }}>
                <Button
                    type="primary"
                    onClick={handleDownload}
                    disabled={selectedDateString === "" || !selectedDateVal || downloading}
                    loading={downloading}
                    style={{ minWidth: 100 }}
                >
                    Download
                </Button>
            </Space>
        </Space>
    );
};

export default ExcelTab;

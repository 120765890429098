import React, { useState } from "react";
import { mapIssueLogBackToEnum, useRCIssuesLogMutate, useRCIssuesLogSettings } from "../../../hooks/useRCIssuesLog";
import type { RCIssueLogType } from "../../../hooks/useRCIssuesLog";
import { Button, Divider, Form, Input, Modal, Select, Space, Table, Tag, Typography, notification } from "antd";
import MessageCard from "../components/MessageCard";
import useRCIssuesLogServers from "../../../hooks/useRCIssuesLogServers";
import { plainAxiosInstance } from "../../../services/axiosSetup";
import { APIs } from "../../../services/apis";
import { DeleteOutlined } from "@ant-design/icons";

const CommonIssuesLogExcludeAccountSettingsTable = ({ type }: { type: RCIssueLogType }) => {
    const typeNumber = mapIssueLogBackToEnum(type);
    const { rcIssuesLogServers, isLoading: isLoadingRCIssuesLogServers } = useRCIssuesLogServers();
    const { rcIssuesLogSettings, rcIssuesLogSettingsError, isLoading } = useRCIssuesLogSettings({ logType: type });
    const { addIssueLogSettings, deleteIssueLogSettings, uploadIssueLogSettings } = useRCIssuesLogMutate({ type });
    const { Text, Title } = Typography;
    const [openAddModal, setOpenAddModal] = useState(false);
    const [currDeleteData, setCurrDeleteData] = useState<{ mainServerId: string; login: number; loginName: string; type: number } | null>(null);
    const [openUploadModal, setOpenUploadModal] = useState(false);

    const columns = [
        {
            title: "Server",
            dataIndex: "mainServerId",
            sorter: (a: any, b: any) => {
                // sort alphabetically and length
                if (a.mainServerId?.toLowerCase() < b.mainServerId?.toLowerCase()) {
                    return -1;
                }
                if (a.mainServerId?.toLowerCase() > b.mainServerId?.toLowerCase()) {
                    return 1;
                }
                return 0;
            },
        },

        {
            title: "Login",
            dataIndex: "login",
            sorter: (a: any, b: any) => {
                // sort alphabetically and length
                if (a.login?.toLowerCase() < b.login?.toLowerCase()) {
                    return -1;
                }
                if (a.login?.toLowerCase() > b.login?.toLowerCase()) {
                    return 1;
                }
                return 0;
            },
        },
        {
            title: "Login Name",
            dataIndex: "loginName",
            sorter: (a: any, b: any) => {
                // sort alphabetically and length
                if (a.loginName?.toLowerCase() < b.loginName?.toLowerCase()) {
                    return -1;
                }
                if (a.loginName?.toLowerCase() > b.loginName?.toLowerCase()) {
                    return 1;
                }
                return 0;
            },
        },
        {
            title: "Action",
            render: (text: any, data: any) => (
                <Button danger size="small" onClick={() => setCurrDeleteData(data)} type="text" icon={<DeleteOutlined />}>
                    Delete
                </Button>
            ),
        },
    ];

    const massagedData =
        rcIssuesLogSettings && rcIssuesLogSettings?.length >= 1
            ? rcIssuesLogSettings?.map((item: any, index: number) => {
                  return {
                      ...item,
                      key: item.mainServerId + item.login + item.loginName,
                  };
              })
            : [];

    const [form] = Form.useForm();
    const [formUpload] = Form.useForm();
    const [uploadFileBlob, setUploadFileBlob] = useState<Blob | null>(null);
    const { Item } = Form;

    const serverOptions = rcIssuesLogServers || [];
    const initFormValue = {
        mainServerId: null,
        login: "",
        loginName: "",
    };

    const initFormUploadValue = {
        file: null,
    };

    const handleAdd = async ({ mainServerId, login, loginName }: { mainServerId: string; login: number; loginName: string }) => {
        try {
            const resp = await addIssueLogSettings({
                mainServerId,
                login,
                loginName,
                type: typeNumber as number,
            });
            if (resp.status === 200 && resp?.data?.status === 400) {
                notification.error({
                    message: "Failed to add settings",
                    description: "Error: " + resp?.data?.msg,
                });
            } else {
                notification.success({
                    message: "Settings added",
                    description: "Settings added successfully",
                });
                form.resetFields();
                setOpenAddModal(false);
            }
            // console.log(resp);
        } catch (err) {
            console.log("error while adding settings", err);
        }
    };
    const handleDelete = async ({
        mainServerId,
        login,
        loginName,
        type,
    }: {
        mainServerId: string;
        login: number;
        loginName: string;
        type: number;
    }) => {
        try {
            const resp = await deleteIssueLogSettings({
                mainServerId,
                login,
                loginName,
                type,
            });
            if (resp.status === 200 && resp?.data?.status === 400) {
                notification.error({
                    message: "Failed to delete settings",
                    description: "Error: " + resp?.data?.msg,
                });
            } else {
                notification.success({
                    message: "Settings deleted",
                    description: `${mainServerId}: (${login}) ${loginName} deleted successfully`,
                });
                setCurrDeleteData(null);
            }
            // console.log(resp);
        } catch (err) {
            console.log("error while adding settings", err);
        }
    };
    const handleUpload = async ({ formData, type }: { formData: FormData; type: number }) => {
        try {
            const resp = await uploadIssueLogSettings({ formData, type });
            if (resp.status === 200 && resp?.data?.status === 400) {
                notification.error({
                    message: "Failed to upload settings",
                    description: "Error: " + resp?.data?.msg,
                });
            } else {
                notification.success({
                    message: "Settings uploaded",
                    description: "Settings uploaded successfully",
                });
                formUpload.resetFields();
                setOpenUploadModal(false);
            }
            // console.log(resp);
        } catch (err) {
            notification.error({
                message: "Failed to upload settings",
                description: `Error: ${err}`,
            });
            console.log("error while uploading settings", err);
        }
    };

    const handleDownload = async () => {
        plainAxiosInstance
            .get(`${APIs.RC_ISSUES_LOG.GET_ISSUES_LOG_SETTINGS_DOWNLOAD}/${typeNumber}`, {
                headers: {
                    Accept: "application/octet-stream,text/csv, */*",
                },
                responseType: "blob",
            })
            .then(response => {
                // console.log(response);
                // Check if the response content type is 'application/octet-stream'
                const contentType = response.headers["content-type"];
                if (
                    contentType === "application/octet-stream" ||
                    contentType === "text/csv" ||
                    contentType === "text/csv;charset=UTF-8" ||
                    contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                ) {
                    // Handle the file download response
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", `issuelog_${type}_settings.csv`);
                    document.body.appendChild(link);
                    link.click();
                    // Clean up
                    window.URL.revokeObjectURL(url);
                    notification.success({
                        message: "Downloaded",
                        description: `${type} settings downloaded successfully`,
                    });
                } else {
                    notification.error({
                        message: "Error",
                        description: `Received non-file response. Error: ${response}`,
                    });
                    console.log("Received non-file response:", response);
                }
            })
            .catch(err => {
                notification.error({
                    message: "Error",
                    description: `Download error: ${err}`,
                });
                console.log("download error", err);
            });
    };

    return (
        <Space direction="vertical" style={{ width: "100%", padding: "1rem" }}>
            {isLoading ? (
                <MessageCard type="info">Loading...</MessageCard>
            ) : rcIssuesLogSettingsError ? (
                <MessageCard type="error">{rcIssuesLogSettingsError}</MessageCard>
            ) : massagedData?.length === 0 ? (
                <MessageCard type="info">No record</MessageCard>
            ) : (
                <>
                    <Title level={5}>Exclude Account Settings</Title>
                    <MessageCard type={"info"}>
                        {massagedData?.length} record{massagedData?.length >= 2 ? "s" : ""}
                    </MessageCard>
                    <Space style={{ width: "100%" }}>
                        <Button onClick={() => setOpenAddModal(true)}>Add</Button>
                        <Button onClick={() => setOpenUploadModal(true)}>Import</Button>
                        <Button disabled={massagedData?.length <= 0} onClick={() => handleDownload()}>
                            Download
                        </Button>
                    </Space>
                    <Table
                        columns={columns}
                        dataSource={massagedData}
                        size="small"
                        pagination={{ defaultPageSize: 50, pageSizeOptions: ["10", "20", "50", "100", "500", "1000"] }}
                        scroll={{ y: 500 }}
                    />
                    <Form
                        form={form}
                        layout="vertical"
                        initialValues={initFormValue}
                        /* onValuesChange={handleFormUpdate} */ requiredMark={true}
                        onFinish={handleAdd}
                    >
                        <Modal
                            title="Add Settings"
                            okText={"Add"}
                            open={openAddModal}
                            onOk={() => form.submit()}
                            onCancel={() => setOpenAddModal(false)}
                        >
                            {isLoadingRCIssuesLogServers && !rcIssuesLogServers ? (
                                <MessageCard type="info">Loading...</MessageCard>
                            ) : (
                                <Space style={{ width: "100%" }} direction="vertical" size={16}>
                                    <div>
                                        <Text>Server *</Text>
                                        <Item
                                            style={{ margin: 0, padding: 0 }}
                                            name="mainServerId"
                                            rules={[{ required: true, message: "Please select server" }]}
                                        >
                                            <Select
                                                allowClear
                                                style={{ width: "100%" }}
                                                placeholder="Select Server"
                                                // @ts-ignore
                                                options={serverOptions}
                                                showSearch
                                                onChange={data => {
                                                    form.setFieldsValue({ mainServerId: data });
                                                }}
                                            />
                                        </Item>
                                    </div>
                                    <div>
                                        <Text>Login *</Text>
                                        <Item
                                            style={{ margin: 0, padding: 0 }}
                                            labelCol={{ span: 24 }}
                                            wrapperCol={{ span: 24 }}
                                            required
                                            name="login"
                                            rules={[{ required: true, message: "Login is required" }]}
                                        >
                                            <Input placeholder="Enter login" />
                                        </Item>
                                    </div>
                                    <div>
                                        <Text>Login Name *</Text>
                                        <Item
                                            style={{ margin: 0, padding: 0 }}
                                            labelCol={{ span: 24 }}
                                            wrapperCol={{ span: 24 }}
                                            required
                                            name="loginName"
                                            rules={[{ required: true, message: "Login Name is required" }]}
                                        >
                                            <Input placeholder="Enter login name" />
                                        </Item>
                                    </div>
                                </Space>
                            )}
                        </Modal>
                    </Form>
                    <Form
                        form={formUpload}
                        layout="vertical"
                        initialValues={initFormUploadValue}
                        /* onValuesChange={handleFormUpdate} */ requiredMark={true}
                        onFinish={() => {
                            // const formFile = formUpload.getFieldValue("file");
                            // // convert formFile to File blob
                            // const formFileBlob = new Blob([formFile], { type: "text/csv" });

                            const formData = new FormData();
                            formData.append("uploadFile", uploadFileBlob as Blob);
                            handleUpload({ formData, type: typeNumber as number });
                        }}
                    >
                        <Modal
                            title={"Upload settings"}
                            open={openUploadModal}
                            onCancel={() => {
                                formUpload.resetFields();
                                setOpenUploadModal(false);
                                setUploadFileBlob(null);
                            }}
                            onOk={() => formUpload.submit()}
                            okText="Import"
                            okButtonProps={{ danger: true }}
                        >
                            <Space style={{ width: "100%" }} direction="vertical" size={16}>
                                <div>
                                    <Text>CSV File *</Text>
                                    <Item
                                        style={{ margin: 0, padding: 0 }}
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        required
                                        name="file"
                                        rules={[{ required: true, message: "CSV File is required" }]}
                                    >
                                        <Input
                                            type="file"
                                            // accept both .csv and .CSV
                                            accept=".csv, .CSV"
                                            onChange={e => {
                                                if (e.target.files && e.target.files.length > 0) {
                                                    formUpload.setFieldsValue({ file: e.target.value });
                                                    setUploadFileBlob(e.target.files[0]);
                                                } else {
                                                    formUpload.setFieldsValue({ file: null });
                                                    setUploadFileBlob(null);
                                                }
                                            }}
                                        />
                                    </Item>
                                </div>
                                <MessageCard type="info">This will replace duplicated settings</MessageCard>
                            </Space>
                        </Modal>
                    </Form>
                    <Modal
                        title={"Delete Settings Confirmation"}
                        open={currDeleteData !== null}
                        onCancel={() => {
                            setCurrDeleteData(null);
                        }}
                        onOk={() => currDeleteData !== null && handleDelete(currDeleteData)}
                        okText="Delete"
                        okButtonProps={{ danger: true }}
                    >
                        <p>Are you sure you want to delete this data?</p>
                        <Tag color="red" style={{ width: "100%" }}>
                            <Space style={{ width: "100%" }}>
                                <Space>
                                    <Text>Server:</Text>
                                    <Text>{currDeleteData?.mainServerId}</Text>
                                </Space>
                                <Divider type="vertical" />
                                <Space>
                                    <Text>Login:</Text>
                                    <Text>{currDeleteData?.login}</Text>
                                </Space>
                                <Divider type="vertical" />
                                <Space>
                                    <Text>Login Name:</Text>
                                    <Text>{currDeleteData?.loginName}</Text>
                                </Space>
                            </Space>
                        </Tag>
                    </Modal>
                </>
            )}
        </Space>
    );
};

export default CommonIssuesLogExcludeAccountSettingsTable;

import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Modal, Button, Steps, Divider, message, Tag, Space } from "antd";
import {
    CheckCircleOutlined,
    PlayCircleOutlined,
    ArrowLeftOutlined,
    ArrowRightOutlined,
    UndoOutlined,
    ExclamationCircleOutlined,
    HomeOutlined,
} from "@ant-design/icons";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "../../../../../utils/Common";
import { CALLBACK_KEY, SUCCESS_FAILED } from "../../../../../constants";
import { APIs } from "../../../../../services/apis";
import { plainAxiosInstance } from "../../../../../services/axiosSetup";
import { stepInfo } from "../stepInfo";
import {
    FlexiDataTableCallbackProps,
    FlexiDataTableOptionsProps,
    SymbolLeverageMarginLevel,
    SymbolLeverageToolBatchRecordList,
    SymbolLeverageUpdateData,
} from "../../../../../constants/type";
import FlexiDataTable from "../../../../../components/FlexiDataTable";
import { updateDataDummyData } from "../dummydata/updateDataDummyData";
import { marginLevelDummyData } from "../dummydata/marginLevelDummyData";
import { defaultIfEmptyOrNull } from "../../../../../utils/string";
import AuthHelper, { AuthKeys } from "../../../../../helpers/authHelper";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SitePageHeader from "../../../../../components/PageHeader";
import LoadingComponent from "../../../../../components/Loading";
import moment from "moment";

export type SymbolLeverageStepProps = {
    action: string;
    data: any;
};

function SymbolLeverageStep() {
    let navigate = useNavigate();
    let location = useLocation();
    let com_state: SymbolLeverageToolBatchRecordList = location.state.data as SymbolLeverageToolBatchRecordList;
    const [currentState] = useState<SymbolLeverageToolBatchRecordList>(com_state);
    let { id } = useParams();
    const [current, setCurrent] = useState(1);

    const [stepItems, setStepItems] = useState(
        stepInfo.map(item => ({
            key: item.value,
            title: item.label,
            description: item.description,
        }))
    );
    const [uploadData, setUploadData] = useState<SymbolLeverageUpdateData[]>([]);
    const [originLeverageData, setOriginLeverageData] = useState<SymbolLeverageUpdateData[]>([]);
    const [updateLeverageData, setUpdateLeverageData] = useState<SymbolLeverageUpdateData[]>([]);

    const [marginLevelDataBefore, setMarginLevelDataBefore] = useState<SymbolLeverageMarginLevel[]>([]);
    const [marginLevelDataAfter, setMarginLevelDataAfter] = useState<SymbolLeverageMarginLevel[]>([]);

    const [rollBackVisible, setRollBackVisible] = useState(false);
    const [rollBackData, setRollBackData] = useState<SymbolLeverageUpdateData[]>([]);

    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.RISK_TOOLS_SYMBOL_LEVERAGE_EDIT);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(true);

    const [nodata, setNodata] = useState(false);
    const columns0 = useMemo(
        () => [
            DTColProps.XSmall({
                title: "Server",
                dataIndex: "serverId",
                key: "serverId",
            }),
            DTColProps.XSmall({
                title: "Symbol",
                dataIndex: "symbol",
                key: "symbol",
            }),
            DTColProps.XSmall({
                title: "Old Percentage",
                dataIndex: "percentage",
                key: "percentage",
            }),
            DTColProps.XSmall({
                title: "Old Margin Calculation",
                dataIndex: "marginCalculation",
                key: "marginCalculation",
            }),
            DTColProps.XSmall({
                title: "Old Profit Calculation",
                dataIndex: "profitCalculation",
                key: "profitCalculation",
            }),
        ],
        [current]
    );

    const columns1 = useMemo(
        () => [
            DTColProps.XSmall({
                title: "Login",
                dataIndex: "login",
                key: "login",
            }),
            DTColProps.XSmall({
                title: "Margin Level",
                dataIndex: "marginLevel",
                key: "marginLevel",
            }),
        ],
        [current]
    );

    const columns3 = useMemo(
        () => [
            DTColProps.XSmall({
                title: "Server",
                dataIndex: "serverId",
                key: "serverId",
            }),
            DTColProps.XSmall({
                title: "Symbol",
                dataIndex: "symbol",
                key: "symbol",
            }),
            DTColProps.XSmall({
                title: "New Percentage",
                dataIndex: "percentage",
                key: "percentage",
            }),
            DTColProps.XSmall({
                title: "New Margin Calculation",
                dataIndex: "marginCalculation",
                key: "marginCalculation",
            }),
            DTColProps.XSmall({
                title: "New Profit Calculation",
                dataIndex: "profitCalculation",
                key: "profitCalculation",
            }),
            DTColProps.XSmall({
                title: "Percentage After Update",
                dataIndex: "newPercentage",
                key: "newPercentage",
            }),
            DTColProps.XSmall({
                title: "Margin Calculation After Update",
                dataIndex: "newMarginCalculation",
                key: "newMarginCalculation",
            }),
            DTColProps.XSmall({
                title: "Profit Calculation After Update",
                dataIndex: "newProfitCalculation",
                key: "newProfitCalculation",
            }),
            DTColProps.XSmall({
                title: "Status",
                dataIndex: "status",
                key: "status",
                render: (status: number, obj: any) => {
                    return status === 0 ? (
                        <Tag color="default">Waiting</Tag>
                    ) : status === 1 ? (
                        <Tag color="green">Success</Tag>
                    ) : (
                        <Tag color="red">{obj.message}</Tag>
                    );
                },
            }),
        ],
        [current]
    );

    const columns4 = useMemo(
        () => [
            DTColProps.XSmall({
                title: "Server",
                dataIndex: "serverId",
                key: "serverId",
            }),
            DTColProps.XSmall({
                title: "Login",
                dataIndex: "login",
                key: "login",
            }),
            DTColProps.XSmall({
                title: "Margin Level",
                dataIndex: "marginLevel",
                key: "marginLevel",
                render: (marginLevel: number) => marginLevel.toLocaleString(undefined, { maximumFractionDigits: 2 }),
            }),
        ],
        [current]
    );

    const queryUploadData = useCallback(() => {
        setIsLoading(true);
        plainAxiosInstance
            .get(`${APIs.RISK_TOOL.GET_SYMBOL_LEVERAGE_UPDATE_DATA}/${currentState.id}/1`)
            .then((res: any) => {
                if (res.data.length > 0) {
                    const realData: SymbolLeverageUpdateData[] = res.data.map((item: SymbolLeverageUpdateData, index: number) => ({
                        ...item,
                        uniqueKey: `${item.id}_${item.type}_${item.serverId}_${item.symbol}`,
                    }));
                    setUploadData(realData);
                    setNodata(false);
                } else {
                    setUploadData([]);
                    setNodata(true);
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("symbol leverage update data 1", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            )
            .finally(() => setIsLoading(false));
    }, [currentState, uploadData]);

    // const checkgetMarginLevelStatus = status => {
    //     return status == 2 ? "Success" : status == 1 ? "Processing" : "Pendding";
    // };

    const queryMarginLevelDataBefore = async () => {
        setIsLoading(true);
        plainAxiosInstance
            .get(`${APIs.RISK_TOOL.GET_SYMBOL_LEVERAGE_MARGIN_LEVEL}/${currentState.id}/1`)
            .then((res: any) => {
                if (res.data.length > 0) {
                    const realData: SymbolLeverageMarginLevel[] = res.data.map((item: SymbolLeverageMarginLevel, index: number) => ({
                        ...item,
                        uniqueKey: `${item.id}_${item.type}_${item.serverId}_${item.login}`,
                    }));
                    setMarginLevelDataBefore(realData);
                    setNodata(false);
                } else {
                    setMarginLevelDataBefore([]);
                    setNodata(true);
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("symbol leverage margin level 1", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            )
            .finally(() => setIsLoading(false));
    };

    const queryMarginLevelDataAfter = async () => {
        setIsLoading(true);
        plainAxiosInstance
            .get(`${APIs.RISK_TOOL.GET_SYMBOL_LEVERAGE_MARGIN_LEVEL}/${currentState.id}/2`)
            .then((res: any) => {
                if (res.data.length > 0) {
                    const realData: SymbolLeverageMarginLevel[] = res.data.map((item: SymbolLeverageMarginLevel, index: number) => ({
                        ...item,
                        uniqueKey: `${item.id}_${item.type}_${item.serverId}_${item.login}`,
                    }));
                    setMarginLevelDataAfter(realData);
                    setNodata(false);
                } else {
                    setMarginLevelDataAfter([]);
                    setNodata(true);
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("symbol leverage margin level 2", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            )
            .finally(() => setIsLoading(false));
    };

    const refetchOriginLeverage = async () => {
        setIsLoading(true);
        plainAxiosInstance
            .get(`${APIs.RISK_TOOL.GET_SYMBOL_LEVERAGE_REFETCH_ORIGIN_SYMBOL_LEVERAGE}/${currentState.id}/2`)
            .then((res: any) => {
                if (res.data === 0) {
                    queryOriginLeverage();
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) =>
                    ErrorMessageHandler("symbol leverage refetch origin symbol leverage 2", SUCCESS_FAILED.FAILED_LOAD_DATA, err)
                )
            )
            .finally(() => setIsLoading(false));
    };

    const queryOriginLeverage = async () => {
        setIsLoading(true);
        plainAxiosInstance
            .get(`${APIs.RISK_TOOL.GET_SYMBOL_LEVERAGE_UPDATE_DATA}/${currentState.id}/2`)
            .then((res: any) => {
                if (res.data.length > 0) {
                    const realData: SymbolLeverageUpdateData[] = res.data.map((item: SymbolLeverageUpdateData, index: number) => ({
                        ...item,
                        uniqueKey: `${item.id}_${item.type}_${item.serverId}_${item.symbol}`,
                    }));
                    setOriginLeverageData(realData);
                } else {
                    setOriginLeverageData([]);
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) =>
                    ErrorMessageHandler("symbol leverage refetch origin symbol leverage 2", SUCCESS_FAILED.FAILED_LOAD_DATA, err)
                )
            )
            .finally(() => setIsLoading(false));
    };

    const updateLeverage = async () => {
        setIsLoading(true);
        plainAxiosInstance
            .get(`${APIs.RISK_TOOL.GET_SYMBOL_LEVERAGE_EXECUTE_SYMBOL_LEVERAGE_UPDATE}/${currentState.id}`)
            .then(async (res: any) => {
                if (res.data === 0) {
                    message.success("Updated Success");
                    await queryUploadDataStep4();
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) =>
                    ErrorMessageHandler("symbol leverage execute symbol leverage update ", SUCCESS_FAILED.FAILED_LOAD_DATA, err)
                )
            )
            .finally(() => setIsLoading(false));
    };

    const queryUploadDataStep4 = async () => {
        setIsLoading(true);
        plainAxiosInstance
            .get(`${APIs.RISK_TOOL.GET_SYMBOL_LEVERAGE_UPDATE_DATA}/${currentState.id}/1`)
            .then(async (res: any) => {
                if (res.data.length > 0) {
                    const realData: SymbolLeverageUpdateData[] = res.data.map((item: SymbolLeverageUpdateData, index: number) => ({
                        ...item,
                        uniqueKey: `${item.id}_${item.type}_${item.serverId}_${item.symbol}`,
                    }));
                    setUpdateLeverageData(realData);
                    setNodata(false);
                } else {
                    setUpdateLeverageData([]);
                    setNodata(true);
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("symbol leverage update data", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            )
            .finally(() => setIsLoading(false));
    };

    const refetchMarginLevelAfterUpdate = async () => {
        setIsLoading(true);
        plainAxiosInstance
            .get(`${APIs.RISK_TOOL.GET_SYMBOL_LEVERAGE_REFETCH_MARGIN_LEVEL}/${currentState.id}`)
            .then(async (res: any) => {
                if (res.data === 0) {
                    await queryUploadDataStep4();
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) =>
                    ErrorMessageHandler("symbol leverage refetch margin level", SUCCESS_FAILED.FAILED_LOAD_DATA, err)
                )
            )
            .finally(() => setIsLoading(false));
    };

    const confirmUpdateData = async () => {
        setIsLoading(true);
        plainAxiosInstance
            .get(`${APIs.RISK_TOOL.GET_SYMBOL_LEVERAGE_CONFIRM_UPDATE_DATA}/${currentState.id}`)
            .then(async (res: any) => {})
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) =>
                    ErrorMessageHandler("symbol leverage confirm update data", SUCCESS_FAILED.FAILED_LOAD_DATA, err)
                )
            )
            .finally(() => {
                queryMarginLevelDataBefore();
                setIsLoading(false);
            });
    };

    const confirmBeforeMarginLevel = async () => {
        setIsLoading(true);
        plainAxiosInstance
            .get(`${APIs.RISK_TOOL.GET_SYMBOL_LEVERAGE_CONFIRM_BEFORE_MARGIN_LEVEL}/${currentState.id}`)
            .then(async (res: any) => {})
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) =>
                    ErrorMessageHandler("symbol leverage confirm before margin level", SUCCESS_FAILED.FAILED_LOAD_DATA, err)
                )
            )
            .finally(() => {
                queryMarginLevelDataBefore();
                setIsLoading(false);
            });
    };

    const confirmUpdateConfig = async () => {
        setIsLoading(true);
        plainAxiosInstance
            .get(`${APIs.RISK_TOOL.GET_SYMBOL_LEVERAGE_CONFIRM_UPDATE_CONFIG}/${currentState.id}`)
            .then(async (res: any) => {})
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) =>
                    ErrorMessageHandler("symbol leverage confirm update config", SUCCESS_FAILED.FAILED_LOAD_DATA, err)
                )
            )
            .finally(() => {
                queryUploadDataStep4();
                setIsLoading(false);
            });
    };

    const confirmSymbolLeverageUpdate = async () => {
        setIsLoading(true);
        plainAxiosInstance
            .get(`${APIs.RISK_TOOL.GET_SYMBOL_LEVERAGE_CONFIRM_SYMBOL_LEVERAGE_UPDATE}/${currentState.id}`)
            .then(async (res: any) => {})
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) =>
                    ErrorMessageHandler("symbol leverage confirm symbol leverage update", SUCCESS_FAILED.FAILED_LOAD_DATA, err)
                )
            )
            .finally(() => {
                queryMarginLevelDataAfter();
                setIsLoading(false);
            });
    };

    const confirm = async () => {
        setIsLoading(true);
        plainAxiosInstance
            .get(`${APIs.RISK_TOOL.GET_SYMBOL_LEVERAGE_CONFIRM_AFTER_MARGIN_LEVEL}/${currentState.id}`)
            .then(async (res: any) => {
                if (res.data === 0) {
                    message.success("Updated Success");
                    initStep();
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("symbol leverage confirm", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            )
            .finally(() => {
                setIsLoading(false);
            });
    };

    const rollBackOpen = () => {
        setRollBackVisible(true);
        queryRollBackData();
    };

    const queryRollBackData = async () => {
        setIsLoading(true);
        plainAxiosInstance
            .get(`${APIs.RISK_TOOL.GET_SYMBOL_LEVERAGE_UPDATE_DATA}/${currentState.id}/2`)
            .then(async (res: any) => {
                if (res.data.length > 0) {
                    const realData: SymbolLeverageUpdateData[] = res.data.map((item: SymbolLeverageUpdateData, index: number) => ({
                        ...item,
                        uniqueKey: `${item.id}_${item.type}_${item.serverId}_${item.symbol}`,
                    }));
                    setRollBackData(realData);
                } else {
                    setRollBackData([]);
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("symbol leverage update data", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            )
            .finally(() => {
                setIsLoading(false);
            });
    };

    const exeRollBack = async () => {
        setIsLoading(true);
        plainAxiosInstance
            .get(`${APIs.RISK_TOOL.GET_SYMBOL_LEVERAGE_ROLLBACK_SYMBOL_LEVERAGE}/${currentState.id}`)
            .then(async (res: any) => {
                if (res.data === 0) {
                    message.success("Updated Success");
                    queryRollBackData();
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) =>
                    ErrorMessageHandler("symbol leverage rollback symbol leverage", SUCCESS_FAILED.FAILED_LOAD_DATA, err)
                )
            )
            .finally(() => {
                setIsLoading(false);
            });
    };
    const next = (stepKey: number) => {
        switch (stepKey) {
            case 0:
                confirmUpdateData();
                setCurrent(current + 1);
                break;
            case 1:
                confirmBeforeMarginLevel();
                setCurrent(current + 1);
                break;
            case 2:
                confirmUpdateConfig();
                setCurrent(current + 1);
                break;
            case 3:
                confirmSymbolLeverageUpdate();
                setCurrent(current + 1);
                break;
            case 4:
                setCurrent(current + 1);
                break;
        }
    };
    const closeRollbackModal = () => {
        setRollBackVisible(false);
    };

    const handleSubmit = (file: any) => {
        try {
            let fileExtension: string[] = defaultIfEmptyOrNull(/\.[^\.]+/.exec(file.name), [""]),
                isLt5M = file.size / 1024 / 1024 < 5;

            if (fileExtension[0] !== ".xlsx") {
                message.error("Please check file type. Only .xlsx files are allowed.", 3);
                return;
            } else if (!isLt5M) {
                message.error("Please check file size less than 5 MB", 3);
                return;
            }

            Modal.confirm({
                icon: <ExclamationCircleOutlined />,
                title: "Are you sure you want to import leverage update data?",
                width: "30%",
                onOk() {
                    var formData = new FormData();
                    formData.append("uploadFile", file);
                    formData.append("id", `${currentState.id}`);

                    plainAxiosInstance
                        .post(APIs.RISK_TOOL.POST_SYMBOL_LEVERAGE_ADD_UPDATE_DATA, formData)
                        .then(res => {
                            if (res.status === 200 && res.data === 0) {
                                message.success("File upload successfully.", 3);
                            } else {
                                message.error(res.data, 3);
                            }
                        })
                        .catch((error: any) => {
                            message.error(`File upload failed: (${error.response.data.message})`, 3);
                        })
                        .finally(() => queryUploadData());
                },
                onCancel: () => {
                    setIsLoading(false);
                },
            });
        } catch (error) {
            message.error(`Error during uploading file`, 3);
        }
    };

    const tableOpts0: FlexiDataTableOptionsProps = {
        showHideColumns: false,
        enableFilter: false,
        ...(enableUpdate && {
            upload: {
                name: "file",
                multiple: false,
                accept: ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
                showUploadList: false,
                onChange: (info: any) => {
                    if (info.file.status === "error") {
                        message.error(`${info.file.name} file upload failed.`);
                    }
                },
                customRequest: ({ file, onSuccess }: any) =>
                    setTimeout(() => {
                        onSuccess("ok");
                    }, 0),
                beforeUpload: (file: any) => handleSubmit(file),
            },
        }),
    };
    const tableOpts1: FlexiDataTableOptionsProps = {
        showHideColumns: false,
        enableFilter: false,
        export: {
            text: "Download Excel",
        },
        refresh: {
            timer: false,
            enablePeriodSelection: false,
        },
    };
    const tableOpts2: FlexiDataTableOptionsProps = {
        showHideColumns: false,
        enableFilter: false,
        refresh: {
            timer: false,
            enablePeriodSelection: false,
        },
        extraButtons: () => (
            <Button
                key={`btn-refetch-${Math.random()}`}
                style={{ marginLeft: "0.651vw", width: "auto" }}
                icon={<PlayCircleOutlined />}
                disabled={isLoading || currentState.currentStep > 3}
                onClick={event => {
                    event.preventDefault();
                    event.stopPropagation();
                    refetchOriginLeverage();
                }}
            >
                Refetch
            </Button>
        ),
    };
    const tableOpts3: FlexiDataTableOptionsProps = {
        showHideColumns: false,
        enableFilter: false,
        refresh: {
            timer: false,
            enablePeriodSelection: false,
        },
        extraButtons: () => (
            <Button
                key={`btn-update-symbol-leverage-config-${Math.random()}`}
                style={{ marginLeft: "0.651vw", width: "auto" }}
                disabled={isLoading || currentState.currentStep > 4}
                onClick={event => {
                    event.preventDefault();
                    event.stopPropagation();
                    updateLeverage();
                }}
            >
                Update Symbol Leverage Config
            </Button>
        ),
    };
    const tableOpts4: FlexiDataTableOptionsProps = {
        showHideColumns: false,
        enableFilter: false,
        export: {
            text: "Download Excel",
        },
        extraButtons: () => (
            <Button
                key={`btn-refetch-${Math.random()}`}
                style={{ marginLeft: "0.651vw", width: "auto" }}
                icon={<PlayCircleOutlined />}
                disabled={isLoading || currentState.currentStep > 4}
                onClick={event => {
                    event.preventDefault();
                    event.stopPropagation();
                    refetchMarginLevelAfterUpdate();
                }}
            >
                Refetch
            </Button>
        ),
        refresh: {
            timer: false,
            enablePeriodSelection: false,
        },
    };

    const tableOpts5: FlexiDataTableOptionsProps = {
        showHideColumns: false,
        enableFilter: false,
        refresh: {
            timer: false,
            enablePeriodSelection: false,
        },
    };

    const componentCallback0: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            default:
                break;
        }
    };

    const componentCallback1: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.EXPORT_CSV_EXCEL:
                try {
                    plainAxiosInstance
                        .get(`${APIs.RISK_TOOL.GET_SYMBOL_LEVERAGE_DOWNLOAD_MARGIN_LEVEL}?batchId=${id}&type=1`, {
                            headers: {
                                Accept: "application/octet-stream, */*",
                            },
                            responseType: "blob",
                        })
                        .then(response => {
                            const contentType = response.headers["content-type"];
                            if (
                                contentType === "application/octet-stream" ||
                                contentType === "text/csv;charset=UTF-8" ||
                                contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            ) {
                                const url = window.URL.createObjectURL(new Blob([response.data]));
                                const link = document.createElement("a");
                                link.href = url;
                                link.setAttribute("download", `MarginLevel_${moment().format("YYYYMMDDHHmmss")}.xlsx`);
                                document.body.appendChild(link);
                                link.click();
                                // Clean up
                                window.URL.revokeObjectURL(url);
                            } else {
                                message.error(`Received non-file response. Error: ${response}`, 3);
                            }
                        })
                        .catch((error: any) => message.error(`Error occured during download: "${error.message}"`, 3));
                } catch (e: any) {
                    message.error(`Error occured during download: "${e.message}"`, 3);
                }
                break;
            case CALLBACK_KEY.REFRESH:
                queryMarginLevelDataBefore();
                break;
            default:
                break;
        }
    };

    const componentCallback2: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.REFRESH:
                queryOriginLeverage();
                break;
            default:
                break;
        }
    };

    const componentCallback3: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.REFRESH:
                queryUploadDataStep4();
                break;
            default:
                break;
        }
    };

    const componentCallback4: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.EXPORT_CSV_EXCEL:
                try {
                    plainAxiosInstance
                        .get(`${APIs.RISK_TOOL.GET_SYMBOL_LEVERAGE_DOWNLOAD_MARGIN_LEVEL}?batchId=${id}&type=2`, {
                            headers: {
                                Accept: "application/octet-stream, */*",
                            },
                            responseType: "blob",
                        })
                        .then(response => {
                            const contentType = response.headers["content-type"];
                            if (
                                contentType === "application/octet-stream" ||
                                contentType === "text/csv;charset=UTF-8" ||
                                contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            ) {
                                const url = window.URL.createObjectURL(new Blob([response.data]));
                                const link = document.createElement("a");
                                link.href = url;
                                link.setAttribute("download", `MarginLevel_${moment().format("YYYYMMDDHHmmss")}.xlsx`);
                                document.body.appendChild(link);
                                link.click();
                                // Clean up
                                window.URL.revokeObjectURL(url);
                            } else {
                                message.error(`Received non-file response. Error: ${response}`, 3);
                            }
                        })
                        .catch((error: any) => message.error(`Error occured during download: "${error.message}"`, 3));
                } catch (e: any) {
                    message.error(`Error occured during download: "${e.message}"`, 3);
                }
                break;
            case CALLBACK_KEY.REFRESH:
                queryMarginLevelDataAfter();
                break;
            default:
                break;
        }
    };

    const componentCallback5: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.REFRESH:
                queryRollBackData();
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        if (runRefetchDataList) {
            setIsLoading(true);
            initCall();
            setRunRefetchDataList(false);
        }
        return () => {};
    }, [runRefetchDataList]);

    useEffect(() => {
        initStep();
    }, []);

    const initStep = async () => {
        setCurrent(currentState.currentStep - 1);
    };

    const initCall = () => {
        if (currentState.id != undefined) {
            queryUploadData();
            queryMarginLevelDataBefore();
            queryOriginLeverage();
            queryUploadDataStep4();
            queryMarginLevelDataAfter();
        }
    };

    const renderTable = (tableProps: { tbTitle: string; tbCol: any; tbOpt: any; tbData: any; tbCallback: any }) => {
        return (
            <>
                <br />
                <Divider style={{ borderColor: "#FFA500" }}>{tableProps.tbTitle}</Divider>
                <FlexiDataTable
                    rowKeyProperty="uniqueKey"
                    title={false}
                    columns={tableProps.tbCol}
                    options={tableProps.tbOpt}
                    dataSource={tableProps.tbData}
                    loading={false}
                    callback={tableProps.tbCallback}
                    pagination={{ defaultPageSize: 10 }}
                    scroll={{ x: true }}
                />
            </>
        );
    };

    const renderContent = (current: number) => {
        const stepComponents: { [key: number]: React.ReactNode } = {
            0: renderTable({
                tbTitle: "New Symbol Leverage Config",
                tbCol: columns0,
                tbOpt: tableOpts0,
                tbData: uploadData,
                tbCallback: componentCallback0,
            }),
            1: renderTable({
                tbTitle: "Margin Level Before Update",
                tbCol: columns1,
                tbOpt: tableOpts1,
                tbData: marginLevelDataBefore,
                tbCallback: componentCallback1,
            }),
            2: renderTable({
                tbTitle: "Origin Symbol Leverage Config",
                tbCol: columns0,
                tbOpt: tableOpts2,
                tbData: originLeverageData,
                tbCallback: componentCallback2,
            }),
            3: renderTable({
                tbTitle: "Symbol Leverage Config Process",
                tbCol: columns3,
                tbOpt: tableOpts3,
                tbData: updateLeverageData,
                tbCallback: componentCallback3,
            }),
            4: renderTable({
                tbTitle: "Margin Level After Update",
                tbCol: columns4,
                tbOpt: tableOpts4,
                tbData: marginLevelDataAfter,
                tbCallback: componentCallback4,
            }),
            5: (
                <>
                    <br />
                    <Button icon={<UndoOutlined />} onClick={rollBackOpen} disabled={currentState.status == 2}>
                        Rollback
                    </Button>
                </>
            ),
        };

        return stepComponents[current] || null;
    };

    return (
        <SitePageHeader
            title={`Edit Batch ${id}`}
            routes={[
                {
                    path: "/admintools/symbolleverage",
                    breadcrumbName: "Symbol Leverage Tool",
                    icon: <HomeOutlined />,
                },
                {
                    path: "",
                    breadcrumbName: `Edit Batch ${id}`,
                },
            ]}
            onBack={() => navigate("/admintools/symbolleverage")}
        >
            {isLoading ? (
                <div className="loading-container">
                    <LoadingComponent tip="Submitting..." />
                </div>
            ) : (
                <>
                    <Steps size="small" current={current} onChange={current => setCurrent(current)} items={stepItems} />
                    <div className="system-leverage-step-modal-content">{renderContent(current)}</div>
                    <div className="system-leverage-step-modal-content-steps-action">
                        <Space>
                            {current > 0 && (
                                <Button onClick={() => setCurrent(current - 1)} icon={<ArrowLeftOutlined />} hidden={currentState.status == 2}>
                                    Previous
                                </Button>
                            )}
                            {current < stepInfo.length - 1 && current !== 5 && (
                                <Button
                                    icon={<ArrowRightOutlined />}
                                    type="primary"
                                    onClick={() => next(current)}
                                    disabled={isLoading || nodata}
                                    hidden={currentState.status == 2}
                                >
                                    Next
                                </Button>
                            )}
                            <Button
                                type="primary"
                                icon={<CheckCircleOutlined />}
                                onClick={confirm}
                                hidden={current !== 5 || currentState.status == 2}
                            >
                                Confirm
                            </Button>
                            <Button key="back" onClick={() => navigate("/admintools/symbolleverage")}>
                                Close
                            </Button>
                        </Space>
                    </div>
                    <Modal
                        open={rollBackVisible}
                        title={"Rollback Data"}
                        width={"70%"}
                        onCancel={closeRollbackModal}
                        maskClosable={false}
                        footer={[
                            <div>
                                <Button danger type="primary" key="comfirm" onClick={exeRollBack} loading={isLoading} disabled={isLoading}>
                                    Confirm Roll Back
                                </Button>
                                <Button key="back" onClick={closeRollbackModal}>
                                    Close
                                </Button>
                            </div>,
                        ]}
                    >
                        <FlexiDataTable
                            rowKeyProperty="uniqueKey"
                            title={false}
                            columns={columns3}
                            options={tableOpts5}
                            dataSource={rollBackData}
                            loading={false}
                            callback={componentCallback5}
                            pagination={{ defaultPageSize: 10 }}
                            scroll={{ x: true }}
                        />
                    </Modal>
                </>
            )}
        </SitePageHeader>
    );
}
export default SymbolLeverageStep;

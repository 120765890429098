import { useEffect, useMemo, useState } from "react";
import FlexiDataTable from "../../../../components/FlexiDataTable";
import {
    AttributeProps,
    BrandsList,
    CleanSymbolsList,
    CreateEditInlineFormBased,
    CrmBrandList,
    CustomPaginationProps,
    FlexiDataTableCallbackProps,
    NavigationItem,
    RegulatorList,
    ToxicClientCheckAccountDetails,
    ToxicClientCheckAccountRestrictions,
    ToxicClientCheckAttrDetails,
    ToxicClientCheckAuditLogData,
    ToxicClientCheckClientRestrictions,
    ToxicClientCheckData,
    ToxicClientCheckUploadEntryProps,
    ToxicClientLabelProps,
    ToxicClientRestrictions,
    UsersList,
    triggeredByAccounts,
} from "../../../../constants/type";
import {
    CALLBACK_KEY,
    ComponentType,
    InnerPageActionMode,
    SUCCESS_FAILED,
    TIMEZONE_FORMATS,
    TIMEZONE_FORMATS_ENUM,
    TOXIC_CLIENT_CHECK_ACCOUNT_RESTRICTIONS_CODES,
    TOXIC_CLIENT_CHECK_CLIENT_RESTRICTIONS_CODES,
    TOXIC_CLIENT_CHECK_CRM_MAPPING_MODE_ENUM,
    TOXIC_CLIENT_CHECK_ENTRY_CRM_MAPPING_MODE,
    TOXIC_CLIENT_CHECK_ENTRY_STATUS,
    TOXIC_CLIENT_CHECK_RESTRICTIONS_NAMES,
    TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM,
} from "../../../../constants";
import { DTColProps, DataTableColumnRender, ErrorCatchValidator, ErrorMessageHandler } from "../../../../utils/Common";
import { APIs, apiRequest } from "../../../../services/apiConfig";
import { Badge, Button, Checkbox, Form, Modal, Popconfirm, Segmented, Tabs, Tooltip, Upload, message } from "antd";
import { SortList, ToObjectWithKey } from "../../../../utils/array";
import { DateTimeUtil } from "../../../../utils/datetime";
import { objectToArray } from "../../../../utils/object";
import ToxicClientCheckEntryCreateEdit from "./CreateEditPage";
import AuthHelper, { AuthKeys } from "../../../../helpers/authHelper";
import {
    DeleteOutlined,
    DownloadOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
    HistoryOutlined,
    InfoCircleOutlined,
    SlidersOutlined,
    UploadOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { isEmptyOrNull } from "../../../../utils/string";
import * as XLSX from "xlsx";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import LoadingComponent from "../../../../components/Loading";
import AuditLog from "./Components/AuditLog";
import AccountRestrictions from "./Components/AccountRestrictions";
import ClientRestrictions from "./Components/ClientRestrictions";
import CheckListCustomGroupedFilter from "./Components/checkListCustomGroupedFilter";
import BatchUpdateRestrictions from "./BatchUpdateRestrictions";
import UnconfirmedBrandsTab from "./Components/UnconfirmedBrandsTab";

const SegmentList: NavigationItem[] = [
    { key: "1", title: "Accounts" },
    { key: "2", title: "Clients" },
];

const uploadDummyRequest = ({ file, onSuccess }: any) => {
    setTimeout(() => {
        onSuccess("ok");
    }, 0);
};

interface ToxicClientCheckListCreateEditInlineFormBased extends CreateEditInlineFormBased {
    backActiveSegment?: string;
    filterParams?: any;
}

interface ToxicClientCheckUploadDataResponse {
    invalidEntries: ToxicClientCheckUploadEntryProps[];
    invalidEntryCount: number;
    validEntryCount: number;
    unconfirmedBrandEntries: ToxicClientCheckUploadEntryProps[];
}

interface ToxicClientCheckCommonFilterPops {
    brands: BrandsList[];
    regulators: RegulatorList[];
    users: UsersList[];
    crmBrands: CrmBrandList[];
    tcLabelProps: ToxicClientLabelProps[];
    tcLabelAttrs: AttributeProps[];
    tcRestrictions: ToxicClientRestrictions[];
    cleanSymbols: CleanSymbolsList[];
}

const initialUploadedItemRes: ToxicClientCheckUploadDataResponse = {
    invalidEntries: [],
    invalidEntryCount: 0,
    validEntryCount: 0,
    unconfirmedBrandEntries: [],
};

const ToxicClientCheckList = () => {
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.TOXIC_CLIENT_CHECK_EDIT);

    const [data, setData] = useState<ToxicClientCheckData[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isFirstOpt, setIsFirstOpt] = useState<boolean>(true);
    const [refetch, setRefetch] = useState<number>(0);
    const [formObject, setFormObject] = useState<ToxicClientCheckListCreateEditInlineFormBased>({ mode: InnerPageActionMode.CREATE_NEW });
    const [pagination, setPagination] = useState<CustomPaginationProps>({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const [filterParams, setFilterParams] = useState<any>({});
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(false);
    const [servers, setServers] = useState<any>({});
    const [labels, setLabels] = useState<ToxicClientLabelProps[]>([]);
    const [filterForm] = Form.useForm();
    const [sorting, setSorting] = useState<string>("maxIssuedDate,desc|entryId,desc");
    const [currentActiveSegment, setCurrentActiveSegment] = useState<string>("1"); // For segment
    const [accData, setAccData] = useState<ToxicClientCheckAccountDetails[]>([]);
    const [accPagination, setAccPagination] = useState<CustomPaginationProps>({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const [currTemplateVer, setCurrTemplateVer] = useState<string>("");
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [isPreview, setIsPreview] = useState<boolean>(false);
    const [isPreviewModalOpen, setIsPreviewModalOpen] = useState<boolean>(false);
    const [isDownloadErrChecked, setIsDownloadErrChecked] = useState<boolean>(false);
    const [successMsg, setSuccessMsg] = useState<string>("");
    const [uploadItem, setUploadItem] = useState<any>(undefined);
    const [uploadedItemRes, setUploadedItemRes] = useState<ToxicClientCheckUploadDataResponse>(initialUploadedItemRes);
    const [isAuditLogModalOpen, setIsAuditLogModalOpen] = useState<boolean>(false);
    const [tccAuditLogData, setTccAuditLogData] = useState<ToxicClientCheckAuditLogData[]>([]);
    const [isAccRestrictionsModalOpen, setIsAccRestrictionsModalOpen] = useState<boolean>(false);
    const [tccAccRestrictionsForm] = Form.useForm();
    const [tccClientRestrictionsForm] = Form.useForm();
    const [isClientRestrictionsModalOpen, setIsClientRestrictionsModalOpen] = useState<boolean>(false);
    const [currModalId, setCurrModalId] = useState<any>({
        name: "",
        serverBrand: "",
        type: "",
        id: 0,
    });
    const [cfData, setCfData] = useState<ToxicClientCheckCommonFilterPops>({
        brands: [],
        regulators: [],
        users: [],
        crmBrands: [],
        tcLabelProps: [],
        tcLabelAttrs: [],
        tcRestrictions: [],
        cleanSymbols: [],
    });
    const [currTriggeredByAccs, setCurrTriggeredByAccs] = useState<triggeredByAccounts[]>([]);
    const [isExporting, setIsExporting] = useState<boolean>(false);
    const [isAuditLogLoading, setIsAuditLogLoading] = useState<boolean>(false);

    // brands confirmation in preview upload modal
    const [brandsConfirmationForm] = Form.useForm();
    const [unconfirmedBrands, setUnconfirmedBrands] = useState<number>(0);

    const columns = useMemo(
        () => [
            /**
             *  23/04/2024 - Xin
             *  hiding this away for now, might use in the future
             */
            // {
            //     title: "Account ID",
            //     dataIndex: "accountServerIdPairs",
            //     key: "accountServerIdPairs",
            //     options: {
            //         filter: {
            //             type: ComponentType.rebateAccounts,
            //             value: servers,
            //             inputProps: {
            //                 mode: "multiple",
            //             },
            //         },
            //         form: filterForm,
            //         visible: false,
            //     },
            // },
            DTColProps.Small({
                title: "Client ID",
                dataIndex: "clientId",
                key: "clientId",
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: [],
                        inputProps: {
                            mode: "tags",
                            placeholder: "Please input client id",
                            tokenSeparators: [",", "，", " "],
                        },
                    },
                },
                render: (clientId: number, rowData: ToxicClientCheckData) => {
                    return (
                        <div className="clientId-col">
                            <div>{isEmptyOrNull(clientId) ? "-" : clientId}</div>
                        </div>
                    );
                },
            }),
            DTColProps.Middle({
                title: "Client Name",
                dataIndex: "clientName",
                key: "clientName",
                render: (clientName: string) => (isEmptyOrNull(clientName) ? "-" : clientName),
            }),
            DTColProps.Small({
                title: "CRM Brand",
                dataIndex: "crmBrandId",
                key: "crmBrandId",
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: cfData.crmBrands.map((x: CrmBrandList) => ({
                            text: x.brand,
                            value: x.id,
                        })),
                        inputProps: {
                            mode: "multiple",
                        },
                    },
                },
                render: (crmBrandId: number) => {
                    let thisCrmBrand: any = cfData.crmBrands.find((x: CrmBrandList) => x.id === crmBrandId);
                    if (isEmptyOrNull(thisCrmBrand)) return "-";
                    else return thisCrmBrand.brand;
                },
            }),
            DTColProps.Small({
                title: "Regulator",
                dataIndex: "regulatorId",
                key: "regulatorId",
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: cfData.regulators.map((x: RegulatorList) => ({
                            text: x.name,
                            value: x.id,
                        })),
                        inputProps: {
                            mode: "multiple",
                        },
                    },
                },
                render: (regulatorId: number) => {
                    let thisRegulator: any = cfData.regulators.find((x: RegulatorList) => x.id === regulatorId);
                    if (isEmptyOrNull(thisRegulator)) return "-";
                    else return thisRegulator.name;
                },
            }),
            DTColProps.XSmall(
                {
                    title: "Account Count",
                    dataIndex: "accountCount",
                    key: "accountCount",
                },
                ["text-center"]
            ),
            DTColProps.XSmall(
                {
                    title: "Entry Count",
                    dataIndex: "entryCount",
                    key: "entryCount",
                },
                ["text-center"]
            ),
            DTColProps.DateTime({
                title: "Last Issued Date (Server)",
                dataIndex: "lastIssuedDate",
                key: "lastIssuedDate",
                render: (lastIssuedDate: string) => DataTableColumnRender.DateTime_ServerDateOnly(lastIssuedDate),
                sorter: true,
            }),
            DTColProps.DateTime({
                title: "Last Entry Updated Date (Local)",
                dataIndex: "lastUpdatedDateUtc",
                key: "lastUpdatedDateUtc",
                render: (lastUpdatedDateUtc: string) => DataTableColumnRender.DateTime(lastUpdatedDateUtc),
                sorter: true,
                // defaultSortOrder: "descend",
            }),
        ],
        [servers, cfData, labels]
    );

    const expandedColumns = useMemo(
        () => [
            DTColProps.XSmall({
                title: "Info",
                dataIndex: "accountId",
                key: "accountId",
                fixed: "left",
                render: (accountId: number, rowData: ToxicClientCheckAccountDetails) => {
                    let thisServer: any = Object.values(servers).find((x: any) => x.value === rowData.serverId);
                    let thisBrand: any = cfData.brands.find((y: BrandsList) => y.id === rowData.brandId);
                    return (
                        <div className="account-info-col">
                            <div className="account-item">
                                <span>Account ID</span>
                                <span>{isEmptyOrNull(accountId) ? "-" : accountId}</span>
                            </div>
                            <div className="account-item">
                                <span>Server</span>
                                <span>{isEmptyOrNull(thisServer) ? "-" : thisServer && thisServer.text}</span>
                            </div>
                            <div className="account-item">
                                <span>Brand</span>
                                <span>{isEmptyOrNull(thisBrand) ? "-" : thisBrand && thisBrand.brand}</span>
                            </div>
                            <div className={`tccl-mode-tag tccl-mode-tag-${TOXIC_CLIENT_CHECK_ENTRY_CRM_MAPPING_MODE[rowData.crmMappingMode]}`}>
                                {TOXIC_CLIENT_CHECK_ENTRY_CRM_MAPPING_MODE[rowData.crmMappingMode]}
                                <Tooltip
                                    placement="right"
                                    title={`${
                                        rowData.crmMappingMode === 0
                                            ? "Client data and brand mapping populated from pipeline"
                                            : "Self populated client data and brand mapping by users"
                                    }`}
                                >
                                    <span>
                                        <InfoCircleOutlined />
                                    </span>
                                </Tooltip>
                            </div>
                        </div>
                    );
                },
            }),
            DTColProps.Small({
                title: "Inspector & Status",
                dataIndex: "inspector",
                key: "inspector",
                render: (inspector: number, rowData: ToxicClientCheckAccountDetails) => {
                    let idx = cfData.users.findIndex(x => x.id === inspector);
                    return (
                        <div className="inspector-col">
                            {idx > -1 ? (
                                <>
                                    <div className="title">{cfData.users[idx].email}</div>
                                    <div className="value">{cfData.users[idx].name}</div>
                                </>
                            ) : (
                                <div className="value">-</div>
                            )}
                            <div className="title">Issued on</div>
                            <div className="value">
                                {isEmptyOrNull(rowData.issuedDate) ? "-" : DataTableColumnRender.DateTime_ServerDateOnly(rowData.issuedDate)}
                            </div>
                            <div className={`status-col-statusTag status-col-sts-${rowData.status}`}>
                                {TOXIC_CLIENT_CHECK_ENTRY_STATUS[rowData.status]}
                            </div>
                        </div>
                    );
                },
            }),
            {
                title: "Illegal Profit",
                dataIndex: "illegalProfit",
                key: "illegalProfit",
                width: "10vw",
                render: (illegalProfit: any, rowData: ToxicClientCheckAccountDetails) => {
                    return (
                        <div className="tccl-grouped-info-col">
                            <div className="deductions-grouped-item">
                                <span>Illegal Profit :</span>
                                <span>{isEmptyOrNull(illegalProfit) ? "-" : illegalProfit}</span>
                            </div>
                            <div className="deductions-grouped-item">
                                <span>Suggested Deduction :</span>
                                <span>{isEmptyOrNull(rowData.suggestedDeduction) ? "-" : rowData.suggestedDeduction}</span>
                            </div>
                            <div className="deductions-grouped-item">
                                <span>Actual Deduction :</span>
                                <span>{isEmptyOrNull(rowData.actualDeduction) ? "-" : rowData.actualDeduction}</span>
                            </div>
                            <div className="deductions-grouped-item">
                                <span>Deduction Date (Server) :</span>
                                <span>
                                    {isEmptyOrNull(rowData.deductionDate)
                                        ? "-"
                                        : DataTableColumnRender.DateTime_ServerDateOnly(rowData.deductionDate)}
                                </span>
                            </div>
                            <div className="deductions-grouped-item">
                                <span>Account Currency :</span>
                                <span>{isEmptyOrNull(rowData.accountCurrency) ? "-" : rowData.accountCurrency}</span>
                            </div>
                            <div className="deductions-grouped-item">
                                <span>USD Conversion Rate :</span>
                                <span>{isEmptyOrNull(rowData.accountCurrencyUsdConvRate) ? "-" : rowData.accountCurrencyUsdConvRate}</span>
                            </div>
                        </div>
                    );
                },
            },
            DTColProps.Middle({
                title: "Toxic Client Label & Attributes",
                dataIndex: "labelAttrDetails",
                key: "labelAttrDetails",
                // width: "13vw",
                render: (labelAttrDetails: ToxicClientCheckAttrDetails[], rowData: ToxicClientCheckAccountDetails) => {
                    return (
                        <>
                            <div style={{ display: "flex", justifyContent: "center", marginBottom: 10 }} key={rowData.labelId}>
                                <div
                                    style={{
                                        backgroundColor: `${rowData.labelColor}`,
                                        color: "black",
                                        padding: "0.7vh 0.651vw",
                                        wordWrap: "break-word",
                                        borderRadius: 5,
                                        fontSize: "0.7rem",
                                        fontWeight: "bold",
                                        width: "100%",
                                        textAlign: "center",
                                    }}
                                >
                                    {rowData.labelName}
                                </div>
                            </div>
                            <div className="tccl-grouped-info-col" key={"symbols"}>
                                {!isEmptyOrNull(rowData.symbols) && (
                                    <div className="label-attr-grouped-item">
                                        <span>Symbols :</span>
                                        <span>{rowData.symbols.join(", ")}</span>
                                    </div>
                                )}
                            </div>
                            {labelAttrDetails.map((currAttr: ToxicClientCheckAttrDetails) => {
                                if (currAttr.isAttrActive) {
                                    if (currAttr.attrValue !== null) {
                                        switch (currAttr.uiElementType) {
                                            case TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.DropdownMenu:
                                            case TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.Checkbox:
                                                return (
                                                    <div className="tccl-grouped-info-col" key={currAttr.attrId}>
                                                        <div className="label-attr-grouped-item">
                                                            <span>{currAttr.attrName} :</span>
                                                            <span>
                                                                {currAttr.attrValue.length > 0 ? currAttr.attrValue.join(", ") : currAttr.attrValue}
                                                            </span>
                                                        </div>
                                                    </div>
                                                );
                                            case TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.ToggleSwitcher:
                                                return (
                                                    <div className="tccl-grouped-info-col" key={currAttr.attrId}>
                                                        <div className="label-attr-grouped-item">
                                                            <span>{currAttr.attrName} :</span>
                                                            <span>{currAttr.attrValue ? "True" : "False"}</span>
                                                        </div>
                                                    </div>
                                                );
                                            case TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.DatePicker:
                                                return (
                                                    <div className="tccl-grouped-info-col" key={currAttr.attrId}>
                                                        <div className="label-attr-grouped-item">
                                                            <span>
                                                                {`${currAttr.attrName} (${
                                                                    currAttr.timeZoneFormat === TIMEZONE_FORMATS_ENUM.Server
                                                                        ? TIMEZONE_FORMATS[TIMEZONE_FORMATS_ENUM.Server]
                                                                        : currAttr.timeZoneFormat === TIMEZONE_FORMATS_ENUM.Local
                                                                        ? TIMEZONE_FORMATS[TIMEZONE_FORMATS_ENUM.Local]
                                                                        : TIMEZONE_FORMATS[TIMEZONE_FORMATS_ENUM.UTC]
                                                                }) :`}
                                                            </span>
                                                            <span>{DataTableColumnRender.DateTime_ServerDateOnly(currAttr.attrValue)}</span>
                                                        </div>
                                                    </div>
                                                );
                                            case TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.DateTimePicker:
                                                return (
                                                    <div className="tccl-grouped-info-col" key={currAttr.attrId}>
                                                        <div className="label-attr-grouped-item">
                                                            <span>
                                                                {`${currAttr.attrName} (${
                                                                    currAttr.timeZoneFormat === TIMEZONE_FORMATS_ENUM.Server
                                                                        ? TIMEZONE_FORMATS[TIMEZONE_FORMATS_ENUM.Server]
                                                                        : currAttr.timeZoneFormat === TIMEZONE_FORMATS_ENUM.Local
                                                                        ? TIMEZONE_FORMATS[TIMEZONE_FORMATS_ENUM.Local]
                                                                        : TIMEZONE_FORMATS[TIMEZONE_FORMATS_ENUM.UTC]
                                                                }) :`}
                                                            </span>
                                                            <span>
                                                                {currAttr.timeZoneFormat === TIMEZONE_FORMATS_ENUM.Server
                                                                    ? DataTableColumnRender.DateTime_ServerTime(currAttr.attrValue)
                                                                    : currAttr.timeZoneFormat === TIMEZONE_FORMATS_ENUM.Local
                                                                    ? DataTableColumnRender.DateTime(currAttr.attrValue)
                                                                    : moment
                                                                          .parseZone(currAttr.attrValue, "YYYY-MM-DDTHH:mm:ss")
                                                                          .utc(true)
                                                                          .format("YYYY-MM-DD HH:mm:ssZZ")}
                                                            </span>
                                                        </div>
                                                    </div>
                                                );
                                            default:
                                                return (
                                                    <div className="tccl-grouped-info-col" key={currAttr.attrId}>
                                                        <div className="label-attr-grouped-item">
                                                            <span>{currAttr.attrName} :</span>
                                                            <span>{currAttr.attrValue}</span>
                                                        </div>
                                                    </div>
                                                );
                                        }
                                    }
                                }
                            })}
                        </>
                    );
                },
            }),
            {
                title: "Note",
                dataIndex: "note",
                key: "note",
                width: "9vw",
            },
            DTColProps.Small({
                title: "Restrictions",
                dataIndex: "restrictions",
                key: "restrictions",
                render: (_: any, rowData: ToxicClientCheckAccountDetails) => {
                    let thisRestrictionList: any[] = [];
                    Object.keys(rowData.accountRestrictions).forEach((currKey: string) => {
                        if (typeof rowData.accountRestrictions[currKey as keyof ToxicClientCheckAccountRestrictions] === "string") {
                            // for string values
                            thisRestrictionList.push(currKey);
                        } else {
                            if (rowData.accountRestrictions[currKey as keyof ToxicClientCheckAccountRestrictions] === true) {
                                thisRestrictionList.push(currKey);
                            }
                        }
                    });
                    Object.keys(rowData.clientRestrictions).forEach((currKey: string) => {
                        if (rowData.clientRestrictions[currKey as keyof ToxicClientCheckClientRestrictions] === true) {
                            thisRestrictionList.push(currKey);
                        }
                    });
                    return (
                        <div className="all-restrictions-col">
                            <ul className="unordered-list-style">
                                {thisRestrictionList.map((currRestriction: string) => (
                                    <li key={currRestriction} className="restriction-list-item">
                                        {TOXIC_CLIENT_CHECK_RESTRICTIONS_NAMES[currRestriction]}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    );
                },
            }),
            DTColProps.Small({
                title: "Dates (Local)",
                dataIndex: "lastUpdatedDateUtc",
                key: "lastUpdatedDateUtc",
                fixed: "right",
                render: (lastUpdatedDateUtc: string, rowData: ToxicClientCheckAccountDetails) => {
                    let thisOriUser: any = cfData.users.find((y: UsersList) => y.id === rowData.createdBy);
                    let lastUpdatedUser: any = cfData.users.find((y: UsersList) => y.id === rowData.lastUpdatedBy);
                    return (
                        <div className="grouped-dates-col">
                            <div className="created-datetime">
                                <div className="by-user-title">Created by:</div>
                                <div className="by-user">{thisOriUser && thisOriUser.name}</div>
                                <div className="value">{DataTableColumnRender.DateTime(rowData.createdDateUtc)}</div>
                            </div>
                            <div className="last-updated-datetime">
                                <div className="by-user-title">Last updated by:</div>
                                <div className="by-user">{lastUpdatedUser && lastUpdatedUser.name}</div>
                                <div className="value">{DataTableColumnRender.DateTime(rowData.lastUpdatedDateUtc)}</div>
                            </div>
                        </div>
                    );
                },
            }),
            DTColProps.XXSmall({
                title: "",
                dataIndex: "actions",
                key: "actions",
                fixed: "right",
                render: (_: any, rowData: ToxicClientCheckAccountDetails) => (
                    <div className="vertical-actions-col">
                        {enableUpdate && (
                            <Tooltip title="Edit Restrictions" placement="right" key={`edit-r-${rowData.entryId}`}>
                                <Button
                                    type="text"
                                    icon={<SlidersOutlined />}
                                    onClick={() =>
                                        accComponentCallback(CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK, { key: "edit_acc_restrictions", data: rowData })
                                    }
                                />
                            </Tooltip>
                        )}
                        {enableUpdate && (
                            <Tooltip title="Edit" placement="right" key={`edit-tcc-${rowData.entryId}`}>
                                <Button
                                    type="text"
                                    icon={<EditOutlined />}
                                    onClick={() => accComponentCallback(CALLBACK_KEY.DO_EDIT, { data: rowData, filterParams: filterParams })}
                                />
                            </Tooltip>
                        )}
                        {enableUpdate && (
                            <Tooltip title="Delete" placement="right" key={`delete-tcc-${rowData.entryId}`}>
                                <Popconfirm
                                    placement="topRight"
                                    title={"Confirm to delete?"}
                                    icon={<DeleteOutlined style={{ fontSize: "0.875rem" }} />}
                                    onConfirm={() => accComponentCallback(CALLBACK_KEY.DO_DELETE, rowData)}
                                    okText={"Yes"}
                                    cancelText={"No"}
                                    style={{ width: "15.765vw" }}
                                >
                                    <Button
                                        type="text"
                                        icon={<DeleteOutlined />}
                                        onClick={e => {
                                            e.preventDefault();
                                        }}
                                    />
                                </Popconfirm>
                            </Tooltip>
                        )}
                        <Tooltip title="View Logs" placement="right" key={`view-l-${rowData.entryId}`}>
                            <Button
                                type="text"
                                icon={<HistoryOutlined />}
                                onClick={() =>
                                    accComponentCallback(CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK, { key: "view_audit_logs", data: rowData })
                                }
                            />
                        </Tooltip>
                    </div>
                ),
            }),
        ],
        [servers, cfData, enableUpdate, filterParams]
    );

    const accColumns = useMemo(
        () => [
            DTColProps.XSmall({
                title: "Info",
                dataIndex: "accountId",
                key: "accountId",
                fixed: "left",
                render: (accountId: number, rowData: ToxicClientCheckAccountDetails) => {
                    let thisServer: any = Object.values(servers).find((x: any) => x.value === rowData.serverId);
                    let thisBrand: any = cfData.brands.find((y: BrandsList) => y.id === rowData.brandId);
                    return (
                        <div className="account-info-col">
                            <div className="account-item">
                                <span>Account ID</span>
                                <span>{accountId}</span>
                            </div>
                            <div className="account-item">
                                <span>Server</span>
                                <span>{isEmptyOrNull(thisServer) ? "-" : thisServer && thisServer.text}</span>
                            </div>
                            <div className="account-item">
                                <span>Brand</span>
                                <span>{isEmptyOrNull(thisBrand) ? "-" : thisBrand && thisBrand.brand}</span>
                            </div>
                            <div className={`tccl-mode-tag tccl-mode-tag-${TOXIC_CLIENT_CHECK_ENTRY_CRM_MAPPING_MODE[rowData.crmMappingMode]}`}>
                                {TOXIC_CLIENT_CHECK_ENTRY_CRM_MAPPING_MODE[rowData.crmMappingMode]}
                                <Tooltip
                                    placement="right"
                                    title={`${
                                        rowData.crmMappingMode === 0
                                            ? "Client data and brand mapping populated from pipeline"
                                            : "Self populated client data and brand mapping by users"
                                    }`}
                                >
                                    <span>
                                        <InfoCircleOutlined />
                                    </span>
                                </Tooltip>
                            </div>
                        </div>
                    );
                },
            }),
            DTColProps.XSmall({
                title: "CRM Info",
                dataIndex: "clientId",
                key: "clientId",
                fixed: "left",
                render: (clientId: number, rowData: ToxicClientCheckAccountDetails) => {
                    let thisCRMBrand: any = cfData.crmBrands.find((y: CrmBrandList) => y.id === rowData.crmBrandId);
                    let thisRegulator: any = cfData.regulators.find((y: RegulatorList) => y.id === rowData.regulatorId);
                    return (
                        <div className="account-info-col">
                            <div className="account-item">
                                <span>Client ID</span>
                                <span>{isEmptyOrNull(clientId) ? "-" : clientId}</span>
                            </div>
                            <div className="account-item">
                                <span>Client Name</span>
                                <span>{isEmptyOrNull(rowData.clientName) ? "-" : rowData.clientName}</span>
                            </div>
                            <div className="account-item">
                                <span>CRM Brand</span>
                                <span>{isEmptyOrNull(thisCRMBrand) ? "-" : thisCRMBrand && thisCRMBrand.brand}</span>
                            </div>
                            <div className="account-item">
                                <span>Regulator</span>
                                <span>{isEmptyOrNull(thisRegulator) ? "-" : thisRegulator && thisRegulator.name}</span>
                            </div>
                        </div>
                    );
                },
            }),
            DTColProps.Small({
                title: "Inspector & Status",
                dataIndex: "inspector",
                key: "inspector",
                render: (inspector: number, rowData: ToxicClientCheckAccountDetails) => {
                    let idx = cfData.users.findIndex(x => x.id === inspector);
                    return (
                        <div className="inspector-col">
                            {idx > -1 ? (
                                <>
                                    <div className="title">{cfData.users[idx].email}</div>
                                    <div className="value">{cfData.users[idx].name}</div>
                                </>
                            ) : (
                                <div className="value">-</div>
                            )}
                            <div className="title">Issued on</div>
                            <div className="extra">
                                {isEmptyOrNull(rowData.issuedDate) ? "-" : DataTableColumnRender.DateTime_ServerDateOnly(rowData.issuedDate)}
                            </div>
                            <div className={`status-col-statusTag status-col-sts-${rowData.status}`}>
                                {TOXIC_CLIENT_CHECK_ENTRY_STATUS[rowData.status]}
                            </div>
                        </div>
                    );
                },
            }),
            {
                title: "Illegal Profit",
                dataIndex: "illegalProfit",
                key: "illegalProfit",
                width: "10vw",
                render: (illegalProfit: any, rowData: ToxicClientCheckAccountDetails) => {
                    return (
                        <div className="tccl-grouped-info-col">
                            <div className="deductions-grouped-item">
                                <span>Illegal Profit :</span>
                                <span>{isEmptyOrNull(illegalProfit) ? "-" : illegalProfit}</span>
                            </div>
                            <div className="deductions-grouped-item">
                                <span>Suggested Deduction :</span>
                                <span>{isEmptyOrNull(rowData.suggestedDeduction) ? "-" : rowData.suggestedDeduction}</span>
                            </div>
                            <div className="deductions-grouped-item">
                                <span>Actual Deduction :</span>
                                <span>{isEmptyOrNull(rowData.actualDeduction) ? "-" : rowData.actualDeduction}</span>
                            </div>
                            <div className="deductions-grouped-item">
                                <span>Deduction Date (Server) :</span>
                                <span>
                                    {isEmptyOrNull(rowData.deductionDate)
                                        ? "-"
                                        : DataTableColumnRender.DateTime_ServerDateOnly(rowData.deductionDate)}
                                </span>
                            </div>
                            <div className="deductions-grouped-item">
                                <span>Account Currency :</span>
                                <span>{isEmptyOrNull(rowData.accountCurrency) ? "-" : rowData.accountCurrency}</span>
                            </div>
                            <div className="deductions-grouped-item">
                                <span>USD Conversion Rate :</span>
                                <span>{isEmptyOrNull(rowData.accountCurrencyUsdConvRate) ? "-" : rowData.accountCurrencyUsdConvRate}</span>
                            </div>
                        </div>
                    );
                },
            },
            DTColProps.Middle({
                title: "Toxic Client Label & Attributes",
                dataIndex: "labelAttrDetails",
                key: "labelAttrDetails",
                // width: "10vw",
                render: (labelAttrDetails: ToxicClientCheckAttrDetails[], rowData: ToxicClientCheckAccountDetails) => {
                    return (
                        <>
                            <div style={{ display: "flex", justifyContent: "center", marginBottom: 10 }} key={rowData.labelId}>
                                <div
                                    style={{
                                        backgroundColor: `${rowData.labelColor}`,
                                        color: "black",
                                        padding: "0.7vh 0.651vw",
                                        wordWrap: "break-word",
                                        borderRadius: 5,
                                        fontSize: "0.7rem",
                                        fontWeight: "bold",
                                        width: "100%",
                                        textAlign: "center",
                                    }}
                                >
                                    {rowData.labelName}
                                </div>
                            </div>
                            <div className="tccl-grouped-info-col" key={"symbols"}>
                                {!isEmptyOrNull(rowData.symbols) && (
                                    <div className="label-attr-grouped-item">
                                        <span>Symbols :</span>
                                        <span>{rowData.symbols.join(", ")}</span>
                                    </div>
                                )}
                            </div>
                            {labelAttrDetails.map((currAttr: ToxicClientCheckAttrDetails) => {
                                if (currAttr.isAttrActive) {
                                    if (currAttr.attrValue !== null) {
                                        switch (currAttr.uiElementType) {
                                            case TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.DropdownMenu:
                                            case TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.Checkbox:
                                                return (
                                                    <div className="tccl-grouped-info-col" key={currAttr.attrId}>
                                                        <div className="label-attr-grouped-item">
                                                            <span>{currAttr.attrName} :</span>
                                                            <span>
                                                                {currAttr.attrValue.length > 0 ? currAttr.attrValue.join(", ") : currAttr.attrValue}
                                                            </span>
                                                        </div>
                                                    </div>
                                                );
                                            case TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.ToggleSwitcher:
                                                return (
                                                    <div className="tccl-grouped-info-col" key={currAttr.attrId}>
                                                        <div className="label-attr-grouped-item">
                                                            <span>{currAttr.attrName} :</span>
                                                            <span>{currAttr.attrValue ? "Yes" : "No"}</span>
                                                        </div>
                                                    </div>
                                                );
                                            case TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.DatePicker:
                                                return (
                                                    <div className="tccl-grouped-info-col" key={currAttr.attrId}>
                                                        <div className="label-attr-grouped-item">
                                                            <span>
                                                                {`${currAttr.attrName} (${
                                                                    currAttr.timeZoneFormat === TIMEZONE_FORMATS_ENUM.Server
                                                                        ? TIMEZONE_FORMATS[TIMEZONE_FORMATS_ENUM.Server]
                                                                        : currAttr.timeZoneFormat === TIMEZONE_FORMATS_ENUM.Local
                                                                        ? TIMEZONE_FORMATS[TIMEZONE_FORMATS_ENUM.Local]
                                                                        : TIMEZONE_FORMATS[TIMEZONE_FORMATS_ENUM.UTC]
                                                                }) :`}
                                                            </span>
                                                            <span>{DataTableColumnRender.DateTime_ServerDateOnly(currAttr.attrValue)}</span>
                                                        </div>
                                                    </div>
                                                );
                                            case TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.DateTimePicker:
                                                return (
                                                    <div className="tccl-grouped-info-col" key={currAttr.attrId}>
                                                        <div className="label-attr-grouped-item">
                                                            <span>
                                                                {`${currAttr.attrName} (${
                                                                    currAttr.timeZoneFormat === TIMEZONE_FORMATS_ENUM.Server
                                                                        ? TIMEZONE_FORMATS[TIMEZONE_FORMATS_ENUM.Server]
                                                                        : currAttr.timeZoneFormat === TIMEZONE_FORMATS_ENUM.Local
                                                                        ? TIMEZONE_FORMATS[TIMEZONE_FORMATS_ENUM.Local]
                                                                        : TIMEZONE_FORMATS[TIMEZONE_FORMATS_ENUM.UTC]
                                                                }) :`}
                                                            </span>
                                                            <span>
                                                                {currAttr.timeZoneFormat === TIMEZONE_FORMATS_ENUM.Server
                                                                    ? DataTableColumnRender.DateTime_ServerTime(currAttr.attrValue)
                                                                    : currAttr.timeZoneFormat === TIMEZONE_FORMATS_ENUM.Local
                                                                    ? DataTableColumnRender.DateTime(currAttr.attrValue)
                                                                    : moment
                                                                          .parseZone(currAttr.attrValue, "YYYY-MM-DDTHH:mm:ss")
                                                                          .utc(true)
                                                                          .format("YYYY-MM-DD HH:mm:ssZZ")}
                                                            </span>
                                                        </div>
                                                    </div>
                                                );
                                            default:
                                                return (
                                                    <div className="tccl-grouped-info-col" key={currAttr.attrId}>
                                                        <div className="label-attr-grouped-item">
                                                            <span>{currAttr.attrName} :</span>
                                                            <span>{currAttr.attrValue}</span>
                                                        </div>
                                                    </div>
                                                );
                                        }
                                    }
                                }
                            })}
                        </>
                    );
                },
            }),
            {
                title: "Note",
                dataIndex: "note",
                key: "note",
                width: "9vw",
            },
            DTColProps.Small({
                title: "Restrictions",
                dataIndex: "restrictions",
                key: "restrictions",
                render: (_: any, rowData: ToxicClientCheckAccountDetails) => {
                    let thisRestrictionList: any[] = [];
                    Object.keys(rowData.accountRestrictions).forEach((currKey: string) => {
                        if (typeof rowData.accountRestrictions[currKey as keyof ToxicClientCheckAccountRestrictions] === "string") {
                            // for string values
                            thisRestrictionList.push(currKey);
                        } else {
                            if (rowData.accountRestrictions[currKey as keyof ToxicClientCheckAccountRestrictions] === true) {
                                thisRestrictionList.push(currKey);
                            }
                        }
                    });
                    Object.keys(rowData.clientRestrictions).forEach((currKey: string) => {
                        if (rowData.clientRestrictions[currKey as keyof ToxicClientCheckClientRestrictions] === true) {
                            thisRestrictionList.push(currKey);
                        }
                    });
                    return (
                        <div className="all-restrictions-col">
                            <ul className="unordered-list-style">
                                {thisRestrictionList.map((currRestriction: string) => (
                                    <li key={currRestriction} className="restriction-list-item">
                                        {TOXIC_CLIENT_CHECK_RESTRICTIONS_NAMES[currRestriction]}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    );
                },
            }),
            DTColProps.Small({
                title: "Dates (Local)",
                dataIndex: "lastUpdatedDateUtc",
                key: "lastUpdatedDateUtc",
                fixed: "right",
                render: (lastUpdatedDateUtc: string, rowData: ToxicClientCheckAccountDetails) => {
                    let thisOriUser: any = cfData.users.find((y: UsersList) => y.id === rowData.createdBy);
                    let lastUpdatedUser: any = cfData.users.find((y: UsersList) => y.id === rowData.lastUpdatedBy);
                    return (
                        <div className="grouped-dates-col">
                            <div className="created-datetime">
                                <div className="by-user-title">Created by:</div>
                                <div className="by-user">{thisOriUser && thisOriUser.name}</div>
                                <div className="value">{DataTableColumnRender.DateTime(rowData.createdDateUtc)}</div>
                            </div>
                            <div className="last-updated-datetime">
                                <div className="by-user-title">Last updated by:</div>
                                <div className="by-user">{lastUpdatedUser && lastUpdatedUser.name}</div>
                                <div className="value">{DataTableColumnRender.DateTime(rowData.lastUpdatedDateUtc)}</div>
                            </div>
                        </div>
                    );
                },
            }),
            DTColProps.XXSmall({
                title: "",
                dataIndex: "actions",
                key: "actions",
                fixed: "right",
                render: (_: any, rowData: ToxicClientCheckAccountDetails) => (
                    <div className="vertical-actions-col">
                        {enableUpdate && (
                            <Tooltip title="Edit Restrictions" placement="right" key={`edit-r-${rowData.entryId}`}>
                                <Button
                                    type="text"
                                    icon={<SlidersOutlined />}
                                    onClick={() =>
                                        accComponentCallback(CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK, { key: "edit_acc_restrictions", data: rowData })
                                    }
                                />
                            </Tooltip>
                        )}
                        {enableUpdate && (
                            <Tooltip title="Edit" placement="right" key={`edit-tcc-${rowData.entryId}`}>
                                <Button
                                    type="text"
                                    icon={<EditOutlined />}
                                    onClick={() => accComponentCallback(CALLBACK_KEY.DO_EDIT, { data: rowData, filterParams: filterParams })}
                                />
                            </Tooltip>
                        )}
                        {enableUpdate && (
                            <Tooltip title="Delete" placement="right" key={`delete-tcc-${rowData.entryId}`}>
                                <Popconfirm
                                    placement="topRight"
                                    title={"Confirm to delete?"}
                                    icon={<DeleteOutlined style={{ fontSize: "0.875rem" }} />}
                                    onConfirm={() => accComponentCallback(CALLBACK_KEY.DO_DELETE, rowData)}
                                    okText={"Yes"}
                                    cancelText={"No"}
                                    style={{ width: "15.765vw" }}
                                >
                                    <Button
                                        type="text"
                                        icon={<DeleteOutlined />}
                                        onClick={e => {
                                            e.preventDefault();
                                        }}
                                    />
                                </Popconfirm>
                            </Tooltip>
                        )}
                        <Tooltip title="View Logs" placement="right" key={`view-l-${rowData.entryId}`}>
                            <Button
                                type="text"
                                icon={<HistoryOutlined />}
                                onClick={() =>
                                    accComponentCallback(CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK, { key: "view_audit_logs", data: rowData })
                                }
                            />
                        </Tooltip>
                    </div>
                ),
            }),
        ],
        [servers, cfData, enableUpdate, filterParams]
    );

    const previewColumns = [
        DTColProps.XSmall({
            title: "Row",
            dataIndex: "rowNum",
            key: "rowNum",
        }),
        DTColProps.XSmall({
            title: "Account ID",
            dataIndex: "accountId",
            key: "accountId",
        }),
        DTColProps.XSmall({
            title: "Server",
            dataIndex: "server",
            key: "server",
        }),
        DTColProps.XSmall({
            title: "CRM Mapping Mode",
            dataIndex: "crmMappingMode",
            key: "crmMappingMode",
            render: (crmMappingMode: number, rowData: ToxicClientCheckUploadEntryProps) => {
                if (
                    rowData.brand === null &&
                    rowData.clientId === null &&
                    rowData.clientName === null &&
                    rowData.crmBrand === null &&
                    rowData.regulator === null
                ) {
                    return (
                        <div
                            className={`tccl-mode-tag tccl-mode-tag-${
                                TOXIC_CLIENT_CHECK_ENTRY_CRM_MAPPING_MODE[TOXIC_CLIENT_CHECK_CRM_MAPPING_MODE_ENUM.Auto]
                            }`}
                        >
                            {TOXIC_CLIENT_CHECK_ENTRY_CRM_MAPPING_MODE[TOXIC_CLIENT_CHECK_CRM_MAPPING_MODE_ENUM.Auto]}
                            <Tooltip placement="top" title={"Client data and brand mapping populated from pipeline"}>
                                <span>
                                    <InfoCircleOutlined />
                                </span>
                            </Tooltip>
                        </div>
                    );
                } else {
                    return (
                        <div
                            className={`tccl-mode-tag tccl-mode-tag-${
                                TOXIC_CLIENT_CHECK_ENTRY_CRM_MAPPING_MODE[TOXIC_CLIENT_CHECK_CRM_MAPPING_MODE_ENUM.Manual]
                            }`}
                        >
                            {TOXIC_CLIENT_CHECK_ENTRY_CRM_MAPPING_MODE[TOXIC_CLIENT_CHECK_CRM_MAPPING_MODE_ENUM.Manual]}
                            <Tooltip placement="top" title={"Self populated client data and brand mapping by users"}>
                                <span>
                                    <InfoCircleOutlined />
                                </span>
                            </Tooltip>
                        </div>
                    );
                }
            },
        }),
        DTColProps.Small({
            title: "Toxic Client Label",
            dataIndex: "toxicClientLabel",
            key: "toxicClientLabel",
        }),
        DTColProps.XLarge({
            title: "Remarks",
            dataIndex: "remarks",
            key: "remarks",
            render: (remarks: string) => <div className="preview-upload-remarks-col">{remarks}</div>,
        }),
    ];

    const getExpandedRowRender = (rowData: ToxicClientCheckData) => {
        let newData: any = rowData.accountDetails.map((x: ToxicClientCheckAccountDetails) => {
            let currBrand = cfData.brands.find((y: BrandsList) => y.id === x.brandId);
            return {
                ...x,
                brandName: currBrand !== undefined ? currBrand.brand : "",
                clientRestrictions: rowData.clientRestrictions,
            };
        });

        return (
            <div className="tccl-expandable-table">
                <FlexiDataTable
                    bordered
                    rowKeyProperty="entryId"
                    title="Accounts"
                    columns={expandedColumns}
                    options={{
                        enableFilter: false,
                        showHideColumns: false,
                    }}
                    dataSource={newData}
                    callback={expandedComponentCallback}
                    loading={false}
                    {...(newData.length < 10 && { pagination: false })}
                />
            </div>
        );
    };

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.HANDLE_PAGINATION_SORTING:
                FormData.sorter.order === undefined
                    ? setSorting("maxIssuedDate,desc|entryId,desc")
                    : setSorting(
                          FormData.sorter.column && FormData.sorter.order
                              ? `${FormData.sorter.column.key === "lastUpdatedDateUtc" ? "maxLastUpdatedDateUtc" : "maxIssuedDate"},${
                                    FormData.sorter.order === "ascend" ? "asc" : "desc"
                                }|entryId,desc`
                              : ""
                      );
                setPagination(prev => ({ ...prev, current: FormData.pagination.current, pageSize: FormData.pagination.pageSize }));
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK:
                let thisCrmBrand: any = Object.values(cfData.crmBrands).find((x: any) => x.id === FormData.data.crmBrandId);
                if (FormData.key === "edit_client_restrictions") {
                    tccClientRestrictionsForm.setFieldValue("clientId", FormData.data.clientId);
                    tccClientRestrictionsForm.setFieldValue("crmServerId", FormData.data.crmServerId);
                    tccClientRestrictionsForm.setFieldValue("crmBrandId", FormData.data.crmBrandId);
                    tccClientRestrictionsForm.setFieldValue("regulatorId", FormData.data.regulatorId);
                    tccClientRestrictionsForm.setFieldsValue(FormData.data.clientRestrictions);

                    setCurrModalId({
                        name: "client",
                        type: "restriction",
                        id: FormData.data.clientId,
                        serverBrand: isEmptyOrNull(thisCrmBrand) ? "" : thisCrmBrand.brand,
                    });
                    setIsClientRestrictionsModalOpen(true);
                }
                break;
            default:
                break;
        }
    };

    const expandedComponentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, rowData: any) => {
        switch (type) {
            case CALLBACK_KEY.DO_EDIT:
                setIsFirstOpt(prev => !prev);
                setFormObject({
                    mode: InnerPageActionMode.EDIT,
                    id: rowData.data["entryId"],
                    backActiveSegment: currentActiveSegment,
                    filterParams: rowData.filterParams,
                });
                setRefetch(prev => prev + 1);
                break;
            case CALLBACK_KEY.DO_DELETE:
                setIsLoading(true);
                apiRequest(APIs.DELETE_TOXIC_CLIENT_CHECK_ENTRY, { entryId: rowData["entryId"] })
                    .then((res: any) => {
                        ErrorMessageHandler(`Toxic client check entry`, SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                        setTimeout(() => setRunRefetchDataList(true), 300);
                    })
                    .catch((error: any) => {
                        ErrorCatchValidator(error, (err: any) =>
                            ErrorMessageHandler("toxic client check entry", SUCCESS_FAILED.FAILED_DELETE_DATA, err)
                        );
                    })
                    .finally(() => setIsLoading(false));
                break;
            case CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK:
                let thisServer: any = Object.values(servers).find((x: any) => x.value === rowData.data.serverId);
                if (rowData.key === "view_audit_logs") {
                    getAuditLog(rowData.data.entryId);
                    setCurrModalId({
                        name: "account",
                        type: "log",
                        id: rowData.data.accountId,
                        serverBrand: isEmptyOrNull(thisServer) ? "" : thisServer.text,
                    });
                    setIsAuditLogModalOpen(true);
                }
                if (rowData.key === "edit_acc_restrictions") {
                    tccAccRestrictionsForm.setFieldValue("accountId", rowData.data.accountId);
                    tccAccRestrictionsForm.setFieldValue("serverId", rowData.data.serverId);
                    tccAccRestrictionsForm.setFieldValue("brandId", rowData.data.brandId);
                    tccAccRestrictionsForm.setFieldsValue(rowData.data.accountRestrictions);
                    tccAccRestrictionsForm.setFieldValue("clientId", rowData.data.clientId);
                    tccAccRestrictionsForm.setFieldValue("crmServerId", rowData.data.crmServerId);
                    tccAccRestrictionsForm.setFieldValue("crmBrandId", rowData.data.crmBrandId);
                    tccAccRestrictionsForm.setFieldValue("regulatorId", rowData.data.regulatorId);
                    tccAccRestrictionsForm.setFieldsValue(rowData.data.clientRestrictions);

                    let triggeredByAccounts: any[] = [];
                    triggeredByAccounts.push({
                        accountId: rowData.data.accountId,
                        serverId: rowData.data.serverId,
                        brandId: rowData.data.brandId,
                    });
                    setCurrTriggeredByAccs(triggeredByAccounts);
                    setCurrModalId({
                        name: "account",
                        type: "restriction",
                        id: rowData.data.accountId,
                        serverBrand: isEmptyOrNull(thisServer) ? "" : thisServer.text,
                    });
                    setIsAccRestrictionsModalOpen(true);
                }
                break;
            default:
                break;
        }
    };

    const accComponentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.HANDLE_PAGINATION_SORTING:
                setAccPagination(prev => ({ ...prev, current: FormData.pagination.current, pageSize: FormData.pagination.pageSize }));
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.DO_EDIT:
                setIsFirstOpt(prev => !prev);
                setFormObject({
                    mode: InnerPageActionMode.EDIT,
                    id: FormData.data["entryId"],
                    backActiveSegment: currentActiveSegment,
                    filterParams: FormData.filterParams,
                });
                setRefetch(prev => prev + 1);
                break;
            case CALLBACK_KEY.DO_DELETE:
                setIsLoading(true);
                apiRequest(APIs.DELETE_TOXIC_CLIENT_CHECK_ENTRY, { entryId: FormData["entryId"] })
                    .then((res: any) => {
                        ErrorMessageHandler(`Toxic client check entry`, SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                        setTimeout(() => setRunRefetchDataList(true), 300);
                    })
                    .catch((error: any) => {
                        ErrorCatchValidator(error, (err: any) =>
                            ErrorMessageHandler("toxic client check entry", SUCCESS_FAILED.FAILED_DELETE_DATA, err)
                        );
                    })
                    .finally(() => setIsLoading(false));
                break;
            case CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK:
                let thisServer: any = Object.values(servers).find((x: any) => x.value === FormData.data.serverId);
                if (FormData.key === "view_audit_logs") {
                    getAuditLog(FormData.data.entryId);
                    setCurrModalId({
                        name: "account",
                        type: "log",
                        id: FormData.data.accountId,
                        serverBrand: isEmptyOrNull(thisServer) ? "" : thisServer.text,
                    });
                    setIsAuditLogModalOpen(true);
                }
                if (FormData.key === "edit_acc_restrictions") {
                    tccAccRestrictionsForm.setFieldValue("accountId", FormData.data.accountId);
                    tccAccRestrictionsForm.setFieldValue("serverId", FormData.data.serverId);
                    tccAccRestrictionsForm.setFieldValue("brandId", FormData.data.brandId);
                    tccAccRestrictionsForm.setFieldsValue(FormData.data.accountRestrictions);
                    tccAccRestrictionsForm.setFieldValue("clientId", FormData.data.clientId);
                    tccAccRestrictionsForm.setFieldValue("crmServerId", FormData.data.crmServerId);
                    tccAccRestrictionsForm.setFieldValue("crmBrandId", FormData.data.crmBrandId);
                    tccAccRestrictionsForm.setFieldValue("regulatorId", FormData.data.regulatorId);
                    tccAccRestrictionsForm.setFieldsValue(FormData.data.clientRestrictions);

                    let triggeredByAccounts: any[] = [];
                    triggeredByAccounts.push({
                        accountId: FormData.data.accountId,
                        serverId: FormData.data.serverId,
                        brandId: FormData.data.brandId,
                    });
                    setCurrTriggeredByAccs(triggeredByAccounts);
                    setCurrModalId({
                        name: "account",
                        type: "restriction",
                        id: FormData.data.accountId,
                        serverBrand: isEmptyOrNull(thisServer) ? "" : thisServer.text,
                    });
                    setIsAccRestrictionsModalOpen(true);
                }
                break;
            default:
                break;
        }
    };

    const customFilterComponentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.CREATE_NEW:
                setIsFirstOpt(prev => !prev);
                setFormObject({
                    mode: InnerPageActionMode.CREATE_NEW,
                    id: undefined,
                    backActiveSegment: currentActiveSegment,
                    filterParams: filterParams,
                });
                setRefetch(prev => prev + 1);
                break;
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                let fParams: any = {};
                let restrictionFilters: any = {};
                Object.keys(FormData)
                    .filter(x => FormData[x] !== undefined && FormData[x].toString().length > 0)
                    .map(x => {
                        if (x === "createdDateUtc") {
                            if (FormData[x] === null) return false;
                            else {
                                fParams["createdDateUtcFrom"] = DateTimeUtil.GetUTC(FormData[x][0]);
                                fParams["createdDateUtcTo"] = DateTimeUtil.GetUTC(FormData[x][1]);
                            }
                        } else if (x === "lastUpdatedDateUtc") {
                            if (FormData[x] === null) return false;
                            else {
                                fParams["lastUpdatedDateUtcFrom"] = DateTimeUtil.GetUTC(FormData[x][0]);
                                fParams["lastUpdatedDateUtcTo"] = DateTimeUtil.GetUTC(FormData[x][1]);
                            }
                        } else if (x === "deductionDate") {
                            if (FormData[x] === null) return false;
                            else {
                                fParams["deductionDateFrom"] = DateTimeUtil.GetUTC(FormData[x][0], "YYYY-MM-DD");
                                fParams["deductionDateTo"] = DateTimeUtil.GetUTC(FormData[x][1], "YYYY-MM-DD");
                            }
                        } else if (x === "issuedDate") {
                            if (FormData[x] === null) return false;
                            else {
                                fParams["issuedDateFrom"] = DateTimeUtil.GetOrigin(FormData[x][0], "YYYY-MM-DD");
                                fParams["issuedDateTo"] = DateTimeUtil.GetOrigin(FormData[x][1], "YYYY-MM-DD");
                            }
                        } else if (x === "accountId") {
                            let intAccIds: number[] = [];
                            FormData[x].forEach((y: string) => intAccIds.push(Number(y)));
                            fParams["accountIds"] = intAccIds;
                        } else if (x === "serverId") {
                            fParams["serverIds"] = FormData[x];
                        } else if (x === "brandId") {
                            fParams["brandIds"] = FormData[x];
                        } else if (x === "clientId") {
                            let intClientIds: number[] = [];
                            FormData[x].forEach((y: string) => intClientIds.push(Number(y)));
                            fParams["clientIds"] = intClientIds;
                        } else if (x === "crmBrandId") {
                            fParams["crmBrandIds"] = FormData[x];
                        } else if (x === "regulatorId") {
                            fParams["regulatorIds"] = FormData[x];
                        } else if (x === "status") {
                            fParams["statuses"] = FormData[x];
                        } else if (x === "labelId") {
                            fParams["labelIds"] = FormData[x];
                        } else if (x === "crmMappingMode") {
                            fParams["crmMappingModes"] = FormData[x];
                        } else if (x === "pickupTeam") {
                            fParams["pickupTeams"] = FormData[x];
                        } else if (x === "illegalProfit") {
                            if (FormData[x] === undefined) return false;
                            else {
                                let tmp = FormData[x].split("|");
                                fParams["illegalProfitFrom"] = Number(tmp[0]);
                                fParams["illegalProfitTo"] = Number(tmp[1]);
                            }
                        } else if (x === "actualDeduction") {
                            if (FormData[x] === undefined) return false;
                            else {
                                let tmp = FormData[x].split("|");
                                fParams["actualDeductionFrom"] = Number(tmp[0]);
                                fParams["actualDeductionTo"] = Number(tmp[1]);
                            }
                        } else if (x === "suggestedDeduction") {
                            if (FormData[x] === undefined) return false;
                            else {
                                let tmp = FormData[x].split("|");
                                fParams["suggestedDeductionFrom"] = Number(tmp[0]);
                                fParams["suggestedDeductionTo"] = Number(tmp[1]);
                            }
                        } else if (x === "inspector") {
                            fParams["inspectors"] = FormData[x];
                        } else if (x === "groupChangeFilter") {
                            if (FormData[x].isGroupChangeHasValue === undefined) return false;
                            else {
                                restrictionFilters["isGroupChangeHasValue"] = true;
                                if (FormData[x].isGroupChangeHasValue === "byKeyword") {
                                    restrictionFilters["groupChange"] = FormData[x].groupChange;
                                }
                            }
                            fParams["restrictionFilters"] = restrictionFilters;
                        } else if (x === "addingCidTool") {
                            restrictionFilters["addingCidTool"] = FormData[x];
                            fParams["restrictionFilters"] = restrictionFilters;
                        } else if (x === "closeOnly") {
                            restrictionFilters["closeOnly"] = FormData[x];
                            fParams["restrictionFilters"] = restrictionFilters;
                        } else if (x === "readOnly") {
                            restrictionFilters["readOnly"] = FormData[x];
                            fParams["restrictionFilters"] = restrictionFilters;
                        } else if (x === "closeAccount") {
                            restrictionFilters["closeAccount"] = FormData[x];
                            fParams["restrictionFilters"] = restrictionFilters;
                        } else if (x === "kyc") {
                            restrictionFilters["kyc"] = FormData[x];
                            fParams["restrictionFilters"] = restrictionFilters;
                        } else if (x === "leverage") {
                            restrictionFilters["leverage"] = FormData[x];
                            fParams["restrictionFilters"] = restrictionFilters;
                        } else if (x === "warningLetter") {
                            restrictionFilters["warningLetter"] = FormData[x];
                            fParams["restrictionFilters"] = restrictionFilters;
                        } else if (x === "withdrawBan") {
                            restrictionFilters["withdrawBan"] = FormData[x];
                            fParams["restrictionFilters"] = restrictionFilters;
                        } else if (x === "creditPromotion") {
                            restrictionFilters["creditPromotion"] = FormData[x];
                            fParams["restrictionFilters"] = restrictionFilters;
                        } else if (x === "openAccount") {
                            restrictionFilters["openAccount"] = FormData[x];
                            fParams["restrictionFilters"] = restrictionFilters;
                        } else if (x === "swap") {
                            restrictionFilters["swap"] = FormData[x];
                            fParams["restrictionFilters"] = restrictionFilters;
                        } else if (x === "sBook") {
                            restrictionFilters["sBook"] = FormData[x];
                            fParams["restrictionFilters"] = restrictionFilters;
                        } else {
                            fParams[x] = FormData[x];
                        }
                        return x;
                    });
                currentActiveSegment === "2" && setSorting("maxIssuedDate,desc|entryId,desc");

                if (
                    restrictionFilters?.hasOwnProperty("groupChange") &&
                    (restrictionFilters.groupChange === "" || restrictionFilters.groupChange === undefined)
                )
                    message.error("Please provide a keyword if you intend to execute a keyword-based search.");
                else {
                    setFilterParams(fParams);
                    setAccPagination(prev => ({ ...prev, current: 1 }));
                    setPagination(prev => ({ ...prev, current: 1 }));
                    setRunRefetchDataList(true);
                }
                break;
            case CALLBACK_KEY.OTHERS:
                if (FormData === "batch_update_restrictions") {
                    setIsFirstOpt(prev => !prev);
                    setFormObject({
                        mode: InnerPageActionMode.OTHERS,
                        id: undefined,
                        backActiveSegment: currentActiveSegment,
                        filterParams: filterParams,
                    });
                    setRefetch(prev => prev + 1);
                }
                break;
            default:
                break;
        }
    };

    const getCheckListPayload = (activeSegment: string, fParams: any) => {
        if (activeSegment === "1") {
            return {
                checkListGroupingMode: 1,
                limit: accPagination.pageSize,
                current: accPagination.current,
                ...(Object.keys(fParams).length > 0 && { ...fParams }),
            };
        } else {
            return {
                checkListGroupingMode: 2,
                limit: pagination.pageSize,
                current: pagination.current,
                ...(Object.keys(fParams).length > 0 && { ...fParams }),
                ...(sorting.length > 0 && { order: sorting }),
            };
        }
    };

    const sortTccDataRes = (unSortedDataRes: ToxicClientCheckData[]) => {
        let newDataRes: ToxicClientCheckData[] = [...unSortedDataRes];
        switch (sorting) {
            case "maxIssuedDate,asc|entryId,desc":
                newDataRes.sort((a: ToxicClientCheckData, b: ToxicClientCheckData) => (a.lastIssuedDate < b.lastIssuedDate ? -1 : 1));
                setData(newDataRes);
                break;
            case "maxLastUpdatedDateUtc,asc|entryId,desc":
                newDataRes.sort((a: ToxicClientCheckData, b: ToxicClientCheckData) => (a.lastUpdatedDateUtc < b.lastUpdatedDateUtc ? -1 : 1));
                setData(newDataRes);
                break;
            case "maxLastUpdatedDateUtc,desc|entryId,desc":
                newDataRes.sort((a: ToxicClientCheckData, b: ToxicClientCheckData) => (a.lastUpdatedDateUtc > b.lastUpdatedDateUtc ? -1 : 1));
                setData(newDataRes);
                break;
            case "maxIssuedDate,desc|entryId,desc":
            default:
                newDataRes.sort((a: ToxicClientCheckData, b: ToxicClientCheckData) => (a.lastIssuedDate > b.lastIssuedDate ? -1 : 1));
                setData(newDataRes);
                break;
        }
    };

    const getToxicClientCheckList = (segmentToReload?: string, filterVals?: any) => {
        apiRequest(APIs.GET_TOXIC_CLIENT_CHECK_LIST, {
            ...getCheckListPayload(
                segmentToReload === undefined ? currentActiveSegment : segmentToReload,
                filterVals === undefined ? filterParams : filterVals
            ),
        })
            .then(data => {
                if (data.result.length > 0) {
                    if (segmentToReload === undefined) {
                        if (currentActiveSegment === "1") {
                            setAccData(data.result);
                            setAccPagination(prev => ({ ...prev, total: data.total }));
                        } else {
                            let newData: ToxicClientCheckData[] = data.result.map((x: ToxicClientCheckData) => ({
                                ...x,
                                clientKey: `${x.clientId}-${x.crmServerId}-${x.crmBrandId}-${x.regulatorId}`,
                            }));
                            sortTccDataRes(newData);
                            setPagination(prev => ({ ...prev, total: data.total }));
                        }
                    } else {
                        if (segmentToReload === "1") {
                            setAccData(data.result);
                            setAccPagination(prev => ({ ...prev, total: data.total }));
                        } else {
                            let newData: ToxicClientCheckData[] = data.result.map((x: ToxicClientCheckData) => ({
                                ...x,
                                clientKey: `${x.clientId}-${x.crmServerId}-${x.crmBrandId}-${x.regulatorId}`,
                            }));
                            sortTccDataRes(newData);
                            setPagination(prev => ({ ...prev, total: data.total }));
                        }
                    }
                } else {
                    setData([]);
                    setPagination(prev => ({ ...prev, total: 0 }));
                    setAccData([]);
                    setAccPagination(prev => ({ ...prev, total: 0 }));
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("toxic client check list", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            )
            .finally(() => setIsLoading(false));
    };

    const getConfig = () => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, {
            filterType: [
                "server",
                "brand",
                "regulator",
                "user",
                "crmserver",
                "toxicclientlabelattr",
                "toxicclientlabel",
                "toxicclientrestriction",
                "cleansymbol",
            ],
        })
            .then((data: any) => {
                if (data["servers"] && data["servers"].length > 0) {
                    let newServersList = ToObjectWithKey(
                        data.servers.map((x: any) => ({ value: x["id"], text: x["server"] })),
                        "value"
                    );
                    setServers(newServersList);
                } else setServers({});
                setCfData({
                    brands: data.brands,
                    regulators: data.regulators,
                    users: data.users,
                    crmBrands: data.crmBrands,
                    tcLabelProps: data.toxicClientLabels,
                    tcLabelAttrs: data.toxicClientLabelAttrs,
                    tcRestrictions: data.toxicClientRestrictions,
                    cleanSymbols: data.cleanSymbols,
                });

                apiRequest(APIs.GET_TOXIC_CLIENT_LABEL_LIST, {})
                    .then((res: any) => {
                        setLabels(res);
                        setRunRefetchDataList(true);
                    })
                    .catch((error: any) =>
                        ErrorCatchValidator(error, (err: any) => console.log("Failed to get toxic client label config list: ", err))
                    );
            })
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => console.log("Failed to get filter config list: ", err)));

        apiRequest(APIs.GET_SETTING_LIST, { keys: ["ToxicClientChecklistUploadTemplateVersion"] })
            .then(data => {
                setCurrTemplateVer(data.length > 0 ? data[0].value : "");
            })
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => console.log("Failed to load template version number: ", err)));
    };

    const convertExcelToJSON = (uploadedFile: any) => {
        if (!uploadedFile) return null;
        if (uploadedFile.size > 5000000) {
            message.error("Maximum file size is 5 mb");
            return null;
        }

        /* Boilerplate to set up FileReader */
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;

        // Set up callback for when FileReader is done loading
        reader.onload = (event: any) => {
            /* Parse data */
            const bstr = event.target.result;
            const wb = XLSX.read(bstr, {
                type: rABS ? "binary" : "array",
                bookVBA: true,
                raw: true,
            });

            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];

            /* Convert array of arrays */
            const excelRows: any[] = XLSX.utils.sheet_to_json(ws);

            if (excelRows.length === 0) {
                message.error(`File is empty`);
                return null;
            }

            setUploadItem(uploadedFile);
            setIsUploading(true);
            setIsPreviewModalOpen(true);

            let formData = new FormData();
            formData.append("File", uploadedFile);
            formData.append("isPreviewOnly", "true");

            apiRequest(APIs.UPLOAD_TOXIC_CLIENT_CHECK_TEMPLATE, formData, "POST", "json", {
                Accept: "text/plain",
                "Content-type": "multipart/form-data",
            })
                .then((data: ToxicClientCheckUploadDataResponse) => {
                    setUploadedItemRes(data);
                    setUnconfirmedBrands(data.unconfirmedBrandEntries.length);
                    setSuccessMsg(
                        `${
                            data.invalidEntryCount > 0
                                ? "Invalid entry(s)"
                                : "All records in the Excel file have successfully passed validation checks without any errors."
                        }`
                    );
                })
                .catch((err: any) =>
                    ErrorCatchValidator(err, (err: any) => {
                        if (err.status === -3) {
                            setSuccessMsg("Invalid entry(s)");
                            setUploadedItemRes(initialUploadedItemRes);
                            Modal.warning({
                                title: "Warning!",
                                content: err.message,
                                onOk: () => setIsPreviewModalOpen(false),
                            });
                        } else message.error(`Failed to upload toxic client check list for preview: ${err.message}`, 5);
                    })
                )
                .finally(() => {
                    setIsUploading(false);
                });
        };

        // Call FileReader
        if (rABS) {
            reader.readAsBinaryString(uploadedFile);
        } else {
            reader.readAsArrayBuffer(uploadedFile);
        }
    };

    const handleModalOk = () => {
        if (isPreview) {
            setIsUploading(true);
            if (isDownloadErrChecked === true) {
                const newEntriesData = uploadedItemRes.invalidEntries.map(currErrObj => {
                    const newObj = { ...currErrObj };
                    delete newObj["rowNum"];
                    return newObj;
                });
                apiRequest(APIs.DOWNLOAD_TOXIC_CLIENT_CHECK_TEMPLATE, { entries: newEntriesData }, "POST", "arraybuffer")
                    .then((res: any) => {
                        const fileName = res.headers["x-filename"];
                        const url = window.URL.createObjectURL(new Blob([res.data])); // Create blob link to download
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", fileName); // or any other extension
                        // Append to html link element page
                        document.body.appendChild(link);
                        // start download
                        link.click();
                        // Clean up and remove the link
                        document.body.removeChild(link);
                        window.URL.revokeObjectURL(url);
                    })
                    .catch(error => {
                        ErrorCatchValidator(error, (err: any) =>
                            ErrorMessageHandler("toxic client template", SUCCESS_FAILED.FAILED_DOWNLOAD_DATA, err)
                        );
                    });
            }

            let formData = new FormData();
            formData.append("File", uploadItem);
            formData.append("isPreviewOnly", "false");

            let vantageBrandSettings: any[] = [];
            if (uploadedItemRes.unconfirmedBrandEntries.length > 0) {
                brandsConfirmationForm
                    .validateFields()
                    .then((values: any) => {
                        let confirmedBrands = values.previewTable;
                        Object.keys(confirmedBrands).forEach((accServerKey: string) => {
                            let accServerKeyValues = accServerKey.split("_"); // [ accountId, serverId ]
                            vantageBrandSettings.push({
                                accountId: Number(accServerKeyValues[0]),
                                serverId: Number(accServerKeyValues[1]),
                                brandId: confirmedBrands[accServerKey].brandId,
                            });
                        });
                    })
                    .catch(err => {
                        message.error("Please confirm all unconfirmed brand entries before proceeding.");
                    });

                formData.append("VantageBrandSettings", JSON.stringify(vantageBrandSettings));
            }

            apiRequest(APIs.UPLOAD_TOXIC_CLIENT_CHECK_TEMPLATE, formData, "POST", "json", {
                Accept: "text/plain",
                "Content-type": "multipart/form-data",
            })
                .then((data: ToxicClientCheckUploadDataResponse) => {
                    setUploadedItemRes(data);
                    setSuccessMsg(
                        `Toxic client check template has been uploaded successfully with ${
                            data.invalidEntryCount > 0 ? data.invalidEntryCount : "no"
                        } error(s)!`
                    );
                    setIsPreview(false);
                })
                .catch((err: any) =>
                    ErrorCatchValidator(err, (err: any) => {
                        message.error(`Failed to batch upload toxic client check list: ${err.message}`, 5);
                    })
                )
                .finally(() => {
                    setIsUploading(false);
                    setIsDownloadErrChecked(false);
                });
        } else {
            setIsPreviewModalOpen(false);
            setIsDownloadErrChecked(false);
            setRunRefetchDataList(true);
            setSuccessMsg("");
        }
    };

    const getAuditLog = (id: number) => {
        setIsAuditLogLoading(true);
        apiRequest(APIs.GET_TOXIC_CLIENT_CHECK_AUDIT_LOG, {
            entryId: id,
            isDetailedMode: false,
        })
            .then((data: any) => {
                setTccAuditLogData(data && data.length > 0 ? data : []);
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("toxic client check audit log", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            )
            .finally(() => setIsAuditLogLoading(false));
    };

    const modalTitle: string = useMemo(() => {
        if (currModalId.type === "log") {
            return `Latest Activity for Account - ${currModalId.id} ${isEmptyOrNull(currModalId.serverBrand) ? "" : `(${currModalId.serverBrand})`}`;
        } else {
            if (currModalId.name === "client") {
                return `Edit Client Restrictions for Client - ${currModalId.id} ${
                    isEmptyOrNull(currModalId.serverBrand) ? "" : `(${currModalId.serverBrand})`
                }`;
            } else {
                return `Edit Restrictions for Account - ${currModalId.id} ${
                    isEmptyOrNull(currModalId.serverBrand) ? "" : `(${currModalId.serverBrand})`
                }`;
            }
        }
    }, [currModalId]);

    const updateUnconfirmedBrandsCount = (selectedRowsCount: number) => {
        let currUnconfirmedBrandsCount = unconfirmedBrands;
        setUnconfirmedBrands(currUnconfirmedBrandsCount - selectedRowsCount); // Update untouched count based on selected rows
    };

    const handleModalCancel = () => {
        setIsPreviewModalOpen(false);
        setIsPreview(false);
        setIsDownloadErrChecked(false);
        setUploadedItemRes({
            invalidEntries: [],
            invalidEntryCount: 0,
            validEntryCount: 0,
            unconfirmedBrandEntries: [],
        });
        brandsConfirmationForm.resetFields();
    };

    const uploadPreviewTabItems = useMemo(() => {
        let defaultList = [
            {
                label: <span>{`${successMsg}`}</span>,
                key: "invalidEntries",
                children: (
                    <FlexiDataTable
                        bordered
                        rowKeyProperty="rowNum"
                        columns={previewColumns}
                        dataSource={uploadedItemRes.invalidEntries}
                        title={false}
                        options={{
                            showHideColumns: false,
                            enableFilter: false,
                        }}
                        loading={false}
                    />
                ),
            },
        ];
        if (uploadedItemRes.unconfirmedBrandEntries.length > 0) {
            defaultList.push({
                label: (
                    <>
                        <span style={{ marginRight: 3 }}>Unconfirmed Brand Entries</span> <Badge count={unconfirmedBrands} overflowCount={999} />
                    </>
                ),
                key: "unconfirmedBrands",
                children: (
                    <UnconfirmedBrandsTab
                        data={uploadedItemRes.unconfirmedBrandEntries}
                        form={brandsConfirmationForm}
                        servers={objectToArray(servers || [])}
                        updateUntouchedRowsCount={updateUnconfirmedBrandsCount}
                    />
                ),
            });
        }
        return defaultList;
    }, [successMsg, uploadedItemRes, servers, unconfirmedBrands]);

    useEffect(() => {
        if (runRefetchDataList) {
            setIsLoading(true);
            setRunRefetchDataList(false);
            getToxicClientCheckList();
        }
        return () => {};
    }, [runRefetchDataList]);

    useEffect(() => {
        filterForm.setFieldsValue({
            // accountServerIdPairs: [],
        });
        getConfig();
    }, []);

    return (
        <div className="toxic-client-check-list-tab">
            <div className="single-page with-background">
                <div className={`${isFirstOpt ? "active" : ""}`}>
                    <div className="toxic-client-check-tab-content-nav-bar">
                        <Segmented
                            value={currentActiveSegment}
                            options={SegmentList.map((x: NavigationItem) => ({
                                label: x.title,
                                value: x.key,
                            }))}
                            onChange={(activeKey: any) => {
                                setCurrentActiveSegment(activeKey);
                                setRunRefetchDataList(true);
                            }}
                        />
                        <div className="excel-related-btn-group">
                            {enableUpdate && (
                                <>
                                    <Tooltip title={`Template ver: ${currTemplateVer.length > 0 ? currTemplateVer : "-"}`} placement="left">
                                        <ExclamationCircleOutlined className="ver-icon" />
                                    </Tooltip>
                                    <Button
                                        icon={<DownloadOutlined />}
                                        loading={isDownloading}
                                        onClick={() => {
                                            setIsDownloading(true);
                                            apiRequest(APIs.DOWNLOAD_TOXIC_CLIENT_CHECK_TEMPLATE, {}, "POST", "arraybuffer")
                                                .then((res: any) => {
                                                    const fileName = res.headers["x-filename"];
                                                    const url = window.URL.createObjectURL(new Blob([res.data])); // Create blob link to download
                                                    const link = document.createElement("a");
                                                    link.href = url;
                                                    link.setAttribute("download", fileName); // or any other extension
                                                    // Append to html link element page
                                                    document.body.appendChild(link);
                                                    // start download
                                                    link.click();
                                                    // Clean up and remove the link
                                                    document.body.removeChild(link);
                                                    window.URL.revokeObjectURL(url);
                                                })
                                                .catch(error => {
                                                    ErrorCatchValidator(error, (err: any) =>
                                                        ErrorMessageHandler("toxic client template", SUCCESS_FAILED.FAILED_DOWNLOAD_DATA, err)
                                                    );
                                                })
                                                .finally(() => setIsDownloading(false));
                                        }}
                                    >
                                        Download Template
                                    </Button>
                                    <Upload
                                        name="file"
                                        customRequest={uploadDummyRequest}
                                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        onChange={(info: any) => {
                                            if (info.file.status === "error") {
                                                message.error(`${info.file.name} file upload failed.`);
                                            }
                                        }}
                                        multiple={true}
                                        showUploadList={false}
                                        beforeUpload={(file: any) => {
                                            convertExcelToJSON(file);
                                        }}
                                    >
                                        <Button
                                            icon={<UploadOutlined />}
                                            style={{ marginLeft: "0.651vw" }}
                                            onClick={() => {
                                                setIsPreview(true);
                                            }}
                                        >
                                            Upload Excel
                                        </Button>
                                    </Upload>
                                </>
                            )}
                            <Button
                                icon={<DownloadOutlined />}
                                loading={isExporting}
                                onClick={() => {
                                    setIsExporting(true);
                                    apiRequest(
                                        APIs.DOWNLOAD_TOXIC_CLIENT_CHECK_LIST_EXCEL,
                                        {
                                            ...(Object.keys(filterParams).length > 0 && { ...filterParams }),
                                        },
                                        "POST",
                                        "arraybuffer"
                                    )
                                        .then((res: any) => {
                                            const fileName = res.headers["x-filename"];
                                            const url = window.URL.createObjectURL(new Blob([res.data])); // Create blob link to download
                                            const link = document.createElement("a");
                                            link.href = url;
                                            link.setAttribute("download", fileName); // or any other extension
                                            // Append to html link element page
                                            document.body.appendChild(link);
                                            // start download
                                            link.click();
                                            // Clean up and remove the link
                                            document.body.removeChild(link);
                                            window.URL.revokeObjectURL(url);
                                        })
                                        .catch(error => {
                                            ErrorCatchValidator(error, (err: any) =>
                                                ErrorMessageHandler("toxic client list excel", SUCCESS_FAILED.FAILED_DOWNLOAD_DATA, err)
                                            );
                                        })
                                        .finally(() => setIsExporting(false));
                                }}
                            >
                                Export as Excel
                            </Button>
                        </div>
                    </div>
                    <CheckListCustomGroupedFilter
                        callback={customFilterComponentCallback}
                        options={{
                            add: enableUpdate,
                            extraButtons: () => {
                                if (enableUpdate)
                                    return (
                                        <Tooltip title={"At least two filters are required."} placement="left" key={"tcc-batch-update-restrictions"}>
                                            <Button
                                                key={"tcc-batch-update-restrictions"}
                                                icon={<InfoCircleOutlined />}
                                                onClick={() => customFilterComponentCallback(CALLBACK_KEY.OTHERS, "batch_update_restrictions")}
                                                disabled={Object.keys(filterParams).length < 2}
                                            >
                                                Batch Update Restrictions
                                            </Button>
                                        </Tooltip>
                                    );
                                else return null;
                            },
                        }}
                        servers={objectToArray(servers || [])}
                        users={cfData.users}
                        labels={labels}
                        brands={cfData.brands}
                        crmBrands={cfData.crmBrands}
                        regulators={cfData.regulators}
                        filterFormInstance={filterForm}
                    />
                    {currentActiveSegment === "1" && (
                        <>
                            <FlexiDataTable
                                bordered
                                rowKeyProperty="entryId"
                                title={false}
                                columns={accColumns}
                                options={{
                                    enableFilter: false,
                                    serverFiltering: true,
                                    showHideColumns: false,
                                }}
                                dataSource={accData}
                                callback={accComponentCallback}
                                loading={isLoading}
                                serverSide={true}
                                pagination={accPagination}
                            />
                        </>
                    )}
                    {currentActiveSegment === "2" && (
                        <>
                            <FlexiDataTable
                                bordered
                                rowKeyProperty="clientKey"
                                title={false}
                                columns={columns}
                                options={{
                                    enableFilter: false,
                                    showHideColumns: false,
                                    serverFiltering: true,
                                    expandable: {
                                        expandedRowRender: getExpandedRowRender,
                                    },
                                    customExtraActionButton: [
                                        enableUpdate && {
                                            text: "",
                                            value: "edit_client_restrictions",
                                            icon: <SlidersOutlined />,
                                        },
                                    ],
                                }}
                                dataSource={data}
                                callback={componentCallback}
                                loading={isLoading}
                                serverSide={true}
                                pagination={pagination}
                            />
                        </>
                    )}
                </div>
                <div className={`${isFirstOpt ? "" : "active"}`}>
                    {formObject.mode === InnerPageActionMode.OTHERS ? (
                        <BatchUpdateRestrictions
                            {...formObject}
                            callback={(action: InnerPageActionMode, value: any) => {
                                switch (action) {
                                    case InnerPageActionMode.BACK:
                                        setIsFirstOpt(prev => !prev);
                                        if (value?.hasOwnProperty("refreshMainList") && value["refreshMainList"]) {
                                            setIsLoading(true);
                                            getToxicClientCheckList(
                                                value?.hasOwnProperty("reloadSegment") ? value["reloadSegment"] : undefined,
                                                value["fParams"]
                                            );
                                        }
                                        break;
                                }
                            }}
                            resetState={refetch}
                            servers={SortList(objectToArray(servers || []), "text")}
                            brands={cfData.brands}
                            crmBrands={cfData.crmBrands}
                            regulators={cfData.regulators}
                        />
                    ) : (
                        <ToxicClientCheckEntryCreateEdit
                            {...formObject}
                            callback={(action: InnerPageActionMode, value: any) => {
                                switch (action) {
                                    case InnerPageActionMode.BACK:
                                        setIsFirstOpt(prev => !prev);
                                        if (value?.hasOwnProperty("refreshMainList") && value["refreshMainList"]) {
                                            setIsLoading(true);
                                            getToxicClientCheckList(
                                                value?.hasOwnProperty("reloadSegment") ? value["reloadSegment"] : undefined,
                                                value["fParams"]
                                            );
                                        }
                                        break;
                                }
                            }}
                            resetState={refetch}
                            servers={SortList(objectToArray(servers || []), "text")}
                            brands={cfData.brands}
                            crmBrands={cfData.crmBrands}
                            regulators={cfData.regulators}
                            inspectors={cfData.users}
                            tcLabels={labels}
                            cleanSymbols={cfData.cleanSymbols}
                        />
                    )}
                </div>
                <Modal
                    wrapClassName="toxic-client-check-upload-modal-container"
                    title={`Preview Toxic Client Check Upload`}
                    centered
                    maskClosable={false}
                    width={1300}
                    open={isPreviewModalOpen}
                    onOk={() => handleModalOk()}
                    onCancel={() => handleModalCancel()}
                    footer={[
                        <>
                            <div className="footer-buttons">
                                {isPreview === true && uploadedItemRes.invalidEntries.length > 0 && (
                                    <div className="download-err-checkbox">
                                        <Checkbox
                                            checked={isDownloadErrChecked}
                                            onChange={(e: CheckboxChangeEvent) => setIsDownloadErrChecked(e.target.checked)}
                                            key="checkbox-download"
                                        >
                                            Download Errors
                                        </Checkbox>
                                    </div>
                                )}
                                <div>
                                    <Button key="back" onClick={() => handleModalCancel()}>
                                        Cancel
                                    </Button>
                                    <Button
                                        key="submit"
                                        type="primary"
                                        loading={isUploading}
                                        onClick={() => handleModalOk()}
                                        disabled={isUploading || unconfirmedBrands > 0}
                                    >
                                        {`${
                                            isPreview === true
                                                ? uploadedItemRes.invalidEntries.length > 0
                                                    ? "Submit with Errors"
                                                    : "Submit"
                                                : "Close"
                                        }`}
                                    </Button>
                                </div>
                            </div>
                        </>,
                    ]}
                >
                    {isUploading ? (
                        <div style={{ textAlign: "center", paddingTop: "8vh", paddingBottom: "8vh" }}>
                            <LoadingComponent />
                        </div>
                    ) : (
                        <div>
                            <div className="total-count-info">
                                <p className="success">
                                    {`Total ${isPreview ? "valid entry" : "succeed"}:`} {uploadedItemRes.validEntryCount}
                                </p>
                                <p className="failed">
                                    {`Total ${isPreview ? "invalid entry" : "failed"}:`} {uploadedItemRes.invalidEntryCount}
                                </p>
                                <p className="total">
                                    {`Total record(s):`} {uploadedItemRes.validEntryCount + uploadedItemRes.invalidEntryCount}
                                </p>
                            </div>
                            {isPreview ? (
                                <Tabs
                                    type="card"
                                    defaultActiveKey="invalidEntries"
                                    tabBarStyle={{ marginBottom: "0" }}
                                    items={uploadPreviewTabItems}
                                />
                            ) : (
                                <FlexiDataTable
                                    bordered
                                    rowKeyProperty="rowNum"
                                    columns={previewColumns}
                                    dataSource={uploadedItemRes.invalidEntries}
                                    title={successMsg.length > 0 ? `NOTE: ${successMsg}` : false}
                                    options={{
                                        showHideColumns: false,
                                        enableFilter: false,
                                    }}
                                    loading={false}
                                />
                            )}
                        </div>
                    )}
                </Modal>
                <Modal
                    width={1000}
                    title={modalTitle}
                    open={isAuditLogModalOpen}
                    onCancel={() => setIsAuditLogModalOpen(false)}
                    cancelText="Close"
                    okButtonProps={{
                        style: { display: "none" },
                    }}
                >
                    {isAuditLogLoading ? (
                        <div style={{ textAlign: "center", paddingTop: "8vh", paddingBottom: "8vh" }}>
                            <LoadingComponent />
                        </div>
                    ) : (
                        <AuditLog
                            auditLogData={tccAuditLogData}
                            users={cfData.users}
                            tcLabelProps={cfData.tcLabelProps}
                            brands={cfData.brands}
                            crmBrands={cfData.crmBrands}
                            regulators={cfData.regulators}
                            tcLabelAttrs={cfData.tcLabelAttrs}
                            tcRestrictions={cfData.tcRestrictions}
                            servers={objectToArray(servers || [])}
                        />
                    )}
                </Modal>
                <Modal
                    width={1000}
                    destroyOnClose
                    title={modalTitle}
                    open={isAccRestrictionsModalOpen}
                    onCancel={() => setIsAccRestrictionsModalOpen(false)}
                    onOk={() => {
                        tccAccRestrictionsForm.validateFields().then((values: any) => {
                            apiRequest(APIs.UPDATE_TOXIC_CLIENT_CHECK_RESTRICTIONS, {
                                accountRestrictions: {
                                    accountId: values.accountId,
                                    serverId: values.serverId,
                                    brandId: values.brandId,
                                    clientId: values.clientId,
                                    crmServerId: values.crmServerId,
                                    crmBrandId: values.crmBrandId,
                                    regulatorId: values.regulatorId,
                                    restrictionsWithValues: [
                                        {
                                            restrictionCode: TOXIC_CLIENT_CHECK_ACCOUNT_RESTRICTIONS_CODES.addingCidTool,
                                            restrictionValue: values.addingCidTool,
                                        },
                                        {
                                            restrictionCode: TOXIC_CLIENT_CHECK_ACCOUNT_RESTRICTIONS_CODES.closeOnly,
                                            restrictionValue: values.closeOnly,
                                        },
                                        {
                                            restrictionCode: TOXIC_CLIENT_CHECK_ACCOUNT_RESTRICTIONS_CODES.groupChange,
                                            restrictionValue: isEmptyOrNull(values.groupChange) ? null : values.groupChange,
                                        },
                                        {
                                            restrictionCode: TOXIC_CLIENT_CHECK_ACCOUNT_RESTRICTIONS_CODES.readOnly,
                                            restrictionValue: values.readOnly,
                                        },
                                        {
                                            restrictionCode: TOXIC_CLIENT_CHECK_ACCOUNT_RESTRICTIONS_CODES.sBook,
                                            restrictionValue: values.sBook,
                                        },
                                    ],
                                },
                                clientRestrictions: {
                                    clientId: values.clientId,
                                    crmServerId: values.crmServerId,
                                    crmBrandId: values.crmBrandId,
                                    regulatorId: values.regulatorId,
                                    restrictionsWithValues: [
                                        {
                                            restrictionCode: TOXIC_CLIENT_CHECK_CLIENT_RESTRICTIONS_CODES.closeAccount,
                                            restrictionValue: values.closeAccount,
                                        },
                                        {
                                            restrictionCode: TOXIC_CLIENT_CHECK_CLIENT_RESTRICTIONS_CODES.creditPromotion,
                                            restrictionValue: values.creditPromotion,
                                        },
                                        {
                                            restrictionCode: TOXIC_CLIENT_CHECK_CLIENT_RESTRICTIONS_CODES.kyc,
                                            restrictionValue: values.kyc,
                                        },
                                        {
                                            restrictionCode: TOXIC_CLIENT_CHECK_CLIENT_RESTRICTIONS_CODES.leverage,
                                            restrictionValue: values.leverage,
                                        },
                                        {
                                            restrictionCode: TOXIC_CLIENT_CHECK_CLIENT_RESTRICTIONS_CODES.openAccount,
                                            restrictionValue: values.openAccount,
                                        },
                                        {
                                            restrictionCode: TOXIC_CLIENT_CHECK_CLIENT_RESTRICTIONS_CODES.swap,
                                            restrictionValue: values.swap,
                                        },
                                        {
                                            restrictionCode: TOXIC_CLIENT_CHECK_CLIENT_RESTRICTIONS_CODES.warningLetter,
                                            restrictionValue: values.warningLetter,
                                        },
                                        {
                                            restrictionCode: TOXIC_CLIENT_CHECK_CLIENT_RESTRICTIONS_CODES.withdrawBan,
                                            restrictionValue: values.withdrawBan,
                                        },
                                    ],
                                    triggeredByAccounts: currTriggeredByAccs,
                                },
                            })
                                .then(() => {
                                    ErrorMessageHandler("Toxic client account restrictions", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                                    setIsAccRestrictionsModalOpen(false);
                                    setRunRefetchDataList(true);
                                })
                                .catch((error: any) =>
                                    ErrorCatchValidator(error, (err: any) =>
                                        ErrorMessageHandler("toxic client account restrictions", SUCCESS_FAILED.FAILED_UPDATE_DATA, err)
                                    )
                                );
                        });
                    }}
                >
                    <AccountRestrictions accForm={tccAccRestrictionsForm} />
                </Modal>
                <Modal
                    width={600}
                    destroyOnClose
                    title={modalTitle}
                    open={isClientRestrictionsModalOpen}
                    onCancel={() => setIsClientRestrictionsModalOpen(false)}
                    onOk={() => {
                        tccClientRestrictionsForm.validateFields().then((values: any) => {
                            apiRequest(APIs.UPDATE_TOXIC_CLIENT_CHECK_RESTRICTIONS, {
                                clientRestrictions: {
                                    clientId: values.clientId,
                                    crmServerId: values.crmServerId,
                                    crmBrandId: values.crmBrandId,
                                    regulatorId: values.regulatorId,
                                    restrictionsWithValues: [
                                        {
                                            restrictionCode: TOXIC_CLIENT_CHECK_CLIENT_RESTRICTIONS_CODES.closeAccount,
                                            restrictionValue: values.closeAccount,
                                        },
                                        {
                                            restrictionCode: TOXIC_CLIENT_CHECK_CLIENT_RESTRICTIONS_CODES.creditPromotion,
                                            restrictionValue: values.creditPromotion,
                                        },
                                        {
                                            restrictionCode: TOXIC_CLIENT_CHECK_CLIENT_RESTRICTIONS_CODES.kyc,
                                            restrictionValue: values.kyc,
                                        },
                                        {
                                            restrictionCode: TOXIC_CLIENT_CHECK_CLIENT_RESTRICTIONS_CODES.leverage,
                                            restrictionValue: values.leverage,
                                        },
                                        {
                                            restrictionCode: TOXIC_CLIENT_CHECK_CLIENT_RESTRICTIONS_CODES.openAccount,
                                            restrictionValue: values.openAccount,
                                        },
                                        {
                                            restrictionCode: TOXIC_CLIENT_CHECK_CLIENT_RESTRICTIONS_CODES.swap,
                                            restrictionValue: values.swap,
                                        },
                                        {
                                            restrictionCode: TOXIC_CLIENT_CHECK_CLIENT_RESTRICTIONS_CODES.warningLetter,
                                            restrictionValue: values.warningLetter,
                                        },
                                        {
                                            restrictionCode: TOXIC_CLIENT_CHECK_CLIENT_RESTRICTIONS_CODES.withdrawBan,
                                            restrictionValue: values.withdrawBan,
                                        },
                                    ],
                                },
                            })
                                .then(() => {
                                    ErrorMessageHandler("Toxic client restrictions", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                                    setIsClientRestrictionsModalOpen(false);
                                    setRunRefetchDataList(true);
                                })
                                .catch((error: any) =>
                                    ErrorCatchValidator(error, (err: any) =>
                                        ErrorMessageHandler("toxic client restrictions", SUCCESS_FAILED.FAILED_UPDATE_DATA, err)
                                    )
                                );
                        });
                    }}
                >
                    <ClientRestrictions form={tccClientRestrictionsForm} />
                </Modal>
            </div>
        </div>
    );
};

export default ToxicClientCheckList;

import { Button } from "antd";
//import { publicIpv4 } from "public-ip";
import { useState, useEffect } from "react";

import bg_image from "../../assets/images/vantage-zoom-bg-D_removed.png";
import logo_jpg from "../../assets/images/vantage-full-logo-CMYK.jpg";
import { StringFormat } from "../../utils/array";

export const getActualSSOLoginUrl = (): string => StringFormat(process.env.REACT_APP_SSO_URL || "", window.location.origin);

function SSOLoginPage() {
    //const [, setIpAddress] = useState<string>("");
    const [isLoading] = useState<boolean>(false);

    useEffect(() => {
        //publicIpv4().then((res: any) => setIpAddress(res));
        return () => {};
    }, []);

    return (
        <div
            className="login-container"
            style={{
                backgroundColor: `#024854`,
                backgroundImage: `url(${bg_image})`,
                backgroundPosition: "top right",
                backgroundRepeat: "no-repeat",
            }}
        >
            <div className="login-form">
                <div>
                    <img alt={"logo"} src={logo_jpg} className="img-logo" />
                </div>
                <div className="title-div">
                    Welcome to the <span>Insight</span>
                </div>
                <div className="title-div-2">Please login to continue.</div>
                <Button
                    type="primary"
                    htmlType="submit"
                    className="login-btn"
                    loading={isLoading}
                    onClick={() => {
                        window.location.href = getActualSSOLoginUrl();
                    }}
                >
                    Login via VIMS
                </Button>
            </div>
        </div>
    );
}

export default SSOLoginPage;

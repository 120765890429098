import { FormComponent } from "@/components/FormComponent";
import LoadingComponent from "@/components/Loading";
import SitePageHeader from "@/components/PageHeader";
import { ComponentType, SUCCESS_FAILED } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import useFilterConfigs from "@/hooks/useFilterConfigs";
import { apiRequest, APIs } from "@/services/apiConfig";
import { ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { HomeOutlined } from "@ant-design/icons";
import { Button, Col, Form, message, Row } from "antd";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BridgeTypeDetails } from "./index";

export type BrandState = {
    action: string;
    data: any;
};

function BridgeCreateEditPageV2() {
    let navigate = useNavigate();
    let location = useLocation();
    let com_state: BrandState = location.state as BrandState;
    const [currentState] = useState<BrandState>(com_state || { action: "add", data: null });
    const [isAddAction, setIsAddAction] = useState<boolean>(currentState.action === "add");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { filterConfigs } = useFilterConfigs({ filterType: ["riskinsightbridgetypes"] });
    const RiskInsightBridgeTypes: BridgeTypeDetails[] = filterConfigs?.riskInsightBridgeTypes ?? [];
    const [bridgeForm] = Form.useForm();
    let { id } = useParams();

    const onSubmit = (values: any) => {
        const params = Object.keys(values).reduce((acc: any, key: string) => {
            if (key === "bridgeTypeDetails") {
                acc['bridgeType'] = values[key].reduce((accx: number, x: number) => accx + x, 0) ;
                return acc;
            }
            if(key === 'bridgeId' && isAddAction){
                return acc;
            }
            acc[key] = values[key];
            return acc;
        }, {});
        setIsLoading(true);

        if (isAddAction) {
            params['enable'] = true;
            apiRequest(APIs.CREATE_BRIDGE, params)
                .then((data) => {
                    ErrorMessageHandler("New bridge", SUCCESS_FAILED.SUCCESS_CREATE_DATA);
                    navigate("/siteadmin/brandserver/bridge");
                })
                .catch((error) => {
                    ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("new bridge", SUCCESS_FAILED.FAILED_CREATE_DATA, err));
                    setIsLoading(false);
                });
        } else {
            apiRequest(APIs.UPDATE_BRIDGE, params)
                .then((data) => {
                    ErrorMessageHandler("Existing bridge", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                    navigate("/siteadmin/brandserver/bridge");
                })
                .catch((error) => {
                    ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("existing bridge", SUCCESS_FAILED.FAILED_UPDATE_DATA, err));
                    setIsLoading(false);
                });
        }
    };

    const getBridgeById = () => {
        setIsLoading(true);
        apiRequest(APIs.GET_BRIDGE_LIST, { bridgeId: id })
            .then((data: any) => {
                if (data.result.length > 0) {
                    // setIsAddAction(false);
                    const dataResult = {
                        ...data.result[0],
                        bridgeTypeDetails: data.result[0].bridgeTypeDetails.map((x: any) => x.bridgeType)
                    }
                    bridgeForm.setFieldsValue(dataResult);
                }
                setIsLoading(false);
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("bridge list", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
                navigate("/siteadmin/brandserver/bridge");
            });
    };

    useEffect(() => {
      if (!isAddAction) {
        getBridgeById();
      }else{
        bridgeForm.setFieldsValue({ enable: true });
      }
      return () => {};
    }, [isAddAction, bridgeForm]);

    return (
        <SitePageHeader
            title={isAddAction ? "Create New Bridge" : "Edit Bridge"}
            routes={[
                {
                    path: "/siteadmin/brandserver/bridge",
                    breadcrumbName: "Bridge Listing",
                    icon: <HomeOutlined />,
                },
                {
                    path: "",
                    breadcrumbName: isAddAction ? "Create New Bridge" : "Edit Bridge",
                },
            ]}
            onBack={() => navigate("/siteadmin/brandserver/bridge")}
        >
            {isLoading ? (
                <div className="loading-container">
                    <LoadingComponent tip="Submitting..." />
                </div>
            ) : (
                <Form
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    form={bridgeForm}
                    layout="horizontal"
                    initialValues={currentState.data}
                    onFinish={onSubmit}
                    onFinishFailed={({errorFields}) => {
                        const error = errorFields[0];
                        message.error(`Error: [${error.name}] ${error.errors}`);
                    }}
                >
                    <Row>
                        <Col span={15}>
                            <FormComponent label={""} name={"bridgeId"} extra={{ type: ComponentType.hidden, value: "" }} />
                            <FormComponent
                                label="Bridge Name"
                                name="bridgeDn"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    rules: [
                                        { required: true, message: REQUIRED_FIELD },
                                        { max: 100, message: "Max 100 characters allowed" }
                                    ],
                                }}
                            />
                            <FormComponent
                                label="Type"
                                name="bridgeTypeDetails"
                                extra={{
                                    type: ComponentType.dropdown,
                                    value: RiskInsightBridgeTypes.map((x)=>{return {text: x.bridgeTypeName, value: x.bridgeType}}),
                                    inputProps: {
                                        mode: "multiple",
                                    },
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                }}
                            />
                            <FormComponent
                                label="Host"
                                name="host"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    rules: [{ max: 100, message: "Max 100 characters allowed" }]
                                }}
                            />
                            <FormComponent
                                label="Login"
                                name="login"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    rules: [{ max: 100, message: "Max 100 characters allowed" }]
                                }}
                            />
                            <FormComponent
                                label="Password"
                                name="password"
                                extra={{
                                    type: ComponentType.password,
                                    value: "",
                                    rules: [{ max: 128, message: "Max 128 characters allowed" }]
                                }}
                            />
                            <FormComponent
                                label="Broker Login"
                                name="brokerLogin"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    rules: [{ max: 100, message: "Max 100 characters allowed" }]
                                }}
                            />
                            <FormComponent
                                label="Broker VHost"
                                name="brokerVhost"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    rules: [{ max: 100, message: "Max 100 characters allowed" }]
                                }}
                            />
                            <FormComponent
                                label="Broker Password"
                                name="brokerPassword"
                                extra={{
                                    type: ComponentType.password,
                                    value: "",
                                    rules: [{ max: 128, message: "Max 128 characters allowed" }]
                                }}
                            />
                            <FormComponent
                                label="Version"
                                name="version"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    rules: [
                                        { required: true, message: REQUIRED_FIELD },
                                        { max: 10, message: "Max 10 characters allowed" }
                                    ],
                                }}
                            />
                            <FormComponent
                                label="Enable"
                                name="enable"
                                extra={{
                                    type: ComponentType.switch,
                                    value: "",
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <div className="step-btns-group">
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            )}
        </SitePageHeader>
    );
}

export default BridgeCreateEditPageV2;

import { SUCCESS_FAILED } from '@/constants'
import { plainAxiosInstance } from '@/services/axiosSetup'
import { ErrorCatchValidator, ErrorMessageHandler } from '@/utils/Common'
import { DownloadOutlined, InboxOutlined } from '@ant-design/icons'
import { Button, Col, message, Modal, Row, Upload } from 'antd'
import { RcFile } from 'antd/lib/upload'
import moment from 'moment-timezone'
import { useCallback, useState } from 'react'

type UploadDownloadComponentProps = {
  upload:{
    title: string
    apiUrl: string
    className: string
  }
  download:{
    title: string
    apiUrl: string
    msgPrefix: string
  }
}

const UploadDownloadComponent = ({ upload, download}: UploadDownloadComponentProps) => {
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const beforeUpload = useCallback(async (file: RcFile) => {
    const fileName = file.name;
    const isLt5M = file.size / 1024 / 1024 < 5;

    if (!fileName.endsWith(".xlsx")) {
      Modal.error({
        title: "File extension error",
        content: "File extension must be .xlsx",
      });
    } else if (!isLt5M) {
      Modal.error({
        title: "File size error",
        content: "File must be smaller than 5MB",
      });
    } else {
      Modal.confirm({
        title: "File Confirmation",
        content: "Are you sure you want to upload these accounts?",
        onOk: () => {
          return new Promise<void>((resolve, reject) => {
            const formData = new FormData();
            formData.append('file', file);
            plainAxiosInstance.post(upload.apiUrl, formData)
              .then((res) => {
                if(res.status === 200){
                  message.success('Successfully uploaded file');
                  resolve();
                }
                else{
                  reject('Import failed, no data found')
                }
              })
              .catch((error) => {
                reject(error.message);
              });
          }).catch((error) => {
            message.error(error);
          });
        },
        onCancel: () => {
          message.info('Upload canceled');
        }
      });
    }
  }, [upload.apiUrl]);

  const onClickDownload = useCallback(() => {
    setIsDownloading(true);
    plainAxiosInstance.get(download.apiUrl, { responseType: 'blob' })
      .then((res) => {
        if(res.status !== 200) throw new Error('Failed to download data');
        const contentHeader = res.headers["content-disposition"];
        const fileName = contentHeader ? contentHeader.split("filename=")[1] : download.msgPrefix.replace(/\s/g, "_");

        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${fileName}_${moment(new Date()).format('YYYY-MM-DD')}.xlsx`);
        link.setAttribute("type", "hidden");

        document.body.appendChild(link);
        link.click();
        //clean up
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler(download.msgPrefix, SUCCESS_FAILED.FAILED_DOWNLOAD_DATA, err));
      })
      .finally(() => {
        setIsDownloading(false);
      });
  }, [download.apiUrl]);

  return (
    <>
      <Row className='uploader'>
        <Col span={24}>
          <Upload.Dragger name={"file"} accept={".xlsx"} maxCount={1} showUploadList={false} beforeUpload={beforeUpload} className={upload.className}>
            <p className="ant-upload-drag-icon"><InboxOutlined/></p>
            <p className="ant-upload-text" children={upload.title}/>
            <p className="ant-upload-hint">extension support: xlsx</p>
            <p className="ant-upload-hint">maximum file size: 5 MB</p>
          </Upload.Dragger>
        </Col>
      </Row>
      <Row>
        <Col span={1}>
          <Button type={"primary"} children={download.title} icon={<DownloadOutlined/>} onClick={onClickDownload} loading={isDownloading}/>
        </Col>
      </Row>
    </>
  )
}

export default UploadDownloadComponent
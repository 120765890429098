/***
 * This is the example to generate RTK Query
 *
 * Efficiency used for data listing with filter options
 */

import { createApi } from "@reduxjs/toolkit/query/react";
import { APIs, defaultBaseQueryProps, ResponseProps } from "../../services/apiConfig";
import { User } from "../../constants/type";
import { axiosBaseQuery } from "../axiosBaseQuery";

export interface userProfileRequest { }

export interface userProfileResponse extends ResponseProps<User[]> { }

export const userProfilesApiSlice = createApi({
    reducerPath: "api_1",
    baseQuery: axiosBaseQuery(),
    keepUnusedDataFor: 5, //global caching duration (seconds)
    endpoints: (builder) => ({
        getUsers: builder.query<userProfileResponse, userProfileRequest | undefined>({
            keepUnusedDataFor: 0, //disable for caching for particular api request
            query: (arg) => ({ ...defaultBaseQueryProps, url: APIs.GET_USER_PROFILES, method: "POST", params: arg }),
            transformResponse: (returnValue: userProfileResponse, meta) => {
                return returnValue;
            },
        }),
    }),
});

export const { useGetUsersQuery } = userProfilesApiSlice;

import { SearchOutlined } from "@ant-design/icons";
import { Divider, Empty, Form, List } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { FormComponent } from "../../../components/FormComponent";
import LoadingComponent from "../../../components/Loading";
import { ComponentType, SUCCESS_FAILED } from "../../../constants";
import "../../../index.less";
import { apiRequest, APIs } from "../../../services/apiConfig";
import { DataTableColumnRender, ErrorCatchValidator, ErrorMessageHandler } from "../../../utils/Common";

interface ClientAccountCommentsHistoryProps {
    clientId: number;
    regulatorId: number;
    crmServerId: number;
}

export interface ClientAccountComment {
    accountId: number;
    brandId: number;
    comment: string;
    createdDateUtc: string;
    isDeleted: boolean;
    serverId: number;
    user: string;
}

const ClientAccountCommentsHistory = (props: ClientAccountCommentsHistoryProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState<ClientAccountComment[]>([]);
    const [searchKey, setSearchKey] = useState<string>("");
    const [clientAccCommentsHistoryForm] = Form.useForm();

    const refetchClientAccountCommentsHistory = () => {
        setIsLoading(true);
        apiRequest(APIs.GET_CLIENT_ACCOUNT_COMMENTS, {
            clientId: props.clientId,
            regulatorId: props.regulatorId,
            crmServerId: props.crmServerId,
        })
            .then((data: any) => {
                setData(data);
            })
            .catch((error) => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("comments history", SUCCESS_FAILED.FAILED_LOAD_DATA, err)))
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        refetchClientAccountCommentsHistory();
        return () => {};
    }, []);

    useEffect(() => {
        let filteredData = getFilteredMetric(searchKey, data);
        if (searchKey) {
            setData(filteredData);
        } else {
            refetchClientAccountCommentsHistory();
        }
        return () => {};
    }, [searchKey]);

    const onFilterValueChanged = (values: any) => setSearchKey(values["searchKey"]);

    const getFilteredMetric = (searchKey: string, data: ClientAccountComment[]) => {
        let lowerSearchText = searchKey.toString().toLowerCase();
        return searchKey.length > 0 ? data.filter((x) => x.accountId.toString().indexOf(lowerSearchText) > -1) : data;
    };

    return (
        <>
            <div className="box-header">
                <h1 className="profile-bigTitle">Account Comment History</h1>
            </div>
            <Divider />
            <Form form={clientAccCommentsHistoryForm} colon={false} layout="horizontal" onValuesChange={onFilterValueChanged}>
                <div className="client-comments-history-search-panel">
                    <FormComponent
                        label={" "}
                        name={"searchKey"}
                        extra={{
                            type: ComponentType.text,
                            value: "",
                            itemProps: {
                                style: {
                                    marginBottom: "0px",
                                },
                            },
                            inputProps: {
                                prefix: <SearchOutlined />,
                                allowClear: true,
                                placeholder: "Search Account ID",
                            },
                        }}
                    />
                </div>
            </Form>

            <div className={`client-comments-history nice-scrollbar`}>
                {isLoading ? (
                    <div className="loading-container">
                        <LoadingComponent tip="Loading..." />
                    </div>
                ) : data?.length > 0 ? (
                    <List>
                        {data.map((currData: any, idx: number) => {
                            let isLineThrough = currData.isDeleted;
                            return (
                                <List.Item key={`cach-${idx}`}>
                                    <div className="list-box">
                                        <div className="list-top-small">Account ID: {currData?.accountId}</div>
                                        <div className="list-comment">
                                            <div className={`list-title ${isLineThrough ? "text-line-throught" : ""}`}>{currData?.comment}</div>
                                        </div>
                                        <div className={`list-description ${isLineThrough ? "text-line-throught" : ""}`}>
                                            {DataTableColumnRender.DateTime(currData?.createdDateUtc)} - {currData?.user}
                                        </div>
                                    </div>
                                </List.Item>
                            );
                        })}
                    </List>
                ) : (
                    <Empty />
                )}
            </div>
        </>
    );
};

export default ClientAccountCommentsHistory;

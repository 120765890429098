import { Comment, Typography } from "antd";
import { useState, useEffect } from "react";
import CardBox from "../../../components/Common/CardBox";
import FlexiDataTable from "../../../components/FlexiDataTable";
import { CALLBACK_KEY, ComponentType } from "../../../constants";
import {
    ArchivedAccountProfile,
    CustomPaginationProps,
    FlexiDataColumnProps,
    FlexiDataTableOptionsProps,
    FlexiDataTableCallbackProps,
    BrandsList,
    ServersList,
    WatchListGroup,
    UsersList,
} from "../../../constants/type";
import { apiRequest } from "../../../services/apiConfig";
import { APIs } from "../../../services/apis";
import { currencyRender, DataTableColumnRender, DTColProps, ErrorCatchValidator } from "../../../utils/Common";
import { DateTimeUtil } from "../../../utils/datetime";
import moment from "moment";
const { Paragraph } = Typography;
const ArchiveWatchList = () => {
    const getColumnsConfig = (
        brands: BrandsList[] = [],
        servers: ServersList[] = [],
        watchListGroups: WatchListGroup[] = [],
        usersList: UsersList[] = []
    ) => {
        return [
            DTColProps.Small({
                title: "Info",
                dataIndex: "info",
                key: "info",
                render: (accWatchInfo: any, rowData: ArchivedAccountProfile) => (
                    <>
                        <div className="watch-list-info">
                            <div className="title">Brand</div>
                            <div className="value">{rowData.brand}</div>
                        </div>
                        <div className="watch-list-info">
                            <div className="title">Server</div>
                            <div className="value">{rowData.server}</div>
                        </div>
                        <div className="watch-list-info">
                            <div className="title">Account ID</div>
                            <div className="value">{rowData.accountId}</div>
                        </div>
                    </>
                ),
            }),

            DTColProps.Small({
                title: "Brand",
                dataIndex: "brand",
                key: "brand",
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: brands.map((x) => ({ text: x.brand, value: x.id })),
                    },
                    visible: false,
                },
            }),

            DTColProps.Small({
                title: "Server",
                dataIndex: "server",
                key: "server",
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: servers.map((x) => ({ text: x.server, value: x.id })),
                    },
                    visible: false,
                },
            }),

            DTColProps.Middle({
                title: "Account ID",
                dataIndex: "accountId",
                key: "accountId",
                options: {
                    filter: {
                        type: ComponentType.number,
                    },
                    visible: false,
                },
            }),

            DTColProps.XSmall({
                title: "Action Date (Server)",
                dataIndex: "actionDate",
                key: "actionDate",
                sorter: (a: number, b: number) => (moment(a) > moment(b) ? -1 : 1),
                render: (text: any, rowData: ArchivedAccountProfile) => {
                    return <span>{rowData.actionDate ? moment(rowData.actionDate).format("YYYY-MM-DD HH:mm:ss") : ""}</span>;
                },
                options: {
                    filter: {
                        type: ComponentType.daterange,
                        value: "",
                        inputProps: {
                            showTime: { format: "HH:mm:ss" },
                        },
                    },
                },
            }),

            DTColProps.XSmall(
                {
                    title: "PNL Captured",
                    dataIndex: "pnlCaptured",
                    key: "pnlCaptured",
                    sorter: (a: any, b: any) => a["pnlCaptured"] - b["pnlCaptured"],
                    render: (text: any, rowData: ArchivedAccountProfile) => {
                        return <span>{currencyRender(`${rowData.pnlCaptured}`)}</span>;
                    },
                },
                ["text-right"]
            ),

            DTColProps.XSmall(
                {
                    title: "Current Historical PNL",
                    dataIndex: "pnlHistorical",
                    key: "pnlHistorical",
                    sorter: (a: any, b: any) => a["pnlHistorical"] - b["pnlHistorical"],
                    render: (text: any, rowData: ArchivedAccountProfile) => {
                        return <span>{currencyRender(`${rowData.pnlHistorical}`)}</span>;
                    },
                },
                ["text-right"]
            ),

            DTColProps.XSmall(
                {
                    title: "PNL After Action",
                    dataIndex: "pnlDifference",
                    key: "pnlDifference",
                    sorter: (a: any, b: any) => a["pnlDifference"] - b["pnlDifference"],
                    render: (text: any, rowData: ArchivedAccountProfile) => {
                        return <span>{currencyRender(`${rowData.pnlDifference}`)}</span>;
                    },
                },
                ["text-right"]
            ),

            DTColProps.XSmall(
                {
                    title: "Deposit Captured",
                    dataIndex: "depositCaptured",
                    key: "depositCaptured",
                    sorter: (a: any, b: any) => a["depositCaptured"] - b["depositCaptured"],
                    render: (text: any, rowData: ArchivedAccountProfile) => {
                        return <span>{currencyRender(`${rowData.depositCaptured}`)}</span>;
                    },
                },
                ["text-right"]
            ),

            DTColProps.XSmall(
                {
                    title: "Withdrawal Captured",
                    dataIndex: "withdrawalCaptured",
                    key: "withdrawalCaptured",
                    sorter: (a: any, b: any) => a["withdrawalCaptured"] - b["withdrawalCaptured"],
                    render: (text: any, rowData: ArchivedAccountProfile) => {
                        return <span>{currencyRender(`${rowData.withdrawalCaptured}`)}</span>;
                    },
                },
                ["text-right"]
            ),

            DTColProps.XSmall(
                {
                    title: "PNL Past 7 Days",
                    dataIndex: "pnlPast7Days",
                    key: "pnlPast7Days",
                    sorter: (a: any, b: any) => a["pnlPast7Days"] - b["pnlPast7Days"],
                    render: (text: any, rowData: ArchivedAccountProfile) => {
                        return <span>{currencyRender(`${rowData.pnlPast7Days}`)}</span>;
                    },
                },
                ["text-right"]
            ),

            DTColProps.Small({
                title: "Watch List Group",
                dataIndex: "watchGroupId",
                key: "watchGroupId",
                render: (watchGroupId: number, rowData: ArchivedAccountProfile) => {
                    return rowData.watchGroupName;
                },
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: watchListGroups.map((x) => ({ text: x.name, value: x.id })),
                    },
                },
            }),

            {
                title: "Comments",
                dataIndex: "removedDateUtc",
                key: "removedDateUtc",
                render: (pnlCaptured: number, rowData: ArchivedAccountProfile) => {
                    return (
                        <>
                            <Comment
                                className="custom-comment"
                                content={<Paragraph ellipsis={{ rows: 3, expandable: true, symbol: "more" }}>{rowData.addedComment}</Paragraph>}
                                author={`Added by ${rowData.addedBy}`}
                                datetime={` - ${DataTableColumnRender.DateTime(rowData.addedDateUtc)}`}
                            />{" "}
                            <Comment
                                className="custom-comment"
                                content={<Paragraph ellipsis={{ rows: 3, expandable: true, symbol: "more" }}>{rowData.removedComment}</Paragraph>}
                                author={`Removed by  ${rowData.removedBy}`}
                                datetime={` - ${DataTableColumnRender.DateTime(rowData.removedDateUtc)}`}
                            />
                        </>
                    );
                },
                options: {
                    filter: {
                        text: " Date Time (Removed)",
                        type: ComponentType.daterange,
                        value: "",
                        inputProps: {
                            showTime: { format: "HH:mm:ss" },
                        },
                    },
                },
            },

            DTColProps.XSmall(
                {
                    title: "PNL Threshold",
                    dataIndex: "pnlThreshold",
                    key: "pnlThreshold",
                    sorter: (a: any, b: any) => a["pnlThreshold"] - b["pnlThreshold"],
                    render: (text: any, rowData: ArchivedAccountProfile) => {
                        return <span>{rowData.pnlThreshold ? currencyRender(`${rowData.pnlThreshold}`) : "-"}</span>;
                    },
                },
                ["text-right"]
            ),

            DTColProps.Small({
                title: "PNL Details",
                dataIndex: "addedDateUtc",
                key: "addedDateUtc",
                options: {
                    filter: {
                        text: " Date Time (Added)",
                        type: ComponentType.daterange,
                        value: "",
                        inputProps: {
                            showTime: { format: "HH:mm:ss" },
                        },
                    },
                    visible: false,
                },
            }),
            // DTColProps.Large({
            //     title: "PNL Details",
            //     dataIndex: "addedDateUtc",
            //     key: "addedDateUtc",
            //     render: (pnlCaptured: number, rowData: ArchivedAccountProfile) => {
            //         return (
            //             <Descriptions
            //                 column={1}
            //                 size="small"
            //                 className="tiny-description"
            //                 labelStyle={{ minWidth: "6.306vw" }}
            //                 contentStyle={{ textAlign: "right", minWidth: "6.306vw", display: "block", paddingRight: "1.051vw" }}
            //             >
            //                 <Descriptions.Item label="PNL Captured">{rowData.pnlCaptured}</Descriptions.Item>
            //                 <Descriptions.Item label="Past 7 days Cum PNL">{rowData.pnlPass7Days}</Descriptions.Item>
            //                 <Descriptions.Item label="Historical PNL">{rowData.pnlHistorical}</Descriptions.Item>
            //                 <Descriptions.Item label="PNL Breach (USD)">{rowData.pnlBreach}</Descriptions.Item>
            //                 <Descriptions.Item label="Difference">{rowData.pnlDifference}</Descriptions.Item>
            //                 <Descriptions.Item label=""> </Descriptions.Item>
            //             </Descriptions>
            //         );
            //     },
            //     options: {
            //         filter: {
            //             text: " Date Time (Added)",
            //             type: ComponentType.daterange,
            //             value: "",
            //             inputProps: {
            //                 showTime: { format: "HH:mm:ss" },
            //             },
            //         },
            //     },
            // }),

            DTColProps.Middle({
                title: "Inspector",
                dataIndex: "assigneeId",
                key: "assigneeId",
                render: (assigneeId: number) => {
                    let idx = usersList.findIndex((x) => x.id === assigneeId);
                    return idx > -1 ? (
                        <div className="watch-list-info">
                            <div className="title">{usersList[idx].email}</div>
                            <div className="value">{usersList[idx].name}</div>
                        </div>
                    ) : (
                        "-"
                    );
                },
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: usersList.map((x) => ({ text: x.name + "  |  " + x.email, value: x.id })),
                        inputProps: {
                            mode: "multiple",
                        },
                    },
                },
            }),
        ];
    };

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(false);
    const [tableCols, setTableCols] = useState<FlexiDataColumnProps[]>([...getColumnsConfig()]);
    const [data, setData] = useState<ArchivedAccountProfile[]>([]);
    const [pagination, setPagination] = useState<CustomPaginationProps>({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const [filterParams, setFilterParams] = useState<any>({});
    const [sorting, setSorting] = useState<string>("removedDateUtc,desc");

    const options: FlexiDataTableOptionsProps = {
        serverFiltering: true,
    };

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.HANDLE_PAGINATION_SORTING:
                setSorting(
                    FormData.sorter.column && FormData.sorter.order
                        ? `${FormData.sorter.column.key},${FormData.sorter.order === "ascend" ? "asc" : "desc"}`
                        : ""
                );
                setPagination((prev) => ({ ...prev, current: FormData.pagination.current, pageSize: FormData.pagination.pageSize }));
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                let wlFilterParams: any = {};
                Object.keys(FormData)
                    .filter((x) => FormData[x] && FormData[x].toString().length > 0)
                    .map((x) => {
                        if (x === "server") {
                            wlFilterParams["serverId"] = FormData[x];
                        } else if (x === "brand") {
                            wlFilterParams["brandId"] = FormData[x];
                        } else if (x === "assigneeId") {
                            wlFilterParams["assigneeIds"] = FormData[x];
                        } else if (x === "actionDate") {
                            wlFilterParams["actionDateFrom"] = FormData[x][0];
                            wlFilterParams["actionDateTo"] = FormData[x][1];
                        } else if (x === "addedDateUtc") {
                            wlFilterParams["addedDateUtcFrom"] = DateTimeUtil.GetUTC(FormData[x][0]);
                            wlFilterParams["addedDateUtcTo"] = DateTimeUtil.GetUTC(FormData[x][1]);
                        } else if (x === "removedDateUtc") {
                            wlFilterParams["removedDateUtcFrom"] = DateTimeUtil.GetUTC(FormData[x][0]);
                            wlFilterParams["removedDateUtcTo"] = DateTimeUtil.GetUTC(FormData[x][1]);
                        } else {
                            wlFilterParams[x] = FormData[x];
                        }
                        return x;
                    });
                setFilterParams(wlFilterParams);
                setPagination((prev) => ({ ...prev, current: 1 }));
                setRunRefetchDataList(true);
                break;
        }
    };

    const getArchievedWatchList = () => {
        apiRequest(APIs.GET_ARCHIEVED_WATCH_LIST, {
            limit: pagination.pageSize,
            current: pagination.current,
            ...(Object.keys(filterParams).length > 0 && { ...filterParams }),
            ...(sorting.length > 0 && { order: sorting }),
        })
            .then((data: any) => {
                if (data && data.result && data.result.length > 0) {
                    setData(data.result);
                    setPagination((prev) => ({ ...prev, total: data.total }));
                } else {
                    setData([]);
                    setPagination((prev) => ({ ...prev, total: 0 }));
                }
            })
            .catch((error) => {
                ErrorCatchValidator(error, (err: any) => console.log(err));
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, { filterType: ["brand", "server", "accountwatchlistgroup", "user"] })
            .then((data: any) => {
                setTableCols(getColumnsConfig(data.brands, data.servers, data.accountWatchListGroups, data.users));
                setRunRefetchDataList(true);
            })
            .catch((error) => {
                ErrorCatchValidator(error, (err: any) => console.log(err));
            })
            .finally(() => {
                setIsLoading(false);
            });
        return () => {};
    }, []);

    useEffect(() => {
        if (runRefetchDataList) {
            setIsLoading(true);
            getArchievedWatchList();
            setRunRefetchDataList(false);
        }
        return () => {};
    }, [runRefetchDataList]);

    return (
        <>
            <CardBox title={"Archived Account Watch List"}>
                <FlexiDataTable
                    rowKeyProperty="id"
                    title=""
                    columns={tableCols}
                    options={options}
                    dataSource={data ?? []}
                    callback={componentCallback}
                    loading={isLoading}
                    pagination={pagination}
                    serverSide={true}
                />
            </CardBox>
        </>
    );
};

export default ArchiveWatchList;

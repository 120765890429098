import { useState, useEffect, useMemo, useCallback } from "react";
import CardBox from "../../../components/Common/CardBox";
import FlexiDataTable from "../../../components/FlexiDataTable";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "../../../constants";
import {
    FlexiDataColumnProps,
    CustomPaginationProps,
    FlexiDataTableOptionsProps,
    FlexiDataTableCallbackProps,
    RegulatorList,
    CrmBrandList,
    ProblematicClient,
    UploadedProblematicClientExcelProps,
    ProblematicClientPreviewProps,
    CrmProblematicStatus,
    ProblematicClientAuditLogResponse,
    AccountProblematicDataResponse,
    ServersList,
    NavigationItem,
    CrmBlockTypes,
} from "../../../constants/type";
import { apiRequest } from "../../../services/apiConfig";
import { APIs } from "../../../services/apis";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "../../../utils/Common";
import { Tag, Modal, Form, message, Button, Segmented, Badge } from "antd";
import * as XLSX from "xlsx";
import { DateTimeUtil } from "../../../utils/datetime";
import { Link } from "react-router-dom";
import AuthHelper, { AuthKeys } from "../../../helpers/authHelper";
import HistoricalAccountUploadList from "./historicalAccUploadList";
import CRMCommentModal from "./crnCommentModal";
import ClientAuditLog from "./clientAuditLog";
import DownloadBatchUploadTemplate from "./downloadBatchUploadTemplate";
import ClientBatchUploadResult from "./clientBatchUploadResult";
import ClientAccountModal from "./clientAccountModal";
import TeamExpandableTable from "./teamExpandableTable";
import CRMBlockTypeInfo from "./CRMBlockTypeInfo";

export interface visibleCRMCommentModalProps {
    isShow: boolean;
    editCommentOnly: boolean;
}

const SegmentList: NavigationItem[] = [
    { key: "1", title: "Client List" },
    { key: "2", title: "Historical Account Upload List" },
];

const ProblematicClients = () => {
    const getAuditLog = (reqParams: any) => {
        apiRequest(APIs.GET_AUDIT_LOG_PROBLEMATIC, reqParams)
            .then((data: ProblematicClientAuditLogResponse[]) => {
                updateProblematicClientAuditLogData(data);
            })
            .catch(error => {
                ErrorCatchValidator(error, (err: any) => console.log(err));
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const getColumnsConfig = (
        regulators: RegulatorList[] = [],
        crmBrands: CrmBrandList[] = [],
        crmProblematicStatusList: CrmProblematicStatus[] = [],
        teamList: string[] = [],
        crmBlockTypes: CrmBlockTypes[] = []
    ) => {
        return [
            DTColProps.Small({
                title: "Client ID",
                dataIndex: "clientId",
                key: "clientId",
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: [],
                        inputProps: {
                            mode: "tags",
                            placeholder: "Please insert Client ID",
                            tokenSeparators: [",", "，", " "],
                        },
                    },
                },
                render: (name: string, rowData: ProblematicClient) => {
                    let id = btoa(
                        JSON.stringify({
                            clientId: rowData.clientId,
                            regulatorId: rowData.regulatorId,
                            crmServerId: rowData.crmServerId,
                        })
                    );
                    return (
                        <Link
                            to={`/analysis/clientlist/launch?clientId=${rowData.clientId}&regulatorId=${rowData.regulatorId}&crmServerId=${rowData.crmServerId}`}
                            target="_blank"
                            style={{ color: "#0e65cc" }}
                        >
                            {name}
                        </Link>
                    );
                },
            }),
            DTColProps.Small({
                title: "Account ID",
                dataIndex: "accountId",
                key: "accountId",
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: [],
                        inputProps: {
                            mode: "tags",
                            placeholder: "Please insert Account ID",
                            tokenSeparators: [",", "，", " "],
                        },
                    },
                    visible: false,
                },
            }),
            DTColProps.Small({
                title: "Brand",
                dataIndex: "crmBrand",
                key: "crmBrand",
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: crmBrands.map(x => ({ text: x.brand, value: x.id })),
                        inputProps: {
                            mode: "multiple",
                        },
                    },
                },
            }),
            DTColProps.Middle({
                title: "Regulator",
                dataIndex: "regulatorId",
                key: "regulatorId",
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: regulators.map(x => ({
                            text: x.displayName,
                            value: x.id,
                        })),
                        inputProps: {
                            mode: "multiple",
                        },
                    },
                },
                render: (regulator: number, record: ProblematicClient) => record.regulator,
            }),
            DTColProps.Small({
                title: "Team",
                dataIndex: "team",
                key: "team",
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: teamList.map(x => ({ text: x, value: x })),
                        inputProps: {
                            mode: "multiple",
                        },
                    },
                    visible: false,
                },
            }),
            DTColProps.Large({
                title: "Name",
                dataIndex: "clientName",
                key: "clientName",
            }),
            DTColProps.Middle({
                title: "CRM Block Type",
                dataIndex: "blockType",
                key: "blockType",
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: crmBlockTypes.map(x => ({ text: x.blockType, value: x.blockType })),
                        inputProps: {
                            mode: "multiple",
                        },
                    },
                    visible: false,
                },
            }),
            DTColProps.Small({
                title: "Status",
                dataIndex: "status",
                key: "status",
                align: "center",
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: crmProblematicStatusList.map(x => ({
                            text: x.status,
                            value: x.id,
                        })),
                        inputProps: {
                            style: { width: "100%" },
                            mode: "multiple",
                            allowClear: true,
                        },
                    },
                    visible: false,
                },
            }),
            DTColProps.Small({
                title: "Comment",
                dataIndex: "comment",
                key: "comment",
                options: {
                    filter: {
                        type: ComponentType.text,
                    },
                    visible: false,
                },
            }),
            DTColProps.DateTime({
                width: "16vw",
                title: "Last Modified (Local)",
                dataIndex: "modifiedDateUtc",
                key: "modifiedDateUtc",
                options: {
                    filter: {
                        type: ComponentType.daterange,
                        value: "",
                        inputProps: {
                            showTime: true,
                        },
                    },
                    visible: false,
                },
            }),
            {
                title: <CRMBlockTypeInfo crmBlockTypes={crmBlockTypes} />,
                dataIndex: "crmblocktype",
                key: "crmblocktype",
                render: (crmblocktype: any, rowData: ProblematicClient) => {
                    return rowData.blockTypeTeams.map((x, i) => {
                        return x.crmStatus.toUpperCase() === "BLOCKED" ? (
                            <Badge
                                key={i}
                                dot={x.teams && x.teams.find(y => y.status === 1) ? true : false}
                                style={{ width: 8, height: 8 }}
                                offset={[-8, 1]}
                            >
                                <Tag className={`prb-blockTypeColor prb-blockColor-active`}>{x.blockType}</Tag>
                            </Badge>
                        ) : (
                            <Badge
                                key={i}
                                dot={x.teams && x.teams.find(y => y.status === 1) ? true : false}
                                style={{ width: 8, height: 8 }}
                                offset={[-8, 1]}
                            >
                                <Tag className={`prb-blockTypeColor prb-blockColor-inactive`}>{x.blockType}</Tag>
                            </Badge>
                        );
                    });
                },
            },
        ];
    };

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(false);
    const [tableCols, setTableCols] = useState<FlexiDataColumnProps[]>([...getColumnsConfig()]);
    const [data, setData] = useState<ProblematicClient[]>([]);
    const [pagination, setPagination] = useState<CustomPaginationProps>({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const [currentActiveSegment, setCurrentActiveSegment] = useState<string>("1");
    const [sorting, setSorting] = useState<string>("modifiedDateUtc,desc");
    const [visibleCRMCommentModal, setvisibleCRMCommentModal] = useState<visibleCRMCommentModalProps>({
        isShow: false,
        editCommentOnly: false,
    });
    const [uploadedPreviewModal, setUploadedPreviewModal] = useState<boolean>(false);
    const [latestActivityModal, setLatestActivityModal] = useState<boolean>(false);
    const [problematicAccountModal, setProblematicAccountModal] = useState<boolean>(false);
    const [crmCommentForm] = Form.useForm();
    const [problematicClientPreviewData, setProblematicClientPreviewData] = useState<ProblematicClientPreviewProps[]>([]);
    const [initCrmProblematicStatus, setInitCrmProblematicStatus] = useState<CrmProblematicStatus[]>([]);
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
    const [accountProblematicData, setAccountProblematicData] = useState<AccountProblematicDataResponse[]>([]);
    const [servers, setServers] = useState<ServersList[]>([]);
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.PROBLEMATIC_CLIENT_EDIT);
    const [filterParams, setFilterParams] = useState<any>({ status: [1, 2, 3, 4, 5].filter(item => item !== 2) });
    const [haveError, setHaveError] = useState<boolean>(false);
    const [problematicClientAuditLogData, setProblematicClientAuditLogData] = useState<ProblematicClientAuditLogResponse[]>([]);
    const [blockTypeList, setBlockTypeList] = useState<CrmBlockTypes[]>([]);

    const statusKeyValue: any = useMemo(() => {
        return initCrmProblematicStatus.reduce((finalObj: any, x: any) => {
            finalObj[x.status] = x.id;
            return finalObj;
        }, {});
    }, [initCrmProblematicStatus]);

    const exportToExcel = useCallback(() => {
        setIsBtnLoading(true);
        apiRequest(
            APIs.DOWNLOAD_PROBLEMATIC,
            {
                fileType: 0,
                limit: 0,
                current: pagination.current,
                ...(Object.keys(filterParams)?.length > 0 && {
                    ...filterParams,
                }),
            },
            "POST",
            "arraybuffer"
        )
            .then((res: any) => {
                const fileName = res.headers["x-filename"];
                const url = window.URL.createObjectURL(new Blob([res.data], { type: "application/zip" })); // Create blob link to download
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", fileName); // or any other extension
                document.body.appendChild(link); // Append to html link element page
                link.click(); // start download
                document.body.removeChild(link); // Clean up and remove the link
            })
            .catch(error =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("problematic client", SUCCESS_FAILED.FAILED_DOWNLOAD_DATA, err))
            )
            .finally(() => setIsBtnLoading(false));
    }, [filterParams, pagination]);

    const uploadDummyRequest = ({ file, onSuccess }: any) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    const updateProblematicClientAuditLogData = (data: ProblematicClientAuditLogResponse[]) => {
        setProblematicClientAuditLogData(data);
        setLatestActivityModal(true);
    };

    const doEdit = (editParams: any) => {
        crmCommentForm.setFieldsValue(editParams);
        setvisibleCRMCommentModal({ isShow: true, editCommentOnly: editParams.editCommentOnly });
    };

    const getExpandedRowRender = (record: ProblematicClient) => {
        return (
            <TeamExpandableTable
                record={record}
                enableUpdate={enableUpdate}
                initCrmProblematicStatus={initCrmProblematicStatus}
                doEdit={doEdit}
                getAuditLog={getAuditLog}
            />
        );
    };

    const options: FlexiDataTableOptionsProps = useMemo(
        () => ({
            ...(enableUpdate && {
                upload: {
                    uploadText: isUploading ? "Uploading..." : "Upload Batch File (.csv)",
                    customRequest: uploadDummyRequest,
                    onChange: (info: any) => info.file.status === "error" && message.error(`${info.file.name} file upload failed.`),
                    beforeUpload: (file: any) => convertExcelToJSON(file),
                    disabled: isUploading,
                },
                export: { text: "Export as CSV" },
                extraButtons: () => <DownloadBatchUploadTemplate />,
            }),
            serverFiltering: true,
            showHideColumns: false,
            expandable: {
                expandedRowRender: getExpandedRowRender,
            },
            view: true,
        }),
        [isUploading, enableUpdate]
    );

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.HANDLE_PAGINATION_SORTING:
                FormData.sorter.order === undefined
                    ? setSorting("modifiedDateUtc,desc")
                    : setSorting(
                          FormData.sorter.column && FormData.sorter.order
                              ? `${FormData.sorter.column.key},${FormData.sorter.order === "ascend" ? "asc" : "desc"}`
                              : ""
                      );
                setPagination(prev => ({
                    ...prev,
                    current: FormData.pagination.current,
                    pageSize: FormData.pagination.pageSize,
                }));

                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                let pcFilterParams: any = {};
                Object.keys(FormData)
                    .filter(x => FormData[x] && (Array.isArray(FormData[x]) ? FormData[x].length > 0 : FormData[x].toString().length > 0))
                    .map(x => {
                        if (x === "clientId") {
                            pcFilterParams["clientIds"] = FormData[x];
                        } else if (x === "accountId") {
                            pcFilterParams["accounts"] = FormData[x];
                        } else if (x === "regulatorId") {
                            pcFilterParams["regulatorIds"] = FormData[x];
                        } else if (x === "crmBrand") {
                            pcFilterParams["crmBrands"] = FormData[x];
                        } else if (x === "blockType") {
                            pcFilterParams["blockTypes"] = FormData[x];
                        } else if (x === "team") {
                            pcFilterParams["teams"] = FormData[x];
                        } else if (x === "modifiedDateUtc") {
                            pcFilterParams["modifiedDateUtcFrom"] = DateTimeUtil.GetUTC(FormData[x][0]);
                            pcFilterParams["modifiedDateUtcTo"] = DateTimeUtil.GetUTC(FormData[x][1]);
                        } else {
                            pcFilterParams[x] = FormData[x];
                        }
                        return x;
                    });
                setFilterParams(pcFilterParams);
                setSorting("modifiedDateUtc,desc");
                setPagination(prev => ({ ...prev, current: 1 }));
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.VIEW_RECORD:
                setAccountProblematicData([]);
                let reqParams = {
                    clientId: FormData.clientId,
                    crmServerId: FormData.crmServerId,
                    regulatorId: FormData.regulatorId,
                };
                viewProblematicAccounts(reqParams);
                setProblematicAccountModal(true);
                break;
            case CALLBACK_KEY.EXPORT_CSV_EXCEL:
                exportToExcel();
                break;
            default:
                break;
        }
    };

    const createHashKey = (excelRows: UploadedProblematicClientExcelProps[]) => {
        let newExcelRows: any[] = [];
        if (Object.keys(excelRows[0]).includes("Login") && Object.keys(excelRows[0]).includes("BlockType")) {
            newExcelRows = excelRows.map((row: UploadedProblematicClientExcelProps) => {
                return {
                    hashKey: `${row.Server}|${row.Login}|${row.BlockType}`,
                    ...row,
                };
            });
        }
        return newExcelRows;
    };

    const convertExcelToJSON = (uploadedFile: any) => {
        setIsUploading(true);
        if (!uploadedFile) return null;
        if (uploadedFile.size > 5000000) {
            message.error("Maximum file size is 5 mb");
            return null;
        }

        /* Boilerplate to set up FileReader */
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;

        // Set up callback for when FileReader is done loading
        reader.onload = (event: any) => {
            let duplicates: any[] = [];

            /* Parse data */
            const bstr = event.target.result;
            const wb = XLSX.read(bstr, {
                type: rABS ? "binary" : "array",
                bookVBA: true,
                raw: true,
            });

            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];

            /* Convert array of arrays */
            const excelRows: UploadedProblematicClientExcelProps[] = XLSX.utils.sheet_to_json(ws);

            const hasInvalidLoginInteger = excelRows.some(item => {
                const parsedInteger = parseInt(item.Login, 10);
                return isNaN(parsedInteger) || parsedInteger !== parseFloat(item.Login);
            });

            if (hasInvalidLoginInteger) {
                message.warning(`Please double check the format of Login`);
                return null;
            }

            if (excelRows.length === 0) {
                message.error(`File is empty`);
                return null;
            }

            let newArr = createHashKey(excelRows).reduce((finalArr: any, currRow: any) => {
                if (!finalArr[currRow["hashKey"]]) {
                    finalArr[currRow["hashKey"]] = [];
                }
                finalArr[currRow["hashKey"]].push(currRow);
                return finalArr;
            }, {});
            Object.values(newArr).forEach((currArr: any) => {
                if (currArr.length > 1) {
                    duplicates.push(currArr[0]);
                }
            });

            if (duplicates.length > 0) {
                message.warning(
                    <span>
                        There are <b>{duplicates.length}</b> duplicate(s) of <b>Login {duplicates[0].Login}</b> with{" "}
                        <b>Block Type {duplicates[0].BlockType}</b> found in the csv file.
                    </span>,
                    5
                );
                setIsLoading(false);
                setIsUploading(false);
            } else {
                setIsLoading(true);

                let formData = new FormData();
                formData.append("File", uploadedFile);

                apiRequest(APIs.UPLOAD_PREVIEW_PROBLEMATIC, formData, "POST", "json", {
                    Accept: "text/plain",
                    "Content-type": "multipart/form-data",
                })
                    .then((data: ProblematicClientPreviewProps[]) => {
                        if (data && data.length > 0) {
                            setProblematicClientPreviewData(data);
                            setHaveError(false);
                            setUploadedPreviewModal(true);
                        } else {
                            setData([]);
                        }
                    })
                    .catch((err: any) =>
                        ErrorCatchValidator(err, (err: any) => {
                            message.error(`Failed to batch upload for problematic clients preview: ${err.message}`, 5);
                        })
                    )
                    .finally(() => {
                        setIsLoading(false);
                        setIsUploading(false);
                    });
            }
        };

        // Call FileReader
        if (rABS) {
            reader.readAsBinaryString(uploadedFile);
        } else {
            reader.readAsArrayBuffer(uploadedFile);
        }
    };

    const successfullyUploadedPreviewData = () => {
        message.success("Successfully uploaded!");
        setUploadedPreviewModal(false);
        setProblematicClientPreviewData([]);
        setRunRefetchDataList(true);
    };

    const createProblematicClientPreviewData = () => {
        setIsLoading(true);
        const filteredProblematicClientPreview = problematicClientPreviewData.map(
            ({ accountId, comment, server, serverId, status, brand, regulator, blockType, team }) => ({
                accountId,
                comment,
                server,
                serverId,
                status,
                brand,
                regulator,
                blockType,
                team,
            })
        );
        apiRequest(APIs.CREATE_PROBLEMATIC, {
            clients: filteredProblematicClientPreview,
        })
            .then((data: any) => {
                setIsLoading(false);
                if (data && data.length > 0) {
                    setHaveError(true);
                    setProblematicClientPreviewData(data);
                } else {
                    successfullyUploadedPreviewData();
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("new batch upload", SUCCESS_FAILED.FAILED_CREATE_DATA, err))
            )
            .finally(() => {
                setIsLoading(false);
            });
    };

    const getClientsList = (oriCrmProblematicStatus: CrmProblematicStatus[]) => {
        apiRequest(APIs.GET_PROBLEMATIC_LIST, {
            limit: pagination.pageSize,
            current: pagination.current,
            status: oriCrmProblematicStatus.map(item => item.id),
            ...(Object.keys(filterParams).length > 0 && { ...filterParams }),
            ...(sorting.length > 0 && { order: sorting }),
        })
            .then((data: any) => {
                if (data && data.result && data.result.length > 0) {
                    setData(data.result);
                    setPagination(prev => ({ ...prev, total: data.total }));
                } else {
                    setData([]);
                    setPagination(prev => ({ ...prev, total: 0 }));
                }
            })
            .catch(error => {
                ErrorCatchValidator(error, (err: any) => console.log(err));
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const viewProblematicAccounts = (requestParams: any) => {
        apiRequest(APIs.GET_ACCOUNTS_PROBLEMATIC, requestParams)
            .then((data: AccountProblematicDataResponse[]) => {
                setAccountProblematicData(data);
            })
            .catch(error => {
                ErrorCatchValidator(error, (err: any) => console.log(err));
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const updateProblematicClientStatus = (values: any) => {
        apiRequest(APIs.UPDATE_PROBLEMATIC, {
            teamId: values.teamId,
            comment: values.comment,
            status: values.status,
        })
            .then(() => {
                ErrorMessageHandler("Problematic client status", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                crmCommentForm.resetFields();
                getClientsList(initCrmProblematicStatus);
            })
            .catch(error =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("problematic client status", SUCCESS_FAILED.FAILED_UPDATE_DATA, err))
            );
    };

    const getFilterConfig = () => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, {
            filterType: ["regulator", "crmserver", "crmstatuses", "server", "crmblocktypes"],
        })
            .then((data: any) => {
                apiRequest(APIs.GET_SETTING_LIST, { keys: ["UserTeams"] })
                    .then((res: any) => {
                        const parseJSONValue = (res: any[], key: string) => {
                            const item = res.find(item => item.key === key);
                            return item ? JSON.parse(item.value) : null;
                        };

                        const userTeamsValue = parseJSONValue(res, "UserTeams");
                        setInitCrmProblematicStatus(data.crmProblematicStatus);
                        setTableCols(
                            getColumnsConfig(data.regulators, data.crmBrands, data.crmProblematicStatus, userTeamsValue, data.crmBlockTypes)
                        );
                        setBlockTypeList(data.crmBlockTypes);
                        setServers(data.servers);
                        getClientsList(data.crmProblematicStatus);
                    })
                    .catch((error: any) => {
                        ErrorCatchValidator(error, (err: any) => console.log("Failed to get latest user teams/ block type: ", err));
                    });
            })
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => console.log(err)));
    };

    useEffect(() => {
        getFilterConfig();
        return () => {};
    }, []);

    useEffect(() => {
        if (runRefetchDataList) {
            setIsLoading(true);
            getClientsList(initCrmProblematicStatus);
            setRunRefetchDataList(false);
        }
        return () => {};
    }, [runRefetchDataList]);

    return (
        <div className="problematic-clients-div">
            <CardBox title={"Problematic Clients"}>
                <Segmented
                    value={currentActiveSegment}
                    options={SegmentList.map((x: NavigationItem) => ({
                        label: x.title,
                        value: x.key,
                    }))}
                    onChange={(activeKey: any) => setCurrentActiveSegment(activeKey)}
                />
                {currentActiveSegment === "1" && (
                    <FlexiDataTable
                        rowKeyProperty="id"
                        title=""
                        columns={tableCols}
                        options={options}
                        dataSource={data ?? []}
                        callback={componentCallback}
                        loading={isLoading}
                        pagination={pagination}
                        serverSide={true}
                        filterInitialValue={{ status: [1, 2, 3, 4, 5].filter(item => item !== 2) }}
                        exporting={isBtnLoading}
                    />
                )}
                {currentActiveSegment === "2" && <HistoricalAccountUploadList blockTypeList={blockTypeList} />}
            </CardBox>

            <Modal
                className="problematic-audit-log-modal"
                width={"60%"}
                bodyStyle={{ maxHeight: "85vh", overflowY: "auto" }}
                centered
                destroyOnClose
                maskClosable={false}
                title={"Latest Activity"}
                open={latestActivityModal}
                onOk={() => {
                    setLatestActivityModal(false);
                }}
                onCancel={() => {
                    setLatestActivityModal(false);
                }}
                footer={null}
            >
                <ClientAuditLog statusKeyValue={statusKeyValue} problematicClientAuditLogData={problematicClientAuditLogData} />
            </Modal>

            <Modal
                className="problematic-accounts-modal"
                width={"40%"}
                centered
                destroyOnClose
                maskClosable={false}
                title={"View Problematic Accounts"}
                open={problematicAccountModal}
                onOk={() => {
                    setProblematicAccountModal(false);
                }}
                onCancel={() => {
                    setProblematicAccountModal(false);
                }}
                footer={null}
            >
                <ClientAccountModal accountProblematicData={accountProblematicData} servers={servers} />
            </Modal>

            <Modal
                width={"80%"}
                destroyOnClose
                maskClosable={false}
                title={`Problematic Client Batch Uploaded ${haveError ? "Result" : "Preview"} `}
                open={uploadedPreviewModal}
                onCancel={() => {
                    setUploadedPreviewModal(false);
                    setIsLoading(false);
                }}
                footer={
                    haveError ? null : (
                        <div>
                            <Button onClick={() => setUploadedPreviewModal(false)} disabled={isLoading}>
                                Cancel
                            </Button>
                            <Button type="primary" onClick={createProblematicClientPreviewData} disabled={isLoading}>
                                {isLoading ? "Submitting..." : "Confirm"}
                            </Button>
                        </div>
                    )
                }
            >
                {haveError ? null : <p>Are you confirm to upload all as well?</p>}
                <ClientBatchUploadResult problematicClientPreviewData={problematicClientPreviewData} />
            </Modal>

            <Modal
                width={"40%"}
                className="problematic-crm-comment-modal"
                maskClosable={visibleCRMCommentModal.isShow}
                title={visibleCRMCommentModal.editCommentOnly ? "Edit Comment" : "Update Status"}
                open={visibleCRMCommentModal.isShow}
                onOk={() => {
                    crmCommentForm
                        .validateFields()
                        .then(values => {
                            updateProblematicClientStatus(values);
                            setvisibleCRMCommentModal({ editCommentOnly: false, isShow: false });
                        })
                        .catch((error: any) => {
                            ErrorCatchValidator(error, (err: any) => message.error("Please fill in required fields."));
                        });
                }}
                onCancel={() => {
                    crmCommentForm.resetFields();
                    setvisibleCRMCommentModal({ editCommentOnly: false, isShow: false });
                }}
            >
                <CRMCommentModal
                    initCrmProblematicStatus={initCrmProblematicStatus}
                    editCommentOnly={visibleCRMCommentModal.editCommentOnly}
                    crmCommentForm={crmCommentForm}
                />
            </Modal>
        </div>
    );
};

export default ProblematicClients;

import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CardBox from "../../../components/Common/CardBox";
import FlexiDataTable from "../../../components/FlexiDataTable";
import {
    AccountProfile,
    CustomPaginationProps,
    FlexiDataColumnProps,
    FlexiDataTableCallbackProps,
    FlexiDataTableOptionsProps,
    AccountTypes,
    BrandsList,
    ServersList,
    TagLabel,
} from "../../../constants/type";
import { apiRequest } from "../../../services/apiConfig";
import { APIs } from "../../../services/apis";
import { removeSource, setSource } from "../../../services/localStorage";
import { DTColProps, ErrorCatchValidator } from "../../../utils/Common";
import { CALLBACK_KEY, ComponentType } from "../../../constants";
import { ACCOUNT_PROFILE_FROM_MODULE, getAccountProfileId, getAccountProfileParams, getNavigationUrl } from "../AccountProfileHelper";
import { Tag } from "antd";

const AccountProfilesListing = () => {
    let navigate = useNavigate();
    let location = useLocation();

    const getColumnsConfig = (
        brands: BrandsList[] = [],
        servers: ServersList[] = [],
        accountTypes: AccountTypes[] = [],
        tagLabels: TagLabel[] = []
    ) => {
        return [
            DTColProps.Middle({
                title: "Brand",
                dataIndex: "brand",
                key: "brand",
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: brands.map((x) => ({ text: x.brand, value: x.id })),
                    },
                },
            }),
            DTColProps.Middle({
                title: "Server",
                dataIndex: "server",
                key: "server",
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: servers.map((x) => ({ text: x.server, value: x.id })),
                    },
                },
            }),
            DTColProps.Middle({
                title: "Account ID",
                dataIndex: "accountId",
                key: "accountId",
                options: {
                    filter: {
                        type: ComponentType.number,
                    },
                },
            }),
            // DTColProps.Middle({
            //     title: "Account Type",
            //     dataIndex: "accountType",
            //     key: "accountType",
            //     options: {
            //         filter: {
            //             type: ComponentType.dropdown,
            //             value: accountTypes.map((x) => ({ text: x.name, value: x.id })),
            //         },
            //     },
            // }),
            {
                title: "Group Name",
                dataIndex: "groupName",
                key: "groupName",
                options: {
                    filter: {
                        type: ComponentType.text,
                    },
                },
            },
            DTColProps.Small(
                {
                    title: "Currency",
                    dataIndex: "currency",
                    key: "currency",
                    options: {
                        filter: {
                            type: ComponentType.text,
                        },
                    },
                },
                ["text-center"]
            ),
            DTColProps.Small({
                title: "Remarks",
                dataIndex: "labels",
                key: "labels",
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: tagLabels.map((x: any) => ({ text: x.name, value: x.name })),
                        inputProps: {
                            mode: "multiple",
                        },
                    },
                    visible: false,
                },
                render: (labels: TagLabel[]) => {
                    if (labels?.length > 0) {
                        labels.sort((a: TagLabel, b: TagLabel) => b.name.length - a.name.length);
                        return labels.map((label: TagLabel) => (
                            <Tag key={label.id} style={{ marginBottom: 7, padding: "0 10px 0 10px" }}>
                                {label.name}
                            </Tag>
                        ));
                    } else return "-";
                },
            }),
        ];
    };

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(false);
    const [tableCols, setTableCols] = useState<FlexiDataColumnProps[]>([...getColumnsConfig()]);
    const [data, setData] = useState<AccountProfile[]>([]);
    const [pagination, setPagination] = useState<CustomPaginationProps>({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const [filterParams, setFilterParams] = useState<any>({});
    const [sorting, setSorting] = useState<string>("");

    const options: FlexiDataTableOptionsProps = {
        view: (record: any, option: any) => {
            let newProps = { ...option };
            newProps = <Link to={getNavigationUrl(ACCOUNT_PROFILE_FROM_MODULE.ACCOUNT_PROFILE_LIST, record.id)}>{newProps}</Link>;
            return newProps;
        },
        serverFiltering: true,
    };

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.VIEW_RECORD:
                navigate(getNavigationUrl(ACCOUNT_PROFILE_FROM_MODULE.ACCOUNT_PROFILE_LIST, getAccountProfileParams(FormData.serverId, FormData.accountId, ACCOUNT_PROFILE_FROM_MODULE.ACCOUNT_PROFILE_LIST, "", "", "", "", "")));
                window.scrollTo(0, 0);
                break;
            case CALLBACK_KEY.HANDLE_PAGINATION_SORTING:
                setSorting(
                    FormData.sorter.column && FormData.sorter.order
                        ? `${FormData.sorter.column.key},${FormData.sorter.order === "ascend" ? "asc" : "desc"}`
                        : ""
                );
                setPagination((prev) => ({ ...prev, current: FormData.pagination.current, pageSize: FormData.pagination.pageSize }));
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                let filterParams: any = {};
                Object.keys(FormData)
                    .filter((x) => FormData[x] && FormData[x].toString().length > 0)
                    .map((x) => {
                        if (x === "server") {
                            filterParams["serverId"] = FormData[x];
                        } else if (x === "brand") {
                            filterParams["brandId"] = FormData[x];
                        } else {
                            filterParams[x] = FormData[x];
                        }
                        return x;
                    });
                setFilterParams(filterParams);
                setPagination((prev) => ({ ...prev, current: 1 }));
                setRunRefetchDataList(true);
                break;
        }
    };

    const getAccountList = () => {
        apiRequest(APIs.GET_ACCOUNT_PROFILE_LIST, {
            limit: pagination.pageSize,
            current: pagination.current,
            ...(Object.keys(filterParams).length > 0 && { ...filterParams }),
            ...(sorting.length > 0 && { order: sorting }),
        })
            .then((data: any) => {
                if (data && data.result && data.result.length > 0) {
                    let markupData = data.result.map((x: AccountProfile) => {
                        x.id = getAccountProfileId(x.serverId, x.accountId, ACCOUNT_PROFILE_FROM_MODULE.ACCOUNT_PROFILE_LIST, "", "", "", "", "");
                        return x;
                    });
                    setData(markupData);
                    setPagination((prev) => ({ ...prev, total: data.total }));
                } else {
                    setData([]);
                    setPagination((prev) => ({ ...prev, total: 0 }));
                }
            })
            .catch((error) => {
                ErrorCatchValidator(error, (err: any) => console.log(err));
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        setSource(location);

        apiRequest(APIs.GET_FILTER_CONFIG_LIST, { filterType: ["brand", "server", "accounttype", "taglabel"] })
            .then((data: any) => {
                setTableCols(getColumnsConfig(data.brands, data.servers, data.accountTypes, data.tagLabels));
                //setRunRefetchDataList(true);
            })
            .catch((error) => {
                ErrorCatchValidator(error, (err: any) => console.log(err));
            })
            .finally(() => {
                setIsLoading(false);
            });

        return () => {
            removeSource();
        };
    }, []);

    useEffect(() => {
        if (runRefetchDataList) {
            setIsLoading(true);
            getAccountList();
            setRunRefetchDataList(false);
        }
        return () => {};
    }, [runRefetchDataList]);

    return (
        <>
            <CardBox title={"Account List"}>
                <FlexiDataTable
                    rowKeyProperty="id"
                    title=""
                    columns={tableCols}
                    options={options}
                    dataSource={data ?? []}
                    callback={componentCallback}
                    loading={isLoading}
                    pagination={pagination}
                    serverSide={true}
                />
            </CardBox>
        </>
    );
};

export default AccountProfilesListing;

import { message } from "antd";
import axios, { AxiosResponse } from "axios";
import { initialize, refreshToken, setProfileInfo, userLogout } from "./api";
import { ResponseProps } from "./apiConfig";
import { APIs } from "./apis";
import { getToken } from "./localStorage";
import { getActualSSOLoginUrl } from "../pages/LoginPage/ssoIndex";

let isRefreshingTokenFetching = false;
let failApis: any[] = [];
let axiosInstances: any = {};

let axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    // headers: {
    //     Accept: "text/plain",
    //     "Content-Type": "application/json",
    // },
});

axiosInstance.interceptors.request.use(
    async (config: any) => {
        const token = getToken();
        //const source = getSource("");
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
        }
        // if (source) {
        //     config.headers["Source"] = `${source}`;
        // }

        // let tmp = userInfo();
        // //if (tmp?.isTokenExpired() && config.url !== APIs.USER_LOGIN) {
        // if (true && config.url !== APIs.USER_LOGIN) {
        //     if (getHoldingFlag() === "0") {
        //         setHoldingFlag();

        //         await refreshToken().then((response: any) => {
        //             if (response && response.refresh_token) {
        //                 initialize(response.access_token, response.refresh_token);
        //                 removeHoldingFlag();
        //             }
        //         });
        //     }
        // }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    res => {
        if (res.status === 200) {
            if (
                res.headers["content-type"] === "application/zip" ||
                res.headers["content-type"] === "application/csv" ||
                res.headers["content-type"] === "application/xlsx"
            ) {
                return Promise.resolve({ data: res.data, headers: res.headers });
            }

            const responseData: ResponseProps<any> = res.data;
            if (responseData.status === 0) {
                return Promise.resolve(responseData.data);
            } else {
                return Promise.reject({
                    status: responseData.status,
                    message: responseData.message,
                    data: responseData.data ? responseData.data : {},
                } as ResponseProps<null>);
            }
        }
        return Promise.reject({
            status: 404,
            message: "API calling fail!!",
        } as ResponseProps<null>);
    },
    async err => {
        const oriConfig = err.config;
        if (oriConfig.url !== APIs.USER_LOGIN && err.response) {
            try {
                if (err.response.status === 401) {
                    if (!isRefreshingTokenFetching) {
                        isRefreshingTokenFetching = true;
                        failApis.push(oriConfig);

                        const tkResponse: any = await refreshToken();
                        if (tkResponse && tkResponse.refresh_token) {
                            initialize(tkResponse.access_token, tkResponse.refresh_token);
                            setProfileInfo({ manualCatch: 1 });

                            const failApisCall: any = failApis.map(x => axiosInstance(x));
                            await Promise.all(failApisCall);

                            isRefreshingTokenFetching = false;
                            failApis = [];
                            window.location.reload();
                        } else {
                            message.error(tkResponse.data.message);
                            userLogout();
                            return Promise.reject(err);
                        }
                    } else {
                        failApis.push(oriConfig);
                    }
                }
            } catch (error) {
                let data: any = (oriConfig.method as string).toLowerCase() === "post" ? JSON.parse(oriConfig.data) : oriConfig.params;
                if (data?.manualCatch) {
                    return Promise.reject(err);
                } else {
                    message.error("Token expired. Please login again.");
                    userLogout();
                    return Promise.reject(err);
                }
            }
        }
        return Promise.reject(err);
    }
);

export default axiosInstance;

export const getInstance = (pathname: string) => {
    if (axiosInstances[pathname] && !axiosInstances[pathname].controller.signal.aborted) {
        return axiosInstances[pathname].instance;
    } else {
        const customAxiosInstance = createInstance();
        axiosInstances[pathname] = customAxiosInstance;
        return axiosInstances[pathname].instance;
    }
};

export const cancelPreviousPageRequests = (previousPath: string) => {
    axiosInstances[previousPath]?.controller.abort();
};

let createInstance = () => {
    const controller = new AbortController();

    let axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        signal: controller.signal,
        cancelToken: axios.CancelToken.source().token,
    });

    axiosInstance.interceptors.request.use(
        async (config: any) => {
            const token = getToken();
            //const source = getSource("");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            // if (source) {
            //     config.headers["Source"] = `${source}`;
            // }

            // let tmp = userInfo();
            // //if (tmp?.isTokenExpired() && config.url !== APIs.USER_LOGIN) {
            // if (true && config.url !== APIs.USER_LOGIN) {
            //     if (getHoldingFlag() === "0") {
            //         setHoldingFlag();

            //         await refreshToken().then((response: any) => {
            //             if (response && response.refresh_token) {
            //                 initialize(response.access_token, response.refresh_token);
            //                 removeHoldingFlag();
            //             }
            //         });
            //     }
            // }

            return config;
        },
        error => {
            return Promise.reject(error);
        }
    );

    axiosInstance.interceptors.response.use(
        res => {
            if (res.status === 200) {
                if (
                    res.headers["content-type"] === "application/zip" ||
                    res.headers["content-type"] === "application/csv" ||
                    res.headers["content-type"] === "application/xlsx"
                ) {
                    return Promise.resolve({ data: res.data, headers: res.headers });
                }

                const responseData: ResponseProps<any> = res.data;
                if (responseData.status === 0) {
                    return Promise.resolve(responseData.data);
                } else {
                    return Promise.reject({
                        status: responseData.status,
                        message: responseData.message,
                        data: responseData.data ? responseData.data : {},
                    } as ResponseProps<null>);
                }
            }
            return Promise.reject({
                status: 404,
                message: "API calling fail!!",
            } as ResponseProps<null>);
        },
        async err => {
            const oriConfig = err.config;
            if (oriConfig === undefined) {
                console.log("Request canceled.");
            } else if (err.code && err.code === "ERR_NETWORK") {
                setTimeout(() => {
                    window.location.href = getActualSSOLoginUrl();
                }, 3000);
            } else if (oriConfig.url !== APIs.USER_LOGIN && err.response) {
                try {
                    if (err.response.status === 401) {
                        if (!isRefreshingTokenFetching) {
                            isRefreshingTokenFetching = true;
                            failApis.push(oriConfig);

                            const tkResponse: any = await refreshToken();
                            if (tkResponse && tkResponse.refresh_token) {
                                initialize(tkResponse.access_token, tkResponse.refresh_token);
                                setProfileInfo({ manualCatch: 1 });

                                const failApisCall: any = failApis.map(x => axiosInstance(x));
                                await Promise.all(failApisCall);

                                isRefreshingTokenFetching = false;
                                failApis = [];
                                window.location.reload();
                            } else {
                                message.error(tkResponse.data.message);
                                userLogout();
                                return Promise.reject(err);
                            }
                        } else {
                            failApis.push(oriConfig);
                        }
                    }
                } catch (error: any) {
                    let data: any = (oriConfig.method as string).toLowerCase() === "post" ? JSON.parse(oriConfig.data) : oriConfig.params;
                    if (error.message === "invalid_refresh_token" && error.status === 21) {
                        setTimeout(() => {
                            window.location.href = getActualSSOLoginUrl();
                        }, 1500);
                    } else if (data?.manualCatch) {
                        return Promise.reject(err);
                    } else {
                        message.error("Token expired. Please login again.");
                        userLogout();
                        return Promise.reject(err);
                    }
                }
            }
            return Promise.reject(err);
        }
    );

    const customAxiosInstance = {
        controller,
        instance: axiosInstance,
    };

    return customAxiosInstance;
};

/* plain axios instance, only add in auto refresh token */

const plainAxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

plainAxiosInstance.interceptors.request.use(
    async (config: any) => {
        const token = getToken();
        //const source = getSource("");
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        console.log("error", error);
        return Promise.reject(error);
    }
);

plainAxiosInstance.interceptors.response.use(
    response => {
        return response;
    },
    async error => {
        const originalRequest = error.config;

        if (originalRequest.url !== "/user/login" && error.response) {
            if (error.response.status === 401 && !originalRequest._retry) {
                if (!isRefreshingTokenFetching) {
                    originalRequest._retry = true;
                    isRefreshingTokenFetching = true;

                    try {
                        const refreshTokenResp = await refreshToken();
                        if (refreshTokenResp && refreshTokenResp.refresh_token) {
                            initialize(refreshTokenResp.access_token, refreshTokenResp.refresh_token);
                            isRefreshingTokenFetching = false;
                            originalRequest.headers["Authorization"] = `Bearer ${refreshTokenResp.access_token}`;
                            return plainAxiosInstance(originalRequest);
                        } else {
                            message.error(refreshTokenResp.data.message);
                            userLogout();
                            isRefreshingTokenFetching = false;
                            return Promise.reject(error);
                        }
                    } catch (err) {
                        message.error("Token expired. Please login again.");
                        userLogout();
                        isRefreshingTokenFetching = false;
                        return Promise.reject(err);
                    }
                } else {
                    return Promise.reject(error);
                }
            }
        }

        return Promise.reject(error);
    }
);

export { plainAxiosInstance };

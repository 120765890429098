import { Form, Input } from "antd";
import { FormComponent } from "../../../../../components/FormComponent";
import { ComponentType } from "../../../../../constants";
import { FormInstance } from "antd/es/form";
import { REQUIRED_FIELD } from "../../../../../constants/errorMessage";
import { APIs, apiRequest } from "../../../../../services/apiConfig";
import { isEmptyOrNull } from "../../../../../utils/string";

export interface SymbolRadioComponentProps {
    form: FormInstance;
    sources: any[];
    symbols: any[];
    cleanSymbols: any[];
}

const SymbolRadioComponent = (props: SymbolRadioComponentProps) => {
    const editKey = Form.useWatch("symbolId", props.form);

    return (
        <>
            {isEmptyOrNull(editKey) ? (
                <FormComponent
                    label="Source"
                    name="sourceId"
                    extra={{
                        type: ComponentType.dropdown,
                        value: props.sources,
                        rules: [{ required: true, message: REQUIRED_FIELD }],
                    }}
                />
            ) : (
                <FormComponent
                    label="Source"
                    name="sourceId"
                    extra={{
                        type: ComponentType.dropdown,
                        value: props.sources,
                        inputProps: {
                            disabled: true,
                        },
                    }}
                />
            )}
            <FormComponent
                label="Symbol"
                name="symbol"
                extra={{
                    type: ComponentType.text,
                    value: "",
                    itemProps: {
                        validateTrigger: "onBlur",
                    },
                    rules: [
                        { required: true, message: REQUIRED_FIELD },
                        {
                            validator: editKey
                                ? (_: any, value: any) => {
                                      let currentSource = props.form.getFieldValue("sourceId"),
                                          currentSymbolId = props.form.getFieldValue("symbolId");
                                      if (value.length === 0 || currentSource.length === 0) return Promise.resolve();

                                      return apiRequest(APIs.GET_SYMBOL_LIST, { selectedSourceId: currentSource, symbolListingMode: 3 }).then(res => {
                                          if (res.length > 0) {
                                              let fidx: number = res.findIndex((x: any) => x.symbol.toLowerCase() === value.toLowerCase());
                                              if (fidx > -1 && res[fidx].symbolId !== currentSymbolId) {
                                                  return Promise.reject(new Error("The Symbol already existed. Please try another symbol."));
                                              }
                                          }
                                          return Promise.resolve();
                                      });
                                  }
                                : (_: any, value: any) => {
                                      let currentSource = props.form.getFieldValue("sourceId");
                                      if (value.length === 0 || currentSource.length === 0) return Promise.resolve();

                                      return apiRequest(APIs.GET_SYMBOL_LIST, { selectedSourceId: currentSource, symbolListingMode: 3 }).then(res => {
                                          if (res.length > 0) {
                                              if (res.some((x: any) => x.symbol.toLowerCase() === value.toLowerCase())) {
                                                  return Promise.reject(new Error("The Symbol already existed. Please try another symbol."));
                                              }
                                          }
                                          return Promise.resolve();
                                      });
                                  },
                        },
                    ],
                }}
            />
        </>
    );
};

export default SymbolRadioComponent;

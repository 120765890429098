import React from "react";
import TabContent from "../../SystemMonitor/components/TabContent";
import ServerTimeWidget from "../../SystemMonitor/components/ServerTimeWidget";
import useRCMidBias from "../../../hooks/useRCMidBias";
import { motion } from "framer-motion";
import { Alert, Button, Space, Typography } from "antd";
import { FaRedo } from "react-icons/fa";
import TimeDiff from "../../SystemMonitor/components/TimeDiff";
import MidBiasSolvedTable from "./MidBiasSolvedTable";

const TabSolved = () => {
    const { isLoading, refetchRcMidBias, dataUpdatedAt } = useRCMidBias({ duration: "1day" });
    const { Title } = Typography;
    return (
        <TabContent>
            <div style={{ padding: "1rem" }}>
                <div style={{ display: "flex", alignItems: "center", marginBottom: "1rem", gap: 16 }}>
                    <Space style={{ alignItems: "center" }} wrap>
                        <Title level={2} style={{ padding: 0, margin: 0 }}>
                            Mid Bias Solved
                        </Title>
                        <TimeDiff timestamp={dataUpdatedAt} refetch={refetchRcMidBias} isFetching={isLoading} rounded />
                    </Space>
                </div>
                {/* <Alert type="info" message="Using dummy data, remember to remove" /> */}
                <MidBiasSolvedTable />
                {/* <MidBiasTable /> */}
            </div>
        </TabContent>
    );
};

export default TabSolved;

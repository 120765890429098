import React, { useCallback, useEffect, useMemo, useState } from "react";
import FlexiDataTable from "../../../components/FlexiDataTable";
import CardBox from "../../../components/Common/CardBox";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "../../../utils/Common";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "../../../constants";
import { FlexiDataTableCallbackProps, FlexiDataTableOptionsProps, KeyValuePair } from "../../../constants/type";
import { APIs } from "../../../services/apiConfig";
import { plainAxiosInstance } from "../../../services/axiosSetup";
import { Switch, Tooltip } from "antd";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import LoadingComponent from "../../../components/Loading";

interface LinkedAccountQueryData {
    address: string;
    balance: number;
    city: string;
    country: string;
    createDate: any;
    db: string;
    email: string;
    equity: number;
    flag: any;
    group: string;
    leverage: number;
    login: number;
    name: string;
    phone: string;
    reason: any;
    regdate: any;
    regdateStr: string;
    newKey?: string;
};

const LinkedAccQuery = () => {
    const [data, setData] = useState<LinkedAccountQueryData[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [servers, setServers] = useState<KeyValuePair[]>([]);
    const [filterParams, setFilterParams] = useState<any>({ server: 0, type: "login" });
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(false);
    const [auditKey, setAuditKey] = useState<string>("");
    const [isShowHiddenCols, setIsShowHiddenCols] = useState<boolean>(false);
    const [isFilterLoaded, setIsFilterLoaded] = useState<boolean>(false);
    const [sensitiveDataCalledCount, setSensitiveDataCalledCount] = useState<number>(0);

    const columns = useMemo(() => [
        DTColProps.XSmall({
            title: "Server",
            dataIndex: "db",
            key: "db",
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: servers,
                    inputProps: { allowClear: false },
                },
            },
            fixed: "left",
        }),
        {
            title: "Type",
            dataIndex: "type",
            key: "type",
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: [
                        { text: "Login", value: "login" },
                        { text: "Email", value: "email" },
                        { text: "Phone", value: "phone" },
                    ],
                    inputProps: { allowClear: false },
                },
                visible: false,
            },
        },
        {
            title: "Content",
            dataIndex: "content",
            key: "content",
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                    rules: [{ required: true, message: "This field cannot be empty to perform a search" }],
                },
                visible: false,
            },
        },
        DTColProps.XSmall({
            title: "Login",
            dataIndex: "login",
            key: "login",
            fixed: "left",
        }),
        ...(isShowHiddenCols
            ? [
                DTColProps.Middle({
                    title: "Name",
                    dataIndex: "name",
                    key: "name",
                }),
                DTColProps.Middle({
                    title: "Email",
                    dataIndex: "email",
                    key: "email",
                }),
                DTColProps.Small({
                    title: "Phone",
                    dataIndex: "phone",
                    key: "phone",
                }),
            ]
            : []
        ),
        DTColProps.Middle({
            title: "Reg Date",
            dataIndex: "regdateStr",
            key: "regdateStr",
            width: "10vw",
        }),
        DTColProps.Middle({
            title: "Group",
            dataIndex: "group",
            key: "group",
        }),
        DTColProps.XSmall({
            title: "Leverage",
            dataIndex: "leverage",
            key: "leverage",
        }),
        DTColProps.XSmall({
            title: "Balance",
            dataIndex: "balance",
            key: "balance",
        }),
        DTColProps.XSmall({
            title: "Equity",
            dataIndex: "equity",
            key: "equity",
        }),
        ...(isShowHiddenCols
            ? [
                DTColProps.Small({
                    title: "Country",
                    dataIndex: "country",
                    key: "country",
                }),
                DTColProps.Small({
                    title: "City",
                    dataIndex: "city",
                    key: "city",
                }),
                DTColProps.Middle({
                    title: "Address",
                    dataIndex: "address",
                    key: "address",
                }),
            ]
            : []
        ),
        DTColProps.Middle({
            title: "Change Reason",
            dataIndex: "reason",
            key: "reason",
        }),
    ], [servers, isShowHiddenCols]);

    const options: FlexiDataTableOptionsProps = useMemo(() => ({
        extraButtons: () => {
            return (
                <div key={"switch_sensitive_data"} style={{ marginLeft: 10, display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Tooltip placement="topRight" title={`${isShowHiddenCols ? "Hide" : "Show"} Sensitive Data`}>
                        <Switch
                            checkedChildren={<EyeOutlined />}
                            unCheckedChildren={<EyeInvisibleOutlined />}
                            onChange={(checked: boolean) => getSensitiveData(checked)}
                            disabled={auditKey === "" || isLoading}
                            checked={isShowHiddenCols}
                        />
                    </Tooltip>
                </div>
            );
        },
        serverFiltering: true,
    }), [auditKey, isShowHiddenCols, isLoading]);

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                let fParams: any = {};
                Object.keys(FormData)
                    .filter((x) => FormData[x] !== undefined && FormData[x].toString().length > 0)
                    .map((x) => {
                        if (x === "db") {
                            fParams["server"] = FormData[x];
                        } else {
                            fParams[x] = FormData[x];
                        }
                        return x;
                    });
                setFilterParams(fParams);
                setRunRefetchDataList(true);
                break;
            default:
                break;
        };
    };

    const getLinkedAccountsQueryList = () => {
        if (Object.keys(filterParams).length > 0) {
            const formData = new FormData();
            formData.append("server", `${filterParams?.server}`);
            formData.append("type", `${filterParams?.type}`);
            formData.append("content", `${filterParams?.content}`);

            setIsLoading(true);
            plainAxiosInstance
                .post(`${APIs.RC_LINKED_ACCOUNT_QUERY.POST_LINKED_ACC_QUERY_LIST}`, formData)
                .then((res: any) => {
                    if (res.data) {
                        Object.keys(res.data).map((key: string) => {
                            setAuditKey(key);
                            let newData = res.data[key].map((x: any) => ({
                                ...x,
                                newKey: `${x.db}_${x.login}`,
                            }));
                            setData(newData);
                        });
                    } else setData([]);
                })
                .catch((error: any) =>
                    ErrorCatchValidator(error, (err: any) => {
                        ErrorMessageHandler("linked account query list", SUCCESS_FAILED.FAILED_LOAD_DATA, err);
                        setData([]);
                    })
                )
                .finally(() => setIsLoading(false));
        };
    };

    const getSensitiveData = useCallback((checked: boolean) => {
        if (checked) {
            setIsShowHiddenCols(true);
            let newIsSensitiveDataCalled = sensitiveDataCalledCount + 1;
            setSensitiveDataCalledCount(newIsSensitiveDataCalled);

            if (newIsSensitiveDataCalled > 1) return;
            else {
                setIsLoading(true);
                plainAxiosInstance
                    .get(`${APIs.RC_LINKED_ACCOUNT_QUERY.GET_SENSITIVE_DATA}?auditKey=${auditKey}`)
                    .catch((error: any) => {
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("sensitive data", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
                        setSensitiveDataCalledCount(0);
                        setIsShowHiddenCols(false);
                    })
                    .finally(() => setIsLoading(false));
            };
        } else {
            setIsShowHiddenCols(false);
        };
    }, [auditKey, sensitiveDataCalledCount]);

    const getConfig = () => {
        plainAxiosInstance
            .get(`${APIs.RC_LINKED_ACCOUNT_QUERY.GET_SERVERS}`)
            .then((res: any) => {
                if (res.status === 200) {
                    setServers(res.data.map((x: any) => ({ value: x.value, text: x.name })) as KeyValuePair[]);
                } else setServers([]);
            })
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("server list", SUCCESS_FAILED.FAILED_LOAD_DATA, err)))
            .finally(() => setIsFilterLoaded(true));
    };

    useEffect(() => {
        if (runRefetchDataList) {
            setRunRefetchDataList(false);
            getLinkedAccountsQueryList();
        };
    }, [runRefetchDataList]);

    useEffect(() => {
        getConfig();
        return () => { };
    }, []);

    return (
        <div className="linked-account-query-container">
            <CardBox title="Linked Account Query">
                <LoadingComponent tip="Loading filters..." spinning={!isFilterLoaded}>
                    <FlexiDataTable
                        bordered
                        rowKeyProperty="newKey"
                        title=""
                        columns={columns}
                        options={options}
                        dataSource={data ?? []}
                        callback={componentCallback}
                        loading={isLoading}
                        filterInitialValue={{ db: 0, type: "login" }}
                    />
                </LoadingComponent>
            </CardBox>
        </div>
    );
};

export default LinkedAccQuery;
import { useState, useEffect, useMemo, useCallback } from "react";
import { useNavigate, Link } from "react-router-dom";
import CardBox from "../../../../components/Common/CardBox";
import FlexiDataTable from "../../../../components/FlexiDataTable";
import { ComponentType, CALLBACK_KEY, SUCCESS_FAILED } from "../../../../constants";
import { FlexiDataTableOptionsProps, FlexiDataTableCallbackProps, SymbolAssetTypesList } from "../../../../constants/type";
import AuthHelper, { AuthKeys } from "../../../../helpers/authHelper";
import { apiRequest } from "../../../../services/apiConfig";
import { APIs } from "../../../../services/apis";
import { DTColProps, ErrorMessageHandler, ErrorCatchValidator, DataTableColumnRender } from "../../../../utils/Common";
import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Col, Form, Modal, Row, Upload, message } from "antd";
import { FormComponent } from "../../../../components/FormComponent";
import { REQUIRED_FIELD } from "../../../../constants/errorMessage";
import * as XLSX from "xlsx";
import { ToObjectWithKey } from "../../../../utils/array";
import { DefaultIfEmpty, getAvailableObjectElementKeys } from "../../../../utils/object";

export interface CleanSymbolListProps {}

interface CleanSymbol {
    cleanSymbol: string;
    symbolAssetTypeId: number | null;
    symbolAssetType: string;
    isMainStream: boolean;
    modifiedBy: string | null;
    modifiedDateUtc: string | null;
    createdBy: string;
    createdDateUtc: string;
}

const CleanSymbolList = (props: CleanSymbolListProps) => {
    let navigate = useNavigate();
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.CLEAN_SYMBOL_EDIT);

    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [symbolAssetType, setSymbolAssetType] = useState<SymbolAssetTypesList[]>([]);
    const [data, setData] = useState<CleanSymbol[]>([]);
    const [userObj, setUserObj] = useState<any>({});
    const [filterParams, setFilterParams] = useState<any>({});
    const [downloadForm] = Form.useForm();

    const symbolAssetTypeOptions = useMemo(
        () => symbolAssetType.map((x: SymbolAssetTypesList) => ({ text: x.name, value: x.id })),
        [symbolAssetType]
    );

    const showPreviewError = (data: any) => {
        Modal.error({
            title: "Error Preview",
            width: "52.08vw",
            bodyStyle: { padding: "1vw 2vh" },
            content: (
                <div className="upload-error-div">
                    <FlexiDataTable
                        title={false}
                        loading={false}
                        columns={[
                            DTColProps.XXSmall({
                                title: "Row",
                                dataIndex: "rowNum",
                                key: "rowNum",
                            }),
                            DTColProps.Small({
                                title: "Clean Symbol",
                                dataIndex: "cleanSymbol",
                                key: "cleanSymbol",
                            }),
                            {
                                title: "Remarks",
                                dataIndex: "remarks",
                                key: "remarks",
                            },
                        ]}
                        options={{
                            enableFilter: false,
                            showHideColumns: false,
                        }}
                        dataSource={data}
                        scroll={{ x: "max-content" }}
                        bordered
                        {...(data.length > 10 ? { pagination: { pageSize: 10 } } : { pagination: false })}
                    />
                </div>
            ),
        });
    };

    const convertExcelToJSON = (uploadedFile: any) => {
        if (!uploadedFile) return null;
        if (uploadedFile.size > 5000000) {
            message.error("Maximum file size is 5 mb");
            return null;
        }

        /* Boilerplate to set up FileReader */
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;

        // Set up callback for when FileReader is done loading
        reader.onload = (event: any) => {
            /* Parse data */
            const bstr = event.target.result;
            const wb = XLSX.read(bstr, {
                type: rABS ? "binary" : "array",
                bookVBA: true,
                raw: true,
            });

            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];

            /* Convert array of arrays */
            const excelRows: any[] = XLSX.utils.sheet_to_json(ws);

            if (excelRows.length === 0) {
                message.error(`File is empty`);
                return null;
            }

            let formData1 = new FormData();
            formData1.append("IsPreviewOnly", "true");
            formData1.append("File", uploadedFile);
            apiRequest(APIs.UPLOAD_CLEAN_SYMBOL_TEMPLATE, formData1, "POST", "json", {
                Accept: "text/plain",
                "Content-type": "multipart/form-data",
            })
                .then((data: any) => {
                    if (data.length > 0) {
                        showPreviewError(data);
                    } else {
                        let formData = new FormData();
                        formData.append("IsPreviewOnly", "false");
                        formData.append("File", uploadedFile);

                        apiRequest(APIs.UPLOAD_CLEAN_SYMBOL_TEMPLATE, formData, "POST", "json", {
                            Accept: "text/plain",
                            "Content-type": "multipart/form-data",
                        })
                            .then((data: any) => {
                                message.success("Clean symbols upload successfully.");
                                getListing();
                            })
                            .catch((err: any) =>
                                ErrorCatchValidator(err, (err: any) => {
                                    if (err.status === -3) {
                                        Modal.warning({
                                            title: "Warning!",
                                            content: err.message,
                                        });
                                    } else message.error(`Failed to upload clean symbols: ${err.message}`, 5);
                                })
                            );
                    }
                })
                .catch((err: any) =>
                    ErrorCatchValidator(err, (err: any) => {
                        if (err.status === -3) {
                            Modal.warning({
                                title: "Warning!",
                                content: err.message,
                            });
                        } else message.error(`Failed to upload clean symbols: ${err.message}`, 5);
                    })
                );
        };

        // Call FileReader
        if (rABS) {
            reader.readAsBinaryString(uploadedFile);
        } else {
            reader.readAsArrayBuffer(uploadedFile);
        }
    };

    const exportToExcel = useCallback(() => {
        apiRequest(APIs.EXPORT_CLEAN_SYMBOL, filterParams, "POST", "arraybuffer")
            .then((res: any) => {
                const fileName = res.headers["x-filename"];
                const url = window.URL.createObjectURL(new Blob([res.data])); // Create blob link to download
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", fileName); // or any other extension
                // Append to html link element page
                document.body.appendChild(link);
                // start download
                link.click();
                // Clean up and remove the link
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            })
            .catch(error => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("clean symbols excel", SUCCESS_FAILED.FAILED_DOWNLOAD_DATA, err));
            });
    }, [filterParams]);

    const columns: any[] = useMemo(
        () => [
            {
                title: "Clean Symbol",
                dataIndex: "cleanSymbol",
                key: "cleanSymbol",
                options: {
                    filter: {
                        type: ComponentType.text,
                        value: "",
                    },
                },
            },
            DTColProps.Middle({
                title: "Symbol Asset Type",
                dataIndex: "symbolAssetTypeId",
                key: "symbolAssetTypeId",
                render: (value: string, rowData: CleanSymbol) => rowData.symbolAssetType,
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: symbolAssetTypeOptions,
                        inputProps: {
                            mode: "multiple",
                        },
                        callback: (filterValue: any, rowData: CleanSymbol) => {
                            if (rowData.symbolAssetTypeId === null) {
                                return false;
                            }

                            return filterValue.some((value: number) => rowData.symbolAssetTypeId === value);
                        },
                    },
                },
            }),
            // DTColProps.Small(
            //     {
            //         title: "Is Main Stream",
            //         dataIndex: "isMainStream",
            //         key: "isMainStream",
            //         render: (isMainStream: boolean) =>
            //             isMainStream ? (
            //                 <CheckCircleOutlined style={{ color: "#0ab76e", fontSize: "1.375rem" }} />
            //             ) : (
            //                 <CloseCircleOutlined style={{ color: "#f00f00", fontSize: "1.375rem" }} />
            //             ),
            //         options: {
            //             filter: {
            //                 type: ComponentType.dropdown,
            //                 value: [
            //                     { text: "Yes", value: true },
            //                     { text: "No", value: false },
            //                 ],
            //             },
            //         },
            //     },
            //     ["text-center"]
            // ),
            DTColProps.Small({
                title: "Last Updated By",
                dataIndex: "modifiedBy",
                key: "modifiedBy",
                render: (value: string, rowData: CleanSymbol) =>
                    rowData.modifiedBy === null ? DefaultIfEmpty(userObj, rowData.createdBy, "") : DefaultIfEmpty(userObj, rowData.modifiedBy, ""),
            }),

            DTColProps.DateTime({
                width: "12.5vw",
                title: "Last Updated DateTime (Local)",
                dataIndex: "modifiedDateUtc",
                key: "modifiedDateUtc",
                render: (value: string, rowData: CleanSymbol) =>
                    DataTableColumnRender.DateTime(rowData.modifiedDateUtc === null ? rowData.createdDateUtc : rowData.modifiedDateUtc),
            }),
        ],
        [symbolAssetTypeOptions, userObj]
    );

    const options: FlexiDataTableOptionsProps = {
        add: () => {
            if (enableUpdate) {
                return "/siteadmin/symbolconfig/cleansymbol/create";
            } else return enableUpdate;
        },
        edit: (record: any, overwriteProps: any) => {
            if (enableUpdate) {
                let newProps = { ...overwriteProps };
                newProps.label = <Link to={`/siteadmin/symbolconfig/cleansymbol/edit/${record.cleanSymbol}`}>{newProps.label}</Link>;
                return newProps;
            } else return enableUpdate;
        },
        delete: enableUpdate,
        extraButtons: () => (
            <>
                {enableUpdate && (
                    <>
                        <Button
                            key={`btn-sy-dt-${Math.random()}`}
                            htmlType="button"
                            style={{ marginLeft: "0.651vw", width: "auto" }}
                            onClick={event => {
                                event.preventDefault();
                                event.stopPropagation();
                                downloadForm.setFieldsValue({ downloadMode: "", symbolAssetTypeIds: [] });
                                setIsModalVisible(true);
                            }}
                            icon={<DownloadOutlined />}
                        >
                            Download Template
                        </Button>
                        <Upload
                            name="file"
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            showUploadList={false}
                            onChange={(info: any) => {
                                if (info.file.status === "error") {
                                    message.error(`${info.file.name} file upload failed.`);
                                }
                            }}
                            beforeUpload={(file: any) => {
                                convertExcelToJSON(file);
                            }}
                            customRequest={(info: any) => {}}
                        >
                            <Button
                                htmlType="button"
                                icon={<UploadOutlined />}
                                style={{ marginLeft: "0.651vw" }}
                                onClick={(e: any) => {
                                    e.preventDefault();
                                }}
                            >
                                Upload Excel
                            </Button>
                        </Upload>
                    </>
                )}

                <Button icon={<DownloadOutlined />} style={{ marginLeft: "0.651vw" }} onClick={() => exportToExcel()}>
                    Export as Excel
                </Button>
            </>
        ),
    };

    const componentCallback: FlexiDataTableCallbackProps = (type, cleanSymbol) => {
        switch (type) {
            case CALLBACK_KEY.CREATE_NEW:
                navigate("/siteadmin/symbolconfig/cleansymbol/create", { state: { data: null, action: "add" } });
                break;
            case CALLBACK_KEY.DO_EDIT:
                navigate(`/siteadmin/symbolconfig/cleansymbol/edit/${cleanSymbol.cleanSymbol}`, {
                    state: { data: cleanSymbol.cleanSymbol, action: "edit" },
                });
                break;
            case CALLBACK_KEY.DO_DELETE:
                apiRequest(APIs.DELETE_CLEAN_SYMBOL, {
                    cleanSymbol: cleanSymbol.cleanSymbol,
                })
                    .then(data => {
                        ErrorMessageHandler(cleanSymbol.cleanSymbol, SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                        getListing();
                    })
                    .catch(error =>
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("clean symbol", SUCCESS_FAILED.FAILED_DELETE_DATA, err))
                    );
                break;
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                setFilterParams(
                    getAvailableObjectElementKeys(cleanSymbol).reduce((tmpObj: any, x: string) => {
                        if (x === "cleanSymbol") {
                            tmpObj["cleanSymbols"] = [cleanSymbol[x]];
                        } else if (x === "symbolAssetTypeId") {
                            tmpObj["symbolAssetTypeIds"] = cleanSymbol[x];
                        } else {
                            tmpObj[x] = cleanSymbol[x];
                        }

                        return tmpObj;
                    }, {})
                );
                break;
            default:
                break;
        }
    };

    const downloadTemplate = (values: any) => {
        apiRequest(APIs.DOWNLOAD_CLEAN_SYMBOL_TEMPLATE, values, "POST", "arraybuffer")
            .then((res: any) => {
                const fileName = res.headers["x-filename"];
                const url = window.URL.createObjectURL(new Blob([res.data])); // Create blob link to download
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", fileName); // or any other extension
                // Append to html link element page
                document.body.appendChild(link);
                // start download
                link.click();
                // Clean up and remove the link
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            })
            .catch(error => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("clean symbol template", SUCCESS_FAILED.FAILED_DOWNLOAD_DATA, err));
            });
    };

    const getListing = () => {
        setIsLoading(true);
        apiRequest(APIs.GET_CLEAN_SYMBOL_LISTING, {})
            .then((res: CleanSymbol[]) => {
                setData(res);
            })
            .catch(error => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("clean symbol", SUCCESS_FAILED.FAILED_UPDATE_DATA, err));
            })
            .finally(() => setIsLoading(false));
    };

    const getConfigList = () => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, { filterType: ["symbolassettype"] })
            .then(res => {
                setSymbolAssetType(res.symbolAssetTypes);
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("config info", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
                navigate("/siteadmin/symbolconfig/cleansymbol");
            })
            .finally(() =>
                apiRequest(APIs.GET_USER_PROFILES, { includeSelf: true })
                    .then(data => {
                        setUserObj(ToObjectWithKey([{ id: 0, firstName: "System" }, ...data.filter((x: any) => x.id !== 0)], "id", "firstName"));
                    })
                    .finally(() => getListing())
            );
    };

    useEffect(() => {
        getConfigList();
        return () => {};
    }, []);

    return (
        <>
            <CardBox title={"Clean Symbol Listing"}>
                <FlexiDataTable
                    rowKeyProperty="cleanSymbol"
                    title=""
                    columns={columns}
                    options={options}
                    dataSource={data}
                    callback={componentCallback}
                    loading={isLoading}
                />
            </CardBox>
            <Modal
                width={600}
                title="Download Template"
                open={isModalVisible}
                cancelButtonProps={{ style: { display: "none" } }}
                onCancel={() => setIsModalVisible(false)}
                onOk={() => {
                    downloadForm
                        .validateFields()
                        .then(values => {
                            downloadTemplate(values);
                            setIsModalVisible(false);
                        })
                        .catch(errorInfo => console.log(errorInfo));
                }}
            >
                <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} form={downloadForm} layout="horizontal" initialValues={{}}>
                    <Row>
                        <Col span={24}>
                            <FormComponent
                                label="Mode"
                                name="downloadMode"
                                extra={{
                                    type: ComponentType.dropdown,
                                    value: [
                                        {
                                            text: "With Existing Clean Symbols",
                                            value: 2,
                                        },
                                        {
                                            text: "Blank Template",
                                            value: 1,
                                        },
                                    ],
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <FormComponent
                                label="Symbol Asset Type"
                                name="symbolAssetTypeIds"
                                extra={{
                                    type: ComponentType.dropdown,
                                    value: symbolAssetTypeOptions,
                                    inputProps: {
                                        mode: "multiple",
                                    },
                                }}
                            />
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
};

export default CleanSymbolList;

import CardBox from "@/components/Common/CardBox";
import FlexiDataTable from "@/components/FlexiDataTable";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "@/constants";
import { FlexiDataTableOptionsProps, FlexiDataTableCallbackProps } from "@/constants/type";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import { apiRequest } from "@/services/apiConfig";
import { APIs } from "@/services/apis";
import { ErrorMessageHandler, ErrorCatchValidator, DTColProps } from "@/utils/Common";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

export interface BrandListingPageProps {}

export interface BrandV2Props {
    id: number;
    brand: string;
    enable: boolean;
}

const BrandListingPage = (props: BrandListingPageProps) => {
    let navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState<BrandV2Props[]>([]);
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.ADMIN_BRANDSERVER_BRAND_EDIT);

    const columns: any[] = [
        {
            title: "Brand Name",
            dataIndex: "brand",
            key: "brand",
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        },
    ];

    const options: FlexiDataTableOptionsProps = {
        add: () => {
            if (enableUpdate) {
                return "/siteadmin/brandserver/brand/create";
            } else return enableUpdate;
        },
        edit: (record: any, overwriteProps: any) => {
            if (enableUpdate) {
                let newProps = { ...overwriteProps };
                newProps.label = <Link to={`/siteadmin/brandserver/brand/edit/${record.id}`}>{newProps.label}</Link>;
                return newProps;
            } else return enableUpdate;
        },
        delete: enableUpdate,
    };

    const componentCallback: FlexiDataTableCallbackProps = (type, BrandData) => {
        switch (type) {
            case CALLBACK_KEY.CREATE_NEW:
                navigate("/siteadmin/brandserver/brand/create", { state: { data: null, action: "add" } });
                break;
            case CALLBACK_KEY.DO_EDIT:
                navigate(`/siteadmin/brandserver/brand/edit/${BrandData.id}`, { state: { data: BrandData.id, action: "edit" } });
                break;
            case CALLBACK_KEY.DO_DELETE:
                apiRequest(APIs.DELETE_BRAND_V2, { brandId: BrandData.id })
                    .then(data => {
                        ErrorMessageHandler("The brand", SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                        getBrandList();
                    })
                    .catch(error => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("brand", SUCCESS_FAILED.FAILED_DELETE_DATA, err)));
                break;
            default:
                break;
        }
    };

    const getBrandList = () => {
        setIsLoading(true);
        apiRequest(APIs.GET_BRANDS_LIST_V2, {})
            .then((res: any) => {
                setData(res && res.length > 0 ? res : []);
            })
            .catch(error => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("brand list", SUCCESS_FAILED.FAILED_LOAD_DATA, err)))
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        getBrandList();
    }, []);

    return (
        <div className="v2-brand-listing">
            <CardBox title={"Brand Listing"}>
                <FlexiDataTable
                    rowKeyProperty="id"
                    title=""
                    columns={columns}
                    options={options}
                    dataSource={data}
                    callback={componentCallback}
                    loading={isLoading}
                />
            </CardBox>
        </div>
    );
};

export default BrandListingPage;

import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Col, Form, Modal, Row } from "antd";
import { useEffect, useMemo, useState } from "react";
import FlexiDataTable from "../../../components/FlexiDataTable";
import { FormComponent } from "../../../components/FormComponent";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "../../../constants";
import { REQUIRED_FIELD } from "../../../constants/errorMessage";
import { FlexiDataColumnProps, FlexiDataTableCallbackProps } from "../../../constants/type";
import AuthHelper, { AuthKeys } from "../../../helpers/authHelper";
import { APIs } from "../../../services/apis";
import { plainAxiosInstance } from "../../../services/axiosSetup";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "../../../utils/Common";

type SettingType = "sbook" | "bbook" | "filter";
type GroupSettingForm = {
    id?: number;
    type?: SettingType;
    value?: string;
    remark?: string;
}
const SettingsTab = () => {
	const [linkedAccountGroupSettings, setLinkedAccountGroupSettings] = useState<any[]>([]);
    const [openEditSettingsModal, setOpenEditSettingsModal] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [settingForm, setSettingsForm] = useState<GroupSettingForm | undefined>(undefined);
    const [isNew, setIsNew] = useState<boolean>(true);
    
    useEffect(() => {
      //fetch linked account group settings
      getGroupSettings();
    }, []);

    function getGroupSettings(){
        setIsLoading(true);
        plainAxiosInstance
            .get(`${APIs.RC_LINKED_ACCOUNT_RECORD.GET_GROUP_SETTINGS}`)
            .then((res: any) => {
                if (res.data) {
                setLinkedAccountGroupSettings(res.data);
                }
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("data", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            )
            .finally(() => setIsLoading(false));
    }

    function handleCreateSettings(setting: SettingType) {
        setIsNew(true);
        setSettingsForm({ type: setting });
        setOpenEditSettingsModal(true);
    }

    function handleEditSettings(data: GroupSettingForm) {
        setIsNew(false);
        setSettingsForm(data);
        setOpenEditSettingsModal(true);
    }

   
    return (
        <>
            <div className="group-settings">
                <GroupSettingsTable
                    data={linkedAccountGroupSettings.filter((item) => item.type === "sbook")}
                    title="SBook Group Setting"
                    openCreateSettingsModal={() => handleCreateSettings("sbook")}
                    openEditSettingsModal={handleEditSettings}
                    isLoading={isLoading}
                    onDeleteOk={() => {
                        getGroupSettings();
                    }}
                />
                <GroupSettingsTable
                    data={linkedAccountGroupSettings.filter((item) => item.type === "bbook")}
                    title="BBook Group Setting"
                    openCreateSettingsModal={() => handleCreateSettings("bbook")}
                    openEditSettingsModal={handleEditSettings}
                    isLoading={isLoading}
                    onDeleteOk={() => {
                        getGroupSettings();
                    }}
                />
                <GroupSettingsTable
                    data={linkedAccountGroupSettings.filter((item) => item.type === "filter")}
                    title="Filter Group Setting"
                    openCreateSettingsModal={() => handleCreateSettings("filter")}
                    openEditSettingsModal={handleEditSettings}
                    isLoading={isLoading}
                    onDeleteOk={() => {
                        getGroupSettings();
                    }}
                />
                <CreateEditSettingModal
                    defaultForm={settingForm}
                    open={openEditSettingsModal}
                    onModalCancel={() => {
                        setOpenEditSettingsModal(false);
                        setSettingsForm(undefined);
                    }}
                    onModalOk={() => {
                        getGroupSettings();
                        setOpenEditSettingsModal(false);
                        setSettingsForm(undefined);
                    }}
                    isNew={isNew}
                />
            </div>
        </>
    );
};

type GroupSettingsTableProps = {
    data: any[];
    title: string;
    openCreateSettingsModal: () => void;
    openEditSettingsModal: (data: GroupSettingForm) => void;
    isLoading: boolean;
    onDeleteOk: () => void;
};
const GroupSettingsTable = ({ data, title, openCreateSettingsModal, openEditSettingsModal, isLoading, onDeleteOk }: GroupSettingsTableProps) => {
    const authHp = new AuthHelper();
    const authEditEnabled = authHp.isAuthorized(AuthKeys.TEMPLATE_EDIT);
    const [isDeletingRow, setIsDeletingRow] = useState<boolean>(false);

    const columns: FlexiDataColumnProps[] = useMemo(() => [
        DTColProps.Middle({
            title: "type",
            dataIndex: "type",
            key: "type",
        }),
        DTColProps.Middle({
            title: "value",
            dataIndex: "value",
            key: "value",
        }),
        DTColProps.Middle({
            title: "Remark",
            dataIndex: "remark",
            key: "remark",
        }),
        DTColProps.Middle({
            title: "Update Time",
            dataIndex: "modifyTime",
            key: "modifyTime",
        }),
    ],[]);

    function deleteRow(record: any) {
        setIsDeletingRow(true);
        plainAxiosInstance
            .delete(`${APIs.RC_LINKED_ACCOUNT_RECORD.DELETE_GROUP_SETTINGS}?id=${record.id}`)
            .then((res: any) => {
                ErrorMessageHandler("Group Setting", SUCCESS_FAILED.SUCCESS_DELETE_DATA)
                onDeleteOk();
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("group setting", SUCCESS_FAILED.FAILED_DELETE_DATA, err))
            )
            .finally(() => setIsDeletingRow(false));
    }

    const componentCallback: FlexiDataTableCallbackProps = (type, data) => {
        switch (type) {
            case CALLBACK_KEY.CREATE_NEW:
                openCreateSettingsModal();
                break;
            case CALLBACK_KEY.DO_EDIT:
                openEditSettingsModal(data);
                break;
            case CALLBACK_KEY.DO_DELETE:
                deleteRow(data);
                break;
            default:
                break;
        }
    };
    return(
        <>
            <FlexiDataTable
                rowKeyProperty="id"
                title={title}
                columns={columns}
                options={{
                    add: {text: "Add Setting"},
                    edit: authEditEnabled,
                    delete: authEditEnabled,
                    enableFilter: false,
                }}
                dataSource={data}
                callback={componentCallback}
                bordered
                loading={isLoading || isDeletingRow}
            />
        </>
    )
}


type CreateSettingsModalProps = {
    open: boolean;
    defaultForm?: GroupSettingForm;
    onModalCancel: () => void;
    onModalOk: () => void;
    isNew: boolean;
};
type SettingTypeOptions = {
    text: SettingType;
    value: SettingType;
}
const CreateEditSettingModal = ({ open, onModalCancel, onModalOk, defaultForm, isNew}: CreateSettingsModalProps) => {
    const [settingForm] = Form.useForm<GroupSettingForm>();
    const typeLabel = defaultForm 
        ? defaultForm.type === "sbook" ? "SBook" : defaultForm.type === "bbook" ? "BBook" : "Filter" 
        : "";
    const title = isNew ? "Create New Setting" : `Edit ${typeLabel} Group Setting`;
    const settingTypeOptions: SettingTypeOptions[] = [
        { text: "sbook", value: "sbook" },
        { text: "bbook", value: "bbook" },
        { text: "filter", value: "filter" },
    ]

    function onCancelForm() {
        settingForm.resetFields();
        onModalCancel();
    }

    function onSubmitForm() {
        settingForm.validateFields().then((values) => {
            //create/edit new setting
            updateSetting(values);
        }).catch((error) => {
            const msg = isNew ? SUCCESS_FAILED.FAILED_CREATE_DATA : SUCCESS_FAILED.FAILED_UPDATE_DATA
            const err = {
                message: "Invalid form data",
            }
            ErrorMessageHandler("group setting", msg, err)
        });
    }

    function updateSetting(values: GroupSettingForm){
        plainAxiosInstance
        .put(APIs.RC_LINKED_ACCOUNT_RECORD.POST_UPDATE_GROUP_SETTINGS, values)
            .then((res: any) => {
                ErrorMessageHandler("Group Setting", isNew ? SUCCESS_FAILED.SUCCESS_CREATE_DATA : SUCCESS_FAILED.SUCCESS_UPDATE_DATA)
                onModalOk();
                settingForm.resetFields();
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("data", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            )
    }

    function onOkConfirm(){
        Modal.confirm({
            icon: <ExclamationCircleOutlined />,
            title: `Are you sure you want to ${title.toLowerCase()}?`,
            width: "30%",
            onOk() {
                onSubmitForm();
            },
            onCancel() {},
        });
    }

    useEffect(()=>{
        settingForm.setFieldsValue(defaultForm ? defaultForm : {});
    }, [defaultForm]);
    
    return (
        <Modal
            width="50vw"
            destroyOnClose
            maskClosable={false}
            title={title}
            open={open}
            onCancel={onCancelForm}
            onOk={onOkConfirm}
        >
            <Form
                form={settingForm}
                layout="horizontal"
                labelCol={{ span: 7 }}
                wrapperCol={{ span: 14 }}
            >
                <Row>
                    <Col span={24}>
                        <FormComponent
                            label="Id"
                            name="id"
                            extra={{
                                type: ComponentType.hidden,
                                value: defaultForm ? defaultForm.id : null,
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <FormComponent
                            label="Type"
                            name="type"
                            extra={{
                                type: ComponentType.dropdown,
                                value: settingTypeOptions,
                                inputProps: {
                                    allowClear: false,
                                    showSearch: false,
                                },
                                rules: [{ required: true, message: REQUIRED_FIELD }],
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <FormComponent
                            label="Value"
                            name="value"
                            extra={{
                                type: ComponentType.text,
                                value: "",
                                rules: [{ required: true, message: REQUIRED_FIELD }],
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <FormComponent
                            label="Remark"
                            name="remark"
                            extra={{
                                type: ComponentType.text,
                                value: "",
                            }}
                        />
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

export default SettingsTab;

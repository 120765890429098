import { useMemo, useState } from "react";
import FlexiDataTable from "../../../components/FlexiDataTable";
import { FlexiDataColumnProps, FlexiDataTableCallbackProps, FlexiDataTableOptionsProps } from "../../../constants/type";
import { DTColProps } from "../../../utils/Common";
import { CALLBACK_KEY, ComponentType } from "../../../constants";
import { HFTResultFormatted, HFTThreshold } from "./index";
import { EditOutlined } from "@ant-design/icons";
import { Button } from "antd";
import CommentModal from './CommentModal';

type HFTReportDataTableProps = {
    data: any[]
    rowKeyProperty: string
    min: 2 | 10 | 30
    hftThreshold: HFTThreshold;
    showAlarmColumn?: boolean;
    searchTabColumnType?: "profit" | "amount"
    isLoading: boolean
    refreshData: () => void
}

const COLOR_CLASS = {
    RED_1: "row-red-1",//#ef4444
    RED_2: "row-red-2",//#b91c1c
    RED_3: "row-red-3",//#7f1d1d
    ORANGE: "row-orange",//#f97316
}

const HFTReportDataTable = ({ data, isLoading, rowKeyProperty, min, hftThreshold, refreshData, showAlarmColumn = false, searchTabColumnType = undefined}:HFTReportDataTableProps) => {
    
    const [showCommentModal, setShowCommentModal] = useState<boolean>(false);
    const [selectedData, setSelectedData] = useState<HFTResultFormatted | undefined>(undefined);

    const getThresholdColor = (item: HFTResultFormatted, min: 2 | 10 | 30) => {
        let colorClass = "";
        let t = {...hftThreshold};
        const { todayProfit, historicalPnl, todayProfitThreshold } = item;

        if(todayProfitThreshold !== null){
            if (todayProfit > (todayProfitThreshold * (100 + t.threshold1)) / 100) {
                colorClass = COLOR_CLASS.RED_1;
                if (todayProfit > (todayProfitThreshold * (100 + t.threshold2)) / 100) {
                    colorClass = COLOR_CLASS.RED_2;
                    if (
                        todayProfit >
                        (todayProfitThreshold * (100 + t.threshold3)) / 100
                    ) {
                        colorClass = COLOR_CLASS.RED_3; 
                    }
                }
            }
        }else{
            if(min === 2){
                colorClass = getThresholdColorifTodayProfitNull(todayProfit, historicalPnl!, t.newprofit2minLevel1, t.newprofit2minLevel2, t.newprofitpnl2min);
            }
            if(min === 10){
                colorClass = getThresholdColorifTodayProfitNull(todayProfit, historicalPnl!, t.newprofit10minLevel1, t.newprofit10minLevel2, t.newprofitpnl10min);
            }
            if(min === 30){
                colorClass = getThresholdColorifTodayProfitNull(todayProfit, historicalPnl!, t.newprofit30minLevel1, t.newprofit30minLevel2, t.newprofitpnl30min);
            }
        }
        return colorClass;
    };
    
    const columns: FlexiDataColumnProps[] = useMemo(
        () => [
            DTColProps.Small({
                title: "Server",
                dataIndex: "serverName",
                key: "serverName",
                sorter: (a: any, b: any) => a.serverName.localeCompare(b.serverName),
                //using this column to serve as a search all filter
                realTitle: "Search All",
                options: {
                    filter: {
                        type: ComponentType.text,
                        text: "Search All",
                        callback: (filterValue: any, rowData: any) => {
                            return Object.values(rowData).some((value: any) => value?.toString().toLowerCase().includes(filterValue.toLowerCase()));
                        },
                    },
                },
            }),
            DTColProps.Small({
                title: "Login",
                dataIndex: "login",
                key: "login",
                sorter: (a: any, b: any) => a.login - b.login,
            }),
            DTColProps.Middle({
                title: "Group",
                dataIndex: "group",
                key: "group",
                sorter: (a: any, b: any) => a.group.localeCompare(b.group),
            }),
            ...(searchTabColumnType === "amount"
                ? [
                      DTColProps.Small({
                          title: "Balance",
                          dataIndex: "balance",
                          key: "balance",
                          sorter: (a: HFTResultFormatted, b: HFTResultFormatted) => a.balance - b.balance
                      }),
                      DTColProps.Small({
                          title: "Credit",
                          dataIndex: "credit",
                          key: "credit",
                          sorter: (a: HFTResultFormatted, b: HFTResultFormatted) => a.credit - b.credit
                      }),
                  ]
                : []),
            DTColProps.Small({
                title: "Count",
                dataIndex: "count",
                key: "count",
                defaultSortOrder: searchTabColumnType === "amount" ? "descend" : undefined,
                sorter: (a: any, b: any) => a.count - b.count,
            }),
            DTColProps.Small({
                title: "Volume (open)",
                dataIndex: "sumAmountsText",
                key: "sumAmountsText",
                sorter: (a: any, b: any) => a.sumAmounts - b.sumAmounts,
            }),
            DTColProps.Small({
                title: "DPM",
                dataIndex: "dpmText",
                key: "dpmText",
                sorter: (a: any, b: any) => a.dpm - b.dpm,
            }),
            DTColProps.Small({
                title: "HFT Profit(T)",
                dataIndex: "todayProfitText",
                key: "todayProfitText",
                defaultSortOrder: searchTabColumnType === "profit" ? "descend" : undefined,
                sorter: (a: any, b: any) => a.todayProfit - b.todayProfit,
            }),
            ...((!searchTabColumnType || searchTabColumnType !== "amount")
                ? [
                    DTColProps.Small({
                        title: "Symbol(T)",
                        dataIndex: "maxProfitSymbol",
                        key: "maxProfitSymbol",
                        sorter: (a: any, b: any) => a.maxProfitSymbol.localeCompare(b.maxProfitSymbol),
                    }),
                  ]
                : []),
            DTColProps.Small({
                title: "Win Ratio(T)",
                dataIndex: "winRatioText",
                key: "winRatioText",
                sorter: (a: any, b: any) => a.winRatio - b.winRatio,
            }),
            DTColProps.Small({
                title: "HFT profit(H)",
                dataIndex: "hftRatioText",
                key: "hftRatioText",
                sorter: (a: any, b: any) => a.hftRatio - b.hftRatio,
            }),
            DTColProps.Small({
                title: "Historical PNL",
                dataIndex: "historicalPnlText",
                key: "historicalPnlText",
                sorter: (a: any, b: any) => a.historicalPnl - b.historicalPnl,
            }),
            ...(showAlarmColumn
                ? [
                      DTColProps.Small({
                          title: "Alarm",
                          dataIndex: "alarmSort",
                          key: "alarmSort",
                          sorter: (a: any, b: any) => Number(a.alarmSort) - Number(b.alarmSort),
                          defaultSortOrder: "descend",
                          sortDirections: ["descend", "ascend"],
                          render(bool: boolean) {
                              return bool ? "Yes" : "No";
                          },
                      }),
                  ]
                : searchTabColumnType === "amount" ?
                [
                    DTColProps.Small({
                        title: "Stop Out(T)",
                        dataIndex: "stopOut",
                        key: "stopOut",
                        sorter: (a: HFTResultFormatted, b: HFTResultFormatted) => a.stopOut - b.stopOut,
                    }),
                ]
                : []            
            ),
            DTColProps.Large({
                title: "Comment",
                dataIndex: "comment",
                key: "comment",
                sorter: (a: any, b: any) => {
                    if (a.comment === null && b.comment === null) return 0;
                    if (a.comment === null) return 1;
                    if (b.comment === null) return -1;
                    return a.comment.localeCompare(b.comment);
                },
                render: (comment: string, rowData: any) => {
                    return(
                    <>
                        <div className="comment-cell">
                            <Button 
                                type="link" 
                                icon={<EditOutlined />} 
                                title="Edit Comment"
                                onClick={()=> {
                                    setSelectedData(rowData);
                                    setShowCommentModal(true)
                                }}
                            />
                            <span>{comment}</span>
                        </div>
                    </>
                )},
            }),
        ],
        [showAlarmColumn, searchTabColumnType]
    );

    const options: FlexiDataTableOptionsProps = {
        defaultCollapseFilterPanel: true,
        recordRowClassName: (record) => {
            if(searchTabColumnType){
                return "";
            }
            return getThresholdColor(record, min);
        }
    }

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, data: any) => {
       switch(type){
              default:
                break
         }
    };

    return (
        <div className="report-table">
            <FlexiDataTable
                rowKeyProperty={rowKeyProperty}
                title={""}
                columns={columns}
                dataSource={data}
                loading={isLoading}
                bordered
                options={options}
                callback={componentCallback}
            />
            <CommentModal 
                open={showCommentModal} 
                onModalCancel={()=> setShowCommentModal(false)} 
                data={selectedData} 
                onModalOk={()=>{
                    refreshData();
                    setShowCommentModal(false);
                }}
            />
        </div>
    );
};

export default HFTReportDataTable;


function getThresholdColorifTodayProfitNull(todayProfit: number, historicalPnl: number, newprofitLevel1: number, newprofitLevel2: number, newprofitpnl: number){
    let colorClass = "";
    if(
        ((todayProfit >= newprofitLevel1 &&
            todayProfit <= newprofitLevel2 &&
            historicalPnl / todayProfit > newprofitpnl) ||
            todayProfit > newprofitLevel2)
    ){
        colorClass = COLOR_CLASS.ORANGE;
    }
    return colorClass;

}

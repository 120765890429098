import BasicFilter from "@/components/BasicFilter";
import useRCDividendRecord, { useRCDividendRecordMutation } from "@/hooks/useRCDividendRecord";
import useRCServers from "@/hooks/useRCServers";
import { Badge, Button, Col, DatePicker, Input, notification, Row, Select, Table, Typography } from "antd";
import React, { useState } from "react";
import moment from "moment";
import { DownloadOutlined } from "@ant-design/icons";
import useRCDownloads from "@/hooks/useRCDownloads";
import { colorMap } from "@/pages/SystemMonitor/StatusMonitor/statusUtils";
import DownloadsModal from "@/pages/PriceMonitor/Downloads/DownloadsModal";

const subAccountMsg = (subAccount: number) => {
    return subAccount === 1 ? "yes" : "no";
};

const statusMsg = (status: number) => {
    return status === 1 ? "received" : "unreceived";
};

const decimalFormat = (value: string, decimal: number) => {
    return value !== null ? parseFloat(value).toFixed(decimal) : value;
};
const columns = [
    {
        title: "Server",
        dataIndex: "server",
        sorter: (a: any, b: any) => a.server.localeCompare(b.server),
    },
    {
        title: "ex-dividend date",
        dataIndex: "separateDate",
    },
    {
        title: "Login",
        dataIndex: "login",
    },
    {
        title: "Symbol",
        dataIndex: "symbol",
        sorter: (a: any, b: any) => a.symbol.localeCompare(b.symbol),
    },
    {
        title: "Group",
        dataIndex: "group",
    },
    {
        title: "Sub Account",
        dataIndex: "subAccount",
        render: (value: any, data: any) => {
            return <div>{subAccountMsg(value)}</div>;
        },
    },
    {
        title: "Clean Symbol",
        dataIndex: "cleanSymbol",
    },
    {
        title: "Position",
        dataIndex: "position",
    },
    {
        title: "Contract Size",
        dataIndex: "contractSize",
    },
    {
        title: "Base Dividend",
        dataIndex: "baseDividend",
    },
    {
        title: "Eod Price",
        dataIndex: "eodPrice",
        render: (value: any, data: any) => {
            return <div>{decimalFormat(value, 5)}</div>;
        },
    },
    {
        title: "Status",
        dataIndex: "status",
        render: (value: any, data: any) => {
            return <div>{statusMsg(value)}</div>;
        },
    },
    {
        title: "Dividend",
        dataIndex: "dividend",
    },
    {
        title: "Digits",
        dataIndex: "digits",
    },
    {
        title: "Create Date",
        dataIndex: "formatCreateDate",
    },
];

const DividendRecordTable = () => {
    const [serverId, setServerId] = useState(0);
    const [currPage, setCurrPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(50);
    const [separateDate, setSeparateDate] = useState(
        // use today date
        moment()
    );
    const [symbol, setSymbol] = useState("");
    const massagedDateString = separateDate
        ? moment(separateDate).format("YYYYMMDD")
        : // use today date
          moment().format("YYYYMMDD");
    const { rcDividendRecord, isLoading } = useRCDividendRecord({
        page: currPage,
        rows: itemsPerPage,
        sortBy: [],
        sortDesc: [],
        serverId: serverId ?? 0,
        separateDate: massagedDateString,
    });
    const { generateReport, isLoadingGenerateReport } = useRCDividendRecordMutation();
    // console.log(massagedDateString);
    const { rcServers, isLoading: isLoadingRcServers } = useRCServers();
    const { rcDownloads, isLoading: isLoadingRCDownloads } = useRCDownloads();
    // console.log("rcDividendRecord", rcDividendRecord);
    // console.log("rcServers", rcServers);
    const { Text } = Typography;
    const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
    const handleDownloadReportModalOpen = (open: boolean) => {
        setIsDownloadModalOpen(open);
    };

    return (
        <>
            <div>
                <BasicFilter
                    titleBarChildren={
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                flexWrap: "wrap",
                                gap: "4px",
                                alignItems: "center",
                            }}
                        >
                            <Button
                                type="ghost"
                                icon={<DownloadOutlined />}
                                disabled={isLoadingGenerateReport || rcDividendRecord?.total === 0}
                                onClick={() => {
                                    generateReport({
                                        serverId: serverId,
                                        separateDate: massagedDateString,
                                        symbol: symbol,
                                    }).then(res => {
                                        notification.info({
                                            message: "Generate Report Request",
                                            description: res.data,
                                        });
                                        // console.log("tada", res);
                                    });
                                }}
                            >
                                Generate Report
                            </Button>
                            <Button
                                type="ghost"
                                style={{ display: "flex", gap: 6, alignItems: "center" }}
                                onClick={() => handleDownloadReportModalOpen(true)}
                                disabled={isLoadingRCDownloads}
                            >
                                <span>Download Report</span>
                                {rcDownloads?.[1] !== undefined &&
                                    rcDownloads?.[1]?.filter(each => each?.status?.toLowerCase() === "completed")?.length >= 1 && (
                                        <Badge
                                            count={rcDownloads?.[1]?.filter(each => each?.status?.toLowerCase() === "completed")?.length}
                                            color={colorMap.success}
                                            size="small"
                                        />
                                    )}
                            </Button>
                        </div>
                    }
                >
                    <Row gutter={[8, 8]}>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                            <Text>Server</Text>
                            {!isLoadingRcServers && rcServers && (
                                <Select
                                    // showSearch
                                    allowClear
                                    style={{ width: "100%" }}
                                    placeholder="Select Server"
                                    value={serverId}
                                    onChange={value => {
                                        setServerId(value === null ? 0 : value);
                                        setCurrPage(1);
                                    }}
                                    options={[
                                        { label: "All Servers", value: 0 },
                                        ...(rcServers?.map(server => ({
                                            label: server.serverName,
                                            value: server.oldServerId,
                                        })) || []),
                                    ]}
                                />
                            )}
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                            <Text>Separate Date</Text>
                            <DatePicker
                                style={{ width: "100%" }}
                                onChange={date => {
                                    if (date) {
                                        setSeparateDate(date);
                                    } else {
                                        setSeparateDate(moment());
                                    }
                                    setCurrPage(1);
                                }}
                                //max date is today
                                disabledDate={current => current && current > moment().endOf("day")}
                                value={separateDate}
                            />
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                            <Text>Symbol</Text>
                            <Input
                                allowClear
                                value={symbol}
                                onChange={e => {
                                    setSymbol(e.target.value);
                                    setCurrPage(1);
                                }}
                                style={{ width: "100%" }}
                            />
                        </Col>
                    </Row>
                </BasicFilter>

                <Table
                    columns={columns}
                    dataSource={rcDividendRecord?.resultList}
                    loading={isLoading}
                    pagination={{
                        // get total pages from rcDividendRecord.totalPage and total rows from rcDividendRecord.total
                        total: rcDividendRecord?.total,
                        pageSize: itemsPerPage,
                        showSizeChanger: true,
                        pageSizeOptions: ["10", "20", "50", "100"],
                        // update itemsPerPage when user change items per page
                        onShowSizeChange: (current, size) => setItemsPerPage(size),
                        // update page when user change page
                        onChange: page => setCurrPage(page),
                    }}
                    rowKey={record => record.login + record.symbol + record.server + record.updateDate}
                    scroll={{ x: "max-content" }}
                    size="small"
                />
            </div>
            <DownloadsModal open={isDownloadModalOpen} handleModalOpen={handleDownloadReportModalOpen} filterName={"DividendSeparateProcess"} />
        </>
    );
};

export default DividendRecordTable;

import { useEffect, useState } from "react";
import { APIs, apiRequest } from "../../../services/apiConfig";
import { ServersList } from "../../../constants/type";
import { GetUniqueKeysList, ToObjectWithKey } from "../../../utils/array";
import { Badge, Empty, Form, Input, Tooltip } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import useFilterConfigs from "../../../hooks/useFilterConfigs";

interface IHolidayNotice {
    description: string;
    symbol: string;
    serverId: number;
    server: string;
    timeRange: string;
}

const HolidayNotice = () => {
    const [holidayNoticeList, setHolidayNoticeList] = useState<IHolidayNotice[]>([]);
    const [, setServers] = useState<ServersList[]>([]);
    const [filterText, setFilterText] = useState<string>("");

    const getTemplate = (fText: string, list: IHolidayNotice[]) => {
        let filteredText: string = fText.toLowerCase();
        let filteredList = list.filter(
            x =>
                x.server.toLowerCase().indexOf(filteredText) > -1 ||
                x.description.toLowerCase().indexOf(filteredText) > -1 ||
                x.symbol.toLowerCase().indexOf(filteredText) > -1 ||
                x.timeRange.toLowerCase().indexOf(filteredText) > -1
        );
        if (filteredList.length > 0) {
            return (
                <div className="item-container nice-scrollbar">
                    {GetUniqueKeysList(filteredList, "description", true).map((x: string, index: number) => {
                        const tmp = filteredList.filter(y => y.description === x);
                        return (
                            <div key={`thl-i-${index}`} className="item">
                                <div className="title">
                                    <span className="desc">{x}</span>
                                    <Badge count={tmp.length} overflowCount={99999} />
                                </div>
                                {tmp.map((y: IHolidayNotice, idx: number) => (
                                    <div key={`thl-i-si-${idx}`} className="sub-item">
                                        <Tooltip title={y.server}>
                                            <span>{y.server}</span>
                                        </Tooltip>
                                        <Tooltip title={y.symbol}>
                                            <span>{y.symbol}</span>
                                        </Tooltip>
                                        <Tooltip title={y.timeRange}>
                                            <span>{y.timeRange}</span>
                                        </Tooltip>
                                    </div>
                                ))}
                            </div>
                        );
                    })}
                </div>
            );
        }
        return (
            <div style={{ marginTop: "50px" }}>
                <Empty />
            </div>
        );
    };

    const getHolidayNoticeList = (sList: ServersList[]) => {
        // console.log("filterConfigs?.sources", sList);
        apiRequest(APIs.GET_HOLIDAY_NOTICE, { isTodayOnly: true })
            .then((res: any) => {
                if (res && res.length > 0) {
                    let tmpServer: any = ToObjectWithKey(sList, "id", "name");
                    setHolidayNoticeList(
                        res.map((x: any) => ({
                            ...x,
                            server: tmpServer[x.sourceId],
                        }))
                    );
                }
            })
            .catch((err: any) => {
                console.log("APIs.GET_HOLIDAY_NOTICE", err);
            });
    };

    const { filterConfigs, filterConfigsError, isLoading } = useFilterConfigs({
        filterType: ["source"],
    });
    useEffect(() => {
        if (filterConfigs?.sources && !isLoading) {
            setServers(filterConfigs?.sources);
            getHolidayNoticeList(filterConfigs?.sources);
            filterConfigsError && console.error(filterConfigsError);
        }
    }, [filterConfigs, isLoading, filterConfigsError]);

    // const initialData = () => {
    //     apiRequest(APIs.GET_FILTER_CONFIG_LIST, { filterType: ["source"] }).then((data: any) => {
    //         setServers(data.sources);
    //         getHolidayNoticeList(data.sources);
    //     });
    // };

    // useEffect(() => {
    //     initialData();
    //     return () => {};
    // }, []);

    return (
        <div className="holiday-notice-container">
            <div className="title-div">Today Holiday Symbol</div>
            <div className="search-panel">
                <Input type="text" prefix={<SearchOutlined />} onChange={(e: any) => setFilterText(e.target.value)} />
            </div>
            {isLoading ? "loading..." : getTemplate(filterText, holidayNoticeList)}
        </div>
    );
};

export default HolidayNotice;

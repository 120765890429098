import React, { useEffect, useRef, useCallback } from "react";
import CanvasJSReact from "@canvasjs/react-charts";
import { Space } from "antd";
import "./canvasjs.css";

const _CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJS = CanvasJSReact.CanvasJS;

interface CanvasJSChartProps {
    options: any;
    onRef?: (chart: any) => void;
}

const CanvasJSChart: React.FC<CanvasJSChartProps> = ({ options, onRef }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const chartRef = useRef<any>(null);

    useEffect(() => {
        if (containerRef.current) {
            chartRef.current = new _CanvasJS.Chart(containerRef.current, options);
            chartRef.current.render();
            if (onRef) {
                onRef(chartRef.current);
            }
        }
        return () => {
            if (chartRef.current) {
                chartRef.current.destroy();
            }
            if (onRef) {
                onRef(undefined);
            }
        };
    }, [options, onRef]);

    useEffect(() => {
        if (chartRef.current) {
            chartRef.current.options = options;
            chartRef.current.render();
        }
    }, [options]);

    return <div ref={containerRef} style={{ height: "400px", width: "100%", position: "relative" }} />;
};

type DataPoint = {
    x: number | Date;
    y: number;
    indexLabel?: string;
    indexLabelFontWeight?: string;
    indexLabelFontSize?: number;
    indexLabelFontColor?: string;
};

type BiasLineChartProps = {
    biaslineCompareData: {
        s1?: DataPoint[];
        s2?: DataPoint[];
        p1?: DataPoint[];
        minus3s?: DataPoint[];
        average?: DataPoint[];
        plus3s?: DataPoint[];
        candleData?: { x: number; y: number; mark?: boolean }[];
        compareCandleData?: { x: number; y: number; mark?: boolean }[];
        spreadRatio: string;
        symbolName: string;
        compareSymbolName: string;
        lineMinimum?: number;
        lineMaximum?: number;
        candleMinimum?: number;
        candleMaximum?: number;
    };
};

const MidBiasLineChart: React.FC<BiasLineChartProps> = ({ biaslineCompareData }) => {
    const candleChartsRef = useRef<Array<any>>([]);
    const lineChartsRef = useRef<Array<any>>([]);

    const showTooltip = useCallback((e: any) => {
        candleChartsRef.current.forEach((chart: any) => {
            if (chart !== e.chart) chart.toolTip.showAtX(e.entries[0].xValue);
        });
    }, []);

    const hideTooltip = useCallback((e: any) => {
        candleChartsRef.current.forEach((chart: any) => {
            if (chart !== e.chart) chart.toolTip.hide();
        });
    }, []);

    const showCrosshair = useCallback((e: any) => {
        candleChartsRef.current.forEach((chart: any) => {
            if (chart !== e.chart) chart.axisX[0].crosshair.showAt(e.value);
        });
    }, []);

    const hideCrosshair = useCallback((e: any) => {
        candleChartsRef.current.forEach((chart: any) => {
            if (chart !== e.chart) chart.axisX[0].crosshair.hide();
        });
    }, []);

    const showLineTooltip = useCallback((e: any) => {
        lineChartsRef.current.forEach((chart: any) => {
            if (chart !== e.chart) chart.toolTip.showAtX(e.entries[0].xValue);
        });
    }, []);

    const hideLineTooltip = useCallback((e: any) => {
        lineChartsRef.current.forEach((chart: any) => {
            if (chart !== e.chart) chart.toolTip.hide();
        });
    }, []);

    const showLineCrosshair = useCallback((e: any) => {
        lineChartsRef.current.forEach((chart: any) => {
            if (chart !== e.chart) chart.axisX[0].crosshair.showAt(e.value);
        });
    }, []);

    const hideLineCrosshair = useCallback((e: any) => {
        lineChartsRef.current.forEach((chart: any) => {
            if (chart !== e.chart) chart.axisX[0].crosshair.hide();
        });
    }, []);

    const processDataPoints = (data: any, formatter?: (x: number) => Date): DataPoint[] => {
        if (!data) return [];
        return data.map((point: any) => {
            const basePoint = { x: formatter ? formatter(point.x) : point.x, y: point.y };
            return point.mark
                ? { ...basePoint, indexLabel: "*", indexLabelFontWeight: "bolder", indexLabelFontSize: 30, indexLabelFontColor: "red" }
                : basePoint;
        });
    };

    const formatDate = (date: number) => new Date(date.toString().replace(/^(\d{4})(\d\d)(\d\d)(\d\d)(\d\d)(\d\d)$/, "$4:$5:$6 $2/$3/$1"));

    const dataPoints = processDataPoints(biaslineCompareData.s1);
    const dataPoints2 = processDataPoints(biaslineCompareData.s2);
    const dataPoints3 = processDataPoints(biaslineCompareData.p1);
    const dataPoints4 = processDataPoints(biaslineCompareData.minus3s);
    const dataPoints5 = processDataPoints(biaslineCompareData.average);
    const dataPoints6 = processDataPoints(biaslineCompareData.plus3s);
    const dataPoints7 = processDataPoints(biaslineCompareData.candleData, formatDate);
    const dataPoints8 = processDataPoints(biaslineCompareData.compareCandleData, formatDate);

    const chartOptions = [
        {
            options: {
                exportEnabled: true,
                zoomEnabled: true,
                title: { text: "Tick Chart對比圖" },
                toolTip: { shared: true, updated: showLineTooltip, hidden: hideLineTooltip },
                axisX: {
                    valueFormatString: " ",
                    crosshair: { enabled: true, snapToDataPoint: true, updated: showLineCrosshair, hidden: hideLineCrosshair },
                },
                axisY: { valueFormatString: " " },
                data: [
                    { type: "line", name: "s1(ours)", showInLegend: true, dataPoints },
                    { type: "line", name: "s2(theirs)", showInLegend: true, dataPoints: dataPoints2 },
                ],
            },
            refArray: lineChartsRef,
        },
        {
            options: {
                exportEnabled: true,
                zoomEnabled: true,
                title: { text: "標準化正負" + biaslineCompareData.spreadRatio + "倍點差對比圖" },
                toolTip: { shared: true, updated: showLineTooltip, hidden: hideLineTooltip },
                axisX: {
                    valueFormatString: " ",
                    crosshair: { enabled: true, snapToDataPoint: true, updated: showLineCrosshair, hidden: hideLineCrosshair },
                },
                axisY: { valueFormatString: " " },
                data: [
                    { type: "line", name: "p1", showInLegend: true, dataPoints: dataPoints3 },
                    { type: "line", name: "avg", showInLegend: true, dataPoints: dataPoints4 },
                    { type: "line", name: "+" + biaslineCompareData.spreadRatio + "s", showInLegend: true, dataPoints: dataPoints5 },
                    { type: "line", name: "-" + biaslineCompareData.spreadRatio + "s", showInLegend: true, dataPoints: dataPoints6 },
                ],
            },
            refArray: lineChartsRef,
        },
        {
            options: {
                exportEnabled: true,
                zoomEnabled: true,
                title: { text: biaslineCompareData.symbolName },
                toolTip: {
                    contentFormatter: (e: any) =>
                        `<strong>${CanvasJS.formatDate(e.entries[0].dataPoint.x, "YYYY-MM-DD HH:mm:ss")}</strong><br>Open: ${
                            e.entries[0].dataPoint.y[0]
                        }<br>High: ${e.entries[0].dataPoint.y[1]}<br>Low: ${e.entries[0].dataPoint.y[2]}<br>Close: ${e.entries[0].dataPoint.y[3]}`,
                    updated: showTooltip,
                    hidden: hideTooltip,
                },
                axisX: {
                    labelFormatter: (e: any) => CanvasJS.formatDate(e.value, "HH:mm"),
                    interval: 5,
                    intervalType: "minute",
                    crosshair: { enabled: true, snapToDataPoint: true, updated: showCrosshair, hidden: hideCrosshair },
                },
                axisY: {
                    minimum: biaslineCompareData.candleMinimum,
                    maximum: biaslineCompareData.candleMaximum,
                },
                data: [{ type: "candlestick", name: " ", dataPoints: dataPoints7 }],
            },
            refArray: candleChartsRef,
        },
        {
            options: {
                exportEnabled: true,
                zoomEnabled: true,
                title: { text: biaslineCompareData.compareSymbolName },
                toolTip: {
                    contentFormatter: (e: any) =>
                        `<strong>${CanvasJS.formatDate(e.entries[0].dataPoint.x, "YYYY-MM-DD HH:mm:ss")}</strong><br>Open: ${
                            e.entries[0].dataPoint.y[0]
                        }<br>High: ${e.entries[0].dataPoint.y[1]}<br>Low: ${e.entries[0].dataPoint.y[2]}<br>Close: ${e.entries[0].dataPoint.y[3]}`,
                    updated: showTooltip,
                    hidden: hideTooltip,
                },
                axisX: {
                    labelFormatter: (e: any) => CanvasJS.formatDate(e.value, "HH:mm"),
                    interval: 5,
                    intervalType: "minute",
                    crosshair: { enabled: true, snapToDataPoint: true, updated: showCrosshair, hidden: hideCrosshair },
                },
                axisY: {
                    minimum: biaslineCompareData.candleMinimum,
                    maximum: biaslineCompareData.candleMaximum,
                },
                data: [{ type: "candlestick", name: " ", dataPoints: dataPoints8 }],
            },
            refArray: candleChartsRef,
        },
    ];

    return (
        <Space wrap>
            {chartOptions.map((chartOption, index) => (
                <div style={{ display: "inline-block", width: "500px" }} key={index}>
                    <CanvasJSChart
                        key={index}
                        options={chartOption.options}
                        onRef={(ref: any) => {
                            if (ref) {
                                chartOption.refArray.current.push(ref);
                            }
                        }}
                    />
                </div>
            ))}
        </Space>
    );
};

export default MidBiasLineChart;

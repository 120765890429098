export const getDeductionListDummyData = [
    {
        batchId: "20240828003",
        serverId: "AU2",
        oldServerId: 30,
        server: "AU2",
        login: 6196103,
        exchangeRate: 7.80157,
        adjustmentProduct: -25.83,
        currencyProduct: "USD",
        adjustmentClient: -201.51,
        currencyClient: "HKD",
        comment: "Cash Adj-Rollover-USNote10Y",
        status: 1,
        errorMsg: null,
        createDate: "2024-08-28T00:28:42",
        updateDate: "2024-08-28T01:02:12",
        logins: [],
    },
    {
        batchId: "20240828003",
        serverId: "AU2",
        oldServerId: 30,
        server: "AU2",
        login: 6198088,
        exchangeRate: 1.0,
        adjustmentProduct: -10.08,
        currencyProduct: "USD",
        adjustmentClient: -10.08,
        currencyClient: "USD",
        comment: "Cash Adj-Rollover-USNote10Y",
        status: 1,
        errorMsg: null,
        createDate: "2024-08-28T00:28:42",
        updateDate: "2024-08-28T01:02:12",
        logins: [],
    },
    {
        batchId: "20240828003",
        serverId: "AU4",
        oldServerId: 51,
        server: "AU4",
        login: 6329682,
        exchangeRate: 1.0,
        adjustmentProduct: -29.11,
        currencyProduct: "USD",
        adjustmentClient: -29.11,
        currencyClient: "USD",
        comment: "Cash Adj-Rollover-USNote10Y",
        status: 1,
        errorMsg: null,
        createDate: "2024-08-28T00:28:42",
        updateDate: "2024-08-28T01:02:02",
        logins: [],
    },
    {
        batchId: "20240828003",
        serverId: "AU4",
        oldServerId: 51,
        server: "AU4",
        login: 6542407,
        exchangeRate: 1.0,
        adjustmentProduct: -6.93,
        currencyProduct: "USD",
        adjustmentClient: -6.93,
        currencyClient: "USD",
        comment: "Cash Adj-Rollover-USNote10Y",
        status: 1,
        errorMsg: null,
        createDate: "2024-08-28T00:28:42",
        updateDate: "2024-08-28T01:02:02",
        logins: [],
    },
];

import { FormComponent } from "@/components/FormComponent";
import LoadingComponent from "@/components/Loading";
import SitePageHeader from "@/components/PageHeader";
import { ComponentType, SUCCESS_FAILED } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { HomeOutlined } from "@ant-design/icons";
import { Popconfirm, Button, Form, Row, Col } from "antd";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import { defaultIfEmptyOrNull, isEmptyOrNull } from "@/utils/string";
import { apiRequest, APIs } from "@/services/apiConfig";
import { ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { BrandV2Props } from ".";

export interface BrandCreateEditPageV2Props {}

type BrandCreateEditPageState = {
    action: string;
    data: any;
};

const BrandCreateEditPageV2 = (props: BrandCreateEditPageV2Props) => {
    let navigate = useNavigate();
    let location = useLocation();
    let com_state: BrandCreateEditPageState = location.state as BrandCreateEditPageState;
    let { id } = useParams();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [brandForm] = Form.useForm();

    const stateInfo = useMemo(() => {
        let tmpState = Object.assign({ action: "add", data: null }, com_state || {});
        return {
            isAddAction: isEmptyOrNull(id) ? tmpState.action === "add" : false,
            data: defaultIfEmptyOrNull(tmpState.data, id),
        };
    }, [com_state, id]);

    const getBrandList = useCallback(() => {
        setIsLoading(true);
        apiRequest(APIs.GET_BRANDS_LIST_V2, { brandId: stateInfo.data })
            .then((data: any) => {
                if (data.length > 0) {
                    let tmp = data[0] as BrandV2Props;
                    brandForm.setFieldsValue({ brandId: tmp.id, brandName: tmp.brand });
                }
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("brand", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
                navigate("/siteadmin/brandserver/brand");
            })
            .finally(() => setIsLoading(false));
    }, [stateInfo]);

    const deleteBrand = (brandId: number) => {
        apiRequest(APIs.DELETE_BRAND_V2, { brandId })
            .then(data => {
                ErrorMessageHandler("The brand", SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                navigate("/siteadmin/brandserver/brand");
            })
            .catch(error => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("brand", SUCCESS_FAILED.FAILED_DELETE_DATA, err)));
    };

    const onSubmit = useCallback(
        (values: any) => {
            setIsLoading(true);
            if (stateInfo.isAddAction) {
                apiRequest(APIs.CREATE_BRAND_V2, values)
                    .then(data => {
                        ErrorMessageHandler("New brand", SUCCESS_FAILED.SUCCESS_CREATE_DATA);
                        navigate("/siteadmin/brandserver/brand");
                    })
                    .catch(error => {
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("new brand", SUCCESS_FAILED.FAILED_CREATE_DATA, err));
                        setIsLoading(false);
                    });
            } else {
                apiRequest(APIs.UPDATE_BRAND_V2, values)
                    .then(data => {
                        ErrorMessageHandler("Existing brand", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                        navigate("/siteadmin/brandserver/brand");
                    })
                    .catch(error => {
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("existing brand", SUCCESS_FAILED.FAILED_UPDATE_DATA, err));
                        setIsLoading(false);
                    });
            }
        },
        [stateInfo]
    );

    useEffect(() => {
        if (!stateInfo.isAddAction) {
            getBrandList();
        }
    }, [stateInfo, getBrandList]);

    return (
        <SitePageHeader
            title={stateInfo.isAddAction ? "Create New Brand" : "Edit Brand"}
            routes={[
                {
                    path: "/siteadmin/brandserver/brand",
                    breadcrumbName: "Brand Listing",
                    icon: <HomeOutlined />,
                },
                {
                    path: "",
                    breadcrumbName: stateInfo.isAddAction ? "Create New Brand" : "Edit Brand",
                },
            ]}
            onBack={() => navigate("/siteadmin/brandserver/brand")}
            extraProps={{
                extra: stateInfo.isAddAction
                    ? []
                    : [
                          <Popconfirm
                              key={"cetmp-del"}
                              title="Confirm to delete?"
                              onConfirm={() => deleteBrand(stateInfo.data)}
                              okText="Yes"
                              cancelText="No"
                          >
                              <Button type="primary" danger>
                                  Delete Brand
                              </Button>
                          </Popconfirm>,
                      ],
            }}
        >
            {isLoading ? (
                <div className="loading-container">
                    <LoadingComponent tip="Submitting..." />
                </div>
            ) : (
                <Form
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    form={brandForm}
                    layout="horizontal"
                    initialValues={stateInfo.data}
                    onFinish={onSubmit}
                >
                    <Row>
                        <Col span={15}>
                            <FormComponent label={""} name={"brandId"} extra={{ type: ComponentType.hidden, value: "" }} />
                            <FormComponent
                                label="Brand Name"
                                name="brandName"
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <div className="step-btns-group">
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            )}
        </SitePageHeader>
    );
};

export default BrandCreateEditPageV2;

import { FormComponent } from "@/components/FormComponent";
import { ComponentType } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { KeyValuePair } from "@/constants/type";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Form, Input, Button } from "antd";

export interface DataCenterSettingsComponentProps {
    dataCenterType: KeyValuePair[];
}

const DataCenterSettingsComponent = (props: DataCenterSettingsComponentProps) => {
    return (
        <Form.List name="dataCenterSettings">
            {(fields, { add, remove }, { errors }) => (
                <>
                    {fields.map((field, index) => (
                        <Input.Group key={`dataCenterSettingscmp-${index}`} compact className="input-row-container datacenter-div">
                            <FormComponent
                                label=""
                                name={[field.name, "typeFlag"]}
                                extra={{
                                    type: ComponentType.dropdown,
                                    value: props.dataCenterType,
                                    rules: [{ required: true, message: REQUIRED_FIELD }],
                                    itemProps: {
                                        style: { width: "calc(23% - 40px)" },
                                        wrapperCol: { span: 24 },
                                    },
                                    inputProps: {
                                        placeholder: "Please select type",
                                        style: {
                                            width: "100%",
                                        },
                                    },
                                }}
                            />
                            <FormComponent
                                label=""
                                name={[field.name, "dcDn"]}
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    itemProps: { wrapperCol: { span: 24 } },
                                    inputProps: {
                                        placeholder: "Please fill in DC Name",
                                    },
                                }}
                            />
                            <FormComponent
                                label=""
                                name={[field.name, "dcUrl"]}
                                extra={{
                                    type: ComponentType.text,
                                    value: "",
                                    itemProps: { wrapperCol: { span: 24 } },
                                    inputProps: {
                                        placeholder: "Please fill in DC Url",
                                    },
                                }}
                            />
                            <FormComponent
                                label=""
                                name={[field.name, "enable"]}
                                extra={{
                                    type: ComponentType.checkbox,
                                    value: "Enable",
                                    itemProps: {
                                        colon: false,
                                    },
                                }}
                            />
                            <Button type="text" icon={<DeleteOutlined />} className="btn-remove" onClick={() => remove(field.name)} />
                        </Input.Group>
                    ))}
                    <Button type="dashed" icon={<PlusOutlined />} className="btn-add-new" onClick={() => add({ enable: true })}>
                        Add New Bridge Mapping
                    </Button>
                </>
            )}
        </Form.List>
    );
};

export default DataCenterSettingsComponent;

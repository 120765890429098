import { Descriptions, Modal, Form, Col, Row, Comment, Typography, message, Button, Tabs } from "antd";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import FlexiDataTable from "../../../components/FlexiDataTable";
import { FormComponent } from "../../../components/FormComponent";
import { SUCCESS_FAILED, CALLBACK_KEY, ComponentType } from "../../../constants";
import {
    AccountProfile,
    CustomPaginationProps,
    FlexiDataTableOptionsProps,
    FlexiDataTableCallbackProps,
    FlexiDataColumnProps,
    BrandsList,
    ServersList,
    WatchListGroup,
    AccWatchListProfile,
    UsersList,
} from "../../../constants/type";
import { apiRequest, APIs } from "../../../services/apiConfig";
import { removeSource, setSource } from "../../../services/localStorage";
import { currencyRender, DataTableColumnRender, DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "../../../utils/Common";
import { ACCOUNT_PROFILE_FROM_MODULE, getAccountProfileId, getAccountProfileParams, getNavigationUrl } from "../AccountProfileHelper";
import { REQUIRED_FIELD, SOMETHING_WENT_WRONG } from "../../../constants/errorMessage";
import AuthHelper, { AuthKeys } from "../../../helpers/authHelper";
import { ProfileInfoProps, initialValues } from "../AccountProfiles/viewProfile";
import LoadingComponent from "../../../components/Loading";
import "./AccountWatchList.less";
import moment from "moment";
import { DateTimeUtil } from "../../../utils/datetime";

const { Paragraph } = Typography;

const initialPaginationVals = {
    current: 1,
    pageSize: 10,
    total: 0,
};

function AccountWatchList() {
    let navigate = useNavigate();
    let location = useLocation();

    const getColumnsConfig = (
        brands: BrandsList[] = [],
        servers: ServersList[] = [],
        watchListGroups: WatchListGroup[] = [],
        usersList: UsersList[] = []
    ) => {
        return [
            {
                title: "Brand",
                dataIndex: "brand",
                key: "brand",
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: brands.map((x) => ({ text: x.brand, value: x.id })),
                    },
                    visible: false,
                },
            },

            {
                title: "Server",
                dataIndex: "server",
                key: "server",
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: servers.map((x) => ({ text: x.server, value: x.id })),
                    },
                    visible: false,
                },
            },

            {
                title: "Account ID",
                dataIndex: "accountId",
                key: "accountId",
                options: {
                    filter: {
                        type: ComponentType.number,
                    },
                    visible: false,
                },
            },

            DTColProps.XSmall({
                title: "Info",
                dataIndex: "info",
                key: "info",
                render: (accWatchInfo: any, rowData: AccWatchListProfile) => (
                    <>
                        <div className="watch-list-info">
                            <div className="title">Brand</div>
                            <div className="value">{rowData.brand}</div>
                        </div>
                        <div className="watch-list-info">
                            <div className="title">Server</div>
                            <div className="value">{rowData.server}</div>
                        </div>
                        <div className="watch-list-info">
                            <div className="title">Account ID</div>
                            <div className="value">{rowData.accountId}</div>
                        </div>
                    </>
                ),
            }),

            DTColProps.XSmall({
                title: "Action Date (Server)",
                dataIndex: "actionDate",
                key: "actionDate",
                sorter: (a: number, b: number) => (moment(a) > moment(b) ? -1 : 1),
                render: (text: any, rowData: AccWatchListProfile) => {
                    // const reformatActionDate = moment(rowData.actionDate).format("YYYY-MM-DD HH:mm:ss");
                    // const [datePart, timePart] = reformatActionDate.split(' ');
                    // console.log("`${datePart}\n${timePart}`", `${datePart}\n${timePart}`)
                    // return <span>{rowData.actionDate ? `${datePart}\n${timePart}` : ""}</span>;
                    return <span>{rowData.actionDate ? moment(rowData.actionDate).format("YYYY-MM-DD HH:mm:ss") : ""}</span>;
                },
                options: {
                    filter: {
                        type: ComponentType.daterange,
                        value: "",
                        inputProps: {
                            showTime: { format: "HH:mm:ss" },
                        },
                    },
                },
            }),

            DTColProps.XSmall(
                {
                    title: "PNL Captured",
                    dataIndex: "pnlCaptured",
                    key: "pnlCaptured",
                    sorter: (a: any, b: any) => a["pnlCaptured"] - b["pnlCaptured"],
                    render: (text: any, rowData: AccWatchListProfile) => {
                        return <span>{currencyRender(`${rowData.pnlCaptured}`)}</span>;
                    },
                },
                ["text-right"]
            ),

            DTColProps.XSmall(
                {
                    title: "Current Historical PNL",
                    dataIndex: "pnlHistorical",
                    key: "pnlHistorical",
                    sorter: (a: any, b: any) => a["pnlHistorical"] - b["pnlHistorical"],
                    render: (text: any, rowData: AccWatchListProfile) => {
                        return <span>{currencyRender(`${rowData.pnlHistorical}`)}</span>;
                    },
                },
                ["text-right"]
            ),

            DTColProps.XSmall(
                {
                    title: "PNL After Action",
                    dataIndex: "pnlDifference",
                    key: "pnlDifference",
                    sorter: (a: any, b: any) => a["pnlDifference"] - b["pnlDifference"],
                    render: (text: any, rowData: AccWatchListProfile) => {
                        return <span>{currencyRender(`${rowData.pnlDifference}`)}</span>;
                    },
                },
                ["text-right"]
            ),

            DTColProps.XSmall({
                title: "Deposit & Withdrawal Captured",
                dataIndex: "beforeDepositAndWithdrawal",
                key: "beforeDepositAndWithdrawal",
                render: (accWatchInfo: any, rowData: AccWatchListProfile) => (
                    <>
                        <div className="watch-list-info">
                            <div className="title">Deposit</div>
                            <div className="value">{rowData.depositCaptured ? currencyRender(`${rowData.depositCaptured}`) : "-"}</div>
                        </div>
                        <div className="watch-list-info">
                            <div className="title">Withdrawal</div>
                            <div className="value">{rowData.withdrawalCaptured ? currencyRender(`${rowData.withdrawalCaptured}`) : "-"}</div>
                        </div>
                    </>
                ),
            }),

            DTColProps.XSmall({
                title: "Current Deposit & Withdrawal",
                dataIndex: "currentDepositAndWithdrawal",
                key: "currentDepositAndWithdrawal",
                render: (accWatchInfo: any, rowData: AccWatchListProfile) => (
                    <>
                        <div className="watch-list-info">
                            <div className="title">Deposit</div>
                            <div className="value">{rowData.depositCurrent ? currencyRender(`${rowData.depositCurrent}`) : "-"}</div>
                        </div>
                        <div className="watch-list-info">
                            <div className="title">Withdrawal</div>
                            <div className="value">{rowData.withdrawalCurrent ? currencyRender(`${rowData.withdrawalCurrent}`) : "-"}</div>
                        </div>
                    </>
                ),
            }),

            DTColProps.XSmall(
                {
                    title: "PNL Past 7 Days",
                    dataIndex: "pnlPast7Days",
                    key: "pnlPast7Days",
                    sorter: (a: any, b: any) => a["pnlPast7Days"] - b["pnlPast7Days"],
                    render: (text: any, rowData: AccWatchListProfile) => {
                        return <span>{currencyRender(`${rowData.pnlPast7Days}`)}</span>;
                    },
                },
                ["text-right"]
            ),

            DTColProps.XSmall({
                title: "Watch List Group",
                dataIndex: "watchGroupId",
                key: "watchGroupId",
                render: (watchGroupId: number, rowData: AccWatchListProfile) => {
                    return rowData.watchGroupName;
                },
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: watchListGroups.map((x: any) => ({ text: x.name, value: x.id })),
                    },
                    visible: true,
                },
            }),

            DTColProps.Middle({
                title: "Comments",
                dataIndex: "currency",
                key: "currency",
                render: (currency: string, rowData: any) => {
                    return (
                        <Comment
                            className="custom-comment"
                            content={
                                <>
                                    <Paragraph ellipsis={{ rows: 4, expandable: true, symbol: "more" }}>{rowData.comment}</Paragraph>
                                    <p style={{ fontSize: "0.5vw", color: "#8e8e8e" }}>
                                        {`(Added by: ${rowData.addedBy} - ${DataTableColumnRender.DateTime(rowData.addedDateUtc)})`}
                                    </p>
                                </>
                            }
                            author={rowData.modifiedBy && rowData.modifiedDateUtc ? rowData.modifiedBy : rowData.addedBy}
                            datetime={
                                rowData.modifiedBy && rowData.modifiedDateUtc
                                    ? ` - ${DataTableColumnRender.DateTime(rowData.modifiedDateUtc)}`
                                    : ` - ${DataTableColumnRender.DateTime(rowData.addedDateUtc)}`
                            }
                        />
                    );
                },
                // className: "vertical-top",
            }),

            DTColProps.XSmall(
                {
                    title: "PNL Threshold",
                    dataIndex: "pnlThreshold",
                    key: "pnlThreshold",
                    sorter: (a: any, b: any) => a["pnlThreshold"] - b["pnlThreshold"],
                    render: (text: any, rowData: AccWatchListProfile) => {
                        return <span>{currencyRender(`${rowData.pnlThreshold}`)}</span>;
                    },
                },
                ["text-right"]
            ),

            DTColProps.XSmall({
                title: "Inspector",
                dataIndex: "assigneeId",
                key: "assigneeId",
                render: (assigneeId: number) => {
                    let idx = usersList.findIndex((x) => x.id === assigneeId);
                    return idx > -1 ? (
                        <div className="watch-list-info">
                            <div className="title">{usersList[idx].email}</div>
                            <div className="value">{usersList[idx].name}</div>
                        </div>
                    ) : (
                        "-"
                    );
                },
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: usersList.map((x) => ({ text: x.name + "  |  " + x.email, value: x.id })),
                        inputProps: {
                            mode: "multiple",
                        },
                    },
                },
            }),

            // DTColProps.Large({
            //     title: "PNL Details",
            //     dataIndex: "accountWatchDetails",
            //     key: "accountWatchDetails",
            //     render: (accWatchInfo: any, rowData: AccWatchListProfile) => {
            //         return (
            //             <Descriptions
            //                 column={1}
            //                 size="small"
            //                 className="tiny-description"
            //                 labelStyle={{ minWidth: "6.306vw"}}
            //                 contentStyle={{ textAlign: "right", minWidth: "6.306vw", display: "block", paddingRight: "1.051vw"}}
            //             >
            //                 <Descriptions.Item label="PNL Captured">{currencyRender(`${rowData.pnlCaptured}`)}</Descriptions.Item>
            //                 <Descriptions.Item label="Past 7 days Cum PNL">{currencyRender(`${rowData.pnlPast7Days}`)}</Descriptions.Item>
            //                 <Descriptions.Item label="Historical PNL">{currencyRender(`${rowData.pnlHistorical}`)}</Descriptions.Item>
            //                 <Descriptions.Item label="PNL Breach (USD)">{currencyRender(`${rowData.pnlBreach}`)}</Descriptions.Item>
            //                 <Descriptions.Item label="Difference">{currencyRender(`${rowData.pnlDifference}`)}</Descriptions.Item>
            //                 <Descriptions.Item label=""> </Descriptions.Item>
            //             </Descriptions>
            //         );
            //     },
            // }),
        ];
    };

    const [visible, setVisible] = useState<boolean>(false);
    const [watchListForm] = Form.useForm();
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.ANALYSIS_ACCOUNT_WATCHLIST_EDIT);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(false);
    const [tableCols, setTableCols] = useState<FlexiDataColumnProps[]>([...getColumnsConfig()]);
    const [data, setData] = useState<AccountProfile[]>([]);
    const [pagination, setPagination] = useState<CustomPaginationProps>(initialPaginationVals);
    const [filterParams, setFilterParams] = useState<any>({});
    const ecOperator = Form.useWatch("operator", watchListForm);
    const [currentSelectedLeftPanelItem, setCurrentSelectedLeftPanelItem] = useState<WatchListGroup>({
        id: -1,
        name: "All",
    });
    const [groupsList, setGroupsList] = useState<WatchListGroup[]>([]);
    const [usersList, setUsersList] = useState<UsersList[]>([]);
    const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
    const [isModalBtnLoading, setIsModalBtnLoading] = useState<boolean>(false);
    const [isModalLoading, setIsModalLoading] = useState<boolean>(false);
    const [accountInfo, setAccountInfo] = useState<ProfileInfoProps>(initialValues);

    const ecAction = Form.useWatch("action", watchListForm);

    const options: FlexiDataTableOptionsProps = {
        view: (record: any, option: any) => {
            let newProps = { ...option };
            newProps = <Link to={getNavigationUrl(ACCOUNT_PROFILE_FROM_MODULE.WATCH_LIST, record.id)}>{newProps}</Link>;
            return newProps;
        },
        serverFiltering: true,
        ...(enableUpdate && {
            rowExtra: [
                { text: "Edit", value: "edit_account" },
                { text: "Delete", value: "remove_account" },
            ],
        }),
        export: { text: "Export as CSV" },
        rowClassName: ["tiny-row"],
    };

    const convertBlobToDownload = (res: any) => {
        const fileName = res.headers["x-filename"];
        const url = window.URL.createObjectURL(new Blob([res.data], { type: "application/zip" })); // Create blob link to download
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName); // or any other extension
        document.body.appendChild(link); // Append to html link element page
        link.click(); // start download
        document.body.removeChild(link); // Clean up and remove the link
    };

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.VIEW_RECORD:
                navigate(getNavigationUrl(ACCOUNT_PROFILE_FROM_MODULE.WATCH_LIST, getAccountProfileParams(FormData.serverId, FormData.accountId, ACCOUNT_PROFILE_FROM_MODULE.WATCH_LIST, "", "", "", "", "")));
                window.scrollTo(0, 0);
                break;
            case CALLBACK_KEY.HANDLE_PAGINATION_SORTING:
                setPagination((prev) => ({ ...prev, current: FormData.pagination.current, pageSize: FormData.pagination.pageSize }));
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                let wlFilterParams: any = {};
                Object.keys(FormData)
                    .filter((x) => FormData[x] && (Array.isArray(FormData[x]) ? FormData[x].length > 0 : FormData[x].toString().length > 0))
                    .map((x) => {
                        if (x === "server") {
                            wlFilterParams["serverId"] = FormData[x];
                        } else if (x === "brand") {
                            wlFilterParams["brandId"] = FormData[x];
                        } else if (x === "assigneeId") {
                            wlFilterParams["assigneeIds"] = FormData[x];
                        } else if (x === "actionDate") {
                            wlFilterParams["actionDateFrom"] = FormData[x][0];
                            wlFilterParams["actionDateTo"] = FormData[x][1];
                        } else {
                            wlFilterParams[x] = FormData[x];
                        }
                        return x;
                    });
                setFilterParams(wlFilterParams);
                setPagination((prev) => ({ ...prev, current: 1 }));
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK:
                if (FormData.key === "edit_account") {
                    let tmp_data = FormData.data;
                    watchListForm.setFieldsValue({
                        action: "edit",
                        accountId: tmp_data.accountId,
                        brandId: tmp_data.brandId,
                        serverId: tmp_data.serverId,
                        comment: tmp_data.comment || "",
                        operator: tmp_data.operator || 0,
                        pnlThreshold: tmp_data.pnlThreshold || "",
                        watchGroupId: tmp_data.watchGroupId || null,
                        pnlCaptured: tmp_data.pnlCaptured || null,
                        depositCaptured: tmp_data.depositCaptured || null,
                        withdrawalCaptured: tmp_data.withdrawalCaptured || null,
                        actionDate: tmp_data.actionDate ? moment(tmp_data.actionDate) : null,
                        assigneeId: tmp_data.assigneeId || null,
                    });
                    setAccountInfo(FormData.data);
                    setVisible(true);
                }
                if (FormData.key === "remove_account") {
                    let tmp_data = FormData.data;
                    watchListForm.setFieldsValue({
                        action: "delete",
                        accountId: tmp_data.accountId,
                        brandId: tmp_data.brandId,
                        serverId: tmp_data.serverId,
                        comment: "",
                    });
                    setAccountInfo(FormData.data);
                    setVisible(true);
                }
                break;
            case CALLBACK_KEY.EXPORT_CSV_EXCEL:
                setIsBtnLoading(true);
                if (currentSelectedLeftPanelItem.name === "All") {
                    apiRequest(
                        APIs.DOWNLOAD_WATCH_LIST,
                        {
                            ...(Object.keys(filterParams)?.length > 0 && { ...filterParams }),
                        },
                        "POST",
                        "arraybuffer"
                    )
                        .then((res: any) => {
                            convertBlobToDownload(res);
                        })
                        .catch((error) => ErrorCatchValidator(error, (err: any) => message.error(SOMETHING_WENT_WRONG, 3)))
                        .finally(() => setIsBtnLoading(false));
                } else if (currentSelectedLeftPanelItem.name === "Uncategorized") {
                    apiRequest(
                        APIs.DOWNLOAD_WATCH_LIST,
                        {
                            queryNonGroup: true,
                            ...(Object.keys(filterParams)?.length > 0 && { ...filterParams }),
                        },
                        "POST",
                        "arraybuffer"
                    )
                        .then((res: any) => {
                            convertBlobToDownload(res);
                        })
                        .catch((error) => ErrorCatchValidator(error, (err: any) => message.error(SOMETHING_WENT_WRONG, 3)))
                        .finally(() => setIsBtnLoading(false));
                } else {
                    apiRequest(
                        APIs.DOWNLOAD_WATCH_LIST,
                        {
                            watchGroupId: currentSelectedLeftPanelItem.id,
                            ...(Object.keys(filterParams)?.length > 0 && { ...filterParams }),
                        },
                        "POST",
                        "arraybuffer"
                    )
                        .then((res: any) => {
                            convertBlobToDownload(res);
                        })
                        .catch((error) => ErrorCatchValidator(error, (err: any) => message.error(SOMETHING_WENT_WRONG, 3)))
                        .finally(() => setIsBtnLoading(false));
                }
                break;
            default:
                break;
        }
    };

    const getDataResponse = (data: any) => {
        if (data && data.result && data.result.length > 0) {
            let wlMarkupData = data.result.map((x: AccWatchListProfile) => {
                x.id = getAccountProfileId(x.serverId, x.accountId, ACCOUNT_PROFILE_FROM_MODULE.WATCH_LIST, "", "", "", "", "");
                return x;
            });
            setData(wlMarkupData);
            setPagination((prev) => ({ ...prev, total: data.total }));
        } else {
            setData([]);
            setPagination((prev) => ({ ...prev, total: 0 }));
        }
    };

    const getWatchList = (watchGroupId: number) => {
        if (watchGroupId === -1) {
            apiRequest(APIs.GET_ACCOUNT_WATCH_LIST, {
                limit: pagination.pageSize,
                current: pagination.current,
                ...(Object.keys(filterParams).length > 0 && { ...filterParams }),
            })
                .then((data: any) => {
                    getDataResponse(data);
                })
                .catch((error) => {
                    ErrorCatchValidator(error, (err: any) =>
                        ErrorMessageHandler("account watch list data by group", SUCCESS_FAILED.FAILED_LOAD_DATA, err)
                    );
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else if (watchGroupId === 0) {
            apiRequest(APIs.GET_ACCOUNT_WATCH_LIST, {
                limit: pagination.pageSize,
                current: pagination.current,
                queryNonGroup: true,
                ...(Object.keys(filterParams).length > 0 && { ...filterParams }),
            })
                .then((data: any) => {
                    getDataResponse(data);
                })
                .catch((error) => {
                    ErrorCatchValidator(error, (err: any) =>
                        ErrorMessageHandler("account watch list data by group", SUCCESS_FAILED.FAILED_LOAD_DATA, err)
                    );
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            apiRequest(APIs.GET_ACCOUNT_WATCH_LIST, {
                limit: pagination.pageSize,
                current: pagination.current,
                watchGroupId: watchGroupId,
                ...(Object.keys(filterParams).length > 0 && { ...filterParams }),
            })
                .then((data: any) => {
                    getDataResponse(data);
                })
                .catch((error) => {
                    ErrorCatchValidator(error, (err: any) =>
                        ErrorMessageHandler("account watch list data by group", SUCCESS_FAILED.FAILED_LOAD_DATA, err)
                    );
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    useEffect(() => {
        setSource(location);

        apiRequest(APIs.GET_FILTER_CONFIG_LIST, { filterType: ["brand", "server", "accountwatchlistgroup", "user"] })
            .then((data: any) => {
                setTableCols(getColumnsConfig(data.brands, data.servers, data.accountWatchListGroups, data.users));
                const all = { id: -1, name: "All", desc: "" };
                const uncategorized = { id: 0, name: "Uncategorized", desc: "" };
                data.accountWatchListGroups.push(...[all, uncategorized]);
                data.accountWatchListGroups.sort((a: WatchListGroup, b: WatchListGroup) => a.id - b.id);
                setUsersList(data.users);
                setGroupsList(data.accountWatchListGroups);
                setRunRefetchDataList(true);
            })
            .catch((error) => {
                ErrorCatchValidator(error, (err: any) => console.log(err));
            })
            .finally(() => {
                setIsLoading(false);
            });

        return () => {
            removeSource();
        };
    }, []);

    useEffect(() => {
        if (runRefetchDataList) {
            setIsLoading(true);
            getWatchList(currentSelectedLeftPanelItem.id);
            setRunRefetchDataList(false);
        }
        return () => {};
    }, [runRefetchDataList]);

    const getEditRemoveModalProps = () => {
        switch (ecAction) {
            case "edit":
                return {
                    title: "Edit watch list information",
                    footer: [
                        <Button
                            key="back"
                            onClick={() => {
                                watchListForm.resetFields();
                                setVisible(false);
                            }}
                        >
                            Cancel
                        </Button>,
                        <Button
                            key="remove"
                            type="primary"
                            onClick={() => {
                                setIsModalLoading(true);
                                setTimeout(() => {
                                    setIsModalLoading(false);
                                    watchListForm.setFieldsValue({
                                        action: "delete",
                                        accountId: accountInfo.accountId,
                                        brandId: accountInfo.brandId,
                                        serverId: accountInfo.serverId,
                                        comment: "",
                                        operator: 0,
                                        pnlThreshold: "",
                                        watchGroupId: null,
                                        pnlCaptured: null,
                                        depositCaptured: null,
                                        withdrawalCaptured: null,
                                        actionDate: null,
                                    });
                                }, 200);
                            }}
                        >
                            Remove account from watch list
                        </Button>,
                        <Button
                            key="submit"
                            loading={isModalBtnLoading}
                            type="primary"
                            onClick={() => {
                                setIsModalBtnLoading(true);
                                watchListForm
                                    .validateFields()
                                    .then((values: any) => {
                                        if (values.operator.toString() === "0" || values.operator === undefined || values.operator === "") {
                                            delete values["operator"];
                                            delete values["pnlThreshold"];
                                        } else {
                                            if (values.pnlThreshold === "") {
                                                delete values["pnlThreshold"];
                                            }
                                        }

                                        if (values.comment === "") {
                                            delete values["comment"];
                                        }
                                        apiRequest(APIs.UPDATE_WATCHLIST_COMMENT, {
                                            ...values,
                                            actionDate: values["actionDate"] ? DateTimeUtil.GetOrigin(values["actionDate"]) : null,
                                        })
                                            .then((data: any) => {
                                                ErrorMessageHandler("Watch list comment", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                                                setVisible(false);
                                                setRunRefetchDataList(true);
                                            })
                                            .catch((error: any) =>
                                                ErrorCatchValidator(error, (err: any) =>
                                                    ErrorMessageHandler("watch list comment", SUCCESS_FAILED.FAILED_UPDATE_DATA, err)
                                                )
                                            )
                                            .finally(() => setIsModalBtnLoading(false));
                                    })
                                    .catch((info) => {
                                        console.log("Validate Failed:", info);
                                    });
                            }}
                        >
                            OK
                        </Button>,
                    ],
                };
            case "delete":
                return {
                    title: "Remove account from watch list",
                    footer: [
                        <Button
                            key="back"
                            onClick={() => {
                                watchListForm.resetFields();
                                setVisible(false);
                            }}
                        >
                            Cancel
                        </Button>,
                        <Button
                            key="submit"
                            loading={isModalBtnLoading}
                            type="primary"
                            onClick={() => {
                                watchListForm
                                    .validateFields()
                                    .then((values: any) => {
                                        delete values["operator"];
                                        delete values["pnlThreshold"];
                                        delete values["pnlCaptured"];
                                        delete values["depositCaptured"];
                                        delete values["withdrawalCaptured"];
                                        delete values["actionDate"];
                                        delete values["assigneeId"];
                                        if (values.comment === "") {
                                            delete values["comment"];
                                        }
                                        setIsModalBtnLoading(true);
                                        apiRequest(APIs.REMOVE_WATCHLIST, { ...values })
                                            .then((data: any) => {
                                                message.success(`Successfully removed ${accountInfo.accountId} from watch list!`);
                                                setVisible(false);
                                                setRunRefetchDataList(true);
                                            })
                                            .catch((error: any) =>
                                                ErrorCatchValidator(error, (err: any) =>
                                                    message.error(`Failed to remove ${accountInfo.accountId} from watch list: ` + err, 3)
                                                )
                                            )
                                            .finally(() => setIsModalBtnLoading(false));
                                    })
                                    .catch((info) => {
                                        console.log("Validate Failed:", info);
                                    });
                            }}
                        >
                            OK
                        </Button>,
                    ],
                };
            default:
                break;
        }
    };

    const removeTableCols = (tabNumber: number, groupsList: WatchListGroup[]) => {
        let idx = tableCols.findIndex((x) => x.key === "watchGroupId");

        if (idx > -1) {
            let tempObject = tableCols[idx];

            if (tempObject.options) {
                tempObject.options.filter =
                    tabNumber > -1
                        ? undefined
                        : {
                              type: ComponentType.dropdown,
                              value: groupsList.map((x: any) => ({ text: x.name, value: x.id })),
                          };
                tempObject.options.visible = tabNumber > -1 ? false : true;
                tableCols[idx] = tempObject;
                setTableCols([...tableCols]);
            }
        } else {
            console.log("Not found with key 'watchGroupId'");
        }
    };

    return (
        <>
            <div className="account-watch-list-container">
                <div className="content-panel">
                    <div className="list-panel">
                        <Tabs
                            className="tab-container"
                            defaultActiveKey={currentSelectedLeftPanelItem.name}
                            type="card"
                            size="middle"
                            onChange={(activeKey: string) => {
                                let fidx: number = groupsList.findIndex((x) => x.name === activeKey);
                                setCurrentSelectedLeftPanelItem(groupsList[fidx]);
                                removeTableCols(
                                    groupsList[fidx].id,
                                    groupsList.filter((y: any) => y.name !== "Uncategorized" && y.name !== "All")
                                );
                                setRunRefetchDataList(true);
                            }}
                            items={
                                groupsList.map((x: WatchListGroup) => ({
                                    label: x.name,
                                    key: x.name,
                                    children: <></>,
                                })) as any[]
                            }
                        />
                        <div className="content">
                            <FlexiDataTable
                                rowKeyProperty="id"
                                title={
                                    <span
                                        style={{ fontWeight: "bold", fontSize: "1.25rem" }}
                                    >{`Account Watch List - ${currentSelectedLeftPanelItem.name}`}</span>
                                }
                                columns={tableCols}
                                options={options}
                                dataSource={data ?? []}
                                callback={componentCallback}
                                loading={isLoading}
                                pagination={pagination}
                                serverSide={true}
                                exporting={isBtnLoading}
                            />
                        </div>
                    </div>

                    <Modal
                        open={visible}
                        width={"40%"}
                        title={ecAction === "edit" ? "Edit watch list information" : "Remove account from watch list"}
                        onCancel={() => {
                            watchListForm.resetFields();
                            setVisible(false);
                        }}
                        {...getEditRemoveModalProps()}
                    >
                        {isModalLoading ? (
                            <div className="loading-container">
                                <LoadingComponent tip="Loading..." />
                            </div>
                        ) : (
                            <Form form={watchListForm} labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} layout="horizontal" initialValues={{}}>
                                <FormComponent label={""} name={"action"} extra={{ type: ComponentType.hidden, value: "" }} />
                                <FormComponent label={""} name={"accountId"} extra={{ type: ComponentType.hidden, value: "" }} />
                                <FormComponent label={""} name={"brandId"} extra={{ type: ComponentType.hidden, value: "" }} />
                                <FormComponent label={""} name={"serverId"} extra={{ type: ComponentType.hidden, value: "" }} />
                                <FormComponent label={""} name={"assigneeId"} extra={{ type: ComponentType.hidden, value: "" }} />
                                {ecAction === "edit" ? (
                                    <>
                                        <Row>
                                            <Col span={24}>
                                                <FormComponent
                                                    label="Inspector"
                                                    name={"assigneeId"}
                                                    extra={{
                                                        type: ComponentType.dropdown,
                                                        value: usersList.map((x) => ({ text: x.name + "  |  " + x.email, value: x.id })),
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24}>
                                                <FormComponent
                                                    label="PNL Captured"
                                                    name={"pnlCaptured"}
                                                    extra={{
                                                        type: ComponentType.number,
                                                        value: "",
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24}>
                                                <FormComponent
                                                    label="Deposit Captured"
                                                    name={"depositCaptured"}
                                                    extra={{
                                                        type: ComponentType.number,
                                                        value: "",
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24}>
                                                <FormComponent
                                                    label="Withdrawal Captured"
                                                    name={"withdrawalCaptured"}
                                                    extra={{
                                                        type: ComponentType.number,
                                                        value: "",
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24}>
                                                <FormComponent
                                                    label="Action Date"
                                                    name="actionDate"
                                                    extra={{
                                                        type: ComponentType.date,
                                                        value: "",
                                                        inputProps: {
                                                            showTime: { format: "HH:mm:ss" },
                                                        },
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={13}>
                                                <FormComponent
                                                    label="PNL Breach (USD)"
                                                    name={"operator"}
                                                    extra={{
                                                        type: ComponentType.dropdown,
                                                        value: [
                                                            { text: "None", value: 0 },
                                                            { text: "Greater Than", value: 1 },
                                                            { text: "Lower Than", value: 2 },
                                                        ],
                                                        itemProps: {
                                                            labelCol: {
                                                                span: 11,
                                                            },
                                                            wrapperCol: {
                                                                span: 13,
                                                            },
                                                        },
                                                        inputProps: {
                                                            allowClear: false,
                                                        },
                                                    }}
                                                />
                                            </Col>
                                            <Col span={9}>
                                                {ecOperator === 0 || ecOperator === undefined || ecOperator === "" ? (
                                                    <FormComponent
                                                        label={""}
                                                        name={"pnlThreshold"}
                                                        extra={{ type: ComponentType.hidden, value: "" }}
                                                    />
                                                ) : (
                                                    <FormComponent
                                                        label=""
                                                        name={"pnlThreshold"}
                                                        extra={{
                                                            type: ComponentType.text,
                                                            value: "",
                                                            itemProps: {
                                                                wrapperCol: {
                                                                    span: 24,
                                                                },
                                                            },
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    message: REQUIRED_FIELD,
                                                                },
                                                            ],
                                                        }}
                                                    />
                                                )}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24}>
                                                <FormComponent
                                                    label="Watch List Group"
                                                    name={"watchGroupId"}
                                                    extra={{
                                                        type: ComponentType.dropdown,
                                                        value: groupsList
                                                            .filter((y: any) => y.name !== "Uncategorized" && y.name !== "All")
                                                            .map((x: any) => ({ text: x.name, value: x.id })),
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </>
                                ) : (
                                    <>
                                        <FormComponent label={""} name={"operator"} extra={{ type: ComponentType.hidden, value: "" }} />
                                        <FormComponent label={""} name={"pnlThreshold"} extra={{ type: ComponentType.hidden, value: "" }} />
                                    </>
                                )}
                                <Row>
                                    <Col span={24}>
                                        <FormComponent
                                            label="Comment"
                                            name={"comment"}
                                            extra={{
                                                type: ComponentType.textarea,
                                                value: "",
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Modal>
                </div>
            </div>
        </>
    );
}

export default AccountWatchList;

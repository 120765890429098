import { Switch } from 'antd'
import React, { useState } from 'react'
import { apiRequest, APIs } from "@/services/apiConfig";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { SUCCESS_FAILED } from '@/constants';
import { BridgeSettingList } from './index';

type EnablerSwitchProps = {
  status: boolean
  rowData: BridgeSettingList
  onChangeCallback: () => void   
}

const EnablerSwitch = ({ status, rowData, onChangeCallback}: EnablerSwitchProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  function onSwitchChange(){
    if(status){
      disableSwitch()
    }else{
      enableSwitch()
    }
  }

  function disableSwitch(){
    setIsLoading(true)
    apiRequest(APIs.DISABLE_BRIDGE, { bridgeId: rowData.bridgeId })
      .then((data) => {
        onChangeCallback()
        ErrorMessageHandler("bridge setting", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
      })
      .catch((error) => {
        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("bridge setting", SUCCESS_FAILED.FAILED_UPDATE_DATA, err));
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function enableSwitch(){
    const params: any = {
      ...rowData,
      enable: true,
    }
    Object.keys(params).forEach(key=>{
      if(key === 'bridgeTypeDetails' || key === 'lowkey'){
        delete params[key]
      }
    })
    setIsLoading(true)
    apiRequest(APIs.UPDATE_BRIDGE, params)
      .then((data) => {
        onChangeCallback()
        ErrorMessageHandler("bridge setting", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
      })
      .catch((error) => {
        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("bridge setting", SUCCESS_FAILED.FAILED_UPDATE_DATA, err));
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <Switch
      loading={isLoading}
      checked={status}
      checkedChildren="Enabled"
      unCheckedChildren="Disabled"
      onChange={onSwitchChange}
    />
  )
}

export default EnablerSwitch

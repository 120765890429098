import React, { useState, useEffect } from "react";
import { Tabs } from "antd";
import Tab20min from "./Tab20min";
import TabSolved from "./TabSolved";
import TabUnsolved from "./TabUnsolved";
import TabReport from "./TabReport";
import TabSettings from "./TabSettings";
import TabRestartServer from "./TabRestartServer";
import PriceMonitorBreadCrumbs from "../components/PriceMonitorBreadCrumbs";

const MidBias = () => {
    const [currTab, setCurrTab] = useState<string | null>(null);

    // Ensure user can change tab by URL params
    useEffect(() => {
        const currTabParam = new URLSearchParams(window.location.search).get("tab");
        if (currTabParam) {
            setCurrTab(currTabParam);
        } else {
            setCurrTab("20min");
        }
    }, []);

    // Handle popstate event
    useEffect(() => {
        const handlePopState = () => {
            const currTabParam = new URLSearchParams(window.location.search).get("tab");
            if (currTabParam) {
                setCurrTab(currTabParam);
            } else {
                setCurrTab("20min");
            }
        };

        window.addEventListener("popstate", handlePopState);

        return () => {
            window.removeEventListener("popstate", handlePopState);
        };
    }, []);

    const handleChangeTab = (activeKey: string) => {
        setCurrTab(activeKey);
        window.history.pushState({}, "", `${window.location.pathname}?tab=${activeKey}`);
    };
    if (!currTab) return null;
    return (
        <>
            <PriceMonitorBreadCrumbs currMenu="Mid Bias Alarm" />
            {/* <SystemBreadCrumbs currMenu="Status Monitor" /> */}
            <Tabs
                activeKey={currTab}
                onChange={handleChangeTab}
                type="card"
                style={{ marginBottom: 0 }}
                items={[
                    {
                        label: "Alarm in 20 min",
                        key: "20min",
                        children: currTab === "20min" && <Tab20min />,
                    },
                    {
                        label: "Solved",
                        key: "solved",
                        children: currTab === "solved" && <TabSolved />,
                    },
                    {
                        label: "Unsolved",
                        key: "unsolved",
                        children: currTab === "unsolved" && <TabUnsolved />,
                    },
                    {
                        label: "Report",
                        key: "report",
                        children: currTab === "report" && <TabReport />,
                    },
                    {
                        label: "Settings",
                        key: "settings",
                        children: currTab === "settings" && <TabSettings />,
                    },
                    {
                        label: "For Restart Server",
                        key: "restart",
                        children: currTab === "restart" && <TabRestartServer />,
                    },
                ]}
                tabBarStyle={{ marginBottom: 0 }}
            />
        </>
    );
};

export default MidBias;

import CardBox from "@/components/Common/CardBox";
import FlexiDataTable from "@/components/FlexiDataTable";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "@/constants";
import { CustomPaginationProps, FlexiDataTableCallbackProps, FlexiDataTableOptionsProps } from "@/constants/type";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import useFilterConfigs from "@/hooks/useFilterConfigs";
import { apiRequest, APIs } from "@/services/apiConfig";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { Tag } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import EnablerSwitch from "./EnablerSwitch";

export type BridgeTypeDetails = {
    bridgeType: number;
    bridgeTypeName: string;
};

export type BridgeSettingList = {
    bridgeId: number;
    bridgeDn: string;
    bridgeType: number;
    enable: boolean;
    version: string;
    host: string;
    login: string;
    password: string;
    brokerVhost: string;
    brokerLogin: string;
    brokerPassword: string;
    bridgeTypeDetails: BridgeTypeDetails[];
    lowkey?: string;
};

const BridgeSettingPage = () => {
    const { filterConfigs } = useFilterConfigs({ filterType: ["riskinsightbridgetypes"] });
    const RiskInsightBridgeTypes: BridgeTypeDetails[] = filterConfigs?.riskInsightBridgeTypes ?? [];
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.ADMIN_BRAND_EDIT);

    const [data, setData] = useState<BridgeSettingList[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(true);
    const [pagination, setPagination] = useState<CustomPaginationProps>({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const [sorting, setSorting] = useState<string>("bridgeDn,asc");
    const [filterParams, setFilterParams] = useState<any>({});

    let navigate = useNavigate();

    const getFilterBridgeList = () => {
        apiRequest(APIs.GET_BRIDGE_LIST, {
            limit: pagination.pageSize,
            current: pagination.current,
            ...(Object.keys(filterParams).length > 0 && { ...filterParams }),
            ...(sorting.length > 0 && { order: sorting }),
        })
            .then((res: any) => {
                const result = res.result;
                if (result && result.length > 0) {
                    const data: BridgeSettingList[] = result.map((item: BridgeSettingList, index: number) => {
                        return {
                            ...item,
                            lowkey: `${index}_${item.bridgeId}`,
                        };
                    });
                    setData(data);
                    setPagination(prev => ({ ...prev, total: res.total }));
                } else {
                    setData([]);
                    setPagination(prev => ({ ...prev, total: 0 }));
                }
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("bridge list", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        if (runRefetchDataList) {
            setIsLoading(true);
            getFilterBridgeList();
            setRunRefetchDataList(false);
        }
        return () => {};
    }, [runRefetchDataList]);

    const columns: any[] = [
        DTColProps.Small({
            title: "BridgeName",
            dataIndex: "bridgeDn",
            key: "bridgeDn",
            options: {
                filter: {
                  type: ComponentType.text,
                  value: ""
                },
            },
            sorter: true,
            defaultSortOrder: 'ascend',
            sortDirections: ['ascend', 'descend', 'ascend']
        }),
        DTColProps.Small({
            title: "Type",
            dataIndex: "bridgeTypeDetails",
            key: "bridgeTypeDetails",
            render: (types: BridgeTypeDetails[], rowData: any) => (
                <>
                {
                    types.map(x=>{
                        return <Tag key={x.bridgeType} color="orange" style={{paddingRight: '8px'}}>{x.bridgeTypeName}</Tag>
                    })
                }
                </>
            ),
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: RiskInsightBridgeTypes.map(x=>{return {text: x.bridgeTypeName, value: x.bridgeType}}),
                    inputProps: {
                      mode: "multiple",
                    },
                },
            },
        }),
        DTColProps.Large({
            title: "Host",
            dataIndex: "host",
            key: "host",
        }),
        DTColProps.Small({
            title: "Login",
            dataIndex: "login",
            key: "login",
        }),
        DTColProps.Small({
          title: "VHost",
          dataIndex: "brokerVhost",
          key: "brokerVhost",
        }),
        DTColProps.Small({
            title: "BrokerLogin",
            dataIndex: "brokerLogin",
            key: "brokerLogin",
        }),
        DTColProps.Small({
          title: "Version",
          dataIndex: "version",
          key: "version",
          options: {
            filter: {
              type: ComponentType.text,
              value: ""
            },
        },
        }),
        DTColProps.Small({
            title: "Status",
            dataIndex: "enable",
            key: "enable",
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: [
                        { text: "Enabled", value: 1 },
                        { text: "Disabled", value: 0 },
                    ],
                },
            },
            render: enableUpdate
                ? (status: boolean, rowData: any) => {
                      return (
                          <EnablerSwitch
                            status={status}
                            rowData={rowData}
                            onChangeCallback={() => setRunRefetchDataList(true)}
                          />
                      );
                  }
                : (enabled: boolean, record: any) => (enabled ? "Enabled" : "Disabled"),
        }),
    ];

    const options: FlexiDataTableOptionsProps = {
        add: () => {
            if (enableUpdate) {
                return "/siteadmin/brandserver/bridge/create";
            } else return enableUpdate;
        },
        edit: enableUpdate,
        serverFiltering: true,
    };

    const componentCallback: FlexiDataTableCallbackProps = (type, FormData) => {
        switch (type) {
            case CALLBACK_KEY.HANDLE_PAGINATION_SORTING:
                FormData.sorter.order === undefined
                    ? setSorting("bridgeDn,asc")
                    : setSorting(
                          FormData.sorter.column && FormData.sorter.order
                              ? `${FormData.sorter.column.key},${FormData.sorter.order === "ascend" ? "asc" : "desc"}`
                              : ""
                      );
                setPagination(prev => ({ ...prev, current: FormData.pagination.current, pageSize: FormData.pagination.pageSize }));
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.CREATE_NEW:
                navigate("/siteadmin/brandserver/bridge/create", { state: { data: null, action: "add" } });
                break;
            case CALLBACK_KEY.DO_EDIT:
                navigate(`/siteadmin/brandserver/bridge/edit/${FormData.bridgeId}`, { state: { data: FormData.bridgeId, action: "edit" } });
                break;
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                let filterParams: any = {};
                Object.keys(FormData)
                    .filter((x) => FormData[x] !== undefined && FormData[x].toString().length > 0)
                    .map((x) => {
                        if (x === "bridgeTypeDetails") {
                            const val = FormData[x].reduce((acc: number, bridgeType: number) => {
                                return acc + bridgeType;
                            }, 0);
                            filterParams["bridgeType"] = val;
                        } else if (x === "enable") {
                            filterParams["enable"] = FormData[x] === 1 ? true : false;
                        } else {
                            filterParams[x] = FormData[x];
                        }
                        return x;
                    });
                    
                setFilterParams(filterParams);
                setPagination((prev) => ({ ...prev, current: 1 }));
                setRunRefetchDataList(true);
                break;
            default:
                break;
        }
    };

    return (
        <div className="v2-bridge-setting">
            <CardBox title={"Bridge Setting"}>
                <FlexiDataTable
                    rowKeyProperty="lowkey"
                    title=""
                    columns={columns}
                    options={options}
                    dataSource={data}
                    callback={componentCallback}
                    loading={isLoading}
                    serverSide={true}
                    pagination={pagination}
                />
            </CardBox>
        </div>
    );
};

export default BridgeSettingPage;

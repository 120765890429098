import { Routes, Route, Link } from "react-router-dom";
import {
    DashboardOutlined,
    EuroCircleOutlined,
    FundProjectionScreenOutlined,
    FundViewOutlined,
    LineChartOutlined,
    RadarChartOutlined,
    SettingOutlined,
    TagsOutlined,
    ToolOutlined,
    BarChartOutlined,
    ApartmentOutlined,
    AlertOutlined,
} from "@ant-design/icons";
import { routeProps } from "../constants/type";
import { userInfo } from "../services/api";
import ReportHistoryPage from "../pages/ReportingModule/ReportHistoryTab";
import TemplatesListPage from "../pages/ReportingModule/Templates/index";
import TemplateCreatePage from "../pages/ReportingModule/Templates/createEditTemplate";
import ViewTemplatePage from "../pages/ReportingModule/Templates/ViewTemplate";
import UserListPage from "../pages/SiteAdminModule/UsersPage/UsersManagementTab";
import UserCreatePage from "../pages/SiteAdminModule/UsersPage/UserCreatePage";
import UserEditPage from "../pages/SiteAdminModule/UsersPage/UserEditPage";
import RiskScoreList from "../pages/SiteAdminModule/RiskScore";
import RiskScoreCreateTemplate from "../pages/SiteAdminModule/RiskScore/createEditPage";
import ViewRiskScorePage from "../pages/SiteAdminModule/RiskScore/viewRiskScorePage";
import RiskScoreBrandList from "../pages/SiteAdminModule/RiskScoreBrand";
import BrandRiskScoreCreateTemplate from "../pages/SiteAdminModule/RiskScoreBrand/createEditPage";
import ViewBrandRiskScore from "../pages/SiteAdminModule/RiskScoreBrand/viewRiskScore";
import NotFoundPage from "../pages/NotFoundPage";
// import Login from "../pages/LoginPage";
import Login from "../pages/LoginPage/ssoIndex";
import SSORedirection from "../pages/LoginPage/ssoRedirection";
import AuthLayout from "../layouts/AuthLayout";
import MyAccountPage from "../pages/MyAccountPage";
import MyAccountEditPage from "../pages/MyAccountPage/MyAccountEditPage";
import ViewReportInstancesPage from "../pages/ReportingModule/ViewReportInstancesPage";
import RouteHelper from "../helpers/routeHelper";
import ForbiddenPage from "../pages/Forbidden";
import JobSchedulers from "../pages/SiteAdminModule/JobSchedulers";
import ResetPasswordPage from "../pages/ResetPasswordPage";
import TagManagementPage from "../pages/SiteAdminModule/TagManagementConfig/TagManagementPage";
import TagCreateEditPage from "../pages/SiteAdminModule/TagManagementConfig/TagCreateEditPage";
import TagViewLogsPage from "../pages/SiteAdminModule/TagManagementConfig/TagViewLogsPage";
import AccountProfilesListing from "../pages/Analysis/AccountProfiles";
import Account360Profile from "../pages/Analysis/AccountProfiles/viewProfile";
import ServerListPage from "../pages/SiteAdminModule/ServersPage/ServerListPage";
import ServerCreateEditPage from "../pages/SiteAdminModule/ServersPage/ServerCreateEditPage";
import BrandListPage from "../pages/SiteAdminModule/BrandsPage/BrandListPage";
import BrandCreateEditPage from "../pages/SiteAdminModule/BrandsPage/BrandCreateEditPage";
import SBMCreateEditPage from "../pages/SiteAdminModule/ServerBrandMappingPage/SBMCreateEditPage";
import SBMListPage from "../pages/SiteAdminModule/ServerBrandMappingPage/SBMListPage";
import GroupPurposeListPage from "../pages/SiteAdminModule/GroupPurposePage/GroupPurposeListPage";
import GroupPurposeCreateEditPage from "../pages/SiteAdminModule/GroupPurposePage/GroupPurposeCreateEditPage";
import TaggingListPage from "../pages/Tagging";
import RolePermissionPage from "../pages/SiteAdminModule/RolePermission";
import RoleCreateEditPage from "../pages/SiteAdminModule/RolePermission/RoleCreateEditPage";
import AccountProfileExportToPDF from "../pages/Analysis/exportToPdf";
import WrapperLayout from "../layouts/WrapperLayout";
import ViewRolePage from "../pages/SiteAdminModule/RolePermission/ViewRolePage";
import DashboardPage from "../pages/Dashboard";
import AppConfiguration from "../pages/SiteAdminModule/Configuration";
import { AuthKeys } from "../helpers/authHelper";
import AccountWatchList from "../pages/Analysis/AccountWatchList";
import TradeMonitoringPage from "../pages/RealTime/Trade/TradeMonitoring";
import TradeMonitoringCreateEditPage from "../pages/RealTime/Trade/TradeMonitoringCreateEditPage";
import TradeMonitoringPanel from "../pages/RealTime/Trade/TradeMonitoringPanel";
import HistoricalEventPage from "../pages/RealTime/HistoricalEvent";
import ArchiveWatchList from "../pages/Analysis/ArchivedWatchList";
import MetricConfiguration from "../pages/SiteAdminModule/Configuration/MetricConfiguration";
import CreateEditMetricConfiguration from "../pages/SiteAdminModule/Configuration/MetricConfiguration/createEditPage";
import MetricGroup from "../pages/SiteAdminModule/Configuration/MetricGroup";
import CreateEditMetricGroupPage from "../pages/SiteAdminModule/Configuration/MetricGroup/createEditPage";
import MetricGlossary from "../pages/SiteAdminModule/Configuration/MetricGlossary";
import MetricGlossaryEditPage from "../pages/SiteAdminModule/Configuration/MetricGlossary/editPage";
import AccSumMetricConfig from "../pages/SiteAdminModule/Configuration/AccSumMetricConfiguration";
import AccSumMetricConfigEditPage from "../pages/SiteAdminModule/Configuration/AccSumMetricConfiguration/editPage";
import AccSymbolConfig from "../pages/SiteAdminModule/Configuration/AccSymbolConfiguration";
import AccSumSymbolConfigEditPage from "../pages/SiteAdminModule/Configuration/AccSymbolConfiguration/editPage";
import NotificationPage from "../pages/NotificationPage/index";
import ClientOverview from "../pages/Analysis/Client360/clientOverview";
import BatchClientListing from "../pages/Analysis/Client360";
import AccWatchListGroup from "../pages/SiteAdminModule/Configuration/AccWatchListGroup";
import CreateEditAccWatchListGroup from "../pages/SiteAdminModule/Configuration/AccWatchListGroup/CreateEditAccWatchListGroup";
import SummaryHistoricalEvent from "../pages/RealTime/SummaryHistoricalEvent";
import DecayChartSummary from "../pages/RealTime/DecayChart";
import UserManualListing from "../pages/SiteAdminModule/Configuration/UserManualConfiguration";
import MenuMangement from "../pages/SiteAdminModule/Menus";
import ActivityLogsPage from "../pages/ActivityLogsPage";
import UserManualView from "../pages/UserManualView";
import HFTBiti from "../pages/RealTime/HFT";
import ClosedDecayChartSummary from "../pages/RealTime/ClosedDecayChart";
import PMIList from "../pages/RealTime/PMI";
import TicketRestoration from "../pages/AdminTools/TicketRestoration";
import CalculateStopOut from "../pages/AdminTools/TicketRestoration/CalculateStopOut";
import NotificationChannelManagement from "../pages/SiteAdminModule/Configuration/NotificationChannelManagement";
import CreateEditNotificationChannel from "../pages/SiteAdminModule/Configuration/NotificationChannelManagement/createEditNotificationChannel";
import HubSwitcher from "../pages/AdminTools/HubSwitcher";
import HubSwitcherPreview from "../pages/AdminTools/HubSwitcher/preview";
import ToxicAccountEventSnapshot from "../pages/RealTime/PMI/eventSnapshot";
import ProblematicClients from "../pages/Analysis/ProblematicClients";
import PreMarketAccModel from "../pages/Analysis/PreMarketAccModel";
import SymbolAssetTypeList from "../pages/SiteAdminModule/NewSymbolConfiguration/SymbolAssetType";
import CleanSymbolList from "../pages/SiteAdminModule/NewSymbolConfiguration/CleanSymbol";
import SymbolAssetTypeCreateEditPage from "../pages/SiteAdminModule/NewSymbolConfiguration/SymbolAssetType/SymbolAssetTypeCreateEditPage";
import CleanSymbolCreateEditPage from "../pages/SiteAdminModule/NewSymbolConfiguration/CleanSymbol/CleanSymbolCreateEditPage";
import SymbolMapping from "../pages/SiteAdminModule/NewSymbolConfiguration/SymbolMap";
import SymbolMapCreateEditPage from "../pages/SiteAdminModule/NewSymbolConfiguration/SymbolMap/SymbolMapCreateEditPage";
import SymbolInfo from "../pages/SiteAdminModule/NewSymbolConfiguration/SymbolInfo";
import LPMonitorSummary from "../pages/LPMonitor/summary";
import LP360Profile from "../pages/LPMonitor/lp360";
import HubStatusDashboard from "../pages/AdminTools/HubStatusDashboard";
import HubConfigurationPage from "../pages/AdminTools/HubConfiguration";
import Development from "../pages/DevelopmentPage";
import IntradayMonitorSearch from "../pages/IntradayMonitor/Search";
import IntradayMonitorSummary from "../pages/IntradayMonitor/Summary/summary";
import IntradayMonitorAlarm from "../pages/IntradayMonitor/Alarm";
import ToxicClientCheck from "../pages/Analysis/ToxicClientCheck";
import LPMonitorAlarm from "../pages/LPMonitor/Alarm";
import SpreadReportPage from "../pages/SpreadReportPage";
import StatusMonitor from "../pages/SystemMonitor/StatusMonitor";
import RejectOrder from "../pages/SystemMonitor/RejectOrder";
import MetaTrader from "../pages/SystemMonitor/MetaTrader";
import ServerPerformance from "../pages/SystemMonitor/ServerPerformance";
import AlarmDashboard from "../pages/AlarmModule/alarmDashboard";
import IssuesLog from "../pages/SystemMonitor/IssuesLog";
import MRGroupConfig from "../pages/LPMonitor/Group";
import AlarmHistoricalPage from "../pages/AlarmModule/alarmHistorical";
import DataCenter from "../pages/SystemMonitor/DataCenter";
import SystemMonitorOverview from "../pages/SystemMonitor/Overview";
import PriceCompare from "../pages/PriceMonitor/PriceCompare";
import PriceAlarm from "../pages/PriceMonitor/PriceAlarm";
import MidBias from "../pages/PriceMonitor/MidBias";
import PriceMonitorSpreadAlarm from "../pages/PriceMonitor/SpreadAlarm";
import LPPriceCompare from "../pages/PriceMonitor/LPPriceCompare";
import PriceAnalysisAlarm from "../pages/PriceMonitor/PriceAnalysisAlarm";
import SwapChargingTool from "../pages/AdminTools/RiskTools/SwapChargingTool";
import LinkedAccountRecord from "../pages/ReportingModule/LinkedAccountRecord";
import LinkedAccQuery from "../pages/ReportingModule/LinkedAccQuery";
import SwapChargingToolProcess from "../pages/AdminTools/RiskTools/SwapChargingToolProcess";
import CommissionRecord from "../pages/ReportingModule/CommissionRecord";
import PriceOutage from "../pages/PriceMonitor/PriceOutage";
import ReadOnlyPage from "../pages/AdminTools/RiskTools/ReadOnly";
import SymbolSwapUpload from "../pages/AdminTools/RiskTools/SymbolSwapUpload";
import HedgeToolsPage from "../pages/AdminTools/RiskTools/HedgeTool";
import HFTReport from "../pages/RealTime/HFTReport";
import OutOfPriceAlarm from "../pages/PriceMonitor/OutOfPriceAlarm";
import GroupTransferTool from "../pages/AdminTools/RiskTools/GroupTransferTool";
import NegativeEquityRecord from "../pages/ReportingModule/NegativeEquityRecord";
import OrderRoutingRecord from "../pages/AdminTools/RiskTools/OrderRoutingRecord";
import ConfigDiff from "../pages/AdminTools/RiskTools/ConfigDiff";
import DailyTickReport from "../pages/ReportingModule/DailyTickReport";
import MTClientSecurity from "@/pages/AdminTools/RiskTools/MTClientSecurity";
import ConfigDiffRecordPage from "../pages/AdminTools/RiskTools/ConfigDiffRecord";
import CADividendMapUpload from "@/pages/AdminTools/RiskTools/CADividendMapUpload";
import SyndicateTrading from "@/pages/RealTime/SyndicateTrading";
import CADividendSeparateProcess from "@/pages/AdminTools/RiskTools/CADividendSeparateProcess";
import DividendRecord from "@/pages/AdminTools/RiskTools/DividendRecord";
import WeekendSwapsRecord from "@/pages/AdminTools/RiskTools/WeekendSwapsRecord";
import AverageSpread from "@/pages/ReportingModule/AverageSpread";
import CreditToBalanceUpload from "@/pages/AdminTools/RiskTools/CreditToBalanceUpload";
import DividendUpload from "@/pages/AdminTools/RiskTools/DividendUpload";
import SymbolLeverageTool from "@/pages/AdminTools/RiskTools/SymbolLeverageTool";
import SymbolLeverageStep from "@/pages/AdminTools/RiskTools/SymbolLeverageTool/components/SymbolLeverageStep";
import BrandListingPage from "@/pages/SiteAdminModule/BrandServerManagementV2/Brand";
import BridgeSettingPageV2 from "@/pages/SiteAdminModule/BrandServerManagementV2/Bridge";
import BridgeCreateEditPageV2 from "@/pages/SiteAdminModule/BrandServerManagementV2/Bridge/BridgeCreateEditPage";
import BrandCreateEditPageV2 from "@/pages/SiteAdminModule/BrandServerManagementV2/Brand/CreateEditPage";
import ServerV2CreateEditPage from "@/pages/SiteAdminModule/BrandServerManagementV2/Server/CreateEditPage";
import ServerPageV2 from "@/pages/SiteAdminModule/BrandServerManagementV2/Server";
import RollOverTool from "@/pages/AdminTools/RiskTools/RollOverTool";
import RollOverToolStep from "@/pages/AdminTools/RiskTools/RollOverTool/components/RollOverToolStep";
import ExternalSystemPage from "@/pages/SiteAdminModule/BrandServerManagementV2/ExternalSystem";
import ExternalSystemCreateEditPage from "@/pages/SiteAdminModule/BrandServerManagementV2/ExternalSystem/CreateEditPage";
import BalanceUpdatePage from "@/pages/AdminTools/RiskTools/BalanceUpdate";
import PriceSettingPage from "@/pages/PriceSettings";

export const auth_route: routeProps[] = [
    {
        key: "/dashboard",
        title: "Dashboard",
        path: "dashboard",
        icon: (
            <Link to="/dashboard">
                <DashboardOutlined />
            </Link>
        ),
        component: <DashboardPage />,
        order: 1,
        showInNavBar: true,
    },
    {
        key: "/alarm-center",
        title: "Alarm Center",
        path: "alarm-center",
        icon: <AlertOutlined />,
        component: <DashboardPage />,
        order: 2,
        showInNavBar: true,
        submenu: [
            {
                key: "/alarm-center/dashboard",
                title: "Alarm Dashboard",
                path: "dashboard",
                component: <AlarmDashboard />,
                icon: <Link to="/alarm-center/dashboard" />,
                order: 1,
                showInNavBar: true,
            },
            {
                key: "/alarm-center/historical",
                title: "Historical Alarm Summary",
                path: "historical",
                component: <AlarmHistoricalPage />,
                icon: <Link to="/alarm-center/historical" />,
                order: 2,
                showInNavBar: true,
            },
        ],
    },
    {
        key: "/report",
        title: "Reporting",
        path: "report",
        icon: <LineChartOutlined />,
        order: 3,
        showInNavBar: true,
        submenu: [
            {
                key: "/report/result",
                title: "Report Result",
                path: "result",
                component: <ReportHistoryPage />,
                icon: <Link to="/report/result" />,
                order: 2,
                showInNavBar: true,
                submenu: [
                    {
                        key: "/report/result/view/:id",
                        title: "View Report Instances",
                        path: "view/:id",
                        icon: <Link to="/report/result/view/:id" />,
                        component: <ViewReportInstancesPage />,
                        referKey: AuthKeys.REPORT_HISTORY_VIEW,
                    },
                    {
                        key: "/report/result/viewtemplate/:id",
                        title: "View Template",
                        path: "viewtemplate/:id",
                        icon: <Link to="/report/result/viewtemplate/:id" />,
                        component: <ViewTemplatePage />,
                        referKey: AuthKeys.REPORT_HISTORY_VIEW,
                    },
                ],
            },
            {
                key: "/report/template",
                title: "Report Template",
                path: "template",
                component: <TemplatesListPage />,
                icon: <Link to="/report/template" />,
                order: 1,
                showInNavBar: true,
                submenu: [
                    {
                        key: "/report/template/create",
                        title: "Create New Template",
                        path: "create",
                        icon: <Link to="/report/template/create" />,
                        component: <TemplateCreatePage />,
                        referKey: AuthKeys.TEMPLATE_EDIT,
                    },
                    {
                        key: "/report/template/edit/:id",
                        title: "Edit Template",
                        path: "edit/:id",
                        icon: <Link to="/report/template/edit/:id" />,
                        component: <TemplateCreatePage />,
                        referKey: AuthKeys.TEMPLATE_EDIT,
                    },
                    {
                        key: "/report/template/view/:id",
                        title: "View Template",
                        path: "view/:id",
                        icon: <Link to="/report/template/view/:id" />,
                        component: <ViewTemplatePage />,
                        referKey: AuthKeys.TEMPLATE_VIEW,
                    },
                ],
            },
            {
                key: "/report/linkedaccountrecord",
                title: "Linked Account Record",
                path: "linkedaccountrecord",
                icon: <Link to="/report/linkedaccountrecord" />,
                component: <LinkedAccountRecord />,
                order: 4,
                showInNavBar: true,
            },
            {
                key: "/report/linkedaccountquery",
                title: "Linked Account Query",
                path: "linkedaccountquery",
                icon: <Link to="/report/linkedaccountquery" />,
                component: <LinkedAccQuery />,
                order: 3,
                showInNavBar: true,
            },
            {
                key: "/report/commissionrecord",
                title: "Commission Record",
                path: "commissionrecord",
                icon: <Link to="/report/commissionrecord" />,
                component: <CommissionRecord />,
                order: 5,
                showInNavBar: true,
            },
            {
                key: "/report/negativeequityrecord",
                title: "Negative Equity Record",
                path: "negativeequityrecord",
                icon: <Link to="/report/negativeequityrecord" />,
                component: <NegativeEquityRecord />,
                order: 6,
                showInNavBar: true,
            },
            {
                key: "/report/tickReport",
                title: "MT4/5 Daily Tick Report",
                path: "tickReport",
                icon: <Link to="/report/tickReport" />,
                component: <DailyTickReport />,
                order: 6,
                showInNavBar: true,
            },
            {
                key: "/report/averageSpread",
                title: "Average Spread",
                path: "averageSpread",
                icon: <Link to="/report/averageSpread" />,
                component: <AverageSpread />,
                order: 6,
                showInNavBar: true,
            },
        ],
    },
    {
        key: "/pricemonitor",
        title: "Price Monitor",
        path: "/pricemonitor",
        icon: <EuroCircleOutlined />,
        order: 4,
        showInNavBar: true,
        submenu: [
            {
                key: "/pricemonitor/pricealarm",
                title: "Price Alarm",
                path: "/pricemonitor/pricealarm",
                component: <PriceAlarm />,
                icon: <Link to="/pricemonitor/pricealarm" />,
                order: 1,
                showInNavBar: true,
            },
            {
                key: "/pricemonitor/priceoutage",
                title: "Price Outage",
                path: "/pricemonitor/priceoutage",
                component: <PriceOutage />,
                icon: <Link to="/pricemonitor/priceoutage" />,
                order: 2,
                showInNavBar: true,
            },
            {
                key: "/pricemonitor/midbias",
                title: "Mid Bias Alarm",
                path: "/pricemonitor/midbias",
                component: <MidBias />,
                icon: <Link to="/pricemonitor/midbias" />,
                order: 3,
                showInNavBar: true,
            },
            {
                key: "/pricemonitor/spreadalarm",
                title: "Spread Alarm",
                path: "/pricemonitor/spreadalarm",
                component: <PriceMonitorSpreadAlarm />,
                icon: <Link to="/pricemonitor/spreadalarm" />,
                order: 4,
                showInNavBar: true,
            },
            {
                key: "/pricemonitor/pricecompare",
                title: "Price Compare",
                path: "/pricemonitor/pricecompare",
                component: <PriceCompare />,
                icon: <Link to="/pricemonitor/pricecompare" />,
                order: 5,
                showInNavBar: true,
            },
            {
                key: "/pricemonitor/lppricecompare",
                title: "LP Price Compare",
                path: "/pricemonitor/lppricecompare",
                component: <LPPriceCompare />,
                icon: <Link to="/pricemonitor/lppricecompare" />,
                order: 6,
                showInNavBar: true,
            },
            {
                key: "/pricemonitor/priceanalysisalarm",
                title: "Price Analysis Alarm",
                path: "/pricemonitor/priceanalysisalarm",
                component: <PriceAnalysisAlarm />,
                icon: <Link to="/pricemonitor/priceanalysisalarm" />,
                order: 7,
                showInNavBar: true,
            },
            {
                key: "/pricemonitor/outofprice",
                title: "Out of Price Alarm",
                path: "/pricemonitor/outofprice",
                component: <OutOfPriceAlarm />,
                icon: <Link to="/pricemonitor/outofprice" />,
                order: 8,
                showInNavBar: true,
            },
            {
                key: "/pricemonitor/pricesettings",
                title: "Price Setting",
                path: "/pricemonitor/pricesettings",
                component: <PriceSettingPage />,
                icon: <Link to="/pricemonitor/pricesettings" />,
                order: 9,
                showInNavBar: true,
            },
        ],
    },
    // {
    //     key: "/pricemonitor",
    //     title: "Price Monitoring",
    //     path: "/pricemonitor",
    //     icon: <EuroCircleOutlined />,
    //     order: 4,
    //     showInNavBar: true,
    //     submenu: [
    //         {
    //             key: "/pricemonitor/midpricemonitoring",
    //             title: "Mid Price Outage",
    //             path: "midpricemonitoring",
    //             component: <MidPriceMonitoring />,
    //             icon: <Link to="/pricemonitor/midpricemonitoring" />,
    //             order: 1,
    //             showInNavBar: true,
    //             referKey: AuthKeys.MID_PRICE_MONITORING_VIEW,
    //         },
    //         {
    //             key: "/pricemonitor/historicalmidpricemonitoring",
    //             title: "Historical Mid Price Outage",
    //             path: "historicalmidpricemonitoring",
    //             component: <HistoricalMidPriceMonitoring />,
    //             icon: <Link to="/pricemonitor/historicalmidpricemonitoring" />,
    //             order: 2,
    //             showInNavBar: true,
    //             referKey: AuthKeys.HISTORICAL_MID_PRICE_MONITORING_VIEW,
    //         },
    //         {
    //             key: "/pricemonitor/priceoutage",
    //             title: "Price Outage",
    //             path: "priceoutage",
    //             component: <PriceOutageListing />,
    //             icon: <Link to="/pricemonitor/priceoutage" />,
    //             order: 3,
    //             showInNavBar: true,
    //             referKey: AuthKeys.PRICE_OUTAGE_VIEW,
    //         },
    //         {
    //             key: "/pricemonitor/historicalpriceoutage",
    //             title: "Historical Price Outage",
    //             path: "historicalpriceoutage",
    //             component: <HistoricalPriceOutage />,
    //             icon: <Link to="/pricemonitor/historicalpriceoutage" />,
    //             order: 4,
    //             showInNavBar: true,
    //             referKey: AuthKeys.HISTORICAL_PRICE_OUTAGE_VIEW,
    //         },
    //     ],
    // },
    {
        key: "/realtime",
        title: "Real Time",
        path: "realtime",
        icon: <FundProjectionScreenOutlined />,
        order: 5,
        showInNavBar: true,
        submenu: [
            {
                key: "/realtime/trademonitor",
                title: "Monitoring Profile",
                path: "trademonitor",
                icon: <Link to="/realtime/trademonitor" />,
                component: <TradeMonitoringPage />,
                order: 1,
                showInNavBar: true,
                submenu: [
                    {
                        key: "/realtime/trademonitor/create",
                        title: "Create New Monitor Profile",
                        path: "create",
                        icon: <Link to="/realtime/trademonitor/create" />,
                        component: <TradeMonitoringCreateEditPage />,
                        referKey: AuthKeys.MONITOR_TRADE_EDIT,
                    },
                    {
                        key: "/realtime/trademonitor/edit",
                        title: "Edit Monitor Profile",
                        path: "edit",
                        icon: <Link to="/realtime/trademonitor/edit" />,
                        component: <TradeMonitoringCreateEditPage />,
                        referKey: AuthKeys.MONITOR_TRADE_EDIT,
                    },
                    {
                        key: "/realtime/trademonitor/launch/:id",
                        title: "Monitor Profile Panel",
                        path: "launch/:id",
                        icon: <Link to="/realtime/trademonitor/launch/:id" />,
                        component: <TradeMonitoringPanel />,
                        referKey: AuthKeys.MONITOR_TRADE_VIEW,
                    },
                    {
                        key: "/realtime/trademonitor/view/:id",
                        title: "Account 360",
                        path: "view/:id",
                        component: <Account360Profile />,
                        referKey: AuthKeys.MONITOR_TRADE_VIEW,
                    },
                ],
            },

            {
                key: "/realtime/historicaleventsummary",
                title: "Historical Event Summary",
                path: "historicaleventsummary",
                icon: <Link to="/realtime/historicaleventsummary" />,
                component: <SummaryHistoricalEvent />,
                order: 2,
                showInNavBar: true,
                submenu: [
                    {
                        key: "/realtime/historicaleventsummary/view",
                        title: "Account 360",
                        path: "view",
                        component: <Account360Profile />,
                        referKey: AuthKeys.HISTORICAL_EVENT_SUMMARY_VIEW,
                    },
                ],
            },
            {
                key: "/realtime/historicaleventbatches",
                title: "Historical Event Batches",
                path: "historicaleventbatches",
                icon: <Link to="/realtime/historicaleventbatches" />,
                component: <HistoricalEventPage />,
                order: 3,
                showInNavBar: true,
                submenu: [
                    {
                        key: "/realtime/historicaleventbatches/view/:id",
                        title: "Account 360",
                        path: "view/:id",
                        component: <Account360Profile />,
                        referKey: AuthKeys.HISTORICAL_EVENT_BATCHES_VIEW,
                    },
                ],
            },
            {
                key: "/realtime/decaychart",
                title: "Decay Analysis",
                path: "decaychart",
                icon: <Link to="/realtime/decaychart" />,
                component: <DecayChartSummary />,
                order: 4,
                showInNavBar: true,
                submenu: [
                    {
                        key: "/realtime/decaychart/view/:id",
                        title: "Account 360",
                        path: "view/:id",
                        component: <Account360Profile />,
                        referKey: AuthKeys.DECAY_ANALYSIS_VIEW,
                    },
                    {
                        key: "/realtime/decaychart/ticket/:id",
                        title: "Ticket View",
                        path: "ticket/:id",
                        component: <DecayChartSummary />,
                        referKey: AuthKeys.DECAY_ANALYSIS_VIEW,
                    },
                ],
            },
            {
                key: "/realtime/hftBiti",
                title: "HFT - BITI",
                path: "hftBiti",
                icon: <Link to="/realtime/hftBiti" />,
                component: <HFTBiti />,
                order: 5,
                showInNavBar: true,
            },
            {
                key: "/realtime/closeddecaychart",
                title: "Closed Decay Analysis",
                path: "closeddecaychart",
                icon: <Link to="/realtime/closeddecaychart" />,
                component: <ClosedDecayChartSummary />,
                order: 6,
                showInNavBar: true,
                submenu: [
                    {
                        key: "/realtime/closeddecaychart/view/:id",
                        title: "Account 360",
                        path: "view/:id",
                        component: <Account360Profile />,
                        referKey: AuthKeys.CLOSED_DECAY_ANALYSIS_VIEW,
                    },
                ],
            },
            {
                key: "/realtime/toxicaccount",
                title: "Toxic Account",
                path: "toxicaccount",
                icon: <Link to="/realtime/toxicaccount" />,
                component: <PMIList />,
                order: 7,
                showInNavBar: true,
                submenu: [
                    {
                        key: "/realtime/toxicaccount/view",
                        title: "Account 360",
                        path: "view",
                        component: <Account360Profile />,
                        referKey: AuthKeys.TOXIC_ACCOUNT_VIEW,
                    },
                    {
                        key: "/realtime/toxicaccount/snapshot/:id",
                        title: "Snapshot View",
                        path: "snapshot/:id",
                        component: <ToxicAccountEventSnapshot />,
                        referKey: AuthKeys.TOXIC_ACCOUNT_VIEW,
                    },
                    // {
                    //     key: "/realtime/toxicaccount/detail/:id",
                    //     title: "Detail View",
                    //     path: "detail/:id",
                    //     component: <PMIList />,
                    //     referKey: AuthKeys.TOXIC_ACCOUNT_VIEW,
                    // },
                ],
            },
            {
                key: "/realtime/hftReport",
                title: "HFT Report",
                path: "hftReport",
                icon: <Link to="/realtime/hftReport" />,
                component: <HFTReport />,
                order: 8,
                showInNavBar: true,
            },
            {
                key: "/realtime/syndicateTrading",
                title: "Syndicate Trading",
                path: "syndicateTrading",
                icon: <Link to="/realtime/syndicateTrading" />,
                component: <SyndicateTrading />,
                order: 9,
                showInNavBar: true,
            },
        ],
    },
    {
        key: "/analysis",
        title: "Analysis",
        path: "/analysis",
        icon: <RadarChartOutlined />,
        order: 6,
        showInNavBar: true,
        submenu: [
            {
                key: "/analysis/premarketAccountModel",
                title: "Pre-Market Account Model",
                path: "premarketAccountModel",
                component: <PreMarketAccModel />,
                icon: <Link to="/analysis/premarketAccountModel" />,
                order: 1,
                showInNavBar: true,
                submenu: [
                    {
                        key: "/analysis/premarketAccountModel/view",
                        title: "Account 360",
                        path: "view",
                        component: <Account360Profile />,
                        referKey: AuthKeys.PREMARKET_ACCOUNT_VIEW,
                    },
                ],
            },
            {
                key: "/analysis/problematicClients",
                title: "Problematic Clients",
                path: "problematicClients",
                component: <ProblematicClients />,
                icon: <Link to="/analysis/problematicClients" />,
                order: 2,
                showInNavBar: true,
                submenu: [
                    {
                        key: "/analysis/problematicClients/view",
                        title: "Account 360",
                        path: "view",
                        component: <Account360Profile />,
                        referKey: AuthKeys.PROBLEMATIC_CLIENT_VIEW,
                    },
                ],
            },
            {
                key: "/analysis/clientlist",
                title: "Client List",
                path: "clientlist",
                component: <BatchClientListing />,
                icon: <Link to="/analysis/clientlist" />,
                order: 4,
                showInNavBar: true,
                submenu: [
                    {
                        key: "/analysis/clientlist/launch",
                        title: "Client List",
                        path: "launch",
                        icon: <Link to="/analysis/clientlist/launch" />,
                        component: <ClientOverview />,
                        referKey: AuthKeys.ANALYSIS_CLIENT_PROFILE_VIEW,
                    },
                    {
                        key: "/analysis/clientlist/view",
                        title: "Account 360",
                        path: "view",
                        component: <Account360Profile />,
                        referKey: AuthKeys.ANALYSIS_CLIENT_PROFILE_VIEW,
                    },
                ],
            },
            {
                key: "/analysis/accountprofile",
                title: "Account List",
                path: "accountprofile",
                component: <AccountProfilesListing />,
                icon: <Link to="/analysis/accountprofile" />,
                order: 5,
                showInNavBar: true,
                submenu: [
                    {
                        key: "/analysis/accountprofile/view",
                        title: "Account 360",
                        path: "view",
                        icon: <Link to="/analysis/accountprofile/view" />,
                        component: <Account360Profile />,
                        referKey: AuthKeys.ANALYSIS_ACCOUNT_PROFILE_VIEW,
                    },
                ],
            },
            {
                key: "/analysis/watchlist",
                title: "Watch List",
                path: "watchlist",
                component: <AccountWatchList />,
                icon: <Link to="/analysis/watchlist" />,
                order: 6,
                showInNavBar: true,
                submenu: [
                    {
                        key: "/analysis/watchlist/view",
                        title: "Account 360",
                        path: "view",
                        icon: <Link to="/analysis/watchlist/view" />,
                        component: <Account360Profile />,
                        referKey: AuthKeys.ANALYSIS_ACCOUNT_WATCHLIST_VIEW,
                    },
                ],
            },
            {
                key: "/analysis/archivewatchlist",
                title: "Archived Account Watch List",
                path: "archivewatchlist",
                icon: <Link to="/analysis/archivewatchlist" />,
                component: <ArchiveWatchList />,
                order: 7,
                showInNavBar: true,
            },
            {
                key: "/analysis/toxicClientCheck",
                title: "Toxic Client Check",
                path: "toxicClientCheck",
                icon: <Link to="/analysis/toxicClientCheck" />,
                component: <ToxicClientCheck />,
                order: 3,
                showInNavBar: true,
            },
        ],
    },
    {
        key: "/symbolanalysis",
        title: "Symbol Analysis",
        path: "/symbolanalysis",
        icon: <BarChartOutlined />,
        order: 7,
        showInNavBar: true,
        submenu: [
            {
                key: "/symbolanalysis/spreadanalysis",
                title: "Spread Analysis",
                path: "spreadanalysis",
                component: <SpreadReportPage />,
                icon: <Link to="/symbolanalysis/spreadanalysis" />,
                order: 1,
                showInNavBar: true,
            },
        ],
    },
    {
        key: "/systemmonitor",
        title: "System Monitor",
        path: "/systemmonitor",
        icon: <ApartmentOutlined />,
        order: 8,
        showInNavBar: true,
        submenu: [
            {
                key: "/systemmonitor/overview",
                title: "Overview",
                path: "/systemmonitor/overview",
                component: <SystemMonitorOverview />,
                icon: <Link to="/systemmonitor/overview" />,
                order: 0,
                showInNavBar: true,
            },
            {
                key: "/systemmonitor/statusmonitor",
                title: "Status Monitor",
                path: "/systemmonitor/statusmonitor",
                component: <StatusMonitor />,
                icon: <Link to="/systemmonitor/statusmonitor" />,
                order: 1,
                showInNavBar: true,
            },
            {
                key: "/systemmonitor/rejectorder",
                title: "Reject Order",
                path: "/systemmonitor/rejectorder",
                component: <RejectOrder />,
                icon: <Link to="/systemmonitor/rejectorder" />,
                order: 2,
                showInNavBar: true,
            },
            {
                key: "/systemmonitor/metatrader",
                title: "Meta Trader",
                path: "/systemmonitor/metatrader",
                component: <MetaTrader />,
                icon: <Link to="/systemmonitor/metatrader" />,
                order: 3,
                showInNavBar: true,
            },
            {
                key: "/systemmonitor/serverperformance",
                title: "Server Performance",
                path: "/systemmonitor/serverperformance",
                component: <ServerPerformance />,
                icon: <Link to="/systemmonitor/serverperformance" />,
                order: 4,
                showInNavBar: true,
            },
            {
                key: "/systemmonitor/issueslog",
                title: "Issues Log",
                path: "/systemmonitor/issueslog",
                component: <IssuesLog />,
                icon: <Link to="/systemmonitor/issueslog" />,
                order: 5,
                showInNavBar: true,
            },
            {
                key: "/systemmonitor/datacenter",
                title: "Data Center",
                path: "/systemmonitor/datacenter",
                component: <DataCenter />,
                icon: <Link to="/systemmonitor/datacenter" />,
                order: 6,
                showInNavBar: true,
            },
        ],
    },
    {
        key: "/lpmonitor",
        title: "LP Monitor",
        path: "/lpmonitor",
        icon: <FundViewOutlined />,
        order: 9,
        showInNavBar: true,
        submenu: [
            {
                key: "/lpmonitor/summary",
                title: "Summary",
                path: "summary",
                component: <LPMonitorSummary />,
                icon: <Link to="/lpmonitor/summary" />,
                order: 1,
                showInNavBar: true,
                submenu: [
                    {
                        key: "/lpmonitor/summary/lp360/:id",
                        title: "LP 360",
                        path: "lp360/:id",
                        component: <LP360Profile />,
                        icon: <Link to="/lpmonitor/summary/lp360/:id" />,
                        referKey: AuthKeys.LP360_VIEW,
                    },
                ],
            },
            {
                key: "/lpmonitor/lp360",
                title: "LP 360",
                path: "lp360",
                component: <LP360Profile />,
                icon: <Link to="/lpmonitor/lp360" />,
                order: 2,
                showInNavBar: true,
            },
            {
                key: "/lpmonitor/alarm",
                title: "Alarm",
                path: "alarm",
                component: <LPMonitorAlarm />,
                icon: <Link to="/lpmonitor/alarm" />,
                order: 3,
                showInNavBar: true,
            },
            {
                key: "/lpmonitor/group",
                title: "Group",
                path: "group",
                component: <MRGroupConfig />,
                icon: <Link to="/lpmonitor/group" />,
                order: 4,
                showInNavBar: true,
            },
        ],
    },
    {
        key: "/intradaymonitor",
        title: "Intraday Monitor",
        path: "/intradaymonitor",
        icon: <FundViewOutlined />,
        order: 10,
        showInNavBar: true,
        submenu: [
            {
                key: "/intradaymonitor/summary",
                title: "Summary",
                path: "summary",
                component: <IntradayMonitorSummary />,
                icon: <Link to="/intradaymonitor/summary" />,
                order: 1,
                showInNavBar: true,
                submenu: [
                    {
                        key: "/intradaymonitor/summary/view",
                        title: "Account 360",
                        path: "view",
                        component: <Account360Profile />,
                        referKey: AuthKeys.INTRADAY_SUMMARY,
                    },
                ],
            },
            {
                key: "/intradaymonitor/search",
                title: "Search",
                path: "search",
                component: <IntradayMonitorSearch />,
                icon: <Link to="/intradaymonitor/search" />,
                order: 2,
                showInNavBar: true,
                submenu: [
                    {
                        key: "/intradaymonitor/search/view",
                        title: "Account 360",
                        path: "view",
                        component: <Account360Profile />,
                        referKey: AuthKeys.INTRADAY_SEARCH_VIEW,
                    },
                ],
            },
            {
                key: "/intradaymonitor/alarm",
                title: "Alarm",
                path: "alarm",
                component: <IntradayMonitorAlarm />,
                icon: <Link to="/intradaymonitor/alarm" />,
                order: 3,
                showInNavBar: true,
                submenu: [
                    {
                        key: "/intradaymonitor/alarm/:severity",
                        title: "Alarm",
                        path: ":severity",
                        component: <IntradayMonitorAlarm />,
                        referKey: AuthKeys.INTRADAY_ALARM_VIEW,
                    },
                    {
                        key: "/intradaymonitor/alarm/:severity/:brand",
                        title: "Alarm",
                        path: ":severity/:brand",
                        component: <IntradayMonitorAlarm />,
                        referKey: AuthKeys.INTRADAY_ALARM_VIEW,
                    },
                    {
                        key: "/intradaymonitor/alarm/accountview",
                        title: "Account 360",
                        path: "accountview",
                        component: <Account360Profile />,
                        referKey: AuthKeys.INTRADAY_ALARM_VIEW,
                    },
                ],
            },
        ],
    },
    {
        key: "/tagging",
        title: "Tagging",
        path: "/tagging",
        icon: <TagsOutlined />,
        order: 11,
        showInNavBar: true,
        submenu: [
            {
                key: "/tagging/list",
                title: "List",
                path: "list",
                component: <TaggingListPage />,
                icon: <Link to="/tagging/list" />,
                order: 1,
                showInNavBar: true,
                submenu: [
                    {
                        key: "/tagging/list/view",
                        title: "Account 360",
                        path: "view",
                        icon: <Link to="/tagging/list/view" />,
                        component: <Account360Profile />,
                        referKey: AuthKeys.TAGGING_LIST_VIEW,
                    },
                ],
            },
        ],
    },
    {
        key: "/admintools",
        title: "Admin Tools",
        path: "/admintools",
        icon: <ToolOutlined />,
        order: 12,
        showInNavBar: true,
        submenu: [
            {
                key: "/admintools/ticket",
                title: "Ticket Restoration",
                path: "ticket",
                component: <TicketRestoration />,
                icon: <Link to="/admintools/ticket" />,
                order: 1,
                showInNavBar: true,
                submenu: [
                    {
                        key: "/admintools/ticket/calculate",
                        title: "Calculating Stop Out",
                        path: "calculate",
                        icon: <Link to="/admintools/ticket/calculate" />,
                        component: <CalculateStopOut />,
                        referKey: AuthKeys.ADMIN_TOOLS_TICKET_RESTORATION_EDIT,
                    },
                ],
            },
            {
                key: "/admintools/hs",
                title: "Hub Failover",
                path: "",
                component: <></>,
                order: 1,
                showInNavBar: true,
                type: "group",
                submenu: [
                    {
                        key: "/admintools/hubSwitcher",
                        title: "Hub Failover Switcher",
                        path: "hubSwitcher",
                        component: <HubSwitcher />,
                        icon: <Link to="/admintools/hubSwitcher" />,
                        order: 1,
                        showInNavBar: true,
                        submenu: [
                            {
                                key: "/admintools/hubSwitcher/preview",
                                title: "Preview",
                                path: "preview",
                                icon: <Link to="/admintools/hubSwitcher/preview" />,
                                component: <HubSwitcherPreview />,
                                referKey: AuthKeys.ADMIN_TOOLS_HUB_SWITCHER_EDIT,
                            },
                            {
                                key: "/admintools/hubSwitcher/preview/:id",
                                title: "Preview",
                                path: "preview/:id",
                                icon: <Link to="/admintools/hubSwitcher/preview/:id" />,
                                component: <HubSwitcherPreview />,
                                referKey: AuthKeys.ADMIN_TOOLS_HUB_SWITCHER_EDIT,
                            },
                        ],
                    },
                    {
                        key: "/admintools/hubStatusDashboard",
                        title: "Hub Status Dashboard",
                        path: "hubStatusDashboard",
                        component: <HubStatusDashboard />,
                        icon: <Link to="/admintools/hubStatusDashboard" />,
                        order: 2,
                        showInNavBar: true,
                        submenu: [],
                    },
                    {
                        key: "/admintools/hubConfig",
                        title: "Hub Configuration",
                        path: "hubConfig",
                        component: <HubConfigurationPage />,
                        icon: <Link to="/admintools/hubConfig" />,
                        order: 3,
                        showInNavBar: true,
                    },
                ],
            },
            {
                key: "/admintools/rs",
                title: "Risk Tools",
                path: "",
                component: <></>,
                order: 3,
                showInNavBar: true,
                type: "group",
                submenu: [
                    {
                        key: "/admintools/configdiff",
                        title: "Config Diff",
                        path: "configdiff",
                        component: <ConfigDiff />,
                        icon: <Link to="/admintools/configdiff" />,
                        order: 1,
                        showInNavBar: true,
                    },
                    {
                        key: "/admintools/configdiffrecord",
                        title: "Config Diff Record",
                        path: "configdiffrecord",
                        component: <ConfigDiffRecordPage />,
                        icon: <Link to="/admintools/configdiffrecord" />,
                        order: 2,
                        showInNavBar: true,
                    },
                    {
                        key: "/admintools/grouptransfertool",
                        title: "Group Transfer Tool",
                        path: "grouptransfertool",
                        component: <GroupTransferTool />,
                        icon: <Link to="/admintools/grouptransfertool" />,
                        order: 3,
                        showInNavBar: true,
                    },
                    {
                        key: "/admintools/hedgetool",
                        title: "Hedge Tool",
                        path: "hedgetool",
                        component: <HedgeToolsPage />,
                        icon: <Link to="/admintools/hedgetool" />,
                        order: 4,
                        showInNavBar: true,
                    },
                    {
                        key: "/admintools/orderRoutingRecord",
                        title: "Order Routing Record",
                        path: "orderRoutingRecord",
                        component: <OrderRoutingRecord />,
                        icon: <Link to="/admintools/orderRoutingRecord" />,
                        order: 5,
                        showInNavBar: true,
                    },
                    {
                        key: "/admintools/readOnly",
                        title: "Read Only",
                        path: "readOnly",
                        component: <ReadOnlyPage />,
                        icon: <Link to="/admintools/readOnly" />,
                        order: 6,
                        showInNavBar: true,
                    },
                    {
                        key: "/admintools/swapchargingtool",
                        title: "Swap Charging Tool",
                        path: "swapchargingtool",
                        component: <SwapChargingTool />,
                        icon: <Link to="/admintools/swapchargingtool" />,
                        order: 7,
                        showInNavBar: true,
                    },
                    {
                        key: "/admintools/swapchargingtoolprocess",
                        title: "Swap Charging Tool Process",
                        path: "swapchargingtoolprocess",
                        component: <SwapChargingToolProcess />,
                        icon: <Link to="/admintools/swapchargingtoolprocess" />,
                        order: 8,
                        showInNavBar: true,
                    },
                    {
                        key: "/admintools/mtClientSecurity",
                        title: "MT Client Security",
                        path: "mtClientSecurity",
                        component: <MTClientSecurity />,
                        icon: <Link to="/admintools/mtClientSecurity" />,
                        order: 9,
                        showInNavBar: true,
                    },
                    {
                        key: "/admintools/symbolswapupload",
                        title: "Symbol Swap Upload",
                        path: "symbolswapupload",
                        component: <SymbolSwapUpload />,
                        icon: <Link to="/admintools/symbolswapupload" />,
                        order: 9,
                        showInNavBar: true,
                    },
                    {
                        key: "/admintools/cadividendmapupload",
                        title: "CA Dividend Map Upload",
                        path: "cadividendmapupload",
                        component: <CADividendMapUpload />,
                        icon: <Link to="/admintools/cadividendmapupload" />,
                        order: 10,
                        showInNavBar: true,
                    },
                    {
                        key: "/admintools/cadividendseparateprocess",
                        title: "CA Dividend Separate Process",
                        path: "cadividendseparateprocess",
                        component: <CADividendSeparateProcess />,
                        icon: <Link to="/admintools/cadividendseparateprocess" />,
                        order: 11,
                        showInNavBar: true,
                    },
                    {
                        key: "/admintools/dividendrecord",
                        title: "Dividend Record",
                        path: "dividendrecord",
                        component: <DividendRecord />,
                        icon: <Link to="/admintools/dividendrecord" />,
                        order: 13,
                        showInNavBar: true,
                    },
                    {
                        key: "/admintools/dividendupload",
                        title: "Dividend Upload",
                        path: "dividendupload",
                        component: <DividendUpload />,
                        icon: <Link to="/admintools/dividendupload" />,
                        order: 14,
                        showInNavBar: true,
                    },
                    {
                        key: "/admintools/weekendswapsrecord",
                        title: "CA Dividend Separate Process",
                        path: "weekendswapsrecord",
                        component: <WeekendSwapsRecord />,
                        icon: <Link to="/admintools/weekendswapsrecord" />,
                        order: 12,
                        showInNavBar: true,
                    },
                    {
                        key: "/admintools/credittobalanceupload",
                        title: "Credit To Balance Upload",
                        path: "credittobalanceupload",
                        component: <CreditToBalanceUpload />,
                        icon: <Link to="/admintools/credittobalanceupload" />,
                        order: 15,
                        showInNavBar: true,
                    },
                    {
                        key: "/admintools/symbolleverage",
                        title: "Symbol Leverage Tool",
                        path: "symbolLeverage",
                        component: <SymbolLeverageTool />,
                        icon: <Link to="/admintools/symbolleverage" />,
                        order: 16,
                        showInNavBar: true,
                        submenu: [
                            {
                                key: "/admintools/symbolleverage/edit/:id",
                                title: "Edit Batch",
                                path: "edit/:id",
                                icon: <Link to="/admintools/symbolleverage/edit/:id" />,
                                component: <SymbolLeverageStep />,
                                referKey: AuthKeys.RISK_TOOLS_SYMBOL_LEVERAGE_EDIT,
                            },
                        ],
                    },
                    {
                        key: "/admintools/rollover",
                        title: "Roll Over Tool",
                        path: "rollover",
                        component: <RollOverTool />,
                        icon: <Link to="/admintools/rollover" />,
                        order: 17,
                        showInNavBar: true,
                        submenu: [
                            {
                                key: "/admintools/rollover/edit/:id",
                                title: "Edit Batch",
                                path: "edit/:id",
                                icon: <Link to="/admintools/rollover/edit/:id" />,
                                component: <RollOverToolStep />,
                                referKey: AuthKeys.RISK_TOOLS_ROLLOVER_EDIT,
                            },
                        ],
                    },
                    {
                        key: "/admintools/balanceupdate",
                        title: "Balance Update",
                        path: "balanceupdate",
                        component: <BalanceUpdatePage />,
                        icon: <Link to="/admintools/balanceupdate" />,
                        order: 18,
                        showInNavBar: true,
                    },
                ],
            },
        ],
    },
    {
        key: "/siteadmin",
        title: "Settings",
        path: "/siteadmin",
        icon: <SettingOutlined />,
        order: 13,
        showInNavBar: true,
        submenu: [
            {
                key: "/siteadmin/user",
                title: "Users Management",
                path: "user",
                icon: <Link to="/siteadmin/user" />,
                component: <UserListPage />,
                order: 2,
                showInNavBar: true,
                submenu: [
                    {
                        key: "/siteadmin/user/create",
                        title: "Create New User",
                        path: "create",
                        icon: <Link to="/siteadmin/user/create" />,
                        component: <UserCreatePage />,
                        referKey: AuthKeys.ADMIN_USER_EDIT,
                    },
                    {
                        key: "/siteadmin/user/edit",
                        title: "Edit User",
                        path: "edit",
                        icon: <Link to="/siteadmin/user/edit" />,
                        component: <UserEditPage />,
                        referKey: AuthKeys.ADMIN_USER_EDIT,
                    },
                ],
            },
            {
                key: "/siteadmin/menus",
                title: "Menu Mangement",
                path: "menus",
                component: <MenuMangement />,
                icon: <Link to="/siteadmin/menus" />,
                order: 3,
                showInNavBar: true,
                submenu: [
                    // {
                    //     key: "/siteadmin/rolepermission/view/:id",
                    //     title: "View Role's Permission",
                    //     path: "view/:id",
                    //     component: <ViewRolePage />,
                    //     icon: <Link to="/siteadmin/rolepermission/view/:id" />,
                    //     referKey: AuthKeys.ADMIN_ROLE_PERMISSION_VIEW,
                    // },
                    // {
                    //     key: "/siteadmin/rolepermission/create",
                    //     title: "Create New Role",
                    //     path: "create",
                    //     component: <RoleCreateEditPage />,
                    //     icon: <Link to="/siteadmin/rolepermission/create" />,
                    //     referKey: AuthKeys.ADMIN_ROLE_PERMISSION_EDIT,
                    // },
                    // {
                    //     key: "/siteadmin/rolepermission/edit",
                    //     title: "Edit Role",
                    //     path: "edit",
                    //     component: <RoleCreateEditPage />,
                    //     icon: <Link to="/siteadmin/rolepermission/edit" />,
                    //     referKey: AuthKeys.ADMIN_ROLE_PERMISSION_EDIT,
                    // },
                ],
            },
            {
                key: "/siteadmin/rolepermission",
                title: "Role & Permission",
                path: "rolepermission",
                component: <RolePermissionPage />,
                icon: <Link to="/siteadmin/rolepermission" />,
                order: 4,
                showInNavBar: true,
                submenu: [
                    {
                        key: "/siteadmin/rolepermission/view/:id",
                        title: "View Role's Permission",
                        path: "view/:id",
                        component: <ViewRolePage />,
                        icon: <Link to="/siteadmin/rolepermission/view/:id" />,
                        referKey: AuthKeys.ADMIN_ROLE_PERMISSION_VIEW,
                    },
                    {
                        key: "/siteadmin/rolepermission/create",
                        title: "Create New Role",
                        path: "create",
                        component: <RoleCreateEditPage />,
                        icon: <Link to="/siteadmin/rolepermission/create" />,
                        referKey: AuthKeys.ADMIN_ROLE_PERMISSION_EDIT,
                    },
                    {
                        key: "/siteadmin/rolepermission/edit",
                        title: "Edit Role",
                        path: "edit",
                        component: <RoleCreateEditPage />,
                        icon: <Link to="/siteadmin/rolepermission/edit" />,
                        referKey: AuthKeys.ADMIN_ROLE_PERMISSION_EDIT,
                    },
                ],
            },
            {
                key: "/siteadmin/schedulers",
                path: "schedulers",
                title: "Job Schedulers",
                component: <JobSchedulers />,
                icon: <Link to="/siteadmin/schedulers" />,
                order: 5,
                showInNavBar: true,
            },
            {
                key: "/siteadmin/tag",
                title: "Tag Management",
                path: "tag",
                component: <TagManagementPage />,
                icon: <Link to="/siteadmin/tag" />,
                order: 6,
                showInNavBar: true,
                submenu: [
                    {
                        key: "/siteadmin/tag/create",
                        title: "Create New Tag",
                        path: "create",
                        icon: <Link to="/siteadmin/tag/create" />,
                        component: <TagCreateEditPage />,
                        referKey: AuthKeys.ADMIN_TAG_EDIT,
                    },
                    {
                        key: "/siteadmin/tag/edit",
                        title: "Edit Tag",
                        path: "edit",
                        icon: <Link to="/siteadmin/tag/edit" />,
                        component: <TagCreateEditPage />,
                        referKey: AuthKeys.ADMIN_TAG_EDIT,
                    },
                    {
                        key: "/siteadmin/tag/view/:id",
                        title: "View Tag Logs",
                        path: "view/:id",
                        icon: <Link to="/siteadmin/tag/view/:id" />,
                        component: <TagViewLogsPage />,
                        referKey: AuthKeys.ADMIN_TAG_VIEW,
                    },
                ],
            },
            {
                key: "/siteadmin/appconfig",
                title: "App Configuration",
                path: "appconfig",
                component: <AppConfiguration />,
                order: 7,
                icon: <Link to="/siteadmin/appconfig" />,
                showInNavBar: true,
                submenu: [
                    {
                        key: "/siteadmin/appconfig/metricmapping",
                        title: "Metric Mapping",
                        path: "metricmapping",
                        component: <MetricConfiguration />,
                        referKey: AuthKeys.APP_CONFIGURATION_EDIT,
                        submenu: [
                            {
                                key: "/siteadmin/appconfig/metricmapping/edit",
                                title: "Edit Metric Configuration",
                                path: "edit",
                                icon: <Link to="/siteadmin/appconfig/metricmapping/edit" />,
                                component: <CreateEditMetricConfiguration />,
                                referKey: AuthKeys.APP_CONFIGURATION_EDIT,
                            },
                        ],
                    },
                    {
                        key: "/siteadmin/appconfig/metriccategory",
                        title: "Metric Category",
                        path: "metriccategory",
                        component: <MetricGroup />,
                        referKey: AuthKeys.APP_CONFIGURATION_EDIT,
                        submenu: [
                            {
                                key: "/siteadmin/appconfig/metriccategory/create",
                                title: "Create Metric Category",
                                path: "create",
                                icon: <Link to="/siteadmin/appconfig/metriccategory/create" />,
                                component: <CreateEditMetricGroupPage />,
                                referKey: AuthKeys.APP_CONFIGURATION_EDIT,
                            },
                            {
                                key: "/siteadmin/appconfig/metriccategory/edit",
                                title: "Edit Metric Category",
                                path: "edit",
                                icon: <Link to="/siteadmin/appconfig/metriccategory/edit" />,
                                component: <CreateEditMetricGroupPage />,
                                referKey: AuthKeys.APP_CONFIGURATION_EDIT,
                            },
                        ],
                    },
                    {
                        key: "/siteadmin/appconfig/metricglossary",
                        title: "Metric Glossary",
                        path: "metricglossary",
                        component: <MetricGlossary />,
                        referKey: AuthKeys.APP_CONFIGURATION_EDIT,
                        submenu: [
                            {
                                key: "/siteadmin/appconfig/metricglossary/edit",
                                title: "Update Metric Information",
                                path: "edit",
                                icon: <Link to="/siteadmin/appconfig/metricglossary/edit" />,
                                component: <MetricGlossaryEditPage />,
                                referKey: AuthKeys.APP_CONFIGURATION_EDIT,
                            },
                        ],
                    },
                    {
                        key: "/siteadmin/appconfig/accSumMetricConfig",
                        title: "Account Summary Metric Configuration",
                        path: "accSumMetricConfig",
                        component: <AccSumMetricConfig />,
                        referKey: AuthKeys.APP_CONFIGURATION_EDIT,
                        submenu: [
                            {
                                key: "/siteadmin/appconfig/accSumMetricConfig/edit",
                                title: "Update Account Summary Metric Configuration",
                                path: "edit",
                                icon: <Link to="/siteadmin/appconfig/accSumMetricConfig/edit" />,
                                component: <AccSumMetricConfigEditPage />,
                                referKey: AuthKeys.APP_CONFIGURATION_EDIT,
                            },
                        ],
                    },
                    {
                        key: "/siteadmin/appconfig/accSymbolConfig",
                        title: "Account Symbol Metric Configuration",
                        path: "accSymbolConfig",
                        component: <AccSymbolConfig />,
                        referKey: AuthKeys.APP_CONFIGURATION_EDIT,
                        submenu: [
                            {
                                key: "/siteadmin/appconfig/accSymbolConfig/edit",
                                title: "Update Account Symbol Metric Configuration",
                                path: "edit",
                                icon: <Link to="/siteadmin/appconfig/accSymbolConfig/edit" />,
                                component: <AccSumSymbolConfigEditPage />,
                                referKey: AuthKeys.APP_CONFIGURATION_EDIT,
                            },
                        ],
                    },
                    {
                        key: "/siteadmin/appconfig/accWatchListGroup",
                        title: "Account Watch List Group",
                        path: "accWatchListGroup",
                        component: <AccWatchListGroup />,
                        referKey: AuthKeys.APP_CONFIGURATION_EDIT,
                        submenu: [
                            {
                                key: "/siteadmin/appconfig/accWatchListGroup/create",
                                title: "Create Account Watch List Group",
                                path: "create",
                                icon: <Link to="/siteadmin/appconfig/accWatchListGroup/create" />,
                                component: <CreateEditAccWatchListGroup />,
                                referKey: AuthKeys.APP_CONFIGURATION_EDIT,
                            },
                            {
                                key: "/siteadmin/appconfig/accWatchListGroup/edit",
                                title: "Edit Account Watch List Group",
                                path: "edit",
                                icon: <Link to="/siteadmin/appconfig/accWatchListGroup/edit" />,
                                component: <CreateEditAccWatchListGroup />,
                                referKey: AuthKeys.APP_CONFIGURATION_EDIT,
                            },
                        ],
                    },
                    {
                        key: "/siteadmin/appconfig/usermanualconfig",
                        title: "User Manual Configuration",
                        path: "usermanualconfig",
                        component: <UserManualListing setOpen={() => {}} />,
                        referKey: AuthKeys.APP_CONFIGURATION_EDIT,
                        // submenu: [
                        //     {
                        //         key: "/siteadmin/appconfig/usermanualconfig/edit/:id",
                        //         title: "Update User Manual Configuration",
                        //         path: "edit/:id",
                        //         icon: <Link to="/siteadmin/appconfig/usermanualconfig/edit/:id" />,
                        //         component: <EditUserManual />,
                        //         referKey: AuthKeys.APP_CONFIGURATION_EDIT,
                        //     },
                        //     {
                        //         key: "/siteadmin/appconfig/usermanualconfig/view/:id",
                        //         title: "View User Manual",
                        //         path: "view/:id",
                        //         icon: <Link to="/siteadmin/appconfig/usermanualconfig/view/:id" />,
                        //         component: <ViewUserManual />,
                        //         referKey: AuthKeys.APP_CONFIGURATION_VIEW,
                        //     },
                        // ],
                    },
                    {
                        key: "/siteadmin/appconfig/notificationchannelconfig",
                        title: "Notification Channel Management",
                        path: "notificationchannelconfig",
                        component: <NotificationChannelManagement />,
                        referKey: AuthKeys.APP_CONFIGURATION_EDIT,
                        submenu: [
                            {
                                key: "/siteadmin/appconfig/notificationchannelconfig/create",
                                title: "Create Notification Channel",
                                path: "create",
                                icon: <Link to="/siteadmin/appconfig/notificationchannelconfig/create" />,
                                component: <CreateEditNotificationChannel />,
                                referKey: AuthKeys.APP_CONFIGURATION_EDIT,
                            },
                            {
                                key: "/siteadmin/appconfig/notificationchannelconfig/edit",
                                title: "Edit Notification Channel",
                                path: "edit",
                                icon: <Link to="/siteadmin/appconfig/notificationchannelconfig/edit" />,
                                component: <CreateEditNotificationChannel />,
                                referKey: AuthKeys.APP_CONFIGURATION_EDIT,
                            },
                        ],
                    },
                    // {
                    //     key: "/siteadmin/appconfig/mtserverhubconfig",
                    //     title: "MT Server & Hub configuration",
                    //     path: "mtserverhubconfig",
                    //     component: <MTServerHubConfiguration searchText={""} />,
                    //     referKey: AuthKeys.APP_CONFIGURATION_EDIT,
                    // },
                ],
            },
            {
                key: "/siteadmin/sc",
                title: "Symbol Configuration",
                path: "",
                component: <></>,
                order: 8,
                showInNavBar: true,
                type: "group",
                submenu: [
                    {
                        key: "/siteadmin/symbolconfig/assettype",
                        path: "symbolconfig/assettype",
                        title: "Symbol Asset Type",
                        component: <SymbolAssetTypeList />,
                        order: 1,
                        icon: <Link to="/siteadmin/symbolconfig/assettype" />,
                        showInNavBar: true,
                        submenu: [
                            {
                                key: "/siteadmin/symbolconfig/assettype/create",
                                title: "Create New Symbol Asset Type",
                                path: "create",
                                component: <SymbolAssetTypeCreateEditPage />,
                                referKey: AuthKeys.SYMBOL_ASSET_TYPE_EDIT,
                                icon: <Link to="/siteadmin/symbolconfig/assettype/create" />,
                            },
                            {
                                key: "/siteadmin/symbolconfig/assettype/edit/:id",
                                title: "Edit Symbol Asset Type",
                                path: "edit/:id",
                                component: <SymbolAssetTypeCreateEditPage />,
                                referKey: AuthKeys.SYMBOL_ASSET_TYPE_EDIT,
                                icon: <Link to="/siteadmin/symbolconfig/assettype/edit/:id" />,
                            },
                        ],
                    },
                    {
                        key: "/siteadmin/symbolconfig/cleansymbol",
                        path: "symbolconfig/cleansymbol",
                        title: "Clean Symbol",
                        component: <CleanSymbolList />,
                        order: 2,
                        icon: <Link to="/siteadmin/symbolconfig/assettype" />,
                        showInNavBar: true,
                        submenu: [
                            {
                                key: "/siteadmin/symbolconfig/cleansymbol/create",
                                title: "Create New Clean Symbol",
                                path: "create",
                                component: <CleanSymbolCreateEditPage />,
                                referKey: AuthKeys.CLEAN_SYMBOL_EDIT,
                                icon: <Link to="/siteadmin/symbolconfig/cleansymbol/create" />,
                            },
                            {
                                key: "/siteadmin/symbolconfig/cleansymbol/edit/:id",
                                title: "Edit Clean Symbol",
                                path: "edit/:id",
                                component: <CleanSymbolCreateEditPage />,
                                referKey: AuthKeys.CLEAN_SYMBOL_EDIT,
                                icon: <Link to="/siteadmin/symbolconfig/cleansymbol/edit/:id" />,
                            },
                        ],
                    },
                    {
                        key: "/siteadmin/symbolconfig/symbolmap",
                        path: "symbolconfig/symbolmap",
                        title: "Symbol (MT)",
                        component: <SymbolMapping />,
                        order: 3,
                        icon: <Link to="/siteadmin/symbolconfig/symbolmap" />,
                        showInNavBar: true,
                        submenu: [
                            {
                                key: "/siteadmin/symbolconfig/symbolmap/create",
                                title: "Create New Symbol",
                                path: "create",
                                component: <SymbolMapCreateEditPage />,
                                referKey: AuthKeys.SYMBOL_MAP_EDIT,
                                icon: <Link to="/siteadmin/symbolconfig/symbolmap/create" />,
                            },
                            {
                                key: "/siteadmin/symbolconfig/symbolmap/edit/:id",
                                title: "Edit Symbol",
                                path: "edit/:id",
                                component: <SymbolMapCreateEditPage />,
                                referKey: AuthKeys.SYMBOL_MAP_EDIT,
                                icon: <Link to="/siteadmin/symbolconfig/symbolmap/edit/:id" />,
                            },
                        ],
                    },
                    {
                        key: "/siteadmin/symbolconfig/symbolinfo",
                        path: "symbolconfig/symbolinfo",
                        title: "Symbol",
                        component: <SymbolInfo />,
                        order: 4,
                        icon: <Link to="/siteadmin/symbolconfig/symbolinfo" />,
                        showInNavBar: true,
                    },
                ],
            },
            {
                key: "/siteadmin/rs",
                title: "Risk Score Management",
                path: "",
                component: <></>,
                order: 9,
                showInNavBar: true,
                type: "group",
                submenu: [
                    {
                        key: "/siteadmin/riskscore/account",
                        path: "riskscore/account",
                        title: "Configuration List",
                        component: <RiskScoreList />,
                        order: 1,
                        icon: <Link to="/siteadmin/riskscore/account" />,
                        showInNavBar: true,
                        submenu: [
                            {
                                key: "/siteadmin/riskscore/account/view/:id",
                                title: "View Account Risk Score",
                                path: "view/:id",
                                icon: <Link to="/siteadmin/riskscore/account/view/:id" />,
                                component: <ViewRiskScorePage />,
                                referKey: AuthKeys.ADMIN_RISKSCORE_ACCOUNT_VIEW,
                            },
                            {
                                key: "/siteadmin/riskscore/account/create",
                                title: "Create New Account Risk Score",
                                path: "create",
                                icon: <Link to="/siteadmin/riskscore/account/create" />,
                                component: <RiskScoreCreateTemplate />,
                                referKey: AuthKeys.ADMIN_RISKSCORE_ACCOUNT_EDIT,
                            },
                            {
                                key: "/siteadmin/riskscore/account/edit",
                                title: "Edit Account Risk Score",
                                path: "edit",
                                icon: <Link to="/siteadmin/riskscore/account/edit" />,
                                component: <RiskScoreCreateTemplate />,
                                referKey: AuthKeys.ADMIN_RISKSCORE_ACCOUNT_EDIT,
                            },
                        ],
                    },
                    {
                        key: "/siteadmin/riskscore/brand",
                        path: "riskscore/brand",
                        title: "Metrics Brand Value",
                        component: <RiskScoreBrandList />,
                        icon: <Link to="/siteadmin/riskscore/brand" />,
                        order: 2,
                        showInNavBar: true,
                        submenu: [
                            {
                                key: "/siteadmin/riskscore/brand/view/:id",
                                title: "View Brand Risk Score",
                                path: "view/:id",
                                icon: <Link to="/siteadmin/riskscore/brand/view/:id" />,
                                component: <ViewBrandRiskScore />,
                                referKey: AuthKeys.ADMIN_RISKSCORE_BRAND_VIEW,
                            },
                            {
                                key: "/siteadmin/riskscore/brand/create",
                                title: "Create New Brand Risk Score",
                                path: "create",
                                icon: <Link to="/siteadmin/riskscore/brand/create" />,
                                component: <BrandRiskScoreCreateTemplate />,
                                referKey: AuthKeys.ADMIN_RISKSCORE_BRAND_EDIT,
                            },
                            {
                                key: "/siteadmin/riskscore/edit",
                                title: "Edit Brand Risk Score",
                                path: "edit",
                                icon: <Link to="/siteadmin/riskscore/edit" />,
                                component: <BrandRiskScoreCreateTemplate />,
                                referKey: AuthKeys.ADMIN_RISKSCORE_BRAND_EDIT,
                            },
                        ],
                    },
                ],
            },
            {
                key: "/siteadmin/dw",
                title: "Account Brand Management",
                path: "",
                component: <></>,
                order: 10,
                showInNavBar: true,
                type: "group",
                submenu: [
                    {
                        key: "/siteadmin/serverbrand/server",
                        title: "Server",
                        path: "serverbrand/server",
                        icon: <Link to="/siteadmin/serverbrand/server" />,
                        component: <ServerListPage />,
                        order: 2,
                        showInNavBar: true,
                        submenu: [
                            {
                                key: "/siteadmin/serverbrand/server/create",
                                title: "Create New Server",
                                path: "create",
                                component: <ServerCreateEditPage />,
                                referKey: AuthKeys.ADMIN_SERVER_EDIT,
                                icon: <Link to="/siteadmin/serverbrand/server/create" />,
                            },
                            {
                                key: "/siteadmin/serverbrand/server/edit/:id",
                                title: "Edit Server",
                                path: "edit/:id",
                                component: <ServerCreateEditPage />,
                                referKey: AuthKeys.ADMIN_SERVER_EDIT,
                                icon: <Link to="/siteadmin/serverbrand/server/edit/:id" />,
                            },
                        ],
                    },
                    {
                        key: "/siteadmin/serverbrand/brand",
                        title: "Brand",
                        path: "serverbrand/brand",
                        component: <BrandListPage />,
                        icon: <Link to="/siteadmin/serverbrand/brand" />,
                        order: 3,
                        showInNavBar: true,
                        submenu: [
                            {
                                key: "/siteadmin/serverbrand/brand/create",
                                title: "Create New Brand",
                                path: "create",
                                icon: <Link to="/siteadmin/serverbrand/brand/create" />,
                                component: <BrandCreateEditPage />,
                                referKey: AuthKeys.ADMIN_BRAND_EDIT,
                            },
                            {
                                key: "/siteadmin/serverbrand/brand/edit/:id",
                                title: "Edit Brand",
                                path: "edit/:id",
                                icon: <Link to="/siteadmin/serverbrand/brand/edit/:id" />,
                                component: <BrandCreateEditPage />,
                                referKey: AuthKeys.ADMIN_BRAND_EDIT,
                            },
                        ],
                    },
                    {
                        key: "/siteadmin/serverbrand/group-purpose",
                        title: "Group Purpose",
                        path: "serverbrand/group-purpose",
                        component: <GroupPurposeListPage />,
                        order: 4,
                        icon: <Link to="/siteadmin/serverbrand/group-purpose" />,
                        showInNavBar: true,
                        submenu: [
                            {
                                key: "/siteadmin/serverbrand/group-purpose/create",
                                title: "Create New Group Purpose",
                                path: "create",
                                component: <GroupPurposeCreateEditPage />,
                                referKey: AuthKeys.ADMIN_GROUP_PURPOSE_EDIT,
                                icon: <Link to="/siteadmin/serverbrand/group-purpose/create" />,
                            },
                            {
                                key: "/siteadmin/serverbrand/group-purpose/edit/:id",
                                title: "Edit Group Purpose",
                                path: "edit/:id",
                                component: <GroupPurposeCreateEditPage />,
                                referKey: AuthKeys.ADMIN_GROUP_PURPOSE_EDIT,
                                icon: <Link to="/siteadmin/serverbrand/group-purpose/edit/:id" />,
                            },
                        ],
                    },
                    {
                        key: "/siteadmin/serverbrand/server-brand-mapping",
                        title: "Server Brand Map",
                        path: "serverbrand/server-brand-mapping",
                        component: <SBMListPage />,
                        order: 5,
                        icon: <Link to="/siteadmin/serverbrand/server-brand-mapping" />,
                        showInNavBar: true,
                        submenu: [
                            {
                                key: "/siteadmin/serverbrand/server-brand-mapping/create",
                                title: "Create New Server Brand Mapping",
                                path: "create",
                                icon: <Link to="/siteadmin/serverbrand/server-brand-mapping/create" />,
                                component: <SBMCreateEditPage />,
                                referKey: AuthKeys.ADMIN_SERVER_BRAND_MAPPING_EDIT,
                            },
                            {
                                key: "/siteadmin/serverbrand/server-brand-mapping/edit/:id",
                                title: "Edit New Server Brand Mapping",
                                path: "edit/:id",
                                icon: <Link to="/siteadmin/serverbrand/server-brand-mapping/edit/:id" />,
                                component: <SBMCreateEditPage />,
                                referKey: AuthKeys.ADMIN_SERVER_BRAND_MAPPING_EDIT,
                            },
                        ],
                    },
                ],
            },
            {
                key: "/siteadmin/bbm",
                title: "System Management",
                path: "",
                component: <></>,
                order: 11,
                showInNavBar: true,
                type: "group",
                submenu: [
                    {
                        key: "/siteadmin/brandserver/brand",
                        title: "Brand",
                        path: "brandserver/brand",
                        icon: <Link to="/siteadmin/brandserver/brand" />,
                        component: <BrandListingPage />,
                        order: 1,
                        showInNavBar: true,
                        submenu: [
                            {
                                key: "/siteadmin/brandserver/brand/create",
                                title: "Create New Brand",
                                path: "create",
                                component: <BrandCreateEditPageV2 />,
                                referKey: AuthKeys.ADMIN_BRANDSERVER_BRAND_EDIT,
                                icon: <Link to="/siteadmin/brandserver/brand/create" />,
                            },
                            {
                                key: "/siteadmin/brandserver/brand/edit/:id",
                                title: "Edit Brand",
                                path: "edit/:id",
                                component: <BrandCreateEditPageV2 />,
                                referKey: AuthKeys.ADMIN_BRANDSERVER_BRAND_EDIT,
                                icon: <Link to="/siteadmin/brandserver/brand/edit/:id" />,
                            },
                        ],
                    },
                    {
                        key: "/siteadmin/brandserver/bridge",
                        title: "Bridge",
                        path: "brandserver/bridge",
                        icon: <Link to="/siteadmin/brandserver/bridge" />,
                        component: <BridgeSettingPageV2 />,
                        order: 2,
                        showInNavBar: true,
                        submenu: [
                            {
                                key: "/siteadmin/brandserver/bridge/create",
                                title: "Create New Bridge",
                                path: "create",
                                component: <BridgeCreateEditPageV2 />,
                                referKey: AuthKeys.ADMIN_SERVER_EDIT,
                                icon: <Link to="/siteadmin/brandserver/bridge/create" />,
                            },
                            {
                                key: "/siteadmin/brandserver/bridge/edit/:id",
                                title: "Edit Bridge",
                                path: "edit/:id",
                                component: <BridgeCreateEditPageV2 />,
                                referKey: AuthKeys.ADMIN_SERVER_EDIT,
                                icon: <Link to="/siteadmin/brandserver/bridge/edit/:id" />,
                            },
                        ],
                    },
                    {
                        key: "/siteadmin/brandserver/server",
                        title: "Server",
                        path: "brandserver/server",
                        icon: <Link to="/siteadmin/brandserver/server" />,
                        component: <ServerPageV2 />,
                        order: 3,
                        showInNavBar: true,
                        submenu: [
                            {
                                key: "/siteadmin/brandserver/server/create",
                                title: "Create New Server",
                                path: "create",
                                component: <ServerV2CreateEditPage />,
                                referKey: AuthKeys.ADMIN_SERVER_V2_EDIT,
                                icon: <Link to="/siteadmin/brandserver/server/create" />,
                            },
                            {
                                key: "/siteadmin/brandserver/server/edit/:id",
                                title: "Edit Server",
                                path: "edit/:id",
                                component: <ServerV2CreateEditPage />,
                                referKey: AuthKeys.ADMIN_SERVER_V2_EDIT,
                                icon: <Link to="/siteadmin/brandserver/server/edit/:id" />,
                            },
                        ],
                    },
                    {
                        key: "/siteadmin/brandserver/externalsystem",
                        title: "External System",
                        path: "brandserver/externalsystem",
                        icon: <Link to="/siteadmin/brandserver/externalsystem" />,
                        component: <ExternalSystemPage />,
                        order: 4,
                        showInNavBar: true,
                        submenu: [
                            {
                                key: "/siteadmin/brandserver/externalsystem/create",
                                title: "Create New External System",
                                path: "create",
                                component: <ExternalSystemCreateEditPage />,
                                referKey: AuthKeys.ADMIN_EXTERNAL_SYSTEM_V2_EDIT,
                                icon: <Link to="/siteadmin/brandserver/externalsystem/create" />,
                            },
                            {
                                key: "/siteadmin/brandserver/externalsystem/edit/:id",
                                title: "Edit External System",
                                path: "edit/:id",
                                component: <ExternalSystemCreateEditPage />,
                                referKey: AuthKeys.ADMIN_EXTERNAL_SYSTEM_V2_EDIT,
                                icon: <Link to="/siteadmin/brandserver/externalsystem/edit/:id" />,
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        key: "/myaccount",
        title: "My Account",
        path: "/myaccount",
        component: <MyAccountPage />,
        icon: <Link to="/myaccount" />,
        submenu: [
            {
                key: "/myaccount/edit",
                title: "Edit Account",
                path: "edit",
                component: <MyAccountEditPage />,
                icon: <Link to="/myaccount/edit" />,
            },
        ],
    },
    {
        key: "/notifications",
        title: "Notification",
        path: "/notifications",
        component: <NotificationPage />,
        icon: <Link to="/notifications" />,
        submenu: [
            {
                key: "/myaccount/edit",
                title: "Edit Account",
                path: "edit",
                component: <MyAccountEditPage />,
                icon: <Link to="/myaccount/edit" />,
            },
        ],
    },
    {
        key: "/activitylogs",
        title: "Activity Logs",
        path: "/activitylogs",
        component: <ActivityLogsPage />,
        icon: <Link to="/activitylogs" />,
    },
    {
        key: "all",
        title: "Page Not Found",
        path: "*",
        component: <NotFoundPage />,
    },
];

export const public_route: routeProps[] = [
    {
        key: "/login",
        title: "Login",
        path: "/login",
        component: <Login />,
        icon: <Link to="/login" />,
    },
    {
        key: "/development",
        title: "Development",
        path: "/development",
        component: <Development />,
        icon: <Link to="/development" />,
    },
    {
        key: "/ssoredirection",
        title: "SSO Redirection",
        path: "/ssoredirection",
        component: <SSORedirection />,
    },
    {
        key: "/forbidden",
        title: "Forbidden",
        path: "/forbidden",
        component: <ForbiddenPage />,
    },
    {
        key: "/resetpassword",
        title: "Reset Password",
        path: "/resetpassword",
        component: <ResetPasswordPage />,
        icon: <Link to="/resetpassword" />,
    },
];

export const AppRoute = () => {
    const isLoggedIn = userInfo();
    const routeHelper = new RouteHelper(auth_route);

    return (
        <Routes>
            {isLoggedIn ? (
                <>
                    <Route path={"/"} element={<AuthLayout />}>
                        <Route index element={<DashboardPage />} />
                        {routeHelper.getRoutes()}
                        <Route key={"/system"} path={"/system"} element={<WrapperLayout />}>
                            <Route key={"/system/usermanual"} path={"usermanual"} element={<UserManualView setOpen={() => {}} />} />
                        </Route>
                    </Route>
                    <Route path={"/export"} element={<WrapperLayout />}>
                        <Route key={"/export/accountprofile/:id"} path={"accountprofile/:id"} element={<AccountProfileExportToPDF />} />
                    </Route>
                </>
            ) : (
                <Route key="*" path="*" element={<Login />} />
            )}
            {public_route.map(x => {
                return <Route key={x.key} path={x.path} element={x.component} />;
            })}
        </Routes>
    );
};

export default AppRoute;

import { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import CardBox from "../../../components/Common/CardBox";
import FlexiDataTable from "../../../components/FlexiDataTable";
import { CALLBACK_KEY, ComponentType } from "../../../constants";
import {
    FlexiDataColumnProps,
    CustomPaginationProps,
    FlexiDataTableOptionsProps,
    FlexiDataTableCallbackProps,
    Client,
    RegulatorList,
    CrmServerList,
} from "../../../constants/type";
import { apiRequest } from "../../../services/apiConfig";
import { APIs } from "../../../services/apis";
import { DTColProps, ErrorCatchValidator } from "../../../utils/Common";

interface BatchClientListingProps {}

const BatchClientListing = (props: BatchClientListingProps) => {
    let navigate = useNavigate();
    let location = useLocation();

    const getColumnsConfig = (regulators: RegulatorList[] = [], crmServers: CrmServerList[] = []) => {
        return [
            DTColProps.Middle({
                title: "Client ID",
                dataIndex: "clientId",
                key: "clientId",
                options: {
                    filter: {
                        type: ComponentType.text,
                    },
                },
            }),
            DTColProps.Middle({
                title: "Source",
                dataIndex: "crmServer",
                key: "crmServer",
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: crmServers.map((x) => ({ text: x.server, value: x.id })),
                    },
                },
            }),
            DTColProps.XXLarge({
                title: "Regulator",
                dataIndex: "regulatorName",
                key: "regulatorName",
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: regulators.map((x) => ({ text: x.displayName, value: x.id })),
                    },
                },
            }),
            {
                title: "Name",
                dataIndex: "name",
                key: "name",
                options: {
                    filter: {
                        type: ComponentType.text,
                    },
                },
            },
        ];
    };

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(false);
    const [tableCols, setTableCols] = useState<FlexiDataColumnProps[]>([...getColumnsConfig()]);
    const [data, setData] = useState<Client[]>([]);
    const [pagination, setPagination] = useState<CustomPaginationProps>({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const [filterParams, setFilterParams] = useState<any>({});
    const [sorting, setSorting] = useState<string>("");

    const options: FlexiDataTableOptionsProps = {
        view: (record: any, option: any) => {
            let newProps = { ...option };
            newProps = <Link to={`/analysis/clientlist/launch?clientId=${record["clientId"]}&regulatorId=${record["regulatorId"]}&crmServerId=${record["crmServerId"]}`}>{newProps}</Link>;
            return newProps;
        },
        serverFiltering: true,
    };

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.VIEW_RECORD:
                navigate(`/analysis/clientlist/launch?clientId=${FormData["clientId"]}&regulatorId=${FormData["regulatorId"]}&crmServerId=${FormData["crmServerId"]}`);
                window.scrollTo(0, 0);
                break;
            case CALLBACK_KEY.HANDLE_PAGINATION_SORTING:
                setSorting(
                    FormData.sorter.column && FormData.sorter.order
                        ? `${FormData.sorter.column.key},${FormData.sorter.order === "ascend" ? "asc" : "desc"}`
                        : ""
                );
                setPagination((prev) => ({ ...prev, current: FormData.pagination.current, pageSize: FormData.pagination.pageSize }));
                setRunRefetchDataList(true);
                break;
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                let filterParams: any = {};
                Object.keys(FormData)
                    .filter((x) => FormData[x] && FormData[x].toString().length > 0)
                    .map((x) => {
                        if (x === "regulatorName") {
                            filterParams["regulatorId"] = FormData[x];
                        } else if (x === "crmServer") {
                            filterParams["crmServerId"] = FormData[x];
                        } else {
                            filterParams[x] = FormData[x];
                        }
                        return x;
                    });
                setFilterParams(filterParams);
                setPagination((prev) => ({ ...prev, current: 1 }));
                setRunRefetchDataList(true);
                break;
        }
    };

    const getClientsList = () => {
        apiRequest(APIs.GET_CLIENTS_LIST, {
            limit: pagination.pageSize,
            current: pagination.current,
            isClientSelected: false, //false means listing, true means go to view detail
            ...(Object.keys(filterParams).length > 0 && { ...filterParams }),
            ...(sorting.length > 0 && { order: sorting }),
        })
            .then((data: any) => {
                if (data && data.result && data.result.length > 0) {
                    let markupData = data.result.map((x: Client) => {
                        x.id = btoa(JSON.stringify({ clientId: x.clientId, regulatorId: x.regulatorId, crmServerId: x.crmServerId }));
                        return x;
                    });
                    setData(markupData);
                    setPagination((prev) => ({ ...prev, total: data.total }));
                } else {
                    setData([]);
                    setPagination((prev) => ({ ...prev, total: 0 }));
                }
            })
            .catch((error) => {
                ErrorCatchValidator(error, (err: any) => console.log(err));
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, { filterType: ["regulator", "crmserver"] })
            .then((data: any) => {
                setTableCols(getColumnsConfig(data.regulators, data.crmServers));
                //setRunRefetchDataList(true);
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => console.log(err));
            })
            .finally(() => {
                setIsLoading(false);
            });
        return () => {};
    }, []);
    useEffect(() => {
        if (runRefetchDataList) {
            setIsLoading(true);
            getClientsList();
            setRunRefetchDataList(false);
        }
        return () => {};
    }, [runRefetchDataList]);

    return (
        <>
            <CardBox title={"Client List"}>
                <FlexiDataTable
                    rowKeyProperty="id"
                    title=""
                    columns={tableCols}
                    options={options}
                    dataSource={data ?? []}
                    callback={componentCallback}
                    loading={isLoading}
                    pagination={pagination}
                    serverSide={true}
                />
            </CardBox>
        </>
    );
};

export default BatchClientListing;

import { BellOutlined, BulbOutlined, PoweroffOutlined, QuestionCircleOutlined, UserOutlined } from "@ant-design/icons";
import { Dropdown } from "antd";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../../GlobalProvider";
import { userLogout } from "../../services/api";
import { useAppDispatch } from "../../store/hook";
import { setIsWsConnected } from "../../store/reducers/system";

const SettingButton = () => {
    let navigate = useNavigate();
    const dispatch = useAppDispatch();
    const context = useContext(GlobalContext);

    const onSettingButtonClick = ({ key }: any) => {
        switch (key) {
            case "1":
                navigate("/myaccount");
                break;
            case "2":
                navigate("/notifications");
                break;
            case "3":
                navigate("/activitylogs");
                break;
            case "4":
                navigate("/system/usermanual");
                break;
            case "5":
                userLogout().then((res) => {
                    navigate("/login", { replace: true });
                    //context.stopSignalR();
                    dispatch(setIsWsConnected(false));
                });
                break;
            default:
                break;
        }
    };

    return (
        <div>
            <Dropdown.Button
                type="link"
                size="large"
                className="setting"
                overlayClassName="setting-overlay-panel"
                menu={{
                    onClick: onSettingButtonClick,
                    items: [
                        {
                            label: "My Profile",
                            key: "1",
                            icon: (
                                <div className="topnav-profile">
                                    <UserOutlined />
                                </div>
                            ),
                        },
                        {
                            label: "Notification Center",
                            key: "2",
                            icon: (
                                <div className="topnav-profile">
                                    <BellOutlined rotate={25} className="notif-icon" />
                                </div>
                            ),
                        },
                        {
                            label: "Activity Logs",
                            key: "3",
                            icon: (
                                <div className="topnav-profile">
                                    <BulbOutlined />
                                </div>
                            ),
                        },
                        {
                            label: "User Manual",
                            key: "4",
                            icon: (
                                <div className="topnav-profile">
                                    <QuestionCircleOutlined />
                                </div>
                            ),
                        },
                        {
                            label: "Logout",
                            key: "5",
                            icon: <PoweroffOutlined />,
                            danger: true,
                        },
                    ],
                }}
                placement="bottomLeft"
                icon={<UserOutlined />}
            />
        </div>
    );
};

export default SettingButton;

import { useEffect, useState } from "react";
import AlarmDashboardContentComponents from "./components/alarmDashboard/alarmDashboardContentComponent";
import AlarmDashboardFilterPanel, { AlarmDashboardFilterOption } from "./components/alarmDashboard/alarmDashboardFilterPanel";
import { APIs, apiRequest } from "../../services/apiConfig";
import { ALARM_DASHBOARD_ITEM_CALLBACK_KEY, ALARM_DASHBOARD_ITEM_KEY, SUCCESS_FAILED } from "../../constants";
import { DataTableColumnRender, DimensionLevelObjectConfig, ErrorCatchValidator, ErrorMessageHandler } from "../../utils/Common";
import { ClientProps, KeyValuePair, ProfileProps, StatusListResponseProps, User, componentCallbackProps } from "../../constants/type";
import moment from "moment-timezone";
import { hasAnyKey } from "../../utils/object";
import WorldMapData from "../../assets/data/worldmap.json";
import { SortList, ToObjectWithKey } from "../../utils/array";
import { getProfile } from "../../services/localStorage";
import { clone } from "lodash";

export interface AlarmDashboardProps {}

export interface AlarmDashboardFilterParamsProps {
    dateFrom?: string;
    dateTo?: string;
    clientApps?: string[];
    moduleCodes?: string[];
    breachSeverities?: number[];
}

const AlarmDashboard = (props: AlarmDashboardProps) => {
    const uPr: any = getProfile();
    const serverTime_UTCOffset: number | undefined = uPr?.hasOwnProperty("stom") ? uPr.stom : undefined;
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isMute, setIsMute] = useState<boolean>(false);
    const [configOpt, setConfigOpt] = useState<AlarmDashboardFilterOption[]>([]);
    const [filterParams, setFilterParams] = useState<AlarmDashboardFilterParamsProps>({});
    const [statusList, setStatusList] = useState<any>({});
    const [userOptions, setUserOptions] = useState<KeyValuePair[]>([]);
    const [lpMappingData, setLpMappingData] = useState<any>({
        dimension: {},
        metricName: {},
    });
    const [mappingData, setMappingData] = useState<any>({
        dimension: {},
        country: {},
        metricName: {},
        symbol: {},
        symbol_asset_type_id: {},
        server_id: {},
        brand_id: {},
        group: {},
    });
    const [refreshListState, setRefreshListState] = useState<number>(1);

    const componentCallback = (callbackKey: ALARM_DASHBOARD_ITEM_CALLBACK_KEY, values: componentCallbackProps) => {
        if (ALARM_DASHBOARD_ITEM_CALLBACK_KEY.FILTER_CHANGED === callbackKey) {
            switch (values.type) {
                case ALARM_DASHBOARD_ITEM_KEY.RESET_CALLBACK:
                    setFilterParams(values.values);
                    break;
                case ALARM_DASHBOARD_ITEM_KEY.DATE_MODE:
                    if (values.values === 1) {
                        //Today
                        let currentUtcDate = moment().utc();
                        const fromDate = `${currentUtcDate.format("YYYY-MM-DD")}T00:00:00Z`;
                        const toDate = `${currentUtcDate.add(1, "day").format("YYYY-MM-DD")}T00:00:00Z`;
                        const fromServerDateTime = DataTableColumnRender.DateTime_UTC_TO_ServerTime(fromDate, serverTime_UTCOffset);
                        const toServerDateTime = DataTableColumnRender.DateTime_UTC_TO_ServerTime(toDate, serverTime_UTCOffset);
                        setFilterParams(prev => ({
                            ...prev,
                            ...{
                                dateFrom: moment(fromServerDateTime).format("YYYY-MM-DDTHH:mm:ss"),
                                dateTo: moment(toServerDateTime).format("YYYY-MM-DDTHH:mm:ss"),
                            },
                        }));
                    } else if (values.values === 2) {
                        //Past 24 hours
                        let currentUtcDate = moment().utc();
                        const fromDate = moment(currentUtcDate).subtract(24, "hours").format("YYYY-MM-DDTHH:mm:ss") + "Z";
                        const toDate = currentUtcDate.format("YYYY-MM-DDTHH:mm:ss") + "Z";
                        const fromServerDateTime = DataTableColumnRender.DateTime_UTC_TO_ServerTime(fromDate, serverTime_UTCOffset);
                        const toServerDateTime = DataTableColumnRender.DateTime_UTC_TO_ServerTime(toDate, serverTime_UTCOffset);
                        setFilterParams(prev => ({
                            ...prev,
                            dateFrom: moment(fromServerDateTime).format("YYYY-MM-DDTHH:mm:ss"),
                            dateTo: moment(toServerDateTime).format("YYYY-MM-DDTHH:mm:ss"),
                        }));
                    }
                    break;
                case ALARM_DASHBOARD_ITEM_KEY.MODULES_FILTER:
                    let finalObj: any = { clientApps: [], moduleCodes: [] };
                    if (hasAnyKey(values.values)) {
                        Object.keys(values.values).forEach((i: string) => {
                            if (values.values[i] === true) {
                                let tmp = i.split("|");
                                finalObj[tmp[0]].push(tmp[1]);
                            }
                        });
                    }
                    setFilterParams(prev => ({ ...prev, ...finalObj }));
                    break;
                case ALARM_DASHBOARD_ITEM_KEY.SOUND_HANDLER:
                    setIsMute(!values.values);
                    break;
                default:
                    break;
            }
        } else if (ALARM_DASHBOARD_ITEM_CALLBACK_KEY.REFRESH_LIST === callbackKey) {
            setRefreshListState(prev => prev + 1);
        }
    };

    const getConfig = () => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, {
            filterType: ["mimmetric", "cleansymbol", "symbolassettype", "server", "brand", "user"],
        })
            .then(res => {
                setMappingData({
                    dimension: ToObjectWithKey(DimensionLevelObjectConfig("IntradayDimensionArr"), "value"),
                    metricName:
                        res["mimMetrics"] && res["mimMetrics"].length > 0
                            ? ToObjectWithKey(
                                  res.mimMetrics.map((x: any) => ({
                                      value: x["metricName"],
                                      text: x["metricDisplayName"],
                                      type: x["metricDataType"],
                                  })),
                                  "value"
                              )
                            : {},
                    country: ToObjectWithKey(
                        WorldMapData.features.map((x: any) => ({ text: x.properties.name, value: x.properties.iso_alpha_2_code })),
                        "value"
                    ),
                    symbol:
                        res["cleanSymbols"] && res["cleanSymbols"].length > 0
                            ? ToObjectWithKey(
                                  res.cleanSymbols.map((x: any) => ({ value: x["name"].toLowerCase(), text: x["name"] })),
                                  "value"
                              )
                            : {},
                    symbol_asset_type_id:
                        res["symbolAssetTypes"] && res["symbolAssetTypes"].length > 0
                            ? ToObjectWithKey(
                                  res.symbolAssetTypes.map((x: any) => ({ value: x["id"], text: x["name"] })),
                                  "value"
                              )
                            : {},
                    server_id:
                        res["servers"] && res["servers"].length > 0
                            ? ToObjectWithKey(
                                  res.servers.map((x: any) => ({ value: x["id"], text: x["server"] })),
                                  "value"
                              )
                            : {},
                    brand_id:
                        res["brands"] && res["brands"].length > 0
                            ? ToObjectWithKey(
                                  res.brands.map((x: any) => ({ value: x["id"], text: x["brand"] })),
                                  "value"
                              )
                            : {},
                });
            })
            .finally(() =>
                apiRequest(APIs.GET_USER_PROFILES, { includeSelf: true })
                    .then((res: User[]) => {
                        if (res && res.length > 0) {
                            let myProfile = getProfile(),
                                allOptions: KeyValuePair[] = SortList(
                                    res.filter((x: User) => x.id !== 0).map((x: User) => ({ text: x.email, value: x.id })),
                                    "text"
                                );
                            if (myProfile !== undefined) {
                                let fidx = allOptions.findIndex(x => x.value === (myProfile as ProfileProps).id);
                                if (fidx > -1) {
                                    let tmp = clone(allOptions[fidx]);
                                    allOptions.splice(fidx, 1);
                                    allOptions.unshift({ ...tmp, text: `${tmp.text} (Assign to Me)` });
                                }
                            }
                            setUserOptions(allOptions);
                        }
                    })
                    .finally(() =>
                        apiRequest(APIs.GET_ALARM_DASHBOARD_STATUS_LIST, {})
                            .then((res: StatusListResponseProps[]) => {
                                if (res && res.length > 0) {
                                    setStatusList(
                                        res.reduce((finalObj: any, i: StatusListResponseProps) => {
                                            finalObj[i.moduleCode] = i.status
                                                .filter(x => x.toRenderUIButton)
                                                .map(x => ({
                                                    text: x.statusDescription,
                                                    value: x.statusCode,
                                                    isConsiderCaseClosed: x.isConsiderCaseClosed,
                                                }));
                                            return finalObj;
                                        }, {})
                                    );
                                }
                            })
                            .finally(() =>
                                apiRequest(APIs.GET_ALARM_DASHBOARD_CONFIG, { isModulesEnabled: true })
                                    .then(res => {
                                        if (res && res.length > 0) {
                                            setConfigOpt([
                                                {
                                                    key: "clientApps",
                                                    text: "Application",
                                                    options: res.map((x: any) => ({ text: x.clientAppName, value: x.clientApp })),
                                                },
                                                {
                                                    key: "moduleCodes",
                                                    text: "Modules",
                                                    options: res.reduce((finalArr: KeyValuePair[], x: ClientProps) => {
                                                        if (x.clientAppModules.length > 0) {
                                                            x.clientAppModules.forEach(y => {
                                                                finalArr.push({ text: `(${x.clientApp}) ${y.moduleName}`, value: y.moduleCode });
                                                            });
                                                        }
                                                        return finalArr;
                                                    }, []),
                                                },
                                            ]);
                                            // Default values
                                            let currentUtcDate = moment().utc();
                                            setFilterParams(prev => ({
                                                ...prev,
                                                ...{
                                                    dateFrom: `${currentUtcDate.format("YYYY-MM-DD")}T00:00:00`,
                                                    dateTo: `${currentUtcDate.add(1, "day").format("YYYY-MM-DD")}T00:00:00`,
                                                    clientApps: res.map((x: any) => x.clientApp),
                                                    moduleCodes: res.reduce((finalArr: string[], x: ClientProps) => {
                                                        finalArr = [...finalArr, ...x.clientAppModules.map(x => x.moduleCode)];
                                                        return finalArr;
                                                    }, []),
                                                },
                                            }));
                                        }
                                    })
                                    .catch(error =>
                                        ErrorCatchValidator(error, (err: any) =>
                                            ErrorMessageHandler("alarm dashboard", SUCCESS_FAILED.FAILED_LOAD_DATA, err)
                                        )
                                    )
                                    .finally(() =>
                                        apiRequest(APIs.GET_LP_ALARM_CONFIG, {})
                                            .then((res: any) => {
                                                setLpMappingData({
                                                    dimension: ToObjectWithKey(DimensionLevelObjectConfig("LPDimensionArr"), "value"),
                                                    metricName:
                                                        res.length > 0
                                                            ? ToObjectWithKey(
                                                                  res.reduce((arr: KeyValuePair[], i: any) => {
                                                                      arr = arr.concat(
                                                                          i.dataSources.map((x: any) => ({
                                                                              text: x.propertyDisplayName,
                                                                              value: x.propertyName.toLowerCase(),
                                                                          }))
                                                                      );
                                                                      return arr;
                                                                  }, []),
                                                                  "value"
                                                              )
                                                            : {},
                                                });
                                            })
                                            .catch(error => ErrorCatchValidator(error, (err: any) => console.log(err)))
                                            .finally(() => setIsLoading(false))
                                    )
                            )
                    )
            );
    };

    useEffect(() => {
        getConfig();
    }, []);

    return (
        <div className="alarm-dashboard-main-container">
            <div className="top-header">
                <span className="title-label">Alarm Dashboard</span>
            </div>
            <div className="card-container shadow-light">
                <AlarmDashboardFilterPanel filterOptions={configOpt} filterParams={filterParams} isLoading={isLoading} callback={componentCallback} />
                <AlarmDashboardContentComponents
                    filterParams={filterParams}
                    isLoading={isLoading}
                    mappingData={mappingData}
                    statusObject={statusList}
                    userOptions={userOptions}
                    refreshState={refreshListState}
                    isMute={isMute}
                    lpMappingData={lpMappingData}
                />
            </div>
        </div>
    );
};

export default AlarmDashboard;

import React from "react";
import useRCSpreadReportStore from "../../../store/useRCSpreadReportStore";
import useRCSpreadGroups from "../../../hooks/useRCSpreadGroups";
import useRCSymbols from "../../../hooks/useRCSymbols";
import { Select, DatePicker, Space, Row, Col, Typography, Button, Alert } from "antd";
import type { SelectProps } from "antd";
import moment from "moment";
import BasicFilter from "../../../components/BasicFilter";
import CommonPaddingWrapper from "../CommonPaddingWrapper";
import Loader from "../../SystemMonitor/components/Loader";

const SpreadAnalysisFilters: React.FC = () => {
    // Implement your component logic here
    const { RangePicker } = DatePicker;
    const { Text } = Typography;

    // const { start, end, symbol, groups, setStart, setEnd, setGroups, setSymbol, updateSpreadReportQuery } = useRCSpreadReportStore();
    const start = useRCSpreadReportStore(state => state.start);
    const end = useRCSpreadReportStore(state => state.end);
    const symbol = useRCSpreadReportStore(state => state.symbol);
    const groups = useRCSpreadReportStore(state => state.groups);
    const setStart = useRCSpreadReportStore(state => state.setStart);
    const setEnd = useRCSpreadReportStore(state => state.setEnd);
    const setGroups = useRCSpreadReportStore(state => state.setGroups);
    const setSymbol = useRCSpreadReportStore(state => state.setSymbol);
    const updateSpreadReportQuery = useRCSpreadReportStore(state => state.updateSpreadReportQuery);

    const { rcSymbols, rcSymbolsError, refetchRcSymbols, isLoading: isLoadingRcSymbols, isFetching: isFetchingRcSymbols } = useRCSymbols();
    const {
        rcSpreadGroups,
        rcSpreadGroupsError,
        refetchRcSpreadGroups,
        isLoading: isLoadingRcSpreadGroups,
        isFetching: isFetchingRcSpreadGroups,
    } = useRCSpreadGroups();

    // console.log("spreadReportQuery", spreadReportQuery);
    // console.log("rcSpreadGroups", rcSpreadGroups);
    const optionsRcSymbols: SelectProps["options"] = rcSymbols?.map((each: any) => ({ label: each?.name, value: each?.name })) ?? [];
    const handleChangeSymbol = (value: string) => {
        setSymbol(value);
        // console.log(value);
    };
    const handleResetFilter = () => {
        setStart(new Date().toISOString().split("T")[0]);
        setEnd(new Date().toISOString().split("T")[0]);
        setGroups(1, []);
        setGroups(2, []);
        setGroups(4, []);
        setSymbol(null);
    };

    if ((isLoadingRcSymbols || isLoadingRcSpreadGroups) && !rcSymbolsError && !rcSpreadGroupsError) {
        return (
            <CommonPaddingWrapper>
                <Loader />
            </CommonPaddingWrapper>
        );
    }
    if (rcSymbolsError) {
        return (
            <CommonPaddingWrapper>
                <Alert
                    message={`Having some error while loading symbol. Error: ${rcSymbolsError}`}
                    type="warning"
                    action={
                        <Button size="small" type="text" onClick={() => refetchRcSymbols()} disabled={isLoadingRcSymbols}>
                            Retry
                        </Button>
                    }
                />
            </CommonPaddingWrapper>
        );
    }
    if (rcSpreadGroupsError) {
        return (
            <CommonPaddingWrapper>
                <Alert
                    message={`Having some error while loading spread groups. Error: ${rcSpreadGroupsError}`}
                    type="warning"
                    action={
                        <Button size="small" type="text" onClick={() => refetchRcSpreadGroups()} disabled={isLoadingRcSpreadGroups}>
                            Retry
                        </Button>
                    }
                />
            </CommonPaddingWrapper>
        );
    }

    return (
        <BasicFilter>
            <Row gutter={[8, 8]}>
                <Col span={8} xs={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                    <Text>Symbol *</Text>
                    <Select
                        allowClear
                        style={{ width: "100%" }}
                        placeholder="Select Symbol"
                        value={symbol}
                        onChange={handleChangeSymbol}
                        options={optionsRcSymbols}
                        // allow type to search
                        showSearch
                    />
                </Col>
                <Col span={16} xs={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                    <Text>Date Range *</Text>
                    <RangePicker
                        style={{ width: "100%" }}
                        onChange={(dates, dateStrings) => {
                            // console.log(dateStrings);
                            setStart(dateStrings[0]);
                            setEnd(dateStrings[1]);
                        }}
                        // disable selecting the day after today, and 20 days before today
                        disabledDate={current => {
                            return current && (current > moment().endOf("day") || current < moment().subtract(20, "days").startOf("day"));
                        }}
                        // disabledDate={(current) => current && current > moment().endOf("day")}

                        // read start and end from store
                        value={[moment(start), moment(end)]}
                    />
                </Col>
            </Row>
            <Row gutter={[8, 8]}>
                {Object.keys(rcSpreadGroups)?.map((each: any) => {
                    const options = rcSpreadGroups[each]?.map((each: any) => ({ label: each, value: each })) ?? [];
                    const groupNaming = {
                        1: "Liquidity Provider",
                        2: "Our Servers",
                        4: "Outsider Brokers",
                    };
                    const order = {
                        1: 3,
                        2: 1,
                        4: 2,
                    };
                    return (
                        <Col span={8} key={each} xs={{ span: 24 }} lg={{ span: 8 }} order={order[each as keyof typeof order]}>
                            <Text>{groupNaming[each as keyof typeof groupNaming]}</Text>
                            <Select
                                allowClear
                                mode="multiple"
                                placeholder={`Select ${groupNaming[each as keyof typeof groupNaming]}`}
                                value={groups[each] ?? []}
                                style={{ width: "100%" }}
                                onChange={value => {
                                    setGroups(each, value ?? []);
                                }}
                                options={options}
                            />
                        </Col>
                    );
                })}
            </Row>
            <Space style={{ marginTop: "1rem", display: "flex", flexWrap: "wrap", borderTop: "1px solid rgba(0, 0, 0, 0.06)", paddingTop: "1rem" }}>
                <Button type="default" onClick={() => handleResetFilter()} style={{ minWidth: 100 }}>
                    Reset
                </Button>
                <Button
                    type="primary"
                    onClick={() => {
                        updateSpreadReportQuery();
                    }}
                    disabled={!start || !end || !symbol || (groups[1]?.length <= 0 && groups[2]?.length <= 0 && groups[4]?.length <= 0)}
                    style={{ minWidth: 100 }}
                >
                    Generate Report
                </Button>
            </Space>
        </BasicFilter>
    );
};

export default SpreadAnalysisFilters;

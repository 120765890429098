export const getSupplementaryListDummyData = [
    {
        batchId: "20240828003",
        serverId: "AU2",
        oldServerId: 30,
        server: "AU2",
        login: 6179008,
        exchangeRate: 7.80157,
        adjustmentProduct: 59.78,
        currencyProduct: "USD",
        adjustmentClient: 466.37,
        currencyClient: "HKD",
        comment: "Cash Adj-Rollover-USNote10Y",
        status: 1,
        errorMsg: null,
        createDate: "2024-08-28T00:28:42",
        updateDate: "2024-08-28T00:56:00",
        logins: [],
    },
];

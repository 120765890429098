import { useEffect, useState } from "react";
import { Button, Descriptions, Form, Modal } from "antd";
import { useNavigate } from "react-router-dom";
import { User } from "../../constants/type";
import { userInfo } from "../../services/api";
import CardBox from "../../components/Common/CardBox";
import { DataTableColumnRender, ErrorCatchValidator, ErrorMessageHandler } from "../../utils/Common";
import { FormComponent } from "../../components/FormComponent";
import { REQUIRED_FIELD } from "../../constants/errorMessage";
import LoadingComponent from "../../components/Loading";
import { apiRequest, APIs } from "../../services/apiConfig";
import { ComponentType, SUCCESS_FAILED } from "../../constants";
import { WarningOutlined } from "@ant-design/icons";

function MyAccountPage() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [visible, setVisible] = useState<boolean>(false);
    const [data, setData] = useState<User>();
    const [resetPwdForm] = Form.useForm();

    const loggedInUser = userInfo();
    let navigate = useNavigate();

    useEffect(() => {
        // const id = loggedInUser?.uuid || "";

        setIsLoading(true);

        apiRequest(APIs.GET_USER_PROFILE_BY_ID, {})
            .then((res: any) => {
                setData(res as User);
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("my account data", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
            )
            .finally(() => setIsLoading(false));
        return () => {};
    }, []);

    const onResetPwd = (values: any) => {
        const userId = loggedInUser?.uuid || "";
        setIsLoading(true);
        apiRequest(APIs.UPDATE_PASSWORD, {
            uuid: userId,
            password: values.password,
        })
            .then((res) => {
                ErrorMessageHandler("User password", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                setVisible(false);
                resetPwdForm.resetFields();
            })
            .catch((error) => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("user password", SUCCESS_FAILED.FAILED_UPDATE_DATA, err)))
            .finally(() => {
                setIsLoading(false);
            });
    };

    return isLoading ? (
        <div className="loading-container">
            <LoadingComponent tip="Loading..." />
        </div>
    ) : (
        <>
            <CardBox
                title={"My Profile"}
                extra={
                    <Button
                        type="primary"
                        style={{
                            width: "auto",
                        }}
                        onClick={() => navigate("/myaccount/edit", { state: data })}
                    >
                        Update Profile
                    </Button>
                }
            >
                <div className="my-profile-container">
                    <Descriptions bordered column={1} labelStyle={{ width: "16.275vw" }}>
                        <Descriptions.Item label="Username">{data?.username ? data.username : "-"}</Descriptions.Item>
                        <Descriptions.Item label="Email">{data?.email ? data.email : "-"}</Descriptions.Item>
                        <Descriptions.Item label="First Name">{data?.firstName ? data.firstName : "-"}</Descriptions.Item>
                        <Descriptions.Item label="Last Name">{data?.lastName ? data.lastName : "-"}</Descriptions.Item>
                        <Descriptions.Item label="User Role">{data?.roles ? data.roles.split(",").join(", ") : "-"}</Descriptions.Item>
                        <Descriptions.Item label="User Team">{data?.team ? data.team : "-"}</Descriptions.Item>
                        <Descriptions.Item label="Last Logged In (Local)">
                            {data?.lastLoginUtc ? DataTableColumnRender.DateTime(data.lastLoginUtc) : "-"}
                        </Descriptions.Item>
                        <Descriptions.Item label="Created At (Local)">
                            {data?.createdDateUtc ? DataTableColumnRender.DateTime(data.createdDateUtc) : "-"}
                        </Descriptions.Item>
                        <Descriptions.Item label="Password">
                            <Button onClick={() => setVisible(true)}>Change Password</Button>
                        </Descriptions.Item>
                    </Descriptions>
                </div>
            </CardBox>
            <Modal
                open={visible}
                title="Change Password"
                onCancel={() => {
                    setVisible(false);
                    resetPwdForm.resetFields();
                }}
                onOk={() => {
                    resetPwdForm
                        .validateFields()
                        .then((values) => {
                            onResetPwd(values);
                            setVisible(false);
                        })
                        .catch((info) => {
                            console.log("Validate Failed:", info);
                        });
                }}
            >
                <Form form={resetPwdForm} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} layout="horizontal">
                    <div className="reset-password-pattern">
                        <WarningOutlined className="error" style={{ height: "20px" }} />
                        <span className="error">
                            Password must contain at least 8 characters including at least 1 uppercase letter, 1 lowercase letter and 1 number.
                        </span>
                    </div>
                    <FormComponent
                        label="New Password"
                        name={"password"}
                        extra={{
                            type: ComponentType.password,
                            value: "",
                            rules: [
                                {
                                    required: true,
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || value.length < 1) {
                                            return Promise.reject(new Error(REQUIRED_FIELD));
                                        } else if (!(/[a-z]+/.test(value) && /[A-Z]+/.test(value) && /[0-9]+/.test(value)) || value.length < 8) {
                                            return Promise.reject(new Error("Invalid password."));
                                        }

                                        return Promise.resolve();
                                    },
                                }),
                            ],
                        }}
                    />
                    <FormComponent
                        label="Confirm Password"
                        name={"confirmPassword"}
                        extra={{
                            type: ComponentType.password,
                            value: "",
                            rules: [
                                {
                                    required: true,
                                    message: REQUIRED_FIELD,
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue("password") === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error("Password is not matched, please try again"));
                                    },
                                }),
                            ],
                        }}
                    />
                </Form>
            </Modal>
        </>
    );
}

export default MyAccountPage;

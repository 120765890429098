import FlexiDataTable from "@/components/FlexiDataTable";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "@/constants";
import { FlexiDataColumnProps, FlexiDataTableCallbackProps, FlexiDataTableOptionsProps } from "@/constants/type";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { useMemo, useState } from "react";

type MTClientSecurityListRes = {
  serverCode: string;
  login: number;
  changeType: number;
  isDelete: number;
};
type MTClientSecurityListDataColumn = {
  rowKey: string;
  serverCode: string;
  login: number;
  changeType: string;
  isDelete: string;
};
const MTClientSecurityList = () => {
    const [data, setData] = useState<MTClientSecurityListDataColumn[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const changeTypeArray = [`Schedule change`, `Resign common accounts`, `Resign personal accounts`];
    const deleteTypeArray = [`Not resign yet`, `Resign`, `Disable`];

    const columns: FlexiDataColumnProps[] = useMemo(
        () => [
            DTColProps.Middle({
                title: "Server",
                dataIndex: "serverCode",
                key: "serverCode",
                sorter: (a: MTClientSecurityListDataColumn, b: MTClientSecurityListDataColumn) => a.serverCode.localeCompare(b.serverCode),
                //using this column to serve as a search all filter
                realTitle: "Search All",
                options: {
                  filter: {
                    type: ComponentType.text,
                    text: "Search All",
                    callback: (filterValue: string, rowData: MTClientSecurityListDataColumn) => {
                      return Object.values(rowData).some((value: any) => value?.toString().toLowerCase().includes(filterValue.toLowerCase()));
                    },
                  },
                },
            }),
            DTColProps.Middle({
                title: "Login",
                dataIndex: "login",
                key: "login",
                sorter: (a: MTClientSecurityListDataColumn, b: MTClientSecurityListDataColumn) => a.login - b.login,
            }),
            DTColProps.Middle({
                title: "Change Type",
                dataIndex: "changeType",
                key: "changeType",
            }),
            DTColProps.Middle({
                title: "Is Delete",
                dataIndex: "isDelete",
                key: "isDelete",
            }),
        ],
        []
    );

    const options: FlexiDataTableOptionsProps = {
        delete: true,
    };

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, data: MTClientSecurityListDataColumn) => {
        switch (type) {
          case CALLBACK_KEY.DO_DELETE:
            deleteLogin(data.serverCode, data.login);
            break
          default:
              break;
        }
    };

    useMemo(() => {
        fetchData();
    }, []);

    function fetchData() {
      setIsLoading(true);
      plainAxiosInstance
        .get(APIs.RC_MT_CLIENT_SECURITY.GET_ACCOUNTS)
        .then(res => {
          if (res.data) {
            const data: MTClientSecurityListDataColumn[]  = res.data.map((x: MTClientSecurityListRes)=>({
              ...x,
              rowKey: x.serverCode + x.login + x.changeType,
              changeType: changeTypeArray[x.changeType - 1],
              isDelete: deleteTypeArray[x.isDelete],
            }))
            setData(data);
          }
        })
        .catch((error: any) => {
          return ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("Initial List", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
        })
        .finally(() => {
          setIsLoading(false);
        });
    }

    function deleteLogin(serverCode: string, login: number) {
      setIsLoading(true);
      plainAxiosInstance
        .delete(`${APIs.RC_MT_CLIENT_SECURITY.DELETE_ACCOUNT}/${serverCode}/${login}`)
        .then((res: any) => {
          if (res.status === 200) {
            ErrorMessageHandler(`Login ${login}`, SUCCESS_FAILED.SUCCESS_DELETE_DATA);
            fetchData();
          } else {
            ErrorMessageHandler(`Login ${login}`, SUCCESS_FAILED.FAILED_DELETE_DATA);
          }
        })
        .catch((error: any) =>
            ErrorCatchValidator(error, (err: any) => ErrorMessageHandler(`Login ${login}`, SUCCESS_FAILED.FAILED_DELETE_DATA, err))
        )
        .finally(() => setIsLoading(false));
    }

    return (
      <div className="security-list-container">
        <FlexiDataTable
          rowKeyProperty={"rowKey" as keyof MTClientSecurityListDataColumn}
          title={""}
          columns={columns}
          dataSource={data}
          loading={isLoading}
          options={options}
          callback={componentCallback}
        />
      </div>
    );
};

export default MTClientSecurityList;

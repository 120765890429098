import { useMemo, useState } from "react";
import { Button, Divider, message, Select, Space, Tooltip, Typography } from "antd";
import FlexiDataTable from "@/components/FlexiDataTable";
import { DTColProps } from "@/utils/Common";
import {
    FlexiDataTableCallbackProps,
    FlexiDataTableOptionsProps,
    KeyValuePair,
    RollOverBatchRecordList,
    RollOverDeductionList,
} from "@/constants/type";
import { CaretRightOutlined, CheckCircleOutlined, CloseCircleOutlined, DownloadOutlined, InfoCircleOutlined, PlayCircleOutlined, SyncOutlined } from "@ant-design/icons";
import { CALLBACK_KEY, ComponentType } from "@/constants";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { APIs } from "@/services/apis";
import moment from "moment";

const { Title, Text } = Typography;

export interface RollOverDeductionTableProps {
    data: RollOverDeductionList[];
    tbTitle: string;
    loading: boolean;
    currentState: RollOverBatchRecordList;
    deductionExecutestatus: any;
    executeDeduction: () => void;
    getExecuteStatusLabel: (status: any) => string[];
    refreshDeductionStatus: () => void;
    finishDeductionStep: () => void;
    serverDetailList: KeyValuePair[];
    callDataBasedOnID: (secID: string, sec: number) => void;
}

const RollOverDeductionTable = (props: RollOverDeductionTableProps) => {
    const [deductedServerId, setDeductedServerId] = useState<string>("AU1");

    const columns = [
        DTColProps.XSmall({
            title: "Server",
            dataIndex: "server",
            key: "server",
            sorter: (a: any, b: any) => a.server - b.server,
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: props.serverDetailList,
                },
            },
        }),
        DTColProps.XSmall({
            title: "Login",
            dataIndex: "login",
            key: "login",
        }),
        DTColProps.XSmall({
            title: "Adjustment Product",
            dataIndex: "adjustmentProduct",
            key: "adjustmentProduct",
        }),
        DTColProps.XSmall({
            title: "Currency Product",
            dataIndex: "currencyProduct",
            key: "currencyProduct",
        }),
        DTColProps.XSmall({
            title: "Exchange Rate",
            dataIndex: "exchangeRate",
            key: "exchangeRate",
        }),
        DTColProps.XSmall({
            title: "Adjustment Client",
            dataIndex: "adjustmentClient",
            key: "adjustmentClient",
        }),
        DTColProps.XSmall({
            title: "Currency Client",
            dataIndex: "currencyClient",
            key: "currencyClient",
        }),
        DTColProps.XSmall({
            title: "Comment",
            dataIndex: "comment",
            key: "comment",
        }),
        DTColProps.XSmall({
            title: "Deducted",
            dataIndex: "status",
            key: "status",
            sorter: (a: any, b: any) => a.status - b.status,
            render: (text: number) => {
                return (
                    <span>
                        {text === 1 ? (
                            <CheckCircleOutlined style={{ color: "#0ab76e", fontSize: "1.375rem" }} />
                        ) : (
                            <CloseCircleOutlined style={{ color: "#f00f00", fontSize: "1.375rem" }} />
                        )}
                    </span>
                );
            },
        }, ["text-center"]),
        DTColProps.XSmall({
            title: "Error Message",
            dataIndex: "errorMsg",
            key: "errorMsg",
        }),
    ];

    const options: FlexiDataTableOptionsProps = useMemo(
        () => ({
            showHideColumns: false,
            enableFilter: true,
            extraButtons: () => (
                <Space>
                    <Button
                        key={`btn-exec-deduction-${Math.random()}`}
                        style={{ marginLeft: "0.651vw", width: "auto" }}
                        icon={<PlayCircleOutlined />}
                        disabled={props.currentState.currentStep != 3 || props.loading || props.deductionExecutestatus.status === 1}
                        onClick={event => {
                            event.preventDefault();
                            event.stopPropagation();
                            props.executeDeduction();
                        }}
                    >
                        Click execute deduction
                    </Button>
                    <Tooltip title="Execute deduction" placement="topLeft">
                        <InfoCircleOutlined style={{ fontSize: "1.125rem", cursor: "pointer", marginLeft: "0.5vw" }} />
                    </Tooltip>
                    <Button
                        key={`btn-finish-deduction-step-${Math.random()}`}
                        type="primary"
                        icon={<CheckCircleOutlined />}
                        onClick={props.finishDeductionStep}
                        loading={props.loading}
                        disabled={props.currentState.currentStep != 3 || props.loading}
                    >
                        Finish deduction step
                    </Button>
                </Space>
            ),
        }),
        [props.data]
    );
    const onDownloadNotYetSupplementedCSV = () => {
        try {
            plainAxiosInstance
                .get(
                    `${APIs.RISK_TOOL.GET_ROLLOVER_DOWNLOAD_SUPPLEMENTARY_DEDUCTION}?batchId=${props.currentState.id}&serverId=${deductedServerId}&type=2`,
                    {
                        headers: {
                            Accept: "application/octet-stream,text/csv, */*",
                        },
                        responseType: "blob",
                    }
                )
                .then(response => {
                    const contentType = response.headers["content-type"];
                    const contentDisposition = response.headers["content-disposition"] || response.headers["Content-Disposition"];

                    if (
                        contentType === "application/octet-stream" ||
                        contentType === "text/csv" ||
                        contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    ) {
                        // Extract filename from content-disposition if available
                        let fileName = `DownloadSupplementaryDeduction_${moment().format("YYYYMMDDHHmmss")}.csv`;
                        if (contentDisposition) {
                            const fileNameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
                            if (fileNameMatch && fileNameMatch[1]) {
                                fileName = fileNameMatch[1];
                            }
                        }

                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", fileName);
                        document.body.appendChild(link);
                        link.click();
                        // Clean up
                        window.URL.revokeObjectURL(url);
                    } else {
                        message.error(`Received non-file response. Error: ${response}`, 3);
                    }
                })
                .catch((error: any) => message.error(`Error occurred during download: "${error.message}"`, 3));
        } catch (e: any) {
            message.error(`Error occured during download: "${e.message}"`, 3);
        }
    };

    const componentCallback: FlexiDataTableCallbackProps = (type: CALLBACK_KEY, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                props.callDataBasedOnID(`${FormData["server"]}`, 3);
                break;
            default:
                break;
        }
    };

    return (
        <>
            <br />
            <Divider style={{ borderColor: "#FFA500" }}>{props.tbTitle}</Divider>
            <div className="execute-status">
                <Title
                    level={5}
                    type={
                        props.deductionExecutestatus.status == -1
                            ? "secondary"
                            : props.deductionExecutestatus.status == 1
                            ? "warning"
                            : props.deductionExecutestatus.status == 2
                            ? "success"
                            : props.deductionExecutestatus.status == 3
                            ? "danger"
                            : undefined
                    }
                >
                    Execute status: {props.getExecuteStatusLabel(props.deductionExecutestatus.status)}
                </Title>
                <Text>
                    {props.deductionExecutestatus.successCount} / {props.deductionExecutestatus.allCount}
                </Text>
            </div>
            <FlexiDataTable
                rowKeyProperty="login"
                title={false}
                columns={columns}
                options={options}
                dataSource={props.data}
                loading={props.loading}
                callback={componentCallback}
                pagination={{ defaultPageSize: 10 }}
                scroll={{ x: true }}
            />
            <Divider />
            <Space>
                <Select
                    style={{ width: 120 }}
                    value={deductedServerId}
                    options={props.serverDetailList.filter(server => server.value !== "")}
                    optionFilterProp="label"
                    onChange={setDeductedServerId}
                ></Select>
                <Button type="primary" icon={<DownloadOutlined />} onClick={onDownloadNotYetSupplementedCSV}>
                    Download not yet supplemented CSV
                </Button>
                <CaretRightOutlined />
                <Button type="primary" icon={<SyncOutlined />} onClick={props.refreshDeductionStatus}>
                    Refresh supplemented status
                </Button>
            </Space>
        </>
    );
};
export default RollOverDeductionTable;

import React from "react";
import DividendRecordTable from "./DividendRecordTable";
import CardBox from "@/components/Common/CardBox";
const DividendRecord = () => {
    return (
        <div style={{ margin: "1rem 0 3rem" }}>
            <CardBox title={"Dividend Record"}>
                <DividendRecordTable />
            </CardBox>
        </div>
    );
};

export default DividendRecord;

import { CheckOutlined, ClearOutlined, FilterOutlined } from "@ant-design/icons";
import { Button, Form, Popover, Tag } from "antd";
import { FormInstance } from "antd/es/form";
import { useMemo, useState } from "react";
import { FormComponent } from "../../../../../components/FormComponent";
import { ComponentType } from "../../../../../constants";
import { DefaultIfEmpty } from "../../../../../utils/object";

export interface FilterComponentProps {
    form: FormInstance;
    cleanSymbols: any[];
    symbolAssetType: any[];
    sourceType: any[];
    onFilterSubmit: Function;
}

const FilterComponent = (props: FilterComponentProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [filterParams, setFilterParams] = useState<any>({ cleanSymbols: [], symbolAssetTypeIds: [], sourceTypeIds: [] });

    const selectionObj = useMemo(
        () => ({
            symbolAssetObj: props.symbolAssetType.reduce((finalObj: any, item: any) => {
                finalObj[item.value] = item.text;
                return finalObj;
            }, {}),
            sourceTypeObj: props.sourceType.reduce((finalObj: any, item: any) => {
                finalObj[item.value] = item.text;
                return finalObj;
            }, {}),
        }),
        [props.symbolAssetType, props.sourceType]
    );

    return (
        <div className="filter-component-panel">
            <div className="left">
                <Popover
                    overlayClassName="filter-component-popover-css"
                    open={isOpen}
                    content={
                        <>
                            <Form form={props.form} layout="vertical" labelAlign="left">
                                <FormComponent
                                    label="Source Type"
                                    name="sourceTypeIds"
                                    extra={{
                                        type: ComponentType.dropdown,
                                        value: props.sourceType,
                                        inputProps: {
                                            mode: "multiple",
                                            style: {
                                                width: "100%",
                                            },
                                        },
                                    }}
                                />
                                <FormComponent
                                    label="Clean Symbol"
                                    name="cleanSymbols"
                                    extra={{
                                        type: ComponentType.dropdown,
                                        value: props.cleanSymbols,
                                        inputProps: {
                                            mode: "multiple",
                                            style: {
                                                width: "100%",
                                            },
                                        },
                                    }}
                                />
                                <FormComponent
                                    label="Symbol Asset Type"
                                    name="symbolAssetTypeIds"
                                    extra={{
                                        type: ComponentType.dropdown,
                                        value: props.symbolAssetType,
                                        inputProps: {
                                            mode: "multiple",
                                            style: {
                                                width: "100%",
                                            },
                                        },
                                    }}
                                />
                            </Form>
                        </>
                    }
                    title={
                        <>
                            <div className="filter-header-panel">
                                <div className="text-panel">Filters</div>
                                <div className="buttons">
                                    <Button
                                        type="text"
                                        icon={<ClearOutlined />}
                                        className="reset-style shadow-1"
                                        onClick={() => {
                                            props.form.setFieldsValue({ cleanSymbols: [], symbolAssetTypeIds: [], sourceTypeIds: [] });
                                        }}
                                    />
                                    <Button
                                        type="text"
                                        icon={<CheckOutlined />}
                                        className="save-style shadow-1"
                                        onClick={() => {
                                            props.form
                                                .validateFields()
                                                .then((values: any) => {
                                                    setFilterParams(values);
                                                    props.onFilterSubmit && props.onFilterSubmit(values);
                                                })
                                                .catch(err => {})
                                                .finally(() => setIsOpen(false));
                                        }}
                                    />
                                </div>
                            </div>
                        </>
                    }
                    trigger="click"
                >
                    <FilterOutlined style={{ fontSize: "1.125rem" }} onClick={() => setIsOpen(true)} />
                </Popover>
            </div>
            <div className="right">
                {filterParams.sourceTypeIds && filterParams.sourceTypeIds.length > 0 && (
                    <div className="filter-selection-panel">
                        <span>Source Type:</span>
                        {filterParams.sourceTypeIds.map((x: string, idx: number) => (
                            <Tag key={`sim-fpd-c-${idx}`} style={{ padding: "0 0.5vw 0 0.5vw", borderRadius: "3px" }}>
                                {DefaultIfEmpty(selectionObj.sourceTypeObj, x, x)}
                            </Tag>
                        ))}
                    </div>
                )}
                {filterParams.cleanSymbols && filterParams.cleanSymbols.length > 0 && (
                    <div className="filter-selection-panel">
                        <span>Clean Symbols:</span>
                        {filterParams.cleanSymbols.map((x: string, idx: number) => (
                            <Tag key={`sim-fpd-c-${idx}`} style={{ padding: "0 0.5vw 0 0.5vw", borderRadius: "3px" }}>
                                {x}
                            </Tag>
                        ))}
                    </div>
                )}
                {filterParams.symbolAssetTypeIds && filterParams.symbolAssetTypeIds.length > 0 && (
                    <div className="filter-selection-panel">
                        <span>Symbol Asset Type:</span>
                        {filterParams.symbolAssetTypeIds.map((x: number, idx: number) => (
                            <Tag key={`sim-fpd-at-${idx}`} style={{ padding: "0 0.5vw 0 0.5vw", borderRadius: "3px" }}>
                                {DefaultIfEmpty(selectionObj.symbolAssetObj, x, x)}
                            </Tag>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default FilterComponent;

import { HomeOutlined } from "@ant-design/icons";
import { Breadcrumb, Col, Row, message } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import CardBox from "../../../components/Common/CardBox";
import { Client, MetricGroup } from "../../../constants/type";
import { apiRequest, APIs } from "../../../services/apiConfig";
import ClientAccountCommentsHistory from "./clientAccountCommentsHistory";
import ClientAccounts from "./clientAccounts";
import ClientCommentsHistory from "./clientCommentsHistory";
import ClientAccountSummaryPanel from "./clientAccountSummaryPanel";
import ClientPNLSymbolSummaryChart from "./clientPNLSymbolSummary";
import ClientBalanceEquityChart from "./clientBalanceEquity";
import ClientDailyPnlSummaryChart from "./clientDailyPnlSummary";
import ClientPNLSummaryChart from "./clientPNLSummaryChart";
import { enabledCheckingByLocation } from "../AccountProfiles/viewProfile";
import { getSource } from "../../../services/localStorage";
import { ErrorCatchValidator } from "../../../utils/Common";
import { isEmptyOrNull } from "../../../utils/string";

const getParams = (profileId: string) => {
    const params = new URLSearchParams(profileId);
    const entries = Object.fromEntries(params.entries());

    return {
        clientId: Number(entries.clientId),
        regulatorId: Number(entries.regulatorId),
        crmServerId: Number(entries.crmServerId),
    };
};

const ClientOverview = () => {
    let { id } = useParams();
    let navigate = useNavigate();
    let location = useLocation();
    // const paramsInfo = JSON.parse(atob(id || ""));
    const profileParams = location.search.includes("?") ? location.search : id || "";

    const paramsInfo = getParams(profileParams);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [clientProfile, setClientProfile] = useState<Client>({
        accountCount: 0,
        clientId: 0,
        clientType: "",
        crmServer: "",
        crmServerId: 0,
        name: "",
        regulator: "",
        regulatorId: 0,
        regulatorName: "",
    });
    const [metricCategory, setMetricCategory] = useState<MetricGroup[]>([]);
    const [, setSymbols] = useState<string[]>([]);

    // const [showTags, setShowTags] = useState<boolean>(true);
    // const [labelsForm] = Form.useForm();
    // const [currentState, setCurrentState] = useState<any>(clientProfile || {});
    // const [labels, setLabels] = useState<string[]>([]);
    const getClientsList = () => {
        apiRequest(APIs.GET_CLIENTS_LIST, {
            isClientSelected: true, //false means listing, true means go to view detail
            clientId: paramsInfo.clientId,
            regulatorId: paramsInfo.regulatorId,
            crmServerId: paramsInfo.crmServerId,
        })
            .then((data: any) => {
                if (data === null) {
                    message.error("Client does not exist");
                } 
                if (!isEmptyOrNull(data)) {
                    setClientProfile(data);
                }
            })
            .catch((error: any) => {
                ErrorCatchValidator(error, (err: any) => console.log(err));
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const setSymbolLink = (symbolList: string[]) => {
        symbolList.sort((a: string, b: string) => a.toLowerCase().localeCompare(b.toLowerCase()));
        setSymbols(symbolList);
    };

    useEffect(() => {
        getClientsList();
        return () => {};
    }, []);

    useEffect(() => {
        setIsLoading(true);
        initLoad();
        return () => {};
    }, [id]);

    const initLoad = () => {
        apiRequest(APIs.GET_FILTER_CONFIG_LIST, { filterType: ["metriccategory"] })
            .then((data: any) => {
                setMetricCategory(data.metricCategories);
            })
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => {}));
    };

    // const showClickableTags = () => {
    //     console.log("mouse left select comp, call api to update labels, then call getClientsList to get updated labels");
    //     setShowTags(true);
    // };

    /**
     * 28/04/2023 - Xin
     * refer to the structure in accountOverview.tsx
     * but don't have to change the contents of this function
     */
    // const commentedOutForNow = () => {
    //     return (
    //         <Row>
    //             <Col span={6} className="profile-title">
    //                 Remark(s)
    //             </Col>
    //             <Col span={17} style={{ padding: "0.4rem 0" }}>
    //                 {showTags ? (
    //                     <div className={`free-text-container ${currentState.labels?.length === 0 ? "empty-list" : ""}`} onClick={() => setShowTags(false)}>
    //                         <div className="tag-container">
    //                             {currentState.labels && currentState.labels?.length > 0 &&
    //                                 currentState.labels?.map((x: any) => {
    //                                     return (
    //                                         <Tag key={x}>
    //                                             {/* <Typography.Link href={`/link/${x}`} target="_blank">
    //                                                         {x}
    //                                                     </Typography.Link> */}
    //                                             {x}
    //                                         </Tag>
    //                                     )
    //                                 })
    //                             }
    //                             {currentState.labels && currentState.labels?.length === 0 && <p>None</p>}
    //                         </div>
    //                     </div>
    //                 ) : (
    //                     <Form
    //                         form={labelsForm}
    //                         onValuesChange={(values: any) => setLabels(values)}
    //                         initialValues={currentState || {}}
    //                     >
    //                         <Row>
    //                             <Col span={24}>
    //                                 <FormComponent
    //                                     label=""
    //                                     name={"labels"}
    //                                     extra={{
    //                                         type: ComponentType.dropdown,
    //                                         value: "",
    //                                         inputProps: {
    //                                             mode: "tags",
    //                                             onBlur: showClickableTags,
    //                                             autoFocus: true,
    //                                         }
    //                                     }}
    //                                 />
    //                             </Col>
    //                         </Row>
    //                     </Form>
    //                 )}
    //             </Col>
    //         </Row>
    //     );
    // }

    return (
        <>
            <div className="breadcrumb-panel">
                <Breadcrumb>
                    <Breadcrumb.Item href="#" onClick={() => navigate("/analysis/clientlist")}>
                        <HomeOutlined />
                        <span>Client List</span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Client Profile</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className="client-profile-container">
                <div className="left-panel">
                    <CardBox title={"Client Profile"} small>
                        <Row>
                            <Col span={6} className="profile-title">
                                Client ID
                            </Col>
                            <Col span={17} className="profile-desc">
                                {clientProfile.clientId}
                            </Col>
                        </Row>
                        <Row>
                            <Col span={6} className="profile-title">
                                Client Name
                            </Col>
                            <Col span={17} className="profile-desc">
                                {clientProfile.name}
                            </Col>
                        </Row>
                        <Row>
                            <Col span={6} className="profile-title">
                                Client Type
                            </Col>
                            <Col span={17} className="profile-desc">
                                {clientProfile.clientType}
                            </Col>
                        </Row>
                        <Row>
                            <Col span={6} className="profile-title">
                                Source
                            </Col>
                            <Col span={17} className="profile-desc">
                                {clientProfile.crmServer}
                            </Col>
                        </Row>
                        <Row>
                            <Col span={6} className="profile-title">
                                Regulator
                            </Col>
                            <Col span={17} className="profile-desc">
                                {clientProfile.regulatorName}
                            </Col>
                        </Row>
                        {/**
                         * 28/04/2023 - Xin
                         * refer to the structure in accountOverview.tsx
                         */}
                        {/* {commentedOutForNow()} */}
                        {/**
                         *  01/03/2024 - Xin
                         *  temporarily hiding for now
                         *  - client account summary panel
                         *  - client pnl symbol summary chart
                         *  - client pnl summary charrt
                         *  - client daily pnl summary chart
                         *  - client balance and equity chart
                         */}
                        {/* <ClientAccountSummaryPanel
                            clientId={paramsInfo.clientId}
                            regulatorId={paramsInfo.regulatorId}
                            crmServerId={paramsInfo.crmServerId}
                            metricCategories={metricCategory}
                        /> */}
                        <ClientCommentsHistory
                            clientId={paramsInfo.clientId}
                            regulatorId={paramsInfo.regulatorId}
                            crmServerId={paramsInfo.crmServerId}
                            disableUpdate={!enabledCheckingByLocation(getSource(location))}
                        />
                        <ClientAccountCommentsHistory
                            clientId={paramsInfo.clientId}
                            regulatorId={paramsInfo.regulatorId}
                            crmServerId={paramsInfo.crmServerId}
                        />
                    </CardBox>
                </div>
                <div className="right-panel">
                    <ClientAccounts
                        client360Id={id || ""}
                        clientId={paramsInfo.clientId}
                        regulatorId={paramsInfo.regulatorId}
                        crmServerId={paramsInfo.crmServerId}
                    />
                    {/* <div className="padding-10px">
                        <ClientPNLSymbolSummaryChart
                            clientId={paramsInfo.clientId}
                            regulatorId={paramsInfo.regulatorId}
                            crmServerId={paramsInfo.crmServerId}
                            setSymbolCallback={setSymbolLink}
                        />
                    </div> */}
                    {/* <div className="padding-10px">
                        <ClientPNLSummaryChart
                            clientId={paramsInfo.clientId}
                            regulatorId={paramsInfo.regulatorId}
                            crmServerId={paramsInfo.crmServerId}
                        />
                    </div> */}
                    {/* <div className="padding-10px">
                        <ClientDailyPnlSummaryChart
                            clientId={paramsInfo.clientId}
                            regulatorId={paramsInfo.regulatorId}
                            crmServerId={paramsInfo.crmServerId}
                        />
                    </div> */}
                    {/* <div className="padding-10px">
                        <ClientBalanceEquityChart
                            clientId={paramsInfo.clientId}
                            regulatorId={paramsInfo.regulatorId}
                            crmServerId={paramsInfo.crmServerId}
                        />
                    </div> */}
                </div>
            </div>
        </>
    );
};

export default ClientOverview;

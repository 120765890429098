import { Tabs } from "antd";
import SpreadAlarmSettingPage from "./SpreadAlarmSetting";
import PriceAlarmColorSettingPage from "./PriceAlarmColorSetting";

export interface PriceSettingPageProps {}

const PriceSettingPage = (props: PriceSettingPageProps) => {
    const tabs: any[] = [
        {
            label: "Spread Alarm Setting",
            key: "1",
            children: <SpreadAlarmSettingPage />,
        },
        {
            label: "Price Alarm Color Setting",
            key: "2",
            children: <PriceAlarmColorSettingPage />,
        },
    ];

    return (
        <div className="price-settings-page-container">
            <Tabs destroyInactiveTabPane type="card" defaultActiveKey="2" items={tabs} tabBarStyle={{ marginBottom: "0" }} />
        </div>
    );
};

export default PriceSettingPage;
